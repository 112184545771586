import { Box, Typography } from "@mui/material";
import { ObjectFieldTemplateProps } from "@rjsf/utils";
import React from "react";
import Markdown from "markdown-to-jsx";

export const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box>
        <Typography variant="preTitle" color={"common.black"}>
          {props.title}
        </Typography>
      </Box>

      {props.description &&
        (props.uiSchema?.["ui:enableMarkdownInDescription"] && props.description ? (
          <Markdown
            options={{
              wrapper: (props) => <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }} {...props} />,
              disableParsingRawHTML: true,
              overrides: {
                h1: (props) => <Typography variant="preTitle" sx={{ textWrap: "wrap", width: "100%" }} {...props} />,
                p: (props) => <Typography variant="body" sx={{ textWrap: "wrap", width: "100%" }} {...props} />,
              },
            }}
          >
            {props.description ?? ""}
          </Markdown>
        ) : (
          <Typography variant="preTitle" sx={{ textWrap: "wrap", width: "100%" }}>
            {props.description}
          </Typography>
        ))}

      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, mt: 1 }}>
        {props.properties.map((element) => (
          <Box key={element.name}>{element.content}</Box>
        ))}
      </Box>
    </Box>
  );
};
