import { Box, SxProps } from "@mui/material";
import { Header, HeaderVariant } from "@/components/PageLayout/Header";
import React from "react";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";

interface PageLayoutProps {
  children?: React.ReactNode;
  contentSx?: SxProps;
  sx?: SxProps;
  headerProps?: {
    variant?: HeaderVariant;
    extraContent?: React.ReactNode;
  };
}
export const PageLayout = ({ children, headerProps = {}, contentSx, sx }: PageLayoutProps) => {
  const { variant: headerVariant = HeaderVariant.GO_BACK, extraContent: headerExtraContent } = headerProps;

  if (outsideOfficeClient()) {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: `min-content ${headerExtraContent ? "min-content" : ""} 1fr`,
          height: "100%",
          ...sx,
        }}
      >
        <Header variant={headerVariant} />
        {headerExtraContent}
        <Box
          className="page-layout-container"
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: "common.dorian",
            paddingY: 6,
            paddingX: 2,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              marginX: "auto",
              width: "clamp(612px, 50%, 100%)",
              maxWidth: "100%",
              ...contentSx,
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    );
  }

  return <>{children}</>;
};
