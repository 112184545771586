import { buildSkillStep, createEditorContentField, createUploadFileField } from "@/core/skillSteps/builder";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";

export const summarizeDocumentSkillStep1 = buildSkillStep({
  title: "Resumir documento processual",
  description: outsideOfficeClient()
    ? "Resuma peças e decisões judiciais."
    : "Resuma peças e decisões judiciais. Ao finalizar o resumo, você receberá uma mensagem com o botão para abri-lo como um novo documento.",
  fields: [
    {
      name: "procedural_document",
      type: "string",
      options: [
        {
          label: "Usar as informações descritas no corpo do documento aberto",
          value: "use_editor_content",
        },
        {
          label: "Upload de arquivo (DOCX ou PDF)",
          value: "upload_file",
        },
      ],
      initialValue: "use_editor_content",
      ui: {
        label: "Escolha de onde os dados serão extraídos:",
        widget: "radio",
      },
    },
    createEditorContentField({
      name: "procedural_document_editor_content",
      overrideField: "procedural_document",
      condition: {
        field: "procedural_document",
        value: "use_editor_content",
      },
    }),
    createUploadFileField({
      name: "procedural_document_file",
      overrideField: "procedural_document",
      condition: {
        field: "procedural_document",
        value: "upload_file",
      },
    }),
  ],
  submitButtonText: "Resumir Documento",
});
