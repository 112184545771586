import { Box, MenuItem as MuiMenuItem, Typography } from "@mui/material";
import { ArrowOutwardOutlined, ReportProblemOutlined } from "@mui/icons-material";

import React from "react";
import { useOnboarding } from "@/hooks/useOnboarding";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

interface CompleteProfileNotificationProps {
  onClose: () => void;
}

export const CompleteProfileNotification = ({ onClose }: CompleteProfileNotificationProps) => {
  const { openOnboarding } = useOnboarding();
  const { onboardingModalEnabled } = useFeatureFlags();

  const handleClick = () => {
    onClose();
    openOnboarding({});
  };

  if (!onboardingModalEnabled) return null;

  return (
    <div>
      <MuiMenuItem
        sx={{
          flexDirection: "column",
          p: "16px",
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          borderBottomColor: "common.lightShade",
          "&:last-child": { borderBottom: "none" },
          ":hover": {
            backgroundColor: "common.dorianHover",
          },
          "&:hover span": {
            fontWeight: "700",
            textDecorationLine: "underline",
          },
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <ReportProblemOutlined
            sx={{
              margin: "auto",
              color: "red",
              mb: "4px",
            }}
          />
          <ArrowOutwardOutlined
            sx={{
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
          />
        </Box>

        <Typography
          variant="body1"
          sx={{ fontWeight: "700", color: "common.black", textWrap: "wrap", textAlign: "center" }}
          component="span"
        >
          Seu perfil está incompleto
        </Typography>
        <Typography variant="body2" component="span">
          Clique e complete seu perfil.
        </Typography>
      </MuiMenuItem>
    </div>
  );
};
