export const useClarity = () => {
  const addUserId = (userId?: string) => {
    if (window.clarity && userId) {
      window.clarity("identify", userId);
    }
  };

  return {
    addUserId,
  };
};
