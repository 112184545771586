import { Form, MultiTypeInput, Text, TextInput, Title } from "@/components/FormComponents";
import { ActionId, useMessagesContext } from "@/contexts/MessagesContext";
import { UserInput } from "@/contexts/WebSocketContext";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import React, { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

enum Step {
  REFERENCE_PIECE_COMPLAINT,
  REFERENCE_PIECE_ANSWER,
  CLIENT_NAME,
  WITNESS,
  CASE_BREAKDOWN,
}

export const HearingsScript = () => {
  const [referencePieceComplaint, setReferencePieceComplaint] = useState<UserInput>();
  const [referencePieceAnswer, setReferencePieceAnswer] = useState<UserInput>();
  const [clientName, setClientName] = useState("");
  const [witness, setWitness] = useState("");
  const [caseBreakdown, setCaseBreakdown] = useState("");

  const { goToChat, startSkillProcess } = useMessagesContext();
  const [currentStep, setCurrentStep] = useState(0);
  const steps = useMemo(
    () => [
      Step.REFERENCE_PIECE_COMPLAINT,
      Step.REFERENCE_PIECE_ANSWER,
      Step.CLIENT_NAME,
      Step.WITNESS,
      Step.CASE_BREAKDOWN,
    ],
    []
  );

  const handleGoBack = () => {
    if (currentStep === 0) {
      goToChat();
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const canProceed = () => {
    switch (steps[currentStep]) {
      case Step.REFERENCE_PIECE_COMPLAINT:
        return !!referencePieceComplaint;
      case Step.REFERENCE_PIECE_ANSWER:
        return !!referencePieceAnswer;
      case Step.CLIENT_NAME:
        return !!clientName;
      case Step.WITNESS:
        return !!witness;
      case Step.CASE_BREAKDOWN:
        return !!caseBreakdown;
    }
  };

  const onSubmitHandler = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (!referencePieceComplaint || !referencePieceAnswer) return;

      startSkillProcess({
        skillId: ActionId.HEARINGS_SCRIPT,
        requestId: uuid(),
        payload: {
          reference_piece_complaint: referencePieceComplaint,
          reference_piece_answer: referencePieceAnswer,
          client: clientName,
          witness,
          case_breakdown: caseBreakdown,
        },
        messageToSave: "Elaborar roteiro para audiência",
      });

      goToChat();
    }
  };

  const title = `Roteiro para audiências judiciais - Passo ${currentStep + 1}`;

  return (
    <InternalPageStructure
      goBack={handleGoBack}
      onSubmit={onSubmitHandler}
      goBackButtonText={currentStep === 0 ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep === steps.length - 1 ? "Elaborar roteiro" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={steps.length}
      currentStep={currentStep}
    >
      <Form show={steps[currentStep] === Step.REFERENCE_PIECE_COMPLAINT}>
        <Title>{title}</Title>
        <Text>
          O Assistente Lexter pode te ajudar a elaborar questões para preparar seu cliente ou suas testemunhas durante a
          audiência judicial, baseando-se no contexto do caso.
        </Text>
        <MultiTypeInput
          label="Enviar petição inicial do caso:"
          onChange={setReferencePieceComplaint}
          inputTypes={["FILE"]}
        />
      </Form>

      <Form show={steps[currentStep] === Step.REFERENCE_PIECE_ANSWER}>
        <Title>{title}</Title>
        <MultiTypeInput label="Enviar contestação do caso:" onChange={setReferencePieceAnswer} inputTypes={["FILE"]} />
      </Form>

      <Form show={steps[currentStep] === Step.CLIENT_NAME}>
        <Title>{title}</Title>
        <TextInput
          id="input_client_name"
          label="Qual é o Nome do cliente?"
          placeholder="Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas."
          value={clientName}
          onChange={setClientName}
        />
      </Form>

      <Form show={steps[currentStep] === Step.WITNESS}>
        <Title>{title}</Title>
        <TextInput
          id="input_witness"
          label="Insira o nome das testemunhas e/ou partes a serem ouvidas:"
          placeholder="Exemplo: João da Silva, Maria Souza, Daniel Santos"
          value={witness}
          onChange={setWitness}
        />
      </Form>

      <Form show={steps[currentStep] === Step.CASE_BREAKDOWN}>
        <Title>{title}</Title>
        <TextInput
          id="input_case_breakdown"
          label="Informações extras (indicação dos elementos de relevância das testemunhas para o caso):"
          placeholder="Exemplo: João é testemunha ocular, tendo presenciado todos os acontecimentos; Maria é vizinha de Carlos e conhece toda a rotina do autor e de sua família; Daniel é colega de trabalho de Carlos e conhece o temperamento dele."
          value={caseBreakdown}
          onChange={setCaseBreakdown}
        />
      </Form>
    </InternalPageStructure>
  );
};
