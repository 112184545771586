import { Form, TextInput, Title } from "@/components/FormComponents";
import { ActionId, useMessagesContext } from "@/contexts/MessagesContext";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import React, { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

enum Step {
  DETAILS,
  CLIENT_NAME,
}

export const FeeAgreement = () => {
  const [details, setDetails] = useState<string>("");
  const [clientName, setClientName] = useState<string>("");

  const { goToChat, startSkillProcess } = useMessagesContext();
  const [currentStep, setCurrentStep] = useState(0);
  const steps = useMemo(() => [Step.DETAILS, Step.CLIENT_NAME], []);

  const handleGoBack = () => {
    if (currentStep === 0) {
      goToChat();
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const canProceed = () => {
    switch (steps[currentStep]) {
      case Step.DETAILS:
        return !!details;
      case Step.CLIENT_NAME:
        return !!clientName;
    }
  };

  const onSubmitHandler = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (!details || !clientName) return;

      startSkillProcess({
        skillId: ActionId.FEE_AGREEMENT,
        requestId: uuid(),
        payload: {
          case_breakdown: details,
          client: clientName,
        },
        messageToSave: "Elaborar contrato de honorários",
      });

      goToChat();
    }
  };

  const title = `Elaborar contrato de honorários advocatícios - Passo ${currentStep + 1}`;

  return (
    <InternalPageStructure
      goBack={handleGoBack}
      onSubmit={onSubmitHandler}
      goBackButtonText={currentStep === 0 ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep === steps.length - 1 ? "Gerar contrato" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={steps.length}
      currentStep={currentStep}
    >
      <Form show={steps[currentStep] === Step.DETAILS}>
        <Title>{title}</Title>
        <TextInput
          label="Detalhe o caso:"
          description="Insira um resumo do caso e informações importantes para o contrato, como dados do cliente, valores de honorários, forma de pagamento, multas, datas de vigência, etc."
          placeholder="Exemplo: Contratação de serviços advocatícios para atuar em processo de divórcio judicial entre a Silvana e seu marido. Os honorários cobrados serão de R$ 10.000 reais somado com 10% do patrimônio total a ser partilhado no divórcio."
          value={details}
          onChange={setDetails}
        />
      </Form>

      <Form show={steps[currentStep] === Step.CLIENT_NAME}>
        <Title>{title}</Title>
        <TextInput
          id="input_client_name"
          label="Qual é o nome do cliente?"
          placeholder="Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas."
          value={clientName}
          onChange={setClientName}
        />
      </Form>
    </InternalPageStructure>
  );
};
