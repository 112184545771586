import { buildSkillStep } from "@/core/skillSteps/builder";

export const createInitialPetitionSkillStep1 = buildSkillStep({
  title: "Criar Peça Inaugural - Passo 1",
  fields: [
    {
      name: "area",
      type: "string",
      options: [
        { label: "Cível", value: "civel" },
        { label: "Trabalhista", value: "trabalhista" },
        { label: "Previdenciário", value: "previdenciario" },
        { label: "Tributário", value: "tributario" },
        { label: "Criminal", value: "criminal" },
        {
          label: "Outros",
          value: "outros",
        },
      ],
      initialValue: "civel",
      ui: {
        label: "Para qual área jurídica?",
        placeholder: "Selecione a área jurídica",
      },
    },
    {
      name: "custom_area",
      type: "string",
      overrideField: "area",
      ui: {
        label: "Digite a área jurídica:",
        placeholder: "Exemplos: Direito Ambiental, Direito Administrativo...",
        widget: "textarea",
      },
      condition: {
        field: "area",
        value: "outros",
      },
    },
  ],
});
