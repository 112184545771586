import { isTokenExpired } from "@/utils/isTokenExpired";
import { TokenType } from "./types";
import { env } from "@/constants/environment";
import * as logger from "@/core/logger";
import { getFromStorages, removeFromStorages, setInStorages } from "@/utils/storage";

const getTokenStorageKey = (tokenType: TokenType) => `token_${tokenType}_${env.ENV}`;

export const TokenService = {
  getToken(tokenType: TokenType): string | null {
    const token = getFromStorages(getTokenStorageKey(tokenType));

    if (!token || isTokenExpired(token)) {
      logger.debug(`Firebase token not found or expired: ${token}`);
      return null;
    }

    return token;
  },

  saveToken(token: string, tokenType: TokenType) {
    setInStorages(getTokenStorageKey(tokenType), token);
    // Save the last login timestamp in a cookie to admin use
    setInStorages(`lastLoginTimestamp_${env.ENV}`, `${Date.now()}`);
  },

  removeToken(tokenType: TokenType) {
    removeFromStorages(getTokenStorageKey(tokenType));
    removeFromStorages(`lastLoginTimestamp_${env.ENV}`);
  },
};
