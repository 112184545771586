import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Paper, Typography } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import { PlanChip } from "@/components/PlanChip";
import { useCreditsContext } from "@/contexts/CreditsContext";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { menuOptionsClient, menuOptionsProps } from "./clientConfigMenuOptions";

export function ClientConfig() {
  return (
    <PageLayout>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "left",
          paddingBottom: "28px",
          alignSelf: "center",
        }}
      >
        <Typography variant="h3">Configurações</Typography>
      </Box>

      {menuOptionsClient.map((menuOption, index) => {
        return <MenuOptions key={index} label={menuOption.label} options={menuOption.options} />;
      })}

      <Outlet />
    </PageLayout>
  );
}

function MenuOptions({ label, options }: menuOptionsProps) {
  const navigate = useNavigate();
  const { planType, openBilling } = useCreditsContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingBottom: "24px",
        alignSelf: "center",
      }}
    >
      <Paper
        sx={{
          p: "20px 20px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "text.primary",
          borderRadius: "8px 8px 0 0",
          height: "52px",
        }}
      >
        <Typography variant="preTitle" sx={{ color: "common.white" }}>
          {label.toUpperCase()}
        </Typography>
      </Paper>
      {options.map((option, index) => {
        const isLastElement = index === options.length - 1;
        return (
          <Paper
            key={index}
            sx={{
              p: "20px 20px",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              backgroundColor: "common.white",
              borderRadius: isLastElement ? "0 0 8px 8px" : "0",
              borderBottom: isLastElement ? "" : "1px solid #E5E5E5",
              height: "52px",
            }}
            onClick={() => {
              if (option.openBilling) return openBilling();

              if (option.path) {
                if (option.replaceRoute) {
                  return navigate(option.path, { replace: true });
                }

                return navigate(option.path);
              }
            }}
          >
            <Typography
              sx={{ color: "grey.900", display: "flex", gap: "8px", alignItems: "center" }}
              variant="preTitle"
            >
              {option.label.toUpperCase()}
              {option.withPlanChip && !!planType && <PlanChip planType={planType} />}
            </Typography>
            <KeyboardArrowRightIcon sx={{ color: "text.primary" }} />
          </Paper>
        );
      })}
    </Box>
  );
}
