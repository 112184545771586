/* eslint-disable */

import * as React from "react";
import { Dispatch, SetStateAction } from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";

import ChatIcon from "@/assets/svgs/chat.svg?react";
import { History as HistoryIcon } from "@mui/icons-material";
import { Appeal } from "./Appeal";
import { DefenseStrategy } from "./DefenseStrategy";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Input } from "./Input";
import { LaborComplaint } from "./LaborComplaint";
import { LaborContestation } from "./LaborContestation";
import { LaborOrdinaryAppeal } from "./LaborOrdinaryAppeal";
import { LaborReplication } from "./LaborReplication";
import { LoadingChat } from "./LoadingChat";
import { LoadingMessage, MessageCard } from "./MessageCard";
import { Replication } from "./Replication";

import { Loading } from "@/components/Loading";
import {
  Action,
  ErrorMessage,
  HandleEvaluation,
  Message,
  WaitingForResponse,
  useMessagesContext,
} from "@/contexts/MessagesContext";
import { Threads } from "@/contexts/ThreadsContext/components/Threads";
import { ConnectionState, useSocket } from "@/contexts/WebSocketContext";
import { PaginationPayload } from "@/hooks/useApi/types";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { AppealDocument } from "./AppealDocument";
import { ContractCreation } from "./ContractCreation";
import { ExtrajudicialNotice } from "./ExtrajudicialNotice";
import { InauguralDocument } from "./InauguralDocument";
import { InterviewScript } from "./InterviewScript";
import { LegalQuestion } from "./LegalQuestion";
import { ResponseDocument } from "./ResponseDocument";
import { CreateStrategy } from "./skills/CreateStrategy";
import { LegalAdvice } from "./LegalAdvice";
import { CreateIntercurrentMotion } from "./skills/CreateIntercurrentMotion";
import { HearingsScript } from "./skills/HearingsScript";
import { FeeAgreement } from "./skills/FeeAgreement";
import { CreateSettlementOffer } from "./skills/CreateSettlementOffer";
import { SkillForm } from "./SkillForm";
import { useSkill } from "@/hooks/skills/useSkill";

const MAX_SCROLL_POSITION = 24;

export function Chat() {
  const { threadId } = useParams<{ threadId?: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const skillId = searchParams.get("skill");
  const { data: skill } = useSkill(skillId ?? undefined);

  const {
    currentPage,
    messages,
    error,
    clearError,
    loading,
    sendMessage,
    startSkillProcess,
    goToChat,
    addMessage,
    actions,
    waitingForResponse,
    uploadingFiles,
    clearContext,
    inputContext,
    textActions,
    connectionState,
    threadConnectionState,
    connectToThread,
    loadingState,
    refetchMessages,
    totalResults,
    paginationModel,
    setPaginationModel,
    handleEvaluation,
    currentSkillId,
    startSkill,
  } = useMessagesContext();

  const { thread, cleanUpStatus } = useSocket();

  useEffect(() => {
    if (thread && threadConnectionState === ConnectionState.OPEN) {
      let url = ROUTE_PATHS.THREAD.replace(":threadId", thread.id);
      if (skillId) {
        const params = new URLSearchParams({
          skill: skillId,
        });

        const query = params.toString();
        url += `?${query}`;
      }

      navigate(url, {
        state: location.state,
        replace: true,
      });
    }
  }, [connectionState, threadConnectionState]);

  useEffect(() => {
    if (thread?.id && skill && !skill.disabled) {
      startSkill({ skillId: skill.id, actionId: skill.actionId });
    }
  }, [thread?.id, skill]);

  useEffect(() => {
    if (connectionState !== ConnectionState.OPEN) return;
    if (threadConnectionState === ConnectionState.CONNECTING) return;
    if (cleanUpStatus) return;
    if (location.pathname === ROUTE_PATHS.NEW_CHAT) {
      return connectToThread();
    }

    if (!thread || (Boolean(threadId) && thread?.id !== threadId)) {
      connectToThread({ threadId });
    }
  }, [threadId, location.pathname, connectionState]);

  if (currentSkillId) {
    return (
      <SkillForm
        skillId={currentSkillId}
        goBack={goToChat}
        sendMessage={sendMessage}
        startSkillProcess={startSkillProcess}
      />
    );
  }

  if (currentPage === "DEFENSE_STRATEGY" && !loading) {
    return <DefenseStrategy goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "APPEAL" && !loading) {
    return <Appeal goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "REPLICATION" && !loading) {
    return <Replication goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "LABOR_COMPLAINT" && !loading) {
    return <LaborComplaint goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "LABOR_CONTESTATION" && !loading) {
    return <LaborContestation goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "LABOR_REPLICATION" && !loading) {
    return <LaborReplication goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "LABOR_ORDINARY_APPEAL" && !loading) {
    return <LaborOrdinaryAppeal goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "INAUGURAL_DOCUMENT" && !loading) {
    return <InauguralDocument goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "RESPONSE_DOCUMENT" && !loading) {
    return <ResponseDocument goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "APPEAL_DOCUMENT" && !loading) {
    return <AppealDocument goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "INTERVIEW_SCRIPT" && !loading) {
    return <InterviewScript />;
  }

  if (currentPage === "CREATE_STRATEGY" && !loading) {
    return <CreateStrategy />;
  }

  if (currentPage === "HEARINGS_SCRIPT" && !loading) {
    return <HearingsScript />;
  }

  if (currentPage === "FEE_AGREEMENT" && !loading) {
    return <FeeAgreement />;
  }

  if (currentPage === "INTERCURRENT_MOTION" && !loading) {
    return <CreateIntercurrentMotion />;
  }

  if (currentPage === "CONTRACT_CREATION" && !loading) {
    return <ContractCreation goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "EXTRAJUDICIAL_NOTICE" && !loading) {
    return <ExtrajudicialNotice goBack={goToChat} sendMessage={sendMessage} />;
  }

  if (currentPage === "LEGAL_QUESTION" && !loading) {
    return <LegalQuestion goBack={goToChat} />;
  }

  if (currentPage === "LEGAL_ADVICE" && !loading) {
    return <LegalAdvice />;
  }

  if (currentPage === "SETTLEMENT_OFFER") {
    return <CreateSettlementOffer />;
  }

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {connectionState === ConnectionState.OPEN &&
      threadConnectionState === ConnectionState.OPEN &&
      loadingState !== "LOADING" ? (
        <ChatInternal
          sendMessage={sendMessage}
          messages={messages}
          inputContext={inputContext}
          clearContext={clearContext}
          onError={addMessage}
          uploadingFiles={uploadingFiles}
          textActions={textActions}
          waitingForResponse={waitingForResponse}
          isLoading={loading}
          actions={actions}
          refetchMessages={refetchMessages}
          isRefetching={loadingState === "REFETCHING"}
          threadId={threadId}
          totalResults={totalResults}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          handleEvaluation={handleEvaluation}
        />
      ) : (
        <LoadingChat />
      )}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={clearError}>
        <Alert onClose={clearError} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export enum Tab {
  CHAT,
  THREADS,
}

function ChatInternal({
  sendMessage,
  messages,
  inputContext,
  clearContext,
  onError,
  uploadingFiles,
  textActions,
  waitingForResponse,
  isLoading,
  actions,
  refetchMessages,
  isRefetching,
  threadId,
  totalResults,
  paginationModel,
  setPaginationModel,
  handleEvaluation,
}: {
  sendMessage: (message: Message) => unknown;
  messages: Message[];
  inputContext: string;
  clearContext: () => unknown;
  onError: (message: ErrorMessage) => unknown;
  uploadingFiles: { [fileId: string]: number };
  textActions: Action[];
  waitingForResponse?: WaitingForResponse;
  isLoading?: boolean;
  actions: Action[];
  refetchMessages: ({ threadId, pagination }: { threadId: string; pagination: PaginationPayload }) => Promise<void>;
  isRefetching: boolean;
  threadId?: string;
  totalResults: number;
  paginationModel: PaginationPayload;
  setPaginationModel: Dispatch<SetStateAction<PaginationPayload>>;
  handleEvaluation: (props: HandleEvaluation) => Promise<void>;
}) {
  const insideOfficeClient = !outsideOfficeClient();
  const [activeTab, setActiveTab] = React.useState(Tab.CHAT);
  const getChatList = () => {
    return document.getElementById("ChatInternal");
  };

  useEffect(() => {
    const chatList = getChatList();
    if (chatList) {
      chatList.scrollTop = chatList.scrollHeight - chatList.offsetHeight;
    }
  }, []);

  const onScroll = async () => {
    const chatList = getChatList();

    if (!chatList) return;

    const scrollPosition = chatList?.scrollTop;

    if (
      scrollPosition < MAX_SCROLL_POSITION &&
      !isLoading &&
      !isRefetching &&
      threadId &&
      messages.length <= totalResults
    ) {
      const firstMessage = document.getElementById(messages[0].id);

      await refetchMessages({
        pagination: {
          page: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
        },
        threadId,
      });

      firstMessage?.scrollIntoView({ block: "start", behavior: "instant" });

      setPaginationModel((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        display: "grid",
        gridTemplateRows: `${insideOfficeClient ? "min-content" : ""} minmax(0, 1fr)`,
        gridTemplateColumns: "minmax(0, 1fr)",
        height: "100%",
      }}
    >
      {insideOfficeClient && (
        <Header
          tabs={[
            {
              id: Tab.CHAT,
              icon: <ChatIcon style={{ color: "#8C8CA2" }} />,
            },
            {
              id: Tab.THREADS,
              icon: <HistoryIcon />,
            },
          ]}
          activeTab={activeTab}
          onTabChange={setActiveTab}
        />
      )}

      {activeTab === Tab.CHAT ? (
        <Stack sx={{ height: "100%" }}>
          <Messages
            messages={messages}
            uploadingFiles={uploadingFiles}
            textActions={textActions}
            waitingForResponse={waitingForResponse}
            isLoading={isLoading}
            onScroll={onScroll}
            isRefetching={isRefetching}
            handleEvaluation={handleEvaluation}
          />
          <Divider />
          <Input
            sendMessage={sendMessage}
            context={inputContext}
            clearContext={clearContext}
            onError={onError}
            loading={!!waitingForResponse}
            actions={actions}
          />
          <Footer actions={actions} loading={!!waitingForResponse} />
        </Stack>
      ) : (
        <Stack sx={{ height: "100%", display: "flex", alignItems: "center" }}>
          <Threads onClose={() => setActiveTab(Tab.CHAT)} component="SCREEN" />
        </Stack>
      )}
    </Paper>
  );
}

function Messages({
  messages,
  uploadingFiles,
  textActions,
  waitingForResponse,
  isLoading,
  onScroll,
  isRefetching,
  handleEvaluation,
}: {
  messages: Message[];
  uploadingFiles: { [fileId: string]: number };
  textActions: Action[];
  waitingForResponse?: WaitingForResponse;
  isLoading?: boolean;
  onScroll: () => void;
  isRefetching: boolean;
  handleEvaluation: (props: HandleEvaluation) => Promise<void>;
}) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        pl: 2,
        pr: 2,
        pt: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflowY: "auto",
      }}
      id="ChatInternal"
      onScroll={onScroll}
    >
      <Box
        sx={{
          height: "36px",
        }}
      >
        <Loading isLoading={isRefetching}>
          {
            <Typography variant="body2" color="textSecondary">
              Início da conversa
            </Typography>
          }
        </Loading>
      </Box>
      {messages.map((msg, index) => (
        <MessageCard
          key={msg.id}
          message={msg}
          uploadingFiles={uploadingFiles}
          textActions={textActions}
          isLastMessage={index + 1 === messages.length}
          isLoading={isLoading}
          handleEvaluation={handleEvaluation}
        />
      ))}
      {waitingForResponse && <LoadingMessage waitingForResponse={waitingForResponse} />}
    </Box>
  );
}
