import React, { useState } from "react";
import { Box } from "@mui/material";
import { CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { StripeCardCvcElementChangeEvent, StripeCardExpiryElementChangeEvent } from "@stripe/stripe-js";
import { BaseField, fieldOption } from "../Field";
import CardCvcIcon from "@/assets/svgs/cardcvc.svg?react";
import { usePaymentContext } from "@/contexts/PaymentContext";

export const CardCvcAndExpiryField = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [cardCvcErrorMessage, setCardCvcErrorMessage] = useState<string | null>(null);
  const { onLoadingChange } = usePaymentContext();

  const handleCardExpiryChange = (e: StripeCardExpiryElementChangeEvent) => {
    setErrorMessage(null);
    if (e.error) {
      setErrorMessage("Data de validade inválida");
    }
  };

  const handleCardCvcChange = (e: StripeCardCvcElementChangeEvent) => {
    setCardCvcErrorMessage(null);
    if (e.error) {
      setCardCvcErrorMessage("Código de segurança inválido");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: 2,
      }}
    >
      <BaseField errorMessage={errorMessage} label="DATA DE VALIDADE">
        <CardExpiryElement
          onChange={handleCardExpiryChange}
          options={fieldOption}
          className="credit-card-field-stripe"
          onReady={() => onLoadingChange(false)}
        />
      </BaseField>
      <BaseField withRightIcon errorMessage={cardCvcErrorMessage} label="CÓDIGO DE SEGURANÇA">
        <CardCvcElement
          onChange={handleCardCvcChange}
          options={{
            placeholder: "",
            ...fieldOption,
          }}
          className="credit-card-field-stripe"
        />
        <span>
          <CardCvcIcon />
        </span>
      </BaseField>
    </Box>
  );
};
