import { Chip } from "@/components/Chip";
import { PlanType } from "@/contexts/CreditsContext";
import React, { useMemo } from "react";

interface PlanChipProps {
  planType: PlanType;
}

export const PlanChip = ({ planType }: PlanChipProps) => {
  const { text, color, backgroundColor } = useMemo(() => {
    switch (planType) {
      case PlanType.TRIAL: {
        return {
          backgroundColor: "plan.trial.background",
          color: "plan.trial.text",
          text: "PERÍODO DE TESTES",
        };
      }
      case PlanType.INITIAL: {
        return {
          backgroundColor: "plan.initial.background",
          color: "plan.initial.text",
          text: "Plano Inicial",
        };
      }
      case PlanType.BASIC: {
        return {
          backgroundColor: "plan.basic.background",
          color: "plan.basic.text",
          text: "Plano Básico",
        };
      }
      case PlanType.INTERMEDIARY: {
        return {
          backgroundColor: "plan.intermediary.background",
          color: "plan.intermediary.text",
          text: "Plano Intermediário",
        };
      }
      case PlanType.ADVANCED: {
        return {
          backgroundColor: "plan.advanced.background",
          color: "plan.advanced.text",
          text: "Plano Avançado",
        };
      }
      case PlanType.PROFESSIONAL: {
        return {
          backgroundColor: "plan.professional.background",
          color: "plan.professional.text",
          text: "Plano Profissional",
        };
      }
      case PlanType.CUSTOM: {
        return {
          backgroundColor: "plan.custom.background",
          color: "plan.custom.text",
          text: "Plano Personalizado",
        };
      }
      default: {
        return {
          backgroundColor: "plan.free.background",
          color: "plan.free.text",
          text: "Plano Cortesia",
        };
      }
    }
  }, [planType]);

  return (
    <Chip backgroundColor={backgroundColor} color={color}>
      {text}
    </Chip>
  );
};
