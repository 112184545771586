import * as React from "react";

import { DialogModal } from "@/components/DialogModal";
import { useAuthContext } from "@/contexts/AuthContext";
import { useApi } from "@/hooks/useApi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import { getTermsOfUseConfigs } from "@/taskpane/config";

export const TermsOfUse = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useAuthContext();
  const navigate = useNavigate();
  const { acceptTerms } = useApi();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [notAcceptModalOpen, setNotAcceptModalOpen] = useState(false);

  const handleAccept = async () => {
    const { currentVersion } = getTermsOfUseConfigs();

    try {
      setIsLoading(true);
      await acceptTerms({ version: currentVersion });

      navigate(ROUTE_PATHS.CHAT);
    } catch {
      setIsLoading(false);
      setErrorModalOpen(true);
    }
  };

  const handleDeny = () => {
    setNotAcceptModalOpen(true);
  };

  const handleAcceptByModal = () => {
    setNotAcceptModalOpen(false);
    handleAccept();
  };

  return (
    <>
      <InternalPageStructure
        goBack={logout}
        onSubmit={handleAccept}
        submitButtonText="Li e aceito os termos de Uso"
        submitButtonDisabled={isLoading}
        onSecondaryAction={{
          disabled: isLoading,
          text: "Não aceito os termos de uso",
          onClick: handleDeny,
        }}
        contentBoxProps={{
          sx: {
            p: 0,
            overflow: "hidden",
          },
        }}
      >
        <iframe
          src="https://lexter.ai/termosdeuso-v001-20240327"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
          frameBorder="0"
          height="100%"
          width="100%"
        />
      </InternalPageStructure>

      <DialogModal
        open={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        title="OPS!"
        description="Houve um erro no processamento do seu aceite. Por favor, tente novamente."
        buttons={[
          {
            label: "OK",
            onClick: () => setErrorModalOpen(false),
          },
        ]}
        variant="error"
      />

      <DialogModal
        open={notAcceptModalOpen}
        onClose={() => setNotAcceptModalOpen(false)}
        title="Termos de uso"
        description="Você precisa aceitar os termos e condições de uso da Lexter.Ai para continuar com o uso do produto."
        buttons={[
          {
            label: "Aceitar os termos",
            onClick: handleAcceptByModal,
          },
          {
            label: "Voltar para o Login",
            onClick: logout,
            variant: "text",
            color: "primary",
            sx: {
              color: "text.primary",
              textDecoration: "underline",
            },
          },
        ]}
        buttonsDirection="column"
        variant="error"
      />
    </>
  );
};
