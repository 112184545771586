import { Box } from "@mui/material";
import { LateralMenu } from "@/components/LateralMenu";
import React from "react";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";

const LoggedLayout = ({ children }: { children: React.ReactNode }) => {
  if (outsideOfficeClient()) {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "min-content 1fr",
          height: "100%",
          width: "100%",
        }}
      >
        <LateralMenu />
        <Box
          sx={{
            overflow: "auto",
            gridTemplateRows: "min-content auto",
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }

  return <>{children}</>;
};

export default LoggedLayout;
