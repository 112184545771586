import { Form, MultiTypeInput, Text, TextInput, Title } from "@/components/FormComponents";
import { ActionId, InMessageFile, useMessagesContext } from "@/contexts/MessagesContext";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import { v4 as uuidV4 } from "uuid";
import { UserInput } from "@/contexts/WebSocketContext";
import { userInputToFlowOutput } from "./userInputToFlowOutput";

enum Step {
  CLIENT_NAME,
  DETAILED_INPUT,
}

export const InterviewScript = () => {
  const { goToChat, sendMessage } = useMessagesContext();
  const [error, setError] = useState<string | null>(null);
  const [clientName, setClientName] = useState<string>("");
  const [detailsInput, setDetailsInput] = useState<UserInput>();
  const [currentStep, setCurrentStep] = useState(0);
  const steps = useMemo(() => [Step.CLIENT_NAME, Step.DETAILED_INPUT], []);

  const dismissError = () => {
    setError(null);
  };

  const handleGoBack = () => {
    if (currentStep === 0) {
      goToChat();
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleClientNameChange = (value: string) => {
    setClientName(value);
    dismissError();
  };

  const handleDetailsInputChange = (input: UserInput | undefined) => {
    setDetailsInput(input);
    dismissError();
  };

  const canProceed = () => {
    switch (steps[currentStep]) {
      case Step.CLIENT_NAME:
        return !!clientName;
      case Step.DETAILED_INPUT:
        return !!detailsInput;
      default:
        throw new Error("Invalid step");
    }
  };

  const onSubmitHandler = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (!clientName || !detailsInput) {
        setError("É preciso submeter todos os dados.");
        return;
      }

      const messageFiles: InMessageFile[] = [];

      const caseBreakdown = userInputToFlowOutput(detailsInput);

      if (caseBreakdown.source === "FILE") {
        messageFiles.push({
          ...caseBreakdown.file!,
          type: "UPLOADING",
        });
      }

      sendMessage({
        id: uuidV4(),
        type: "FLOW",
        direction: "SENT",
        author: "Current User",
        date: DateTime.now(),
        status: "READ",
        text: "Criar roteiro de entrevista",
        actions: [
          {
            id: ActionId.CREATE_INTERVIEW_SCRIPT_ACTION,
            client: clientName,
            case_breakdown: caseBreakdown,
          },
        ],
        files: messageFiles,
      });

      goToChat();
    }
  };

  const title = `Roteiro de entrevista com o cliente - Passo ${currentStep + 1}`;

  return (
    <InternalPageStructure
      goBack={handleGoBack}
      onSubmit={onSubmitHandler}
      error={error}
      dismissError={dismissError}
      goBackButtonText={currentStep === 0 ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep === steps.length - 1 ? "Gerar roteiro" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={steps.length}
      currentStep={currentStep}
    >
      <Form show={steps[currentStep] === Step.CLIENT_NAME}>
        <Title>{title}</Title>
        <Text>
          O Assistente Lexter pode te ajudar a elaborar questões para a entrevista com o seu cliente, baseado no
          contexto prévio que você já possui sobre o caso.
        </Text>
        <TextInput
          id="input_client_name"
          label="Qual é o nome do cliente?"
          placeholder="Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas."
          value={clientName}
          onChange={handleClientNameChange}
        />
      </Form>

      <Form show={steps[currentStep] === Step.DETAILED_INPUT}>
        <Title>{title}</Title>
        <Text>
          Aqui você pode inserir a conversa que teve com o cliente para enriquecer as perguntas geradas pelo Assistente
          Lexter.
        </Text>
        <MultiTypeInput
          prefixItemIds="input_details"
          onChange={handleDetailsInputChange}
          inputTypes={[
            {
              type: "FILE",
              label: "Enviar a transcrição da conversa com o cliente no WhatsApp (DOCX ou PDF)",
            },
            {
              type: "CONTENT",
              label:
                "Usar as informações escritas no editor de texto ao lado (Transcrição da conversa com o cliente ou contexto do caso)",
            },
          ]}
        />
      </Form>
    </InternalPageStructure>
  );
};
