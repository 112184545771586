import { ReadOnlyAlert } from "@/components/Warnings";
import { useAuthContext } from "@/contexts/AuthContext";
import { useCreditsContext } from "@/contexts/CreditsContext";
import {
  ConnectionState,
  ExtractClaimsResponse,
  MessageStatus,
  SelectClaimsResponse,
  SkillsPayload,
  SkillsResponse,
  UserInputDTO,
  UserInputSource,
  WebsocketMessageType,
  isUserInput,
  useSocket,
} from "@/contexts/WebSocketContext";
import * as logger from "@/core/logger";
import { useApi } from "@/hooks/useApi";
import { PaginationPayload } from "@/hooks/useApi/types";
import { useEditor } from "@/hooks/useEditor";
import { EditorEvent } from "@/hooks/useEditor/types";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useOnboarding } from "@/hooks/useOnboarding";
import { EXTERNAL_URLS, ROUTE_PATHS } from "@/routes/routePaths";
import { Toast, WebToast } from "@/taskpane/components/core/Toast";
import { isWordDesktop, isWordWeb } from "@/taskpane/config";
import { Claim } from "@/types/claim";
import { findReverse } from "@/utils/findReverse";
import { openUrl } from "@/utils/openUrl";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { DateTime } from "luxon";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import {
  DOWNLOAD_ASSISTANT_ANSWER_ACTION_ID,
  INITIAL_PAGINATION_MESSAGES,
  MESSAGE_LENGTH_LIMIT,
  REPLACE_ENTIRE_DOCUMENT_ACTION_ID,
  REPLACE_SELECTION_ACTION_ID,
  SEND_FAQ_ACTION_ID,
  START_APPEAL_FLOW_ACTION_ID,
  START_CREATE_RESPONSE_TO_CONTESTATION,
  START_CREATE_RESPONSE_TO_CONTESTATION_STRATEGY,
  START_INITIAL_PETITION_FLOW_ACTION_ID,
  START_LABOR_COMPLAINT_FLOW_ACTION_ID,
  START_LABOR_CONTESTATION_FLOW_ACTION_ID,
  START_LABOR_REPLICATION_FLOW_ACTION_ID,
  START_NEW_DOCUMENT_FLOW_ACTION_ID,
  START_ORDINARY_APPEAL_FLOW_ACTION_ID,
  START_PETITION_SUMMARY_FLOW_ACTION_ID,
  START_REPLICATION_CONTESTATION_ACTION_ID,
  START_STRATEGY_FLOW_ACTION_ID,
  TEXT_SELECTION_MAX_LENGTH,
} from "./constants";
import { MessagesContext } from "./context";
import {
  Action,
  ActionDTO,
  ActionId,
  ChatFlow,
  ChatOption,
  ClaimWithCheck,
  CreateDefenseAction,
  CreateDocumentProps,
  ErrorFile,
  FlowMessage,
  HandleEvaluation,
  InMessageFile,
  LoadingState,
  Message,
  MessageDTO,
  Page,
  ResponseMessage,
  ShortCutAction,
  SuggestedSkillsMap,
  TextMessage,
  UploadedFile,
  UploadedFlowOutput,
  UploadingFile,
  WaitingForResponse,
  WaitingForResponseType,
  isCortexResponseMessage,
} from "./types";
import {
  createErrorMessage,
  createEvidenceAndRequiredDocumentMessage,
  messageToDTO,
  removeUrlSignature,
  threadMessagesToChatMessages,
  uploadFileToS3,
} from "./utils";
import { shouldSuggestCreateEvidence } from "./utils/shouldSuggestCreateEvidence";
import { Result } from "@/core/Result";
import { waitingTypeFromSkillId } from "./utils/waitingTypeFromSkillId";
import { useSkills } from "@/hooks/skills/useSkills";
import { Skill } from "@/core/skills/types";

const DEFAULT_ERROR_MESSAGE =
  "Ops! Houve um erro ao analisar os dados enviados. Por favor, clique no botão abaixo e tente novamente.";

const INSUFFICIENT_CREDITS_ERROR_MESSAGE = "Créditos insuficientes";

interface MessagesProviderProps {
  children: ReactNode;
}
export const MessagesProvider = ({ children }: MessagesProviderProps) => {
  const getWelcomeMessage = (): Message => {
    const welcomeMessage = (
      <>
        Olá! Bem-vindo ao Assistente Lexter.
        <br />
        Caso tenha dúvidas, acesse nossa{" "}
        <a href={EXTERNAL_URLS.HELP_CENTER_URL} target="_blank" style={{ color: "white" }} rel="noreferrer">
          Central de Ajuda
        </a>{" "}
        ou entre em contato através do{" "}
        <a href="mailto:meajuda@lexter.ai" style={{ color: "white" }}>
          meajuda@lexter.ai
        </a>
        .
      </>
    );

    return {
      id: uuidV4(),
      type: "INITIAL",
      direction: "RECEIVED",
      author: "Lexter.ai",
      date: DateTime.now(),
      status: "READ",
      text: welcomeMessage,
      actions: [],
    };
  };

  const { openOnboarding } = useOnboarding();
  const { user } = useAuthContext();
  const [messages, setMessages] = useState<Message[]>([]);
  const [waitingForResponse, setWaitingForResponse] = useState<WaitingForResponse>();
  const [currentPage, setCurrentPage] = useState<Page>("CHAT");
  const [inputContext, setInputContext] = useState("");
  const [currentSkillId, setCurrentSkillId] = useState<string | null>(null);
  const [textActions, setTextActions] = useState<Action[]>([
    // {
    //   id: "REPLACE_ENTIRE_DOCUMENT",
    //   text: "Substituir documento inteiro",
    //   onClick: replaceDocumentWithMessage,
    // },
    // {
    //   id: "REPLACE_SELECTION",
    //   text: "Substituir seleção",
    //   onClick: replaceSelectionWithMessage,
    //   disabled: true,
    // },
  ]);
  const [loadingState, setLoadingState] = useState<LoadingState>("LOADING");
  const [totalResults, setTotalResults] = useState<number>(0);
  const location = useLocation();
  const [paginationModel, setPaginationModel] = React.useState(INITIAL_PAGINATION_MESSAGES);
  const { data: skills } = useSkills();

  const socket = useSocket({
    onMessageReceived: ({ type, data }) => {
      switch (type) {
        case WebsocketMessageType.MESSAGE:
          handleMessage(data);
          break;
        case WebsocketMessageType.EXTRACT_CLAIMS:
          handleExtractClaimsResponse(data);
          break;
        case WebsocketMessageType.SELECT_CLAIMS:
          handleSelectClaimsResponse(data);
          break;
        case WebsocketMessageType.SKILLS:
          handleSkillsResponse(data);
          break;
      }
    },
    onThreadConnected: async ({ threadId }) => {
      setWaitingForResponse(undefined);

      await loadMessages({ threadId, pagination: INITIAL_PAGINATION_MESSAGES });
    },
  });

  const navigate = useNavigate();
  const { getSignedUrl, createDocument, cancelExecution, getMessages, evaluateMessage } = useApi();
  const { editor, allTabsAreEmpty: editorIsEmpty } = useEditor();
  const [searchParams] = useSearchParams();
  const skillId = searchParams.get("skill");

  const connectToThread = (params?: { threadId?: string }) => {
    setCurrentPage("CHAT");
    socket.openNewThread({ threadId: params?.threadId });
  };

  const closeThreadConnection = () => {
    socket.closeThread();
    setWaitingForResponse(undefined);
    setCurrentPage("CHAT");
  };

  const loadMessages = async ({ threadId, pagination }: { threadId: string; pagination: PaginationPayload }) => {
    setLoadingState("LOADING");
    try {
      const messagesResponse = await getMessages({ threadId, pagination });

      const processingMessage = messagesResponse.data.find((message) => message.status === MessageStatus.PROCESSING);
      if (processingMessage) {
        setWaitingForResponse({
          type: WaitingForResponseType.GENERIC,
          executionId: processingMessage.id,
        });
      }

      setMessages(threadMessagesToChatMessages(messagesResponse.data));

      setTotalResults(messagesResponse.totalResults);

      if (skillId && actionsOptionsMap[skillId]) {
        handleOptionSelect(actionsOptionsMap[skillId]);
      } else {
        addMessage(getWelcomeMessage());
        setCurrentFlow(initialChatFlow);
      }

      setLoadingState("FINISHED");
    } catch (error) {
      setLoadingState("FINISHED");
      WebToast.error("Não foi possível carregar suas mensagens");
    }
  };

  const refetchMessages = async ({ threadId, pagination }: { threadId: string; pagination: PaginationPayload }) => {
    setLoadingState("REFETCHING");
    try {
      const messagesResponse = await getMessages({ threadId, pagination });
      const messages = threadMessagesToChatMessages(messagesResponse.data);
      setMessages((prev) => [...messages, ...prev]);
      setTotalResults(messagesResponse.totalResults);
      setLoadingState("FINISHED");
    } catch (error) {
      setLoadingState("FINISHED");
      WebToast.error("Não foi possível carregar suas mensagens");
    }
  };

  useEffect(() => {
    if (!outsideOfficeClient()) return;

    const shouldDisplayExitConfirmation = !!waitingForResponse;
    if (!shouldDisplayExitConfirmation) return;

    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      event.returnValue = true;
      return "";
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [waitingForResponse, editorIsEmpty]);

  const [isDocumentReadOnlyOpen, setIsDocumentReadOnlyOpen] = useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = useState<{ [fileId: string]: number }>({});
  const [error, setError] = useState("");
  const flags = useFeatureFlags();
  const { signalInsufficientCredits, checkCreditAvailability, isFreemium, isTrial, getFilteredCommittedActions } =
    useCreditsContext();

  const loading = !!waitingForResponse || loadingState === "LOADING";

  const startSkill = async ({ skillId, actionId }: { skillId: string; actionId: ActionId }) => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(actionId);
    setCurrentSkillId(skillId);
  };

  const checkIfReadOnly = async (): Promise<boolean> => {
    if (!flags.warnReadOnlyDocument || !isWordDesktop()) {
      return false;
    }

    const isDocumentReadOnly = await editor?.checkIfDocumentIsReadOnly();
    if (isDocumentReadOnly) {
      setIsDocumentReadOnlyOpen(true);
    }

    return Boolean(isDocumentReadOnly);
  };

  const createInitialPetition = async (props?: CreateDocumentProps) => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    const { withReferences } = props || {};

    checkCreditAvailability(ActionId.CREATE_INITIAL_PETITION);
    navigate(ROUTE_PATHS.CHAT, {
      state: { withReferences: withReferences },
    });
  };

  const createAppeal = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_APPEAL);
    setCurrentPage("APPEAL");
  };

  const createReplication = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_REPLICATION);
    setCurrentPage("REPLICATION");
  };

  const createLaborComplaint = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_LABOR_COMPLAINT);
    setCurrentPage("LABOR_COMPLAINT");
  };

  const createLaborContestation = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_LABOR_CONTESTATION);
    setCurrentPage("LABOR_CONTESTATION");
  };

  const createLaborReplication = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_LABOR_PLAINTIFF_REPLY);
    setCurrentPage("LABOR_REPLICATION");
  };

  const createLaborOrdinaryAppeal = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_ORDINARY_APPEAL);
    setCurrentPage("LABOR_ORDINARY_APPEAL");
  };

  const createDefenseStrategy = () => {
    setCurrentPage("DEFENSE_STRATEGY");
  };

  const createDefense = async (props?: CreateDocumentProps) => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    const { withReferences } = props || {};

    checkCreditAvailability(ActionId.CREATE_CONTESTATION);
    navigate(ROUTE_PATHS.CREATE_CONTESTATION, {
      state: { withReferences: withReferences },
    });
  };

  const createPetitionSummary = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_PETITION_SUMMARY);
    navigate(ROUTE_PATHS.PETITION_SUMMARY);
  };

  const searchJurisprudence = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.SEARCH_JURISPRUDENCE);
    navigate(ROUTE_PATHS.JURISPRUDENCE_SEARCH);
  };

  const createInauguralDocument = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_ONE_PIECE);
    setCurrentPage("INAUGURAL_DOCUMENT");
  };

  const createResponseDocument = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_ONE_PIECE);
    setCurrentPage("RESPONSE_DOCUMENT");
  };

  const createAppealDocument = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_ONE_PIECE);
    setCurrentPage("APPEAL_DOCUMENT");
  };

  const createInterviewScript = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_INTERVIEW_SCRIPT_ACTION);
    setCurrentPage("INTERVIEW_SCRIPT");
  };

  const createStrategy = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_STRATEGY);
    setCurrentPage("CREATE_STRATEGY");
  };

  const createHearingsScript = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.HEARINGS_SCRIPT);
    setCurrentPage("HEARINGS_SCRIPT");
  };

  const createFeeAgreement = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.FEE_AGREEMENT);
    setCurrentPage("FEE_AGREEMENT");
  };

  const createIntercurrentMotion = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.INTERCURRENT_MOTION);
    setCurrentPage("INTERCURRENT_MOTION");
  };

  const createContract = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_CONTRACT);
    setCurrentPage("CONTRACT_CREATION");
  };

  const createExtrajudicialNotice = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.CREATE_NOTICE_ACTION);
    setCurrentPage("EXTRAJUDICIAL_NOTICE");
  };

  const editDocument = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.EDIT_DOCUMENT);
    navigate(ROUTE_PATHS.EDIT_DOCUMENT);
  };

  const askLegalQuestion = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.LEGAL_QUESTION);
    setCurrentPage("LEGAL_QUESTION");
  };

  const legalAdvice = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.LEGAL_ADVICE);
    setCurrentPage("LEGAL_ADVICE");
  };

  const createSettlementOffer = async () => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    checkCreditAvailability(ActionId.SETTLEMENT_OFFER);
    setCurrentPage("SETTLEMENT_OFFER");
  };

  const restartFlow = () => {
    setCurrentFlow({
      ...initialChatFlow,
      message: "OK! A tarefa anterior foi cancelada, deseja começar uma nova tarefa?",
    });
  };

  const goToChat = () => {
    setCurrentSkillId(null);
    setCurrentPage("CHAT");
  };

  const createInauguralDocumentOption: ChatOption = {
    id: ShortCutAction.CREATE_NEW_DOCUMENT,
    skillId: "create_initial_petition",
    label: "Peça Inaugural",
    action: createInauguralDocument,
  };

  const createResponseOption: ChatOption = {
    id: ShortCutAction.CREATE_REPLICATION,
    label: "Resposta",
    action: createResponseDocument,
  };

  const createIntercurrentMotionOption: ChatOption = {
    id: ShortCutAction.INTERCURRENT_MOTION,
    skillId: "intercurrent_motion",
    label: "Petição Intercorrente",
    action: createIntercurrentMotion,
    hidden: !flags.intercurrentMotionEnabled,
  };

  const createAppealOption: ChatOption = {
    id: ShortCutAction.CREATE_APPEAL,
    skillId: "create_appeal",
    label: "Apelação",
    action: createAppealDocument,
  };

  const mergeSkillsWithChatOptions = (
    oldOptions: ChatOption[],
    skills?: Skill[],
    options?: { replaceMatchingSkillsOnly?: boolean }
  ): ChatOption[] => {
    const { replaceMatchingSkillsOnly = false } = options || {};

    const enabledSkills = skills?.filter((skill) => !skill.disabled) || [];

    const skillToChatOption = (skill: Skill): ChatOption => ({
      label: skill.name,
      action: () =>
        startSkill({
          skillId: skill.id,
          actionId: skill.actionId,
        }),
    });

    if (replaceMatchingSkillsOnly) {
      return oldOptions.map((option) => {
        const newSkill = enabledSkills.find((skill) => skill.id === option.skillId);

        return newSkill ? skillToChatOption(newSkill) : option;
      });
    }

    const enabledSkillIds = enabledSkills.map((skill) => skill.id);

    const oldOptionsWithoutNewSkills = oldOptions.filter(
      (option) => !option.skillId || !enabledSkillIds.includes(option.skillId)
    );

    const newSkillsChatOptions: ChatOption[] = enabledSkills.map(skillToChatOption);

    return [...oldOptionsWithoutNewSkills, ...newSkillsChatOptions];
  };

  const documentCreationText = "Escrever nova peça";

  const legalPieceResponseOption: ChatOption = {
    label: documentCreationText,
    response: {
      message: "Qual tipo de peça você deseja criar?",
      options: [
        ...mergeSkillsWithChatOptions(
          [createInauguralDocumentOption, createResponseOption, createIntercurrentMotionOption, createAppealOption],
          skills,
          { replaceMatchingSkillsOnly: true }
        ),
        {
          label: "Cancelar",
          action: restartFlow,
        },
      ],
    },
  };

  const summaryResponseOption: ChatOption = {
    id: ShortCutAction.SUMMARIZE_DOCUMENT,
    skillId: "summary_procedural_documents",
    label: "Resumir documento processual",
    hidden: isWordWeb(),
    action: createPetitionSummary,
  };

  const createContractOption: ChatOption = {
    id: ShortCutAction.CREATE_CONTRACT,
    label: "Elaborar contrato",
    hidden: !flags.contractCreationEnabled,
    action: createContract,
  };

  const extrajudicialNoticeOption: ChatOption = {
    id: ShortCutAction.CREATE_NOTICE_ACTION,
    label: "Notificação Extrajudicial",
    hidden: !flags.extrajudicialNoticeEnabled,
    action: createExtrajudicialNotice,
  };

  const searchJurisprudenceOption: ChatOption = {
    id: ShortCutAction.SEARCH_JURISPRUDENCE,
    label: "Buscar jurisprudência",
    action: searchJurisprudence,
    hidden: !flags.jurisprudenceSearch,
  };

  const useChatOption: ChatOption = {
    id: ShortCutAction.USE_CHAT,
    label: "Usar chat",
    action: () => setInputVisible(true),
    hidden: !flags.chatAsSkill,
  };

  const interviewScriptOption: ChatOption = {
    id: ShortCutAction.CREATE_INTERVIEW_SCRIPT_ACTION,
    skillId: "create_interview_script",
    label: "Roteiro de entrevista",
    action: createInterviewScript,
    hidden: !flags.interviewScriptEnabled,
  };

  const createStrategyOption: ChatOption = {
    id: ShortCutAction.CREATE_STRATEGY,
    skillId: "create_case_strategy",
    label: "Estratégia Jurídica",
    action: createStrategy,
    hidden: !flags.createStrategyEnabled,
  };

  const hearingsScriptOption: ChatOption = {
    id: ShortCutAction.HEARINGS_SCRIPT,
    skillId: ShortCutAction.HEARINGS_SCRIPT,
    label: "Roteiro para audiências",
    action: createHearingsScript,
    hidden: !flags.hearingsScriptEnabled,
  };

  const createFeeAgreementOption: ChatOption = {
    id: ShortCutAction.FEE_AGREEMENT,
    skillId: "fee_agreement",
    label: "Contrato de honorários",
    action: createFeeAgreement,
    hidden: !flags.feeAgreementEnabled,
  };

  const editDocumentOption: ChatOption = {
    id: ShortCutAction.EDIT_DOCUMENT,
    label: "Editar texto",
    hidden: !flags.editorAsSkill,
    action: editDocument,
  };

  const legalQuestionOption: ChatOption = {
    id: ShortCutAction.LEGAL_QUESTION,
    skillId: "legal_questions",
    label: "Dúvida jurídica",
    hidden: !flags.legalQuestionEnabled,
    action: askLegalQuestion,
  };

  const legalAdviceOption: ChatOption = {
    id: ShortCutAction.LEGAL_ADVICE,
    label: "Elaborar parecer jurídico",
    skillId: "legal_advice",
    hidden: !flags.legalAdviceEnabled,
    action: legalAdvice,
  };

  const createSettlementOfferOption: ChatOption = {
    id: ShortCutAction.SETTLEMENT_OFFER,
    skillId: "settlement_offer",
    label: "Elaborar proposta de acordo",
    hidden: !flags.settlementOfferEnabled,
    action: createSettlementOffer,
  };

  const seeMoreOptions = () => {
    setCurrentFlow({
      ...initialChatFlow,
      options: mergeSkillsWithChatOptions(
        [
          legalPieceResponseOption,
          summaryResponseOption,
          interviewScriptOption,
          createStrategyOption,
          createContractOption,
          extrajudicialNoticeOption,
          legalQuestionOption,
          legalAdviceOption,
          searchJurisprudenceOption,
          createIntercurrentMotionOption,
          createFeeAgreementOption,
          createSettlementOfferOption,
          editDocumentOption,
          hearingsScriptOption,
          useChatOption,
        ],
        skills
      ),
    });
  };

  const initialChatFlow: ChatFlow = {
    message: "Para começar, selecione uma das minhas habilidades abaixo:",
    options: mergeSkillsWithChatOptions(
      [
        legalPieceResponseOption,
        createContractOption,
        legalQuestionOption,
        {
          label: "Ver mais",
          action: seeMoreOptions,
        },
      ],
      skills,
      { replaceMatchingSkillsOnly: true }
    ),
  };

  const DEFAULT_SUGGESTION_MESSAGE = (
    <>
      Seguem algumas sugestões do que você pode fazer agora.
      <br />
      Se tiver alguma dúvida, acesse nossa{" "}
      <a href={EXTERNAL_URLS.HELP_CENTER_URL} target="_blank" style={{ color: "white" }} rel="noreferrer">
        Central de Ajuda
      </a>
      .
    </>
  );

  const legalDocumentCreatedOptions: ChatOption[] = [
    searchJurisprudenceOption,
    editDocumentOption,
    legalQuestionOption,
  ];

  const suggestedSkillsMap: SuggestedSkillsMap = {
    [ActionId.CREATE_INTERVIEW_SCRIPT_ACTION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [legalQuestionOption, createStrategyOption],
    },
    [ActionId.CREATE_ONE_PIECE]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_CONTRACT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [legalQuestionOption, editDocumentOption, createFeeAgreementOption],
    },
    [ActionId.LEGAL_QUESTION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [legalQuestionOption, legalPieceResponseOption, createContractOption, legalAdviceOption],
    },
    [ActionId.CREATE_STRATEGY]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [legalPieceResponseOption, searchJurisprudenceOption, legalQuestionOption],
    },
    [ActionId.SUMMARIZE_DOCUMENT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [createStrategyOption, legalPieceResponseOption, legalQuestionOption],
    },
    [ActionId.LEGAL_ADVICE]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [legalQuestionOption, searchJurisprudenceOption, createFeeAgreementOption, editDocumentOption],
    },
    [ActionId.FEE_AGREEMENT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [createStrategyOption, legalQuestionOption, editDocumentOption],
    },
    [ActionId.SETTLEMENT_OFFER]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [legalQuestionOption, searchJurisprudenceOption, legalQuestionOption, createStrategyOption],
    },
    [ActionId.SEARCH_JURISPRUDENCE]: {
      message: "",
      options: [],
    },
    [ActionId.EDIT_DOCUMENT]: {
      message: "",
      options: [],
    },
    [ActionId.INTERCURRENT_MOTION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_NEW_DOCUMENT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_INITIAL_PETITION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_CONTESTATION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_PETITION_SUMMARY]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [createStrategyOption, legalPieceResponseOption, legalQuestionOption],
    },
    [ActionId.CREATE_APPEAL]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_REPLICATION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_LABOR_COMPLAINT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_LABOR_CONTESTATION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_ORDINARY_APPEAL]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_LABOR_PLAINTIFF_REPLY]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_NOTICE_ACTION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [legalQuestionOption, createStrategyOption, searchJurisprudenceOption, createFeeAgreementOption],
    },
    [ActionId.HEARINGS_SCRIPT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CHAT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [legalQuestionOption, searchJurisprudenceOption, legalPieceResponseOption, editDocumentOption],
    },
  };

  const getSuggestedSkills = (actionId: ActionId): ChatFlow => {
    const baseSuggestedSkills = suggestedSkillsMap[actionId];

    const activeSkillsOptions = baseSuggestedSkills.options
      ? mergeSkillsWithChatOptions(baseSuggestedSkills.options, skills, { replaceMatchingSkillsOnly: true })
      : baseSuggestedSkills.options;

    return {
      ...baseSuggestedSkills,
      options: activeSkillsOptions,
    };
  };

  const [currentFlow, setCurrentFlow] = useState<ChatFlow>();

  const handleOptionSelect = (option: ChatOption) => {
    if (option.response) {
      addMessage({
        id: uuidV4(),
        type: "TEXT",
        direction: "SENT",
        author: "Current User",
        date: DateTime.now(),
        status: "READ",
        text: option.label,
      });

      setCurrentFlow(option.response);
    } else if (option.action) {
      option.action();
    }
  };

  const actionsOptionsMap: Record<string, ChatOption> = {
    [ShortCutAction.CREATE_NEW_DOCUMENT]: legalPieceResponseOption,
    [ShortCutAction.CREATE_LABOR_COMPLAINT]: createInauguralDocumentOption,
    [ShortCutAction.CREATE_CONTRACT]: createContractOption,
    [ShortCutAction.CREATE_INITIAL_PETITION]: createInauguralDocumentOption,
    [ShortCutAction.CREATE_CONTESTATION]: createResponseOption,
    [ShortCutAction.CREATE_LABOR_CONTESTATION]: createResponseOption,
    [ShortCutAction.CREATE_REPLICATION]: createResponseOption,
    [ShortCutAction.CREATE_LABOR_PLAINTIFF_REPLY]: createResponseOption,
    [ShortCutAction.CREATE_APPEAL]: createAppealOption,
    [ShortCutAction.CREATE_ORDINARY_APPEAL]: createAppealOption,
    [ShortCutAction.CREATE_NOTICE_ACTION]: extrajudicialNoticeOption,
    [ShortCutAction.CREATE_INTERVIEW_SCRIPT_ACTION]: interviewScriptOption,
    [ShortCutAction.CREATE_SOCIAL_SECURITY_INITIAL_PETITION]: createInauguralDocumentOption,
    [ShortCutAction.CREATE_CRIMINAL_INITIAL_PETITION]: createInauguralDocumentOption,
    [ShortCutAction.CREATE_CRIMINAL_APPEAL]: createAppealOption,
    [ShortCutAction.CREATE_CRIMINAL_REPLY]: createResponseOption,
    [ShortCutAction.CREATE_LABOR_INITIAL_PETITION]: createInauguralDocumentOption,
    [ShortCutAction.CREATE_LABOR_APPEAL]: createAppealOption,
    [ShortCutAction.CREATE_CIVIL_INITIAL_PETITION]: createInauguralDocumentOption,
    [ShortCutAction.CREATE_CIVIL_REPLY]: createResponseOption,
    [ShortCutAction.CREATE_CIVIL_APPEAL]: createAppealOption,
    [ShortCutAction.LEGAL_QUESTION]: legalQuestionOption,
    [ShortCutAction.USE_CHAT]: useChatOption,
    [ShortCutAction.CREATE_STRATEGY]: createStrategyOption,
    [ShortCutAction.SEARCH_JURISPRUDENCE]: searchJurisprudenceOption,
    [ShortCutAction.LEGAL_ADVICE]: legalAdviceOption,
    [ShortCutAction.INTERCURRENT_MOTION]: createIntercurrentMotionOption,
    [ShortCutAction.FEE_AGREEMENT]: createFeeAgreementOption,
    [ShortCutAction.SETTLEMENT_OFFER]: createSettlementOfferOption,
    [ShortCutAction.HEARINGS_SCRIPT]: hearingsScriptOption,
  };

  const sendCreatePieceMessage = () => {
    handleOptionSelect(legalPieceResponseOption);
  };

  useEffect(() => {
    if (!currentFlow) return;

    addMessage({
      id: uuidV4(),
      type: "ACTION",
      author: "Lexter.ai",
      direction: "RECEIVED",
      date: DateTime.now(),
      status: "READ",
      text: currentFlow.message,
      actions:
        currentFlow.options?.map((option) => ({
          id: option.id,
          text: option.label,
          onClick: () => handleOptionSelect(option),
          hidden: option.hidden,
          disabled: option.disabled,
        })) || [],
    });
  }, [currentFlow]);

  useEffect(() => {
    if (!editor) return;

    editor.addEventListener(EditorEvent.SELECTION_CHANGE, async () => {
      const selection = await editor.getSelection();

      if (selection.length > TEXT_SELECTION_MAX_LENGTH) {
        return Toast.warn(
          `O limite de ${TEXT_SELECTION_MAX_LENGTH.toLocaleString(
            "pt-BR"
          )} caracteres para seleção do texto foi ultrapassado. Ajuste o tamanho do texto selecionado para ter uma melhor performance.`
        );
      }

      setInputContext(selection);

      //If there is text selected, we enable the replace selection action
      if (selection.trim()) {
        setTextActions((prev) =>
          prev.map((action) => {
            if (action.id === "REPLACE_SELECTION") {
              return { ...action, disabled: false };
            }
            return action;
          })
        );
      } else {
        setTextActions((prev) =>
          prev.map((action) => {
            if (action.id === "REPLACE_SELECTION") {
              return { ...action, disabled: true };
            }
            return action;
          })
        );
      }
    });
  }, [editor]);

  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.HOME) {
      setInputVisible(false);
    }
  }, [location.pathname]);

  const clearContext = async () => {
    setInputContext("");
    await editor?.clearSelection();
  };

  const addMessage = (message: Message) => {
    setMessages((previous) => [...previous, message]);
  };

  const [startClaimsExtractionRetry, setStartClaimsExtractionRetry] = useState(() => () => {});
  const startClaimsExtraction = (documentFiles: UploadedFile[]) => {
    setStartClaimsExtractionRetry(() => () => startClaimsExtraction(documentFiles));

    socket.send({
      type: WebsocketMessageType.EXTRACT_CLAIMS,
      data: {
        cortexDocumentIds: documentFiles.map((file) => Number(file.cortexId)),
        documentIds: documentFiles.map((file) => file.id),
      },
    });

    setWaitingForResponse({ type: WaitingForResponseType.EXTRACT_CLAIMS });
  };

  const replaceDocumentAction = async (
    action: ActionDTO,
    newDocument: { id?: number; name?: string }
  ): Promise<{ errorMessage?: string }> => {
    if (!editor) return {};

    if (action.id !== REPLACE_ENTIRE_DOCUMENT_ACTION_ID) {
      return { errorMessage: "Ação inválida" };
    }

    if (action.type === "TEXT") {
      return editor.replaceBody(action.text || "");
    } else if (action.type === "FORMATTED") {
      return editor.openFormattedText({
        document: {
          id: newDocument.id,
          name: newDocument.name,
          formattedText: { lines: action.lines, stylesMap: action.stylesMap },
        },
      });
    }

    return { errorMessage: "Ação inválida" };
  };

  const showOnboardingForNewUserInFirstAction = () => {
    if (!user?.profileCompleted && outsideOfficeClient() && (isFreemium || isTrial)) {
      getFilteredCommittedActions().then((response) => {
        if (response.length === 0) {
          openOnboarding({
            firstTimeInOnboarding: true,
          });
        }
      });
    }
  };

  const showPaywallWithDiscountForFreemiumUsersInSecondAction = () => {
    if (isFreemium || isTrial) {
      getFilteredCommittedActions().then((response) => {
        if (response.length === 2) {
          window.userGuiding.previewGuide(118722);
        }
      });
    }
  };

  const handleMessage = (msg: ResponseMessage) => {
    const retryMessageId = waitingForResponse?.executionId;

    try {
      if (isCortexResponseMessage(msg)) {
        if (!msg.success) {
          throw new Error("Erro ao processar a mensagem");
        }

        const skillId = msg.replyTo.actionId;
        const suggestedSkills = getSuggestedSkills(skillId);
        const showSuggestedSkillsFlow = flags.suggestedSkillsFlowEnabled && suggestedSkills && outsideOfficeClient();

        const newMessages: Message[] = msg.messages
          .map((m: MessageDTO) => {
            const actions: Action[] = [];
            m.actions?.forEach((action) => {
              //The only action we will take instantly is the replace entire
              //document action. All other actions will be displayed to the user
              if (action.id === REPLACE_ENTIRE_DOCUMENT_ACTION_ID) {
                replaceDocumentAction(action, { id: msg.document?.id, name: msg.document?.name }).then(
                  ({ errorMessage }) => {
                    if (errorMessage) {
                      addMessage(createErrorMessage({ text: errorMessage }));
                    } else {
                      showPaywallWithDiscountForFreemiumUsersInSecondAction();
                    }
                  }
                );
              } else if (action.id === REPLACE_SELECTION_ACTION_ID) {
                actions.push({
                  id: action.id,
                  text: "Substituir",
                  // onClick: () => replaceSelectionAction(action),
                  onClick: (message: Message) => editor?.replaceSelection(message.text.toString()),
                });
                actions.push({
                  id: action.id,
                  text: "Inserir",
                  // onClick: () => replaceSelectionAction(action),
                  onClick: (message: Message) => editor?.replaceSelection(message.text.toString()),
                });
              } else if (action.id === DOWNLOAD_ASSISTANT_ANSWER_ACTION_ID) {
                if (outsideOfficeClient()) {
                  editor?.openFormattedText({
                    document: {
                      id: msg.document?.id,
                      name: msg.document?.name,
                      formattedText: { lines: action.lines, stylesMap: action.stylesMap },
                    },
                  });
                } else {
                  m.text = `${m.text} Clique no botão abaixo para abrir o documento em uma nova janela ou para começar uma nova tarefa.`;
                  actions.push({
                    id: action.id,
                    text: "Abrir em novo documento",
                    onClick: async () => {
                      setWaitingForResponse({ type: WaitingForResponseType.GENERIC });
                      await editor?.openFormattedText({
                        document: {
                          id: msg.document?.id,
                          name: msg.document?.name,
                          formattedText: { lines: action.lines, stylesMap: action.stylesMap },
                        },
                        wordOptions: { openInNewWindow: true },
                      });
                      setWaitingForResponse(undefined);
                    },
                  });
                  actions.push({
                    id: action.id,
                    text: "Começar nova tarefa",
                    onClick: () => {
                      handleOptionSelect({
                        label: "Começar nova tarefa",
                        response: {
                          ...initialChatFlow,
                          message: "Ok! Selecione uma das opções abaixo:",
                        },
                      });
                    },
                  });
                }
              } else if (action.id === START_STRATEGY_FLOW_ACTION_ID) {
                actions.push({
                  id: "CRIAR_ESTRATEGIA",
                  text: "Estratégia da petição inicial",
                  onClick: createStrategy,
                });
              } else if (action.id === SEND_FAQ_ACTION_ID) {
                actions.push({
                  id: "ABRIR_FQA",
                  text: "Abrir Central de Ajuda",
                  onClick: () => openUrl(EXTERNAL_URLS.HELP_CENTER_URL),
                });
              } else if (action.id === START_INITIAL_PETITION_FLOW_ACTION_ID) {
                actions.push({
                  id: "ESCREVER_PETICAO_INICIAL",
                  text: "Escrever petição inicial",
                  onClick: () => createInitialPetition(),
                });
              } else if (action.id === START_NEW_DOCUMENT_FLOW_ACTION_ID) {
                actions.push({
                  id: action.id,
                  text: documentCreationText,
                  onClick: () => {
                    if (legalPieceResponseOption.response) {
                      setCurrentFlow(legalPieceResponseOption.response);
                    }
                  },
                });
              } else if (action.id === START_APPEAL_FLOW_ACTION_ID) {
                actions.push({
                  id: "ESCREVER_APELACAO",
                  text: "Escrever apelação",
                  onClick: createAppeal,
                });
              } else if (action.id === START_REPLICATION_CONTESTATION_ACTION_ID) {
                actions.push({
                  id: "ESCREVER_REPLICA",
                  text: "Escrever réplica",
                  onClick: createReplication,
                });
              } else if (action.id === START_LABOR_COMPLAINT_FLOW_ACTION_ID) {
                actions.push({
                  id: "ESCREVER_RECLAMACAO_TRABALHISTA",
                  text: "Escrever reclamação trabalhista",
                  onClick: createLaborComplaint,
                });
              } else if (action.id === START_LABOR_CONTESTATION_FLOW_ACTION_ID) {
                actions.push({
                  id: "ESCREVER_CONTESTACAO_TRABALHISTA",
                  text: "Escrever contestação trabalhista",
                  onClick: createLaborContestation,
                });
              } else if (action.id === START_LABOR_REPLICATION_FLOW_ACTION_ID) {
                actions.push({
                  id: "ESCREVER_REPLICA_TRABALHISTA",
                  text: "Escrever réplica trabalhista",
                  onClick: createLaborReplication,
                });
              } else if (action.id === START_ORDINARY_APPEAL_FLOW_ACTION_ID) {
                actions.push({
                  id: "ESCREVER_RECURSO_ORDINARIO_TRABALHISTA",
                  text: "Escrever recurso ordinário",
                  onClick: createLaborOrdinaryAppeal,
                });
              } else if (action.id === START_CREATE_RESPONSE_TO_CONTESTATION_STRATEGY) {
                actions.push({
                  id: "ESTRATEGIA_CONTESTACAO",
                  text: "Estratégia da contestação",
                  onClick: createDefenseStrategy,
                });
              } else if (action.id === START_CREATE_RESPONSE_TO_CONTESTATION) {
                actions.push({
                  id: "CONTESTACAO",
                  text: "Escrever contestação",
                  onClick: () => createDefense(),
                });
              } else if (action.id === START_PETITION_SUMMARY_FLOW_ACTION_ID && !isWordWeb()) {
                actions.push({
                  id: "RESUMO_DA_PETICAO",
                  text: "Resumir documento processual",
                  onClick: createPetitionSummary,
                });
              }
            });

            const hasActions = actions.length > 0;
            const copyable = !Boolean(msg.replyTo.actionId);

            return {
              id: m.id,
              type: hasActions ? "ACTION" : "TEXT",
              direction: "RECEIVED",
              author: "Lexter.ai",
              date: DateTime.now(),
              status: "READ",
              text: m.text,
              actions: hasActions ? actions : undefined,
              copyable,
            } as Message;
          })
          .filter((m): m is Message => m !== null);

        setMessages((prev) => [...prev, ...newMessages]);

        const documentToOpenInNewWindow = msg.messages.some((m) =>
          m.actions?.some((action) => action.id === DOWNLOAD_ASSISTANT_ANSWER_ACTION_ID)
        );

        const suggestEvidenceEnabled = !isWordWeb() && flags.createEvidenceAndRequiredDocumentsEnabled;

        const editorInstructionText = flags.editorAsSkill
          ? 'Caso queira editar algum trecho, basta selecionar "Editar texto" abaixo e seguir as instruções. Você também pode escolher outras ações:'
          : "Você pode continuar escolhendo outras ações abaixo:";

        if (showSuggestedSkillsFlow && suggestedSkills.options) {
          const suggestionsMessage: Message = {
            id: uuidV4(),
            type: "ACTION",
            direction: "RECEIVED",
            author: "Lexter.ai",
            date: DateTime.now(),
            status: "READ",
            text: suggestedSkills.message,
            actions: suggestedSkills.options.map((option) => ({
              text: option.label,
              id: option.id,
              hidden: option.hidden,
              onClick: () => handleOptionSelect(option),
            })),
          };
          if (suggestEvidenceEnabled && shouldSuggestCreateEvidence(msg.replyTo)) {
            suggestionsMessage.actions.unshift({
              text: "Sugestor de provas e documentos para protocolo",
              id: ShortCutAction.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT,
              onClick: () => {
                sendMessage(
                  createEvidenceAndRequiredDocumentMessage({
                    cortexResponse: msg,
                    showSuggestedSkillsMessage: showSuggestedSkillsFlow,
                  }),
                  {
                    editorContent: msg.replyTo.editorContent,
                  }
                );
              },
            });
          }
          addMessage(suggestionsMessage);
        }

        if (suggestEvidenceEnabled && shouldSuggestCreateEvidence(msg.replyTo) && !showSuggestedSkillsFlow) {
          addMessage({
            id: uuidV4(),
            type: "ACTION",
            direction: "RECEIVED",
            author: "Lexter.ai",
            date: DateTime.now(),
            status: "READ",
            text: "Você gostaria de ver a sugestão de provas e documentos necessários para protocolo?",
            actions: [
              {
                text: "Sim",
                id: ShortCutAction.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT,
                onClick: () => {
                  sendMessage(
                    createEvidenceAndRequiredDocumentMessage({
                      cortexResponse: msg,
                      showSuggestedSkillsMessage: showSuggestedSkillsFlow,
                    }),
                    {
                      editorContent: msg.replyTo.editorContent,
                    }
                  );
                },
              },
              {
                text: "Não",
                onClick: () => {
                  setCurrentFlow({
                    ...initialChatFlow,
                    message: editorInstructionText,
                  });
                },
              },
            ],
          });
        } else if (msg.document && !documentToOpenInNewWindow && !showSuggestedSkillsFlow) {
          setCurrentFlow({
            ...initialChatFlow,
            message: editorInstructionText,
          });
        }
      } else {
        logger.error(`ACTION ERROR - retryMessageId ${retryMessageId} - !isCortexResponseMessage(msg)`);
        setMessages((prev) => [
          ...prev,
          createErrorMessage(
            { text: msg.text },
            { retry: retryMessageId ? () => retryByMessageId(retryMessageId) : undefined }
          ),
        ]);

        if (msg.insufficientCredits) {
          signalInsufficientCredits();
        }
      }
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.error(`ACTION ERROR - retryMessageId ${retryMessageId} - ${e.message}`, e);
      }
      setMessages((prev) => [
        ...prev,
        createErrorMessage(
          { text: DEFAULT_ERROR_MESSAGE },
          {
            retry: retryMessageId ? () => retryByMessageId(retryMessageId) : undefined,
            cancel: () =>
              setCurrentFlow({
                ...initialChatFlow,
                message: "Criação de peça cancelada. Deseja começar uma nova tarefa?",
              }),
          }
        ),
      ]);
    }

    setWaitingForResponse(undefined);
  };

  const uploadFile = async (file: UploadingFile): Promise<UploadedFile> => {
    const fileName = file.file.name;

    const { url, location } = await getSignedUrl({ name: fileName });

    await uploadFileToS3(url, file.file, (progress) => {
      setUploadingFiles((prev) => ({ ...prev, [file.id]: progress }));
    });

    const cortexId = await createDocument({ name: fileName, location });

    setUploadingFiles((prev) => {
      Reflect.deleteProperty(prev, file.id);
      return { ...prev };
    });

    return {
      id: file.id,
      type: "UPLOADED",
      cortexId,
      url: removeUrlSignature(url),
      name: file.file.name,
    };
  };

  const uploadFiles = async (files: UploadingFile[]) => {
    const uploadedFiles = await Promise.all(
      files.map(async (file) => {
        return uploadFile(file)
          .then((value) => value)
          .catch(
            (err): ErrorFile => ({
              id: file.id,
              type: "ERROR",
              name: file.name,
              error: `Erro no upload: ${err}`,
            })
          );
      })
    );

    return { uploadedFiles };
  };

  const createContestationWithClaims = ({ messageText, claimIds }: { messageText: string; claimIds: string[] }) => {
    const lastContestationMessage = findReverse(
      messages,
      (m) => m.type === "FLOW" && m.actions[0].id === ActionId.CREATE_CONTESTATION
    ) as FlowMessage | undefined;

    if (!lastContestationMessage) {
      return;
    }

    const contestationMessage: Message = {
      ...lastContestationMessage,
      id: uuidV4(),
      date: DateTime.now(),
      text: messageText,
      actions: [
        {
          ...lastContestationMessage.actions[0],
          claims_ids: claimIds,
          text: messageText,
        } as CreateDefenseAction,
      ],
    };

    sendMessage(contestationMessage);
  };

  const sendMessage = async (
    message: Message,
    options?: {
      references?: File[];
      editorContent?: string;
    }
  ) => {
    if (socket.threadConnectionState !== ConnectionState.OPEN) return;

    const messagesToSend = (messages: Message[]) => {
      return messages
        .filter((m) => {
          if (m.type === "INITIAL") return false;
          if (m.type === "ERROR") return false;

          const hasFileError = m.type === "FLOW" && m.files?.some((f) => f.type === "ERROR");
          return !hasFileError;
        })
        .map(messageToDTO);
    };

    let referencesUploadedFiles: UploadedFile[] = [];

    const chatMessages = messagesToSend(messages);

    const isOverLimit =
      message.type === "TEXT"
        ? (message.text?.length || 0) + (message.context?.length || 0) > MESSAGE_LENGTH_LIMIT
        : false;
    if (isOverLimit) {
      return setError(`A mensagem excede o limite de ${MESSAGE_LENGTH_LIMIT} caracteres permitidos.`);
    }

    addMessage(message);

    if (message.type === "FILE" || message.type === "FLOW") {
      let inMessageReferenceFiles: InMessageFile[] = [];
      if (options?.references?.length) {
        inMessageReferenceFiles = options.references.map(
          (file) =>
            ({
              id: uuidV4(),
              type: "UPLOADING",
              name: file.name,
              file,
            }) as InMessageFile
        );

        message.files.push(...inMessageReferenceFiles);
      }

      const { uploadedFiles } = await uploadFiles(
        message.files.filter((file: InMessageFile): file is UploadingFile => file.type === "UPLOADING")
      );
      const updatedFiles = message.files.map((file) => {
        return uploadedFiles.find((f) => f.id === file.id) || file;
      });

      setMessages((previous) =>
        previous.map((m) => {
          if (m.id === message.id) {
            return {
              ...m,
              files: updatedFiles,
            };
          }
          return m;
        })
      );

      chatMessages.push(
        messageToDTO({
          ...message,
          files: updatedFiles,
        } as Message)
      );

      referencesUploadedFiles = updatedFiles.filter(
        (file) => file.type === "UPLOADED" && inMessageReferenceFiles.some((ref) => ref.id === file.id)
      ) as UploadedFile[];

      const errorFiles = updatedFiles.filter((file): file is ErrorFile => file.type === "ERROR");
      if (errorFiles.length) {
        addMessage(
          createErrorMessage(
            {
              text: `Não foi possível fazer o upload ${errorFiles.length > 1 ? "dos arquivos" : "do arquivo"}: ${errorFiles
                .map((file) => `'${file.name}'`)
                .join(", ")}.`,
            },
            {
              retry: () => {
                const id = uuidV4();
                logger.info(`ACTION RETRY (DOWNLOAD FAILED) - id ${id}`);
                return sendMessage({
                  ...message,
                  id,
                  files: message.files.map((file) => {
                    const uploadedFile = updatedFiles.find((f) => f.id === file.id);

                    if (uploadedFile) {
                      if (uploadedFile.type === "ERROR") {
                        return {
                          ...file,
                          type: "UPLOADING",
                        } as UploadingFile;
                      }
                      return uploadedFile;
                    }

                    return file;
                  }),
                });
              },
            }
          )
        );

        return;
      }
    } else {
      chatMessages.push(messageToDTO(message));
    }

    if (referencesUploadedFiles.length) {
      startClaimsExtraction(referencesUploadedFiles);
      return;
    }

    const editorBody = await editor?.getBody();

    socket.send({
      type: WebsocketMessageType.MESSAGE,
      data: {
        chat: {
          messages: chatMessages,
          content: options?.editorContent || editorBody || "",
        },
      },
    });

    const messageActionId = message?.type === "FLOW" && message.actions.length && (message.actions[0].id as ActionId);

    let waitingType: WaitingForResponseType = WaitingForResponseType.INTERACTION_WITH_CHAT;

    if (messageActionId) {
      if ([ActionId.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT, ActionId.CREATE_CONTRACT].includes(messageActionId)) {
        waitingType = WaitingForResponseType.CREATE_DOCUMENT;
      } else if (messageActionId === ActionId.CREATE_PETITION_SUMMARY) {
        waitingType = WaitingForResponseType.CREATE_SUMMARY;
      } else {
        waitingType = WaitingForResponseType.CREATE_MOTION;
      }
    }

    showOnboardingForNewUserInFirstAction();
    setWaitingForResponse({ type: waitingType, executionId: message.id });
  };

  const [lastSkillPayload, setLastSkillPayload] = useState<SkillsPayload>();
  const startSkillProcess = async (skillPayload: SkillsPayload) => {
    if (socket.threadConnectionState !== ConnectionState.OPEN) return;

    if (skillPayload.messageToSave) {
      const message: TextMessage = {
        id: skillPayload.requestId,
        type: "TEXT",
        direction: "SENT",
        author: "Current User",
        date: DateTime.now(),
        status: "READ",
        text: skillPayload.messageToSave,
      };

      setMessages((prev) => [...prev, message]);
    }

    const skillDTOResult = await skillWithUploadedFiles(skillPayload);

    if (skillDTOResult.isFailure) {
      addMessage(
        createErrorMessage(
          {
            text: `Não foi possível fazer o upload dos arquivos.`,
          },
          {
            retry: () => {
              const id = uuidV4();
              logger.info(`ACTION RETRY (UPLOAD FAILED) - id ${id}`);
              return startSkillProcess({
                ...skillPayload,
                requestId: id,
              });
            },
          }
        )
      );
      setWaitingForResponse(undefined);
      return;
    }

    const skillDTO = skillDTOResult.getValue();

    socket.send({
      type: WebsocketMessageType.SKILLS,
      data: skillDTO,
    });

    setLastSkillPayload(skillPayload);
    setWaitingForResponse({ type: waitingTypeFromSkillId(skillPayload.skillId), executionId: skillPayload.requestId });
  };

  const skillWithUploadedFiles = async (skill: SkillsPayload): Promise<Result<SkillsPayload<UserInputDTO>>> => {
    try {
      setWaitingForResponse({ type: WaitingForResponseType.DOCUMENT_UPLOAD });

      const skillDTO = { ...skill, payload: { ...skill.payload } } as SkillsPayload<UserInputDTO>;
      await Promise.all(
        Object.keys(skillDTO.payload).map(async (key) => {
          // @ts-expect-error We are sure that the key exists
          const skillPayloadAttribute = skillDTO.payload[key] as UserInputDTO;

          if (isUserInput(skillPayloadAttribute)) {
            switch (skillPayloadAttribute.source) {
              case UserInputSource.FILE: {
                const uploadedFile = await uploadFile({
                  id: uuidV4(),
                  type: "UPLOADING",
                  name: skillPayloadAttribute.file.name,
                  file: skillPayloadAttribute.file,
                }).then((value) => value);

                // @ts-expect-error We are sure that the key exists
                skillDTO.payload[key] = {
                  source: skillPayloadAttribute.source,
                  file: uploadedFile,
                } as UserInputDTO;
                break;
              }
              case UserInputSource.CONTENT: {
                const editorText = await editor?.getBody();

                // @ts-expect-error We are sure that the key exists
                skillDTO.payload[key] = {
                  source: skillPayloadAttribute.source,
                  text: editorText,
                } as UserInputDTO;
                break;
              }
              case UserInputSource.TEXT: {
                // @ts-expect-error We are sure that the key exists
                skillDTO.payload[key] = {
                  source: skillPayloadAttribute.source,
                  text: skillPayloadAttribute.text,
                } as UserInputDTO;
              }
            }
          }
        })
      );

      return Result.ok(skillDTO);
    } catch (e) {
      logger.error("Error getting skill with uploaded files", e);
      return Result.fail(e instanceof Error ? e.message : "");
    } finally {
      setWaitingForResponse(undefined);
    }
  };

  const handleSkillsResponse = (response: SkillsResponse) => {
    const retryRequestId = lastSkillPayload?.requestId;
    try {
      if (!response.success) {
        throw new Error("Erro ao processar");
      }

      let messageToAdd: Message | undefined = undefined;

      if (response.messageToUser) {
        messageToAdd = {
          id: uuidV4(),
          type: "TEXT",
          direction: "RECEIVED",
          author: "Lexter.ai",
          date: DateTime.now(),
          status: "READ",
          text: response.messageToUser,
          copyable: response.skillId === ActionId.LEGAL_QUESTION,
        };
      }

      if (response.document) {
        const { document } = response;

        if (outsideOfficeClient()) {
          editor?.openFormattedText({
            document: {
              id: document.id,
              name: document.name,
              formattedText: document.formattedBody,
            },
          });
        } else {
          const defaultMessage = `Clique no botão abaixo para abrir o documento em uma nova janela ou para começar uma nova tarefa.`;
          response.messageToUser = response.messageToUser
            ? `${response.messageToUser} ${defaultMessage}`
            : defaultMessage;

          messageToAdd = {
            id: uuidV4(),
            type: "ACTION",
            direction: "RECEIVED",
            author: "Lexter.ai",
            date: DateTime.now(),
            status: "READ",
            text: defaultMessage,
            actions: [
              {
                text: "Abrir em novo documento",
                onClick: async () => {
                  setWaitingForResponse({ type: WaitingForResponseType.GENERIC });
                  await editor?.openFormattedText({
                    document: {
                      id: document.id,
                      name: document.name,
                      formattedText: document.formattedBody,
                    },
                    wordOptions: { openInNewWindow: true },
                  });
                  setWaitingForResponse(undefined);
                },
              },
              {
                text: "Começar nova tarefa",
                onClick: () => {
                  handleOptionSelect({
                    label: "Começar nova tarefa",
                    response: {
                      ...initialChatFlow,
                      message: "Ok! Selecione uma das opções abaixo:",
                    },
                  });
                },
              },
            ],
          };
        }
      }

      if (messageToAdd) {
        addMessage(messageToAdd);
      }

      const isLegalQuestion = response.skillId === ActionId.LEGAL_QUESTION;
      const isLegalAdvice = response.skillId === ActionId.LEGAL_ADVICE;

      const isOutsideOfficeClient = outsideOfficeClient();
      const suggestedSkillsFlowEnabled = flags.suggestedSkillsFlowEnabled;

      if ((isLegalQuestion || isLegalAdvice) && isOutsideOfficeClient && !suggestedSkillsFlowEnabled) {
        const setInitialFlow = () => {
          setCurrentFlow({
            ...initialChatFlow,
            message: "Certo, selecione a ação que deseja realizar:",
          });
        };
        const actionText = isLegalQuestion ? "Fazer nova pergunta" : isLegalAdvice ? "Elaborar novo parecer" : "Menu";
        const actionOnClick = isLegalQuestion ? askLegalQuestion : isLegalAdvice ? legalAdvice : setInitialFlow;

        addMessage({
          id: uuidV4(),
          type: "ACTION",
          direction: "RECEIVED",
          author: "Lexter.ai",
          date: DateTime.now(),
          status: "READ",
          text: "No que posso te ajudar agora?",
          actions: [
            {
              text: actionText,
              onClick: actionOnClick,
            },
            {
              text: "Outras ações",
              onClick: setInitialFlow,
            },
          ],
        });
      }

      const suggestedSkills = getSuggestedSkills(response.skillId);

      if (suggestedSkills && suggestedSkills.message && isOutsideOfficeClient && suggestedSkillsFlowEnabled) {
        addMessage({
          id: uuidV4(),
          type: "ACTION",
          direction: "RECEIVED",
          author: "Lexter.ai",
          date: DateTime.now(),
          status: "READ",
          text: suggestedSkills.message,
          actions: suggestedSkills.options
            ? suggestedSkills.options.map((option) => ({
                text: option.label,
                id: option.id,
                hidden: option.hidden,
                onClick: () => handleOptionSelect(option),
              }))
            : undefined,
        } as Message);
      }

      setLastSkillPayload(undefined);
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.error(`SKILL ERROR - retrySkillById ${retryRequestId} - ${e.message}`, e);
      }
      setMessages((prev) => [
        ...prev,
        createErrorMessage(
          { text: response.insufficientCredits ? INSUFFICIENT_CREDITS_ERROR_MESSAGE : DEFAULT_ERROR_MESSAGE },
          {
            retry: retryRequestId ? () => retrySkillById(retryRequestId) : undefined,
            cancel: () => {
              setLastSkillPayload(undefined),
                setCurrentFlow({
                  ...initialChatFlow,
                  message: "Processo cancelado. Deseja começar uma nova tarefa?",
                });
            },
          }
        ),
      ]);

      if (response.insufficientCredits) {
        signalInsufficientCredits();
      }
    }

    setWaitingForResponse(undefined);
  };

  const retrySkillById = (retryRequestId: string) => {
    if (!lastSkillPayload) return;
    if (lastSkillPayload.requestId !== retryRequestId) return;

    logger.info(`ACTION RETRY (RETRY SKILL BY REQUEST ID) - id ${retryRequestId}`);
    startSkillProcess({ ...lastSkillPayload, requestId: uuidV4() } as SkillsPayload);
  };

  const retryByMessageId = (messageId: string) => {
    const messageToRetry = messages.find((m) => m.id === messageId);
    if (!messageToRetry) return;

    logger.info(`ACTION RETRY (RETRY BY MESSAGE ID) - id ${messageId}`);
    sendMessage({ ...messageToRetry, id: uuidV4() });
  };

  const clearError = () => {
    setError("");
  };

  const [claims, setClaims] = useState<Claim[]>([]);
  const [selectedClaimIds, setSelectedClaimIds] = useState<string[]>([]);

  const claimsWithCheck: ClaimWithCheck[] = useMemo(() => {
    return claims.map((claim) => ({
      ...claim,
      checked: selectedClaimIds.includes(claim.claim_id),
    }));
  }, [claims, selectedClaimIds]);

  const displayExtractClaimsError = ({
    failedStep,
  }: {
    failedStep: WebsocketMessageType.EXTRACT_CLAIMS | WebsocketMessageType.SELECT_CLAIMS;
  }) => {
    setCurrentFlow({
      message: "Ocorreu um erro ao extrair as teses dos documentos de referência. Como deseja continuar?",
      options: [
        {
          label: "Tentar novamente",
          action:
            failedStep === WebsocketMessageType.EXTRACT_CLAIMS
              ? startClaimsExtractionRetry
              : startClaimsPrioritizationRetry,
        },
        {
          label: "Gerar sem as teses",
          action: () =>
            createContestationWithClaims({
              messageText: "Gerar sem as teses",
              claimIds: [],
            }),
        },
        {
          label: "Cancelar",
          action: () =>
            setCurrentFlow({
              ...initialChatFlow,
              message: "Criação de peça cancelada. Deseja começar uma nova tarefa?",
            }),
        },
      ],
    });
  };

  const handleExtractClaimsResponse = (data: ExtractClaimsResponse) => {
    setWaitingForResponse(undefined);

    if (!data.success) {
      displayExtractClaimsError({
        failedStep: WebsocketMessageType.EXTRACT_CLAIMS,
      });
      return;
    }

    if (!data.claims?.length) {
      setCurrentFlow({
        message: "Não foi possível extrair nenhuma tese das referências enviadas. Deseja gerar a peça sem referências?",
        options: [
          {
            label: "Gerar nova peça",
            action: () =>
              createContestationWithClaims({
                messageText: "Gerar nova peça",
                claimIds: [],
              }),
          },
          {
            label: "Cancelar",
            action: () =>
              setCurrentFlow({
                ...initialChatFlow,
                message: "Criação de peça cancelada. Deseja começar uma nova tarefa?",
              }),
          },
        ],
      });
      return;
    }

    const lastContestationMessage = findReverse(
      messages,
      (m) => m.type === "FLOW" && m.actions[0].id === ActionId.CREATE_CONTESTATION
    ) as FlowMessage | undefined;
    if (lastContestationMessage?.actions[0].id !== ActionId.CREATE_CONTESTATION) {
      return;
    }

    const initialPetitionId = lastContestationMessage.actions[0].initialPetition.file?.id;
    const initialPetition = lastContestationMessage.files.find((file) => file.id === initialPetitionId);
    if (initialPetition?.type !== "UPLOADED") {
      return;
    }

    startClaimsPrioritization({
      claimIds: data.claims.map((claim) => claim.claim_id),
      initialPetition: {
        source: "FILE",
        file: initialPetition,
      },
    });

    setClaims(data.claims);
  };

  const [startClaimsPrioritizationRetry, setStartClaimsPrioritizationRetry] = useState(() => () => {});
  const startClaimsPrioritization = ({
    claimIds,
    initialPetition,
  }: {
    claimIds: string[];
    initialPetition: UploadedFlowOutput;
  }) => {
    setStartClaimsPrioritizationRetry(() => () => startClaimsPrioritization({ claimIds, initialPetition }));

    socket.send({
      type: WebsocketMessageType.SELECT_CLAIMS,
      data: {
        claimIds,
        initialPetition,
      },
    });
    setWaitingForResponse({ type: WaitingForResponseType.SELECT_CLAIMS });
  };

  const handleSelectClaimsResponse = ({ success, claimIds = [] }: SelectClaimsResponse) => {
    setWaitingForResponse(undefined);

    if (!success) {
      displayExtractClaimsError({
        failedStep: WebsocketMessageType.SELECT_CLAIMS,
      });
      return;
    }

    setSelectedClaimIds(claimIds);

    setCurrentFlow({
      message:
        "Pronto! Com base nos dados enviados, selecione teses das peças referências enviadas. Você deseja visualizar as teses ou que eu gere a peça?",
      options: [
        {
          label: "Visualizar teses",
          action: () => navigate(ROUTE_PATHS.EXTRACTED_CLAIMS),
        },
        {
          label: "Gerar a peça agora",
          action: () =>
            createContestationWithClaims({
              messageText: "Gerar a peça agora",
              claimIds,
            }),
        },
      ],
    });
  };

  const [inputVisible, setInputVisible] = useState<boolean>(false);

  const buildActions = (oldOptions: ChatOption[], skills?: Skill[]): Action[] => {
    const enabledSkills = skills?.filter((skill) => !skill.disabled) || [];
    const enabledSkillIds = enabledSkills.map((skill) => skill.id);

    const oldOptionsWithoutNewSkills = oldOptions.filter(
      (option) => !option.skillId || !enabledSkillIds.includes(option.skillId)
    );

    const oldActions: Action[] = oldOptionsWithoutNewSkills.map((skill) => ({
      text: skill.label,
      onClick: () => handleOptionSelect(skill),
      disabled: skill.disabled,
      hidden: skill.hidden,
    }));

    const newActions: Action[] = enabledSkills.map((skill) => ({
      text: skill.name,
      onClick: () =>
        startSkill({
          skillId: skill.id,
          actionId: skill.actionId,
        }),
    }));

    return [...oldActions, ...newActions];
  };

  // Actions that are accessed via the button next to the input
  const actions = buildActions(
    [
      useChatOption,
      editDocumentOption,
      extrajudicialNoticeOption,
      createContractOption,
      legalQuestionOption,
      legalAdviceOption,
      summaryResponseOption,
      searchJurisprudenceOption,
      interviewScriptOption,
      createStrategyOption,
      createIntercurrentMotionOption,
      createFeeAgreementOption,
      createSettlementOfferOption,
      legalPieceResponseOption,
      hearingsScriptOption,
    ],
    skills
  );

  const clearChatAndStartNewSession = () => {
    if (waitingForResponse) return;

    socket.openNewThread();

    setMessages([]);

    setCurrentFlow({
      ...initialChatFlow,
      message: "Seu histórico de interações foi limpo. Qual ação deseja começar?",
    });
  };

  const cancelOngoingExecution = async (executionId?: string) => {
    const idToCancel = executionId || waitingForResponse?.executionId;
    if (!idToCancel) return;

    const MESSAGE_ERROR = "Erro ao cancelar a operação.";

    try {
      const result = await cancelExecution({ executionId: idToCancel });
      if (result == "ACCEPTED") {
        setWaitingForResponse(undefined);
        setCurrentFlow({
          ...initialChatFlow,
          message: "Operação cancelada conforme solicitado. Qual ação deseja começar?",
        });
        toast.success("Operação cancelada com sucesso.");
      } else {
        toast.error(MESSAGE_ERROR);
      }
    } catch (e) {
      toast.error(MESSAGE_ERROR);
      if (e instanceof Object && "message" in e) {
        logger.error(`Error cancelOngoingExecution: ${e.message}`, e);
      }
    }
  };

  const handleEvaluation = async ({ messageId, newEvaluation, currentEvaluation }: HandleEvaluation) => {
    try {
      setMessages((prev) => {
        return prev.map((m) => {
          if (m.id === messageId) {
            return {
              ...m,
              evaluation: newEvaluation,
            };
          }
          return m;
        });
      });
      await evaluateMessage({ messageId, evaluation: newEvaluation });
    } catch (e) {
      setMessages((prev) => {
        return prev.map((m) => {
          if (m.id === messageId) {
            return {
              ...m,
              evaluation: currentEvaluation,
            };
          }
          return m;
        });
      });
      WebToast.error("Não foi possível avaliar a mensagem.");
    }
  };

  return (
    <MessagesContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        messages,
        error,
        clearError,
        loading,
        sendMessage,
        addMessage,
        actions,
        goToChat,
        waitingForResponse,
        uploadingFiles,
        clearContext,
        inputContext,
        textActions,
        claims: claimsWithCheck,
        createContestationWithClaims,
        clearChatAndStartNewSession,
        cancelOngoingExecution,
        connectionState: socket.connectionState,
        threadConnectionState: socket.threadConnectionState,
        connectToThread,
        closeThreadConnection,
        inputVisible,
        setInputVisible,
        sendCreatePieceMessage,
        startSkillProcess,
        refetchMessages,
        loadingState,
        totalResults,
        paginationModel,
        setPaginationModel,
        handleEvaluation,
        currentSkillId,
        startSkill,
        documentCreationText,
      }}
    >
      <ReadOnlyAlert open={isDocumentReadOnlyOpen} onClose={() => setIsDocumentReadOnlyOpen(false)} />
      {children}
    </MessagesContext.Provider>
  );
};
