import { ArrowOutwardRounded as ArrowOutwardIcon } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import React from "react";

interface ActionCardProps {
  title: string;
  description: string;
  onClick?: () => void;
}
export const ActionCard = ({ title, description, onClick }: ActionCardProps) => (
  <Paper
    elevation={3}
    onClick={onClick}
    sx={{
      display: "flex",
      border: "1px solid",
      borderColor: "common.lightShade",
      cursor: "pointer",
      borderRadius: "8px",
      "&:hover": {
        borderColor: "common.mediumShade",
        "& > *": {
          borderColor: "common.mediumShade",
        },
      },
    }}
  >
    <Box
      sx={{
        width: "100%",
        padding: 3,
        display: "flex",
        flexDirection: "column",
        borderRadius: "7px",
        gap: 2,
        border: "2px solid transparent",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Typography variant="bold" textTransform="uppercase">
          {title}
        </Typography>
        <ArrowOutwardIcon fontSize="medium" sx={{ color: "common.shade" }} />
      </Box>
      <Typography variant="multiLineBody">{description}</Typography>
    </Box>
  </Paper>
);
