import { buildSkillStep } from "@/core/skillSteps/builder";

const createInitialPetitionSkillCivelStep2 = buildSkillStep({
  title: "Criar Peça Inaugural - Passo 2",
  fields: [
    {
      name: "document_name",
      type: "string",
      options: [
        {
          label: "Petição Inicial Geral",
          value: "inicial",
          description: "Ex.: Ação de obrigação de fazer, ação de indenização por danos materiais...",
        },
        {
          value: "Outros",
          description: "Peças específicas",
        },
      ],
      initialValue: "inicial",
      ui: {
        label: "Tipo de Peça:",
        widget: "radio",
      },
    },
    {
      name: "custom_document_name",
      type: "string",
      overrideField: "document_name",
      ui: {
        label: "Qual é o nome da peça?",
        widget: "textarea",
        placeholder:
          "Exemplos: Ação Monitória, Mandado de Segurança, Abertura de Inventário, Protesto Interrupitivo de Prescrição...",
      },
      condition: {
        field: "document_name",
        value: "Outros",
      },
    },
  ],
  condition: {
    field: "area",
    value: "civel",
  },
});

const createInitialPetitionSkillTrabalhistaStep2 = buildSkillStep({
  title: "Criar Peça Inaugural - Passo 2",
  fields: [
    {
      name: "document_name",
      type: "string",
      options: [
        {
          label: "Reclamação Trabalhista",
          value: "inicial",
        },
        {
          value: "Outros",
          description: "Peças específicas",
        },
      ],
      initialValue: "inicial",
      ui: {
        label: "Tipo de Peça:",
        widget: "radio",
      },
    },
    {
      name: "custom_document_name",
      type: "string",
      overrideField: "document_name",
      ui: {
        label: "Qual é o nome da peça?",
        widget: "textarea",
        placeholder: "Exemplos: Dissídio Coletivo, Inquérito para Apuração de Falta Grave, Mandado de Segurança...",
      },
      condition: {
        field: "document_name",
        value: "Outros",
      },
    },
  ],
  condition: {
    field: "area",
    value: "trabalhista",
  },
});

const createInitialPetitionSkillPrevidenciarioStep2 = buildSkillStep({
  title: "Criar Peça Inaugural - Passo 2",
  fields: [
    {
      name: "document_name",
      type: "string",
      ui: {
        label: "Qual é o nome da peça?",
        widget: "textarea",
        placeholder:
          "Exemplos: Ação de concessão de benefício previdenciário, Ação de Revisão da Vida Toda, Ação de anulação de benefício previdenciário...",
      },
    },
  ],
  condition: {
    field: "area",
    value: "previdenciario",
  },
});

const createInitialPetitionSkillTributarioStep2 = buildSkillStep({
  title: "Criar Peça Inaugural - Passo 2",
  fields: [
    {
      name: "document_name",
      type: "string",
      ui: {
        label: "Qual é o nome da peça?",
        widget: "textarea",
        placeholder: "Exemplos: Ação Declaratória; Ação de Anulação de Débito Fiscal; Ação de Repetição de Indébito...",
      },
    },
  ],
  condition: {
    field: "area",
    value: "tributario",
  },
});

const createInitialPetitionSkillCriminalStep2 = buildSkillStep({
  title: "Criar Peça Inaugural - Passo 2",
  fields: [
    {
      name: "document_name",
      type: "string",
      ui: {
        label: "Qual é o nome da peça?",
        widget: "textarea",
        placeholder: "Exemplos: Queixa-Crime, Habeas Corpus, Mandado de Segurança...",
      },
    },
  ],
  condition: {
    field: "area",
    value: "criminal",
  },
});

const createInitialPetitionSkillOutrosStep2 = buildSkillStep({
  title: "Criar Peça Inaugural - Passo 2",
  fields: [
    {
      name: "document_name",
      type: "string",
      ui: {
        label: "Qual é o nome da peça?",
        widget: "textarea",
        placeholder: "Exemplos: Mandado de Segurança, Ação Rescisória, Ação Direta de Inconstitucionalidade...",
      },
    },
  ],
  condition: {
    field: "area",
    value: "outros",
  },
});

export const createInitialPetitionSkillStep2 = [
  createInitialPetitionSkillCivelStep2,
  createInitialPetitionSkillTrabalhistaStep2,
  createInitialPetitionSkillPrevidenciarioStep2,
  createInitialPetitionSkillTributarioStep2,
  createInitialPetitionSkillCriminalStep2,
  createInitialPetitionSkillOutrosStep2,
];
