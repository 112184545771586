import { SxProps, Typography } from "@mui/material";
import React from "react";

interface ChipProps {
  children?: React.ReactNode;
  backgroundColor?: string;
  color?: string;
  sx?: SxProps;
}

export const Chip = ({ children, backgroundColor, color, sx }: ChipProps) => {
  return (
    <Typography
      variant="preTitle"
      sx={{
        backgroundColor: backgroundColor || "primary.main",
        color: color || "common.white",
        borderRadius: "20px",
        px: "8px",
        py: "4px",
        display: "flex",
        width: "fit-content",
        maxWidth: "100%",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
