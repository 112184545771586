import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "@/core/skills/types";
import { Type } from "@sinclair/typebox";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  evidences_list: Type.Optional(Type.String()),
  case_breakdown: Type.String(),
  client: Type.String(),
  area: Type.String(),
});

const actionId = ActionId.CREATE_STRATEGY;

export const createStrategySkill: Skill<typeof inputSchema> = {
  id: "create_case_strategy",
  name: "Estratégia Jurídica",
  credits: CreditPerSkill.INTERMEDIARY_SKILL,
  inputSchema,
  tags: ["litigation", "new"],
  actionId,
  type: "payload",
  getPayload: (input) => ({
    skillId: ActionId.CREATE_STRATEGY,
    requestId: uuidV4(),
    payload: {
      evidences_list: input.evidences_list,
      case_breakdown: input.case_breakdown,
      client: input.client,
      area: input.area,
    },
    messageToSave: "Elaborar estratégia",
  }),
};
