import { env } from "@/constants/environment";
import { getOfficeToken } from "@/contexts/AuthContext/hooks/useLogin/getOfficeToken";
import {
  AcceptTermsParams,
  AddActionOfInterestPayload,
  CancelExecutionPayload,
  CheckCreditsParams,
  CheckCreditsResponse,
  CreditsApiResponse,
  CreditsHistoryApiResponseItem,
  DeletePrecedentPayload,
  GetDocumentResponseType,
  GetMessagesByThreadPayload,
  GetMessagesByThreadResponse,
  GetRecordsPayload,
  GetThreadsPayload,
  GetThreadsResponse,
  Paginated,
  PrecedentPresignedUrlPayload,
  PrecedentPresignedUrlResponse,
  RenamePrecedentPayload,
  HandlePaymentPayload,
  SavePrecedentPayload,
  UserProfile,
  CheckCouponPayload,
  GetPaymentUserPortalPayload,
  RestorePlanPayload,
} from "./types";
import { getPaginationHeaders } from "./utils/getPaginationHeaders";
import { Evaluation, SkillsPayload, UserInputDTO } from "@/contexts/WebSocketContext";
import { Notification } from "@/contexts/NotificationsContext";
import { ActionDTO } from "@/contexts/MessagesContext";
import { Config } from "@/config/Config";
import { withAuthorization } from "@/hooks/useApi/utils/withAuthorization";
import axios from "axios";
import { logger } from "@/core/logger";
import { ThreadRecord } from "../records/types";

const config = Config.getConfig();

const authenticatedCopilotServer = withAuthorization({
  instance: axios.create({
    baseURL: env.API,
    headers: { ...(config.sessionSettings?.toHeaders() || {}) },
  }),
});

const authenticatedLexterApi = withAuthorization({
  instance: axios.create({
    baseURL: env.API_HOST,
    headers: {
      "x-lexter-origin": "copilot-app",
    },
  }),
  withLoginType: false,
});

export const useApi = () => {
  const getCredits = async () => {
    const response = await authenticatedCopilotServer.get<CreditsApiResponse>("/credits");
    return response.data;
  };

  const checkCredits = async ({ action }: CheckCreditsParams) => {
    const response = await authenticatedCopilotServer.post<CheckCreditsResponse>("/credits/check", { action });
    return response.data;
  };

  const getCreditsHistory = async (): Promise<CreditsHistoryApiResponseItem[]> => {
    const response = await authenticatedCopilotServer.get<CreditsHistoryApiResponseItem[]>("/credits/history");
    return response.data;
  };

  const acceptTerms = async ({ version }: AcceptTermsParams): Promise<void> => {
    await authenticatedCopilotServer.put("/acceptTerms", { version });
  };

  const getSignedUrl = async ({ name }: { name: string }): Promise<{ url: string; location: string }> => {
    const response = await authenticatedCopilotServer.post(`${env.API}/url`, { name });
    return response.data;
  };

  const createDocument = async ({ name, location }: { name: string; location: string }): Promise<string> => {
    const response = await authenticatedCopilotServer.post(`${env.API}/files`, { name, location });
    return response.data.toString();
  };

  const signupWithOffice = async () => {
    try {
      const token = await getOfficeToken();
      const headers = { headers: { Authorization: `bearer ${token}` } };
      await authenticatedCopilotServer.post(`/officeSignUp`, {}, headers);
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  };

  const addActionOfInterest = async ({ userId, companyId, token, ...payload }: AddActionOfInterestPayload) => {
    const customHeader = token ? { Authorization: `bearer ${token}` } : {};

    await authenticatedLexterApi.put(
      `/users/${userId}/actionsOfInterest`,
      {
        ...payload,
      },
      {
        headers: { ...customHeader, "x-company-id": companyId },
      }
    );
  };

  const handlePayment = async ({ ...payload }: HandlePaymentPayload) => {
    const response = await authenticatedLexterApi.post(
      `/payments`,
      {
        ...payload,
      },
      {
        headers: { "x-company-id": payload.companyId },
      }
    );

    return response.data;
  };

  const checkCoupon = async ({ couponCode, companyId }: CheckCouponPayload) => {
    const response = await authenticatedLexterApi.get(`/payments/coupons/${couponCode}`, {
      headers: { "x-company-id": companyId },
    });
    return response.data;
  };

  const restorePlan = async ({ companyId, couponCode }: RestorePlanPayload) => {
    const response = await authenticatedLexterApi.put(
      `/companiesPlan/${companyId}/restore`,
      {
        couponCode,
      },
      { headers: { "x-company-id": companyId } }
    );
    return response.data;
  };

  const getPaymentUserPortal = async ({ companyId }: GetPaymentUserPortalPayload) => {
    const response = await authenticatedLexterApi.get(`/payments/userPortal/${companyId}`, {
      headers: { "x-company-id": companyId },
    });
    return response.data;
  };

  const updateUserProfile = async (userProfile: UserProfile) => {
    const response = await authenticatedLexterApi.put(`/users/${userProfile.userId}`, userProfile);
    return response.data;
  };

  const getDocumentById = async (documentId: number) => {
    const response = await authenticatedCopilotServer.get<GetDocumentResponseType>(`/documents/${documentId}/content`);
    return response.data;
  };

  const saveEditedDocument = async (documentId: number, content: string) => {
    await authenticatedCopilotServer.post(`/documents/${documentId}/content`, { content });
  };

  const getRecords = async (props?: GetRecordsPayload) => {
    const { sortBy = "date", sortDirection = "desc", threadId, pagination, search } = props || {};

    const params = new URLSearchParams({
      sortDirection: sortDirection,
      sortBy: sortBy,
    });

    if (search) {
      params.append("search", search);
    }

    const query = params.toString();

    const url = `/threads/${threadId}/records?${query}`;

    try {
      const response = await authenticatedCopilotServer.get<Paginated<ThreadRecord>>(url, {
        headers: {
          ...getPaginationHeaders(pagination),
        },
      });
      return response.data;
    } catch (e) {
      logger.error("Error fetching documents", e);
    }

    return undefined;
  };

  const updateDocumentName = async ({ documentId, documentName }: { documentId: number; documentName: string }) => {
    await authenticatedCopilotServer.put<GetDocumentResponseType>(`/documents/${documentId}/name`, {
      name: documentName,
    });
  };

  const archiveDocument = async (documentId: number) => {
    await authenticatedCopilotServer.post(`/documents/${documentId}/archive`);
  };

  const saveNewDocument = async ({ name, content, threadId }: { name: string; content: string; threadId: string }) => {
    const response = await authenticatedCopilotServer.post<{ id: number }>(`/documents`, { name, content, threadId });
    return { id: response.data.id };
  };

  const saveOpenDocument = async ({ threadId, documentId }: { documentId: number; threadId: string }) => {
    await authenticatedCopilotServer.put(`/threads/${threadId}/documents/open/${documentId}`);
  };

  const deleteOpenDocument = async ({ threadId, documentId }: { documentId: number; threadId: string }) => {
    await authenticatedCopilotServer.delete(`/threads/${threadId}/documents/open/${documentId}`);
  };

  const getThreads = async (props?: GetThreadsPayload) => {
    const { sortBy = "lastEventDate", sortDirection = "desc", search, pagination } = props || {};
    let query = `sortBy=${sortBy}&sortDirection=${sortDirection}`;
    if (search) {
      query = `${query}&search=${search}`;
    }
    const url = `/threads?${query}`;
    const response = await authenticatedCopilotServer.get<GetThreadsResponse>(url, {
      headers: {
        ...getPaginationHeaders(pagination),
      },
    });
    return {
      data: response.data.threads,
      totalResults: response.data.total,
    };
  };

  const deleteThread = async (threadId: string) => {
    const response = await authenticatedCopilotServer.delete(`/threads/${threadId}`);
    return response.data;
  };

  const renameThread = async ({ name, threadId }: { name: string; threadId: string }) => {
    const response = await authenticatedCopilotServer.put(`/threads/${threadId}/name`, { name });
    return response.data;
  };

  const toggleFavoriteThread = async (threadId: string) => {
    const response = await authenticatedCopilotServer.put(`/threads/${threadId}/favorite`);
    return response.data;
  };

  const savePrecedent = async (payload: SavePrecedentPayload) => {
    await authenticatedCopilotServer.post("/precedents", payload);
  };

  const getPrecedentPresignedUrl = async ({ id }: PrecedentPresignedUrlPayload) => {
    const response = await authenticatedCopilotServer.get<PrecedentPresignedUrlResponse>(
      `/precedents/${id}/presignedUrl`
    );
    return response.data;
  };

  const renamePrecedent = async ({ id, name }: RenamePrecedentPayload) => {
    await authenticatedCopilotServer.put(`/precedents/${id}/name`, { name });
  };

  const deletePrecedent = async ({ id }: DeletePrecedentPayload) => {
    await authenticatedCopilotServer.delete(`/precedents/${id}`);
  };

  const addFavoriteSkill = async ({ skillId }: { skillId: string }) => {
    try {
      const response = await authenticatedCopilotServer.put(`/skills/favorites/${skillId}`);
      return response.data;
    } catch (e) {
      logger.error("addFavoriteSkill", e);
      throw e;
    }
  };

  const deleteFavoriteSkill = async ({ skillId }: { skillId: string }) => {
    try {
      const response = await authenticatedCopilotServer.delete(`/skills/favorites/${skillId}`);
      return response.data;
    } catch (e) {
      logger.error("deleteFavoriteSkill", e);
      throw e;
    }
  };

  const getFavoriteSkills = async () => {
    try {
      const response = await authenticatedCopilotServer.get(`/skills/favorites`);
      return response.data;
    } catch (e) {
      logger.error("getFavoriteSkills", e);
      throw e;
    }
  };

  const getMessages = async ({ threadId, pagination }: GetMessagesByThreadPayload) => {
    const response = await authenticatedCopilotServer.get<GetMessagesByThreadResponse>(
      `/threads/${threadId}/messages`,
      {
        headers: {
          ...getPaginationHeaders(pagination),
        },
      }
    );
    return {
      data: response.data.data,
      totalResults: response.data.totalResults,
    };
  };

  const getNotifications = async () => {
    try {
      const response = await authenticatedCopilotServer.get(`/notifications`);
      return response.data;
    } catch (e) {
      logger.error("getNotifications", e);
      throw e;
    }
  };

  const getNotificationById = async (id: number) => {
    try {
      const response = await authenticatedCopilotServer.get<Notification>(`/notifications/${id}`);
      return response.data;
    } catch (e) {
      logger.error("getNotificationById", e);
      throw e;
    }
  };

  const setNotificationOpen = async (id: number) => {
    try {
      const response = await authenticatedCopilotServer.put(`/notifications/visualize/${id}`);
      return response.data;
    } catch (e) {
      logger.error("setNotificationOpen", e);
      throw e;
    }
  };

  const evaluateMessage = async ({ messageId, evaluation }: { messageId: string; evaluation?: Evaluation }) => {
    const response = await authenticatedCopilotServer.post(`/messages/${messageId}/evaluate`, {
      evaluation: evaluation ?? null,
    });
    return response.data;
  };

  const generateThreadName = async ({
    threadId,
    data,
  }: {
    threadId: string;
    data: ActionDTO | SkillsPayload<UserInputDTO>;
  }) => {
    const response = await authenticatedCopilotServer.post(`/threads/${threadId}/name/generate`, { data });
    return response.data;
  };

  const cancelExecution = async ({ executionId }: CancelExecutionPayload) => {
    const response = await authenticatedCopilotServer.delete(`/executions/${executionId}`);
    return response.data;
  };

  return {
    getCredits,
    checkCredits,
    getCreditsHistory,
    acceptTerms,
    signupWithOffice,
    getSignedUrl,
    createDocument,
    cancelExecution,
    updateUserProfile,
    getDocumentById,
    saveEditedDocument,
    getRecords,
    updateDocumentName,
    archiveDocument,
    saveNewDocument,
    getThreads,
    deleteThread,
    renameThread,
    toggleFavoriteThread,
    saveOpenDocument,
    deleteOpenDocument,
    addActionOfInterest,
    savePrecedent,
    getPrecedentPresignedUrl,
    renamePrecedent,
    deletePrecedent,
    addFavoriteSkill,
    getFavoriteSkills,
    deleteFavoriteSkill,
    handlePayment,
    checkCoupon,
    getPaymentUserPortal,
    getMessages,
    getNotifications,
    setNotificationOpen,
    evaluateMessage,
    getNotificationById,
    generateThreadName,
    restorePlan,
  };
};
