import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import * as React from "react";
import { v4 as uuidV4 } from "uuid";

import { ActionId, FlowOutput, InMessageFile, Message } from "@/contexts/MessagesContext";
import { flowActionToText } from "@/utils/flowActionToText";
import * as logger from "../../../../core/logger";
import { InternalPageStructure } from "../../core/InternalPageStructure";
import { FileInput, FlowInput, TextInput } from "../FlowInput";

interface Step {
  name: string;
  idx: number;
}

const STEPS: {
  [key: string]: Step;
} = {
  PETITION: {
    name: "PETITION",
    idx: 0,
  },
  DEFENDANT: {
    name: "DEFENDANT",
    idx: 1,
  },
  FACTS: {
    name: "FACTS",
    idx: 2,
  },
};
const TOTAL_STEPS = Object.keys(STEPS).length;

export function LaborContestation(props: { goBack: () => void; sendMessage: (message: Message) => void }) {
  const { goBack, sendMessage } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [defenseFacts, setDefenseFacts] = React.useState<FlowOutput | null>(null);
  const [claimFile, setClaimFile] = React.useState<File>();
  const [defendant, setDefendant] = React.useState<FlowOutput | null>(null);
  const [currentStep, setCurrentStep] = React.useState<Step>(STEPS.PETITION);

  const dismissError = React.useCallback(() => {
    setError(null);
  }, []);

  const onClaimDocumentChange = React.useCallback((file?: File) => {
    setClaimFile(file);
    dismissError();
  }, []);

  const onDefendantChange = React.useCallback((input: FlowOutput | null) => {
    setDefendant(input);
    dismissError();
  }, []);

  const onDefenseFactsChange = React.useCallback((input: FlowOutput | null) => {
    setDefenseFacts(input);
    dismissError();
  }, []);

  const onSubmit = React.useCallback(() => {
    if (!defenseFacts || !claimFile) {
      setError("É preciso submeter todos os dados");
      return;
    }

    logger.info("Submitting defense message");

    const actionText = `Criar uma contestação para o arquivo ${claimFile.name} com os fatos ${flowActionToText(defenseFacts)}`;

    const claimFileId = uuidV4();

    const files: InMessageFile[] = [
      {
        id: claimFileId,
        type: "UPLOADING",
        file: claimFile,
        name: claimFile.name,
      },
    ];

    if (defenseFacts.file) {
      files.push({
        id: defenseFacts.file.id,
        type: "UPLOADING",
        file: defenseFacts.file.file,
        name: defenseFacts.file.name,
      });
    }

    sendMessage({
      id: uuidV4(),
      type: "FLOW",
      direction: "SENT",
      author: "Current User",
      date: DateTime.now(),
      status: "READ",
      text: actionText,
      actions: [
        {
          id: ActionId.CREATE_LABOR_CONTESTATION,
          laborComplaint: {
            source: "FILE",
            file: {
              id: claimFileId,
              name: claimFile.name,
              file: claimFile,
            },
          },
          userFacts: defenseFacts.source !== "NONE" ? defenseFacts : undefined,
          text: actionText,
          defendant: defendant ?? undefined,
        },
      ],
      files,
    });

    goBack();
  }, [defenseFacts, claimFile, defendant]);

  const onSubmitHandler = () => {
    switch (currentStep.name) {
      case STEPS.PETITION.name:
        setCurrentStep(STEPS.DEFENDANT);
        break;
      case STEPS.DEFENDANT.name:
        setCurrentStep(STEPS.FACTS);
        break;
      case STEPS.FACTS.name:
        onSubmit();
        break;
      default:
        throw new Error("Invalid step");
    }
  };

  const onGoBackHandler = () => {
    switch (currentStep.name) {
      case STEPS.PETITION.name:
        goBack();
        break;
      case STEPS.DEFENDANT.name:
        setCurrentStep(STEPS.PETITION);
        break;
      case STEPS.FACTS.name:
        setCurrentStep(STEPS.DEFENDANT);
        break;
      default:
        throw new Error("Invalid step");
    }
  };

  const canProceed = () => {
    switch (currentStep.name) {
      case STEPS.PETITION.name:
        return !!claimFile;
      case STEPS.DEFENDANT.name:
        return !!defendant;
      case STEPS.FACTS.name:
        return !!defenseFacts;
      default:
        throw new Error("Invalid step");
    }
  };

  return (
    <InternalPageStructure
      goBack={onGoBackHandler}
      onSubmit={onSubmitHandler}
      error={error}
      dismissError={dismissError}
      goBackButtonText={!currentStep.idx ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep.idx === TOTAL_STEPS - 1 ? "Criar Contestação" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={TOTAL_STEPS}
      currentStep={currentStep.idx}
    >
      <Box>
        <Box sx={{ mb: 1, pb: 1 }}>
          <Typography variant="preTitle" color={"text.primary"}>
            {`CRIAR CONTESTAÇÃO - PASSO ${currentStep.idx + 1}`}
          </Typography>
        </Box>

        <PetitionStep onDocumentChange={onClaimDocumentChange} show={currentStep.name === STEPS.PETITION.name} />

        <DefendantStep onDefendantChange={onDefendantChange} show={currentStep.name === STEPS.DEFENDANT.name} />

        <FactsStep onFactsChange={onDefenseFactsChange} show={currentStep.name === STEPS.FACTS.name} />
      </Box>
    </InternalPageStructure>
  );
}

export function PetitionStep({ onDocumentChange, show }: { onDocumentChange: (file?: File) => void; show: boolean }) {
  const handleFileChange = (files: File[]) => {
    onDocumentChange(files[0]);
  };

  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="multiLineBody" color={"text.primary"}>
          Para criar uma contestação, você precisa realizar o envio da reclamação trabalhista.
        </Typography>
      </Box>

      <FileInput onChange={handleFileChange} />
    </Box>
  );
}

export function DefendantStep({
  onDefendantChange,
  show,
}: {
  onDefendantChange: (input: FlowOutput | null) => void;
  show: boolean;
}) {
  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="multiLineBody" color={"text.primary"}>
          Insira abaixo o nome da(s) reclamada(s) a serem defendidos em sua contestação:
        </Typography>

        <TextInput
          onChange={onDefendantChange}
          placeholder="Digite aqui o nome da(s) reclamada(s). Se mais de um, separe com vírgula os nomes."
          minHeight={94}
        />
      </Box>
    </Box>
  );
}

export function FactsStep({
  onFactsChange,
  show,
}: {
  onFactsChange: (input: FlowOutput | null) => void;
  show: boolean;
}) {
  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="multiLineBody" color={"text.primary"}>
          Você pode adicionar o relato dos fatos da(s) reclamada(s) para melhorar a qualidade de sua contestação
          utilizando uma das opções abaixo.
        </Typography>
      </Box>

      <Box>
        <FlowInput onChange={onFactsChange} activeOptions={["CONTENT", "FILE", "NONE"]} />
      </Box>
    </Box>
  );
}
