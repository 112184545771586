import { ActionId } from "@/contexts/MessagesContext";
import { CreditsHistoryApiResponseItem } from "@/hooks/useApi/types";

export interface CreditsContextData {
  credits?: number;
  totalCredits?: number;
  periodSpend?: number;
  planType?: PlanType;
  refreshCredits: () => Promise<void>;
  loading: boolean;
  error: boolean;
  signalInsufficientCredits: () => void;
  openBilling: () => Promise<void>;
  isFreemium: boolean;
  isTrial: boolean;
  isActivePlan: boolean;
  checkCreditAvailability: (actionId: ActionId) => Promise<void>;
  nextBillingDate?: string;
  nextBillingDateFormatted?: string;
  getFilteredCommittedActions: () => Promise<CreditsHistoryApiResponseItem[]>;
  cancellationAtFormatted?: string;
}

export enum PlanType {
  TRIAL = "TRIAL",
  FREEMIUM = "FREEMIUM",
  INITIAL = "INITIAL",
  BASIC = "BASIC",
  INTERMEDIARY = "INTERMEDIARY",
  ADVANCED = "ADVANCED",
  PROFESSIONAL = "PROFESSIONAL",
  CUSTOM = "CUSTOM",
}
