import React, { useState, useMemo } from "react";

import { ActionId, Message } from "@/contexts/MessagesContext";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import { Box } from "@mui/material";
import { v4 as uuidV4 } from "uuid";
import { DateTime } from "luxon";
import { Form, TextInput, Title, Text } from "@/components/FormComponents";

enum Step {
  CONTRACT_TYPE,
  PARTIES_INFORMATION,
  CASE_DETAILS,
}

export function ContractCreation(props: { goBack: () => void; sendMessage: (message: Message) => void }) {
  const { goBack, sendMessage } = props;
  const [error, setError] = useState<string | null>(null);
  const [contractType, setContractType] = useState<string>("");
  const [firstPartyInformation, setFirstPartyInformation] = useState<string>("");
  const [secondPartyInformation, setSecondPartyInformation] = useState<string>("");
  const [caseDetails, setCaseDetails] = useState<string>("");
  const [currentStep, setCurrentStep] = useState(0);

  const caseDetailsMinLength = 140;

  const steps = useMemo(() => {
    return [Step.CONTRACT_TYPE, Step.PARTIES_INFORMATION, Step.CASE_DETAILS];
  }, []);

  const dismissError = () => {
    setError(null);
  };

  const onGoBackHandler = () => {
    if (currentStep === 0) {
      goBack();
      return;
    }

    setCurrentStep(currentStep - 1);
  };

  const onContractTypeChange = (value: string) => {
    setContractType(value);
    dismissError();
  };

  const onFirstPartyInformationChange = (value: string) => {
    setFirstPartyInformation(value);
    dismissError();
  };

  const onSecondPartyInformationChange = (value: string) => {
    setSecondPartyInformation(value);
    dismissError();
  };

  const onCaseDetailsChange = (value: string) => {
    setCaseDetails(value);
    dismissError();
  };

  const canProceed = () => {
    switch (steps[currentStep]) {
      case Step.CONTRACT_TYPE:
        return !!contractType;
      case Step.PARTIES_INFORMATION:
        return !!firstPartyInformation && !!secondPartyInformation;
      case Step.CASE_DETAILS:
        return !!caseDetails && caseDetails.length >= caseDetailsMinLength;
      default:
        throw new Error("Invalid step");
    }
  };

  const onSubmitHandler = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (!contractType || !firstPartyInformation || !secondPartyInformation || !caseDetails) {
        setError("É preciso submeter todos os dados.");
        return;
      }

      sendMessage({
        id: uuidV4(),
        type: "FLOW",
        direction: "SENT",
        author: "Current User",
        date: DateTime.now(),
        status: "READ",
        text: "Elaborar Contrato",
        actions: [
          {
            id: ActionId.CREATE_CONTRACT,
            contract_micro_type: contractType,
            party_1: firstPartyInformation,
            party_2: secondPartyInformation,
            case_breakdown: caseDetails,
          },
        ],
        files: [],
      });

      goBack();
    }
  };

  const title = `Elaborar contrato - Passo ${currentStep + 1}`;

  return (
    <InternalPageStructure
      goBack={onGoBackHandler}
      onSubmit={onSubmitHandler}
      error={error}
      dismissError={dismissError}
      goBackButtonText={currentStep === 0 ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep === steps.length - 1 ? "Gerar contrato" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={steps.length}
      currentStep={currentStep}
    >
      <Box>
        <Form show={steps[currentStep] === Step.CONTRACT_TYPE}>
          <Title>{title}</Title>
          <Text sx={{ mt: 1, mb: 2 }}>Elabore contratos com base no contexto do seu caso.</Text>
          <TextInput
            label="Qual é o tipo do contrato?"
            placeholder="Exemplo: Contrato de aluguel"
            value={contractType}
            onChange={onContractTypeChange}
          />
        </Form>
        <Form show={steps[currentStep] === Step.PARTIES_INFORMATION}>
          <Title>{title}</Title>
          <TextInput
            label="Qual é a parte contratante?"
            placeholder="Exemplo: Maria Souza"
            value={firstPartyInformation}
            onChange={onFirstPartyInformationChange}
          />
          <TextInput
            label="Qual é a parte contratada?"
            placeholder="Exemplo: João da Silva"
            value={secondPartyInformation}
            onChange={onSecondPartyInformationChange}
          />
        </Form>
        <Form show={steps[currentStep] === Step.CASE_DETAILS}>
          <Title>{title}</Title>
          <Text>
            Descreva todas as informações sobre o caso, como objeto e vigência. Para construção do contrato é necessário
            um detalhamento mínimo de {caseDetailsMinLength} caracteres
          </Text>
          <TextInput
            label="Detalhe o caso:"
            placeholder="Exemplo: Maria alugou a casa de João por R$ 2.000,00 mensais. O aluguel terá duração de 1 (um) ano, com início em 1 de Junho de 2023 até 1 de Junho de 2024."
            value={caseDetails}
            onChange={onCaseDetailsChange}
            minLength={caseDetailsMinLength}
          />
        </Form>
      </Box>
    </InternalPageStructure>
  );
}
