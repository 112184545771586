import React, { useMemo, useState, useEffect, useCallback } from "react";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import {
  ActionId,
  Area,
  FlowOutput,
  InMessageFile,
  LegalPieceMacroType,
  LegalPieceMicroType,
  Message,
  ShortCutAction,
} from "@/contexts/MessagesContext";
import { v4 as uuidV4 } from "uuid";
import { AreaOfLawSelection } from "../components/AreaOfLawSelection";
import { ClientNameInput } from "../components/ClientNameInput";
import { DetailedInput } from "../components/DetailedInput";
import { DocumentTypeSelection } from "../components/DocumentTypeSelection";
import { DateTime } from "luxon";
import { useSearchParams } from "react-router-dom";
import { actionsStateMap } from "@/core/actions";
import * as logger from "@/core/logger";

enum Step {
  AREA,
  DOCUMENT_TYPE,
  CLIENT_NAME,
  DETAILED_INPUT,
}

export function InauguralDocument(props: { goBack: () => void; sendMessage: (message: Message) => void }) {
  const { goBack, sendMessage } = props;
  const [error, setError] = useState<string | null>(null);
  const [selectedArea, setSelectedArea] = useState<Area>();
  const [customArea, setCustomArea] = useState<string>("");
  const [documentName, setDocumentName] = useState<string>("");
  const [documentType, setDocumentType] = useState<string>("");
  const [clientName, setClientName] = useState<string>("");
  const [detailedInput, setDetailedInput] = useState<FlowOutput | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [searchParams] = useSearchParams();
  const skillId = searchParams.get("skill");

  const documentMacroType = LegalPieceMacroType.INAUGURAL_DOCUMENT;

  const hasDocumentTypeStep = selectedArea === Area.CIVIL || selectedArea === Area.LABOR || selectedArea === Area.OTHER;

  const steps = useMemo(() => {
    if (hasDocumentTypeStep) {
      return [Step.AREA, Step.DOCUMENT_TYPE, Step.CLIENT_NAME, Step.DETAILED_INPUT];
    }
    return [Step.AREA, Step.CLIENT_NAME, Step.DETAILED_INPUT];
  }, [hasDocumentTypeStep]);

  const handleFetchState = useCallback(() => {
    if (skillId && skillId !== ShortCutAction.CREATE_NEW_DOCUMENT) {
      const stateData = actionsStateMap[skillId as ShortCutAction];

      if (stateData) {
        const { selectedArea, documentType, currentStep } = stateData;
        setSelectedArea(selectedArea);
        setDocumentType(documentType);
        setCurrentStep(currentStep);
      } else {
        logger.error(`No state data found for skillId: ${skillId}`);
      }
    }
  }, [skillId]);

  useEffect(() => {
    handleFetchState();
  }, [handleFetchState, skillId]);

  const dismissError = () => {
    setError(null);
  };

  const onGoBackHandler = () => {
    if (currentStep === 0) {
      goBack();
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const onAreaChange = (event: SelectChangeEvent<Area | undefined>) => {
    setSelectedArea(event.target.value as Area);
    dismissError();
  };

  const onCustomAreaChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCustomArea(event.target.value as string);
    dismissError();
  };

  const onDocumentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentType(event.target.value);
    dismissError();
  };

  const onDocumentNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentName(event.target.value);
    dismissError();
  };

  const onClientNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientName(event.target.value);
    dismissError();
  };

  const onFactsChange = (input: FlowOutput | null) => {
    setDetailedInput(input);
    dismissError();
  };

  const canProceed = () => {
    switch (steps[currentStep]) {
      case Step.AREA:
        if (selectedArea === Area.CIVIL || selectedArea === Area.LABOR) {
          return !!selectedArea;
        }
        if (selectedArea === Area.OTHER) {
          return !!selectedArea && !!customArea;
        }
        return !!selectedArea && !!documentName;
      case Step.DOCUMENT_TYPE:
        if (selectedArea === Area.OTHER) {
          return !!documentName;
        }
        if (documentType === LegalPieceMicroType.OTHER) {
          return !!documentType && !!documentName;
        }
        return !!documentType;
      case Step.CLIENT_NAME:
        return !!clientName;
      case Step.DETAILED_INPUT:
        return !!detailedInput;
      default:
        throw new Error("Invalid step");
    }
  };

  const onSubmitHandler = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (
        !clientName ||
        !selectedArea ||
        (selectedArea === Area.OTHER && !customArea) ||
        ((selectedArea === Area.CIVIL || selectedArea === Area.LABOR) && !documentType) ||
        (documentType === LegalPieceMicroType.OTHER && !documentName) ||
        !detailedInput
      ) {
        setError("É preciso submeter todos os dados.");
        return;
      }

      const messageFiles: InMessageFile[] = [];
      if (detailedInput.file) {
        messageFiles.push({
          id: detailedInput.file.id,
          type: "UPLOADING",
          file: detailedInput.file.file,
          name: detailedInput.file.name,
        });
      }

      const legalPieceMicroType =
        selectedArea === Area.OTHER || documentType === LegalPieceMicroType.OTHER || !hasDocumentTypeStep
          ? documentName
          : documentType;

      sendMessage({
        id: uuidV4(),
        type: "FLOW",
        direction: "SENT",
        author: "Current User",
        date: DateTime.now(),
        status: "READ",
        text: "Criar Peça Inaugural",
        actions: [
          {
            id: ActionId.CREATE_ONE_PIECE,
            client: clientName,
            legal_piece_macro_type: documentMacroType,
            area: selectedArea === Area.OTHER ? customArea : selectedArea,
            legal_piece_micro_type: legalPieceMicroType,
            case_breakdown:
              detailedInput && detailedInput.file
                ? {
                    source: "FILE",
                    file: {
                      id: detailedInput.file.id,
                      type: "UPLOADING",
                      name: detailedInput.file.name,
                      file: detailedInput.file.file,
                    },
                  }
                : detailedInput?.source === "NONE"
                  ? undefined
                  : detailedInput,
            reference_piece: null,
            text: "Criar Peça Inaugural",
          },
        ],
        files: messageFiles,
      });

      goBack();
    }
  };

  return (
    <InternalPageStructure
      goBack={onGoBackHandler}
      onSubmit={onSubmitHandler}
      error={error}
      dismissError={dismissError}
      goBackButtonText={currentStep === 0 ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep === steps.length - 1 ? "Criar Peça" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={steps.length}
      currentStep={currentStep}
    >
      <Box>
        <Box sx={{ mb: 3 }}>
          <Typography variant="preTitle" color={"common.black"}>
            {`Criar Peça Inaugural - Passo ${currentStep + 1}`}
          </Typography>
        </Box>
        <AreaOfLawSelection
          documentMacroType={documentMacroType}
          selectedArea={selectedArea}
          onSelectArea={onAreaChange}
          customArea={customArea}
          onCustomAreaChange={onCustomAreaChange}
          show={steps[currentStep] === Step.AREA}
          onDocumentNameChange={onDocumentNameChange}
          documentName={documentName}
        />
        {hasDocumentTypeStep && (
          <DocumentTypeSelection
            selectedArea={selectedArea}
            documentMacroType={documentMacroType}
            documentType={documentType}
            onDocumentTypeChange={onDocumentTypeChange}
            documentName={documentName}
            onDocumentNameChange={onDocumentNameChange}
            show={steps[currentStep] === Step.DOCUMENT_TYPE}
          />
        )}
        <ClientNameInput
          clientName={clientName}
          onClientNameChange={onClientNameChange}
          show={steps[currentStep] === Step.CLIENT_NAME}
        />
        <DetailedInput
          title="Detalhe o seu caso explicitando os principais fatos e argumentos jurídicos que você gostaria que fossem utilizados:"
          description='Exemplo: "João comprou um pacote de viagem, incluindo voo e hospedagem por R$1.000,00. O voo foi cancelado sem justificativa e agora João busca o reembolso e compensação por danos morais."'
          onChange={onFactsChange}
          show={steps[currentStep] === Step.DETAILED_INPUT}
          showNoFactsOption={false}
        />
      </Box>
    </InternalPageStructure>
  );
}
