import { buildSkillStep, createEditorContentField, createUploadFileField } from "@/core/skillSteps/builder";

export const createInitialPetitionSkillStep4 = buildSkillStep({
  title: "Criar Peça Inaugural - Passo 4",
  description: `# Detalhe o seu caso explicitando os principais fatos e argumentos jurídicos que você gostaria que fossem utilizados:\nExemplo: "João comprou um pacote de viagem, incluindo voo e hospedagem por R$1.000,00. O voo foi cancelado sem justificativa e agora João busca o reembolso e compensação por danos morais."`,
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      options: [
        {
          label: "Usar as informações descritas no corpo do documento aberto",
          value: "use_editor_content",
        },
        {
          label: "Upload de arquivo (DOCX ou PDF)",
          value: "upload_file",
        },
      ],
      initialValue: "use_editor_content",
      ui: {
        label: "Enviar detalhamento do caso:",
        widget: "radio",
      },
    },
    createEditorContentField({
      name: "case_breakdown_editor_content",
      overrideField: "case_breakdown",
      condition: {
        field: "case_breakdown",
        value: "use_editor_content",
      },
    }),
    createUploadFileField({
      name: "case_breakdown_file",
      overrideField: "case_breakdown",
      condition: {
        field: "case_breakdown",
        value: "upload_file",
      },
    }),
  ],
  submitButtonText: "Criar Peça",
});
