import { MenuOutlined as MenuOutlinedIcon } from "@mui/icons-material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ConfigMenu } from "@/components/ConfigMenu";
import * as React from "react";

import { useTheme } from "@mui/material";

interface HeaderProps {
  activeTab: number;
  tabs: Array<{
    id: number;
    icon: React.ReactNode;
  }>;
  onTabChange: (tabId: number) => void;
}

export function Header({ activeTab, tabs, onTabChange }: HeaderProps) {
  const [openSettings, setOpenSettings] = React.useState(false);

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  return (
    <Box
      sx={{
        height: 48,
        backgroundColor: "common.black",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pl: 2,
        pr: 2,
        zIndex: 1,
      }}
    >
      <Brand />

      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        {tabs.map((tab) => (
          <TabButton key={tab.id} active={activeTab === tab.id} onClick={() => onTabChange(tab.id)}>
            {tab.icon}
          </TabButton>
        ))}
      </Box>

      <IconButton onClick={handleOpenSettings}>
        <MenuOutlinedIcon sx={{ color: "common.white" }} />
      </IconButton>

      <ConfigMenu
        open={openSettings}
        onClose={handleCloseSettings}
        sx={{
          top: 56,
          right: 16,
        }}
      />
    </Box>
  );
}

interface TabButtonProps {
  active: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

const TabButton = ({ active, onClick, children }: TabButtonProps) => {
  const theme = useTheme();

  return (
    <IconButton
      sx={{
        borderRadius: 0,
        borderBottom: "4px solid",
        borderColor: active ? "secondary.main" : "transparent",
        "svg path": {
          fill: active ? "white" : theme.palette.common?.mediumShade,
        },
      }}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};

export const Brand = () => (
  <div>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="lexterSymbol">
        <path
          id="Vector"
          d="M23.0327 19.514L29.5769 26.0571C30.6069 24.6695 31.4287 23.1189 31.9974 21.4503L26.8119 16.2656L26.8145 16.263L26.5507 15.9992L32 10.5508C31.4314 8.88221 30.6095 7.33157 29.5796 5.94263L23.0354 12.4858L23.0327 19.514Z"
          fill="#9DEFBF"
        />
        <path
          id="Vector_2"
          d="M15.7464 26.802L16.0102 26.5382L21.4648 31.9919C23.1324 31.422 24.6819 30.599 26.0684 29.5679L19.5229 23.0234H12.4935L5.93604 29.5798C7.32388 30.6096 8.8761 31.4313 10.545 31.9999L15.7451 26.8006L15.7464 26.802Z"
          fill="#9DEFBF"
        />
        <path
          id="Vector_3"
          d="M8.98588 12.4862L2.42442 5.92578C1.39315 7.31208 0.569985 8.86139 0 10.5286L5.20807 15.7359L5.20542 15.7385L5.4692 16.0023L0.00132555 21.4719C0.571311 23.1392 1.3958 24.6885 2.42708 26.0748L8.98721 19.5157L8.98588 12.4862Z"
          fill="#9DEFBF"
        />
        <path
          id="Vector_4"
          d="M12.4937 8.97911H19.5231L26.07 2.43331C24.6834 1.4022 23.1339 0.579169 21.4663 0.00795198L16.2715 5.20192L16.2689 5.19927L16.0051 5.46301L10.5425 0C8.87366 0.568566 7.32277 1.39027 5.93359 2.42005L12.4937 8.97911Z"
          fill="#9DEFBF"
        />
      </g>
    </svg>
  </div>
);
