import * as React from "react";
const SvgCardcvc = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 15, viewBox: "0 0 24 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_3193_30123)" }, /* @__PURE__ */ React.createElement("path", { d: "M0 0.375C0 0.16875 0.16875 0 0.375 0H23.625C23.8312 0 24 0.16875 24 0.375V14.625C24 14.8312 23.8312 15 23.625 15H0.375C0.16875 15 0 14.8312 0 14.625V0.375Z", fill: "#D9D9D9" }), /* @__PURE__ */ React.createElement("mask", { id: "mask0_3193_30123", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 24, height: 15 }, /* @__PURE__ */ React.createElement("path", { d: "M0 0.375C0 0.16875 0.16875 0 0.375 0H23.625C23.8312 0 24 0.16875 24 0.375V14.625C24 14.8312 23.8312 15 23.625 15H0.375C0.16875 15 0 14.8312 0 14.625V0.375Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_3193_30123)" }, /* @__PURE__ */ React.createElement("path", { d: "M0 0.375C0 0.16875 0.16875 0 0.375 0H23.625C23.8312 0 24 0.16875 24 0.375V14.625C24 14.8312 23.8312 15 23.625 15H0.375C0.16875 15 0 14.8312 0 14.625V0.375Z", fill: "#D9D9D9", stroke: "#CBCBCB", strokeWidth: 2, strokeMiterlimit: 10 })), /* @__PURE__ */ React.createElement("mask", { id: "mask1_3193_30123", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 0, y: 2, width: 23, height: 4 }, /* @__PURE__ */ React.createElement("path", { d: "M0.993652 2.19385C0.993652 2.08135 1.06865 2.00635 1.18115 2.00635H22.7999C22.9124 2.00635 22.9874 2.08135 22.9874 2.19385V4.81885C22.9874 4.93135 22.9124 5.00635 22.7999 5.00635H1.18115C1.06865 5.00635 0.993652 4.93135 0.993652 4.81885V2.19385Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_3193_30123)" }, /* @__PURE__ */ React.createElement("path", { d: "M23.9249 1.06885H0.0561523V5.94385H23.9249V1.06885Z", fill: "black" })), /* @__PURE__ */ React.createElement("path", { d: "M21 6.99365H11.0625V12.2437H21V6.99365Z", fill: "white" }), /* @__PURE__ */ React.createElement("mask", { id: "mask2_3193_30123", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 11, y: 6, width: 10, height: 7 }, /* @__PURE__ */ React.createElement("path", { d: "M21 6.99365H11.0625V12.2437H21V6.99365Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask2_3193_30123)" }, /* @__PURE__ */ React.createElement("path", { d: "M21 6.99365H11.0625V12.2437H21V6.99365Z", fill: "white", stroke: "#D0021B", strokeWidth: 2, strokeMiterlimit: 10 })), /* @__PURE__ */ React.createElement("mask", { id: "mask3_3193_30123", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 12, y: 8, width: 8, height: 2 }, /* @__PURE__ */ React.createElement("path", { d: "M19.2751 8.5127H18.9563L18.9751 8.94394L18.6001 8.7002L18.4313 8.98145L18.8251 9.1877L18.4313 9.39394L18.6001 9.67519L18.9751 9.43145L18.9563 9.86269H19.2751L19.2563 9.43145L19.6313 9.67519L19.8001 9.39394L19.4063 9.1877L19.8001 8.98145L19.6313 8.7002L19.2563 8.94394L19.2751 8.5127ZM17.3626 8.5127H17.0438L17.0626 8.94394L16.6876 8.7002L16.5188 8.98145L16.9126 9.1877L16.5188 9.39394L16.6876 9.67519L17.0626 9.43145L17.0438 9.86269H17.3626L17.3438 9.43145L17.7188 9.67519L17.8876 9.39394L17.4938 9.1877L17.8876 8.98145L17.7188 8.7002L17.3438 8.94394L17.3626 8.5127ZM15.4501 8.5127H15.1313L15.1501 8.94394L14.7751 8.7002L14.6251 8.98145L15.0001 9.1877L14.6251 9.39394L14.7938 9.67519L15.1688 9.43145L15.1501 9.86269H15.4688L15.4501 9.43145L15.8251 9.67519L15.9938 9.39394L15.5813 9.1877L15.9751 8.98145L15.8063 8.7002L15.4313 8.94394L15.4501 8.5127ZM13.5376 8.5127H13.2188L13.2376 8.94394L12.8626 8.7002L12.6938 8.98145L13.0876 9.1877L12.6938 9.39394L12.8626 9.67519L13.2376 9.43145L13.2188 9.86269H13.5376L13.5188 9.43145L13.8938 9.67519L14.0626 9.39394L13.6688 9.1877L14.0626 8.98145L13.8938 8.7002L13.5188 8.94394L13.5376 8.5127Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask3_3193_30123)" }, /* @__PURE__ */ React.createElement("path", { d: "M20.3814 7.55664H4.27515V11.9629H20.3814V7.55664Z", fill: "black" }))), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_3193_30123" }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 15, fill: "white" }))));
export default SvgCardcvc;
