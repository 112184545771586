import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DecisionType } from "@/contexts/MessagesContext";

export const DecisionTypeStep = ({
  decisionType,
  onDecisionTypeChange,
  documentName,
  onDocumentNameChange,
  show,
  showDocumentNameField,
}: {
  decisionType?: DecisionType;
  onDecisionTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  documentName?: string;
  onDocumentNameChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  show: boolean;
  showDocumentNameField: boolean;
}) => {
  const [placeholderText, setPlaceholderText] = useState("Selecione um tipo de decisão...");

  useEffect(() => {
    switch (decisionType) {
      case DecisionType.INTERLOCUTORY_DECISIONS:
        setPlaceholderText("Exemplos: Agravo de instrumento, Agravo interno, Recurso em sentido estrito...");
        break;
      case DecisionType.TERMINATIVE_DECISIONS:
        setPlaceholderText("Exemplos: Recurso Especial, Recurso Inominado, Recurso Extraordinário...");
        break;
      default:
        setPlaceholderText("Selecione um tipo de decisão...");
    }
  }, [decisionType]);

  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="preTitle" color={"text.primary"}>
          Tipo de Decisão:
        </Typography>
        <Box sx={{ mt: 2 }}>
          <FormControl component="fieldset">
            <RadioGroup name="decision-type" value={decisionType} onChange={onDecisionTypeChange}>
              <FormControlLabel
                value={DecisionType.TERMINATIVE_DECISIONS}
                control={<Radio />}
                label="Decisões terminativas"
              />
              <FormControlLabel
                value={DecisionType.INTERLOCUTORY_DECISIONS}
                control={<Radio />}
                label="Decisões interlocutórias/monocráticas"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      {showDocumentNameField && (
        <Box sx={{ mt: 2, mb: 1, pb: 1 }}>
          <Typography variant="preTitle" color={"text.primary"}>
            Qual é o nome da peça?
          </Typography>
          <TextField
            value={documentName}
            onChange={onDocumentNameChange}
            placeholder={placeholderText}
            multiline
            fullWidth
            sx={{ mt: 3 }}
          />
        </Box>
      )}
    </Box>
  );
};
