import { buildSkillStep } from "@/core/skillSteps/builder";

export const intercurrentMotionSkillStep1 = buildSkillStep({
  title: "Elaborar Petição Intercorrente - Passo 1",
  description:
    "Elabore petições intercorrentes para juntada de documentos, produção de provas, prorrogação de prazo, baseando-se ou não em uma decisão judicial.",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o nome do cliente?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
  ],
});
