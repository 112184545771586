import { Box, Skeleton, SxProps, Typography } from "@mui/material";
import { GetMoreCredits, NextRecharge, DaysToEndTrial } from "@/components/ConfigMenu/Credits";
import { CreditsError } from "@/components/CreditsError";
import { PlanChip } from "@/components/PlanChip";
import { PlanChipSkeleton } from "@/components/PlanChip/PlanChipSkeleton";
import { useCreditsContext } from "@/contexts/CreditsContext";
import React, { useEffect } from "react";

interface CreditsCardProps {
  sx?: SxProps;
}

export const CreditsCard = ({ sx }: CreditsCardProps) => {
  const { error, credits, loading, planType, refreshCredits, openBilling, isTrial } = useCreditsContext();

  useEffect(() => {
    refreshCredits();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "common.shade",
        p: "16px",
        borderColor: "common.lightShade",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "8px",
        ...sx,
      }}
    >
      {error ? (
        <CreditsError />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            {!loading ? <PlanChip planType={planType!} /> : <PlanChipSkeleton />}

            <Typography
              variant="preTitle"
              color="common.white"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              Créditos disponíveis:
              {!loading ? (
                <Typography
                  variant="body"
                  color="common.white"
                  sx={{
                    display: "inline-block",
                  }}
                >
                  {credits?.toLocaleString("pt-BR") || "--"}
                </Typography>
              ) : (
                <Skeleton
                  variant="text"
                  width="90px"
                  sx={{
                    display: "inline-block",
                    bgcolor: "common.lightShade",
                  }}
                />
              )}
            </Typography>

            {isTrial ? <DaysToEndTrial color="common.white" /> : <NextRecharge />}
          </Box>

          {planType && <GetMoreCredits onOpenBilling={openBilling} />}
        </Box>
      )}
    </Box>
  );
};
