import { useCallback } from "react";

export const useHeap = () => {
  const addUserProperties = useCallback((properties: Record<string, string | number | undefined>) => {
    if (window.heap) {
      window.heap.addUserProperties(properties);
    }
  }, []);

  const identify = useCallback((userId: string) => {
    if (window.heap) {
      window.heap.identify(userId);
    }
  }, []);

  const track = useCallback((eventName: string, properties: Record<string, string | number>) => {
    if (window.heap) {
      window.heap.track(eventName, properties);
    }
  }, []);

  return {
    addUserProperties,
    identify,
    track,
  };
};
