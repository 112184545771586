import { Config } from "@/config/Config";
import { env } from "@/constants/environment";
import {
  ActivateAccountPayload,
  EmailAvailabilityPayload,
  EmailAvailabilityResponse,
  GetSignupSourcePayload,
  GetSignupSourceResponse,
  SendEmailVerificationCodePayload,
  SignupPayload,
} from "@/hooks/useApi/types";
import { isWordDesktop } from "@/taskpane/config";
import axios from "axios";

const config = Config.getConfig();

const copilotServer = axios.create({
  baseURL: env.API,
  headers: { ...(config.sessionSettings?.toHeaders() || {}) },
});

export const LexterCopilotAuthService = {
  activateAccount: async ({ email, code, recaptchaToken }: ActivateAccountPayload) => {
    const isDesktop = isWordDesktop();
    return copilotServer
      .post("/signup/activateAccount", { recaptchaToken, email, code, isDesktop })
      .then((res) => res.data);
  },
  getEmailAvailability: async ({ email, recaptchaToken }: EmailAvailabilityPayload) => {
    const isDesktop = isWordDesktop();
    return copilotServer
      .post<EmailAvailabilityResponse>("/signup/emailAvailability", {
        recaptchaToken,
        email,
        isDesktop,
      })
      .then((res) => res.data);
  },
  sendEmailVerificationCode: async ({ email, recaptchaToken }: SendEmailVerificationCodePayload) => {
    const isDesktop = isWordDesktop();
    return copilotServer
      .post("/signup/sendEmailVerificationCode", { recaptchaToken, email, isDesktop })
      .then((res) => res.data);
  },
  getSignupSource: async ({ email, recaptchaToken }: GetSignupSourcePayload) => {
    const isDesktop = isWordDesktop();
    return copilotServer
      .get<GetSignupSourceResponse>("/signup/source", {
        params: { recaptchaToken, email, isDesktop },
      })
      .then((res) => res.data);
  },
  signup: async (payload: SignupPayload) => {
    const isDesktop = isWordDesktop();
    return copilotServer.post("/signup", { ...payload, isDesktop }).then((res) => res.data);
  },
};
