import * as React from "react";

import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

export function LoadingChat() {
  const flags = useFeatureFlags();

  return (
    <Paper elevation={3} sx={{ height: "100%" }}>
      <Stack sx={{ height: "100%" }}>
        <Messages />
        <Divider />
        {!flags.newActionButtonInFooter && <Input />}
        <Footer />
      </Stack>
    </Paper>
  );
}

function Messages() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        pl: 2,
        pr: 2,
        pt: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <Skeleton
        variant="rounded"
        height={60}
        sx={{
          width: "70%",
          pb: 2,
          alignSelf: "flex-start",
          mb: 2,
        }}
      />

      <Skeleton
        variant="rounded"
        height={110}
        sx={{
          width: "70%",
          pb: 2,
          alignSelf: "flex-end",
          mb: 2,
        }}
      />

      <Skeleton
        variant="rounded"
        height={60}
        sx={{
          width: "70%",
          pb: 2,
          alignSelf: "flex-start",
        }}
      />
    </Box>
  );
}

export function Input() {
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        pl: 3,
        pr: 3,
        mt: 2,
      }}
    >
      <Skeleton variant="rounded" height={64} />
    </Box>
  );
}

export function Footer() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        pt: 1,
        pb: "10px",
        pl: 3,
        pr: 3,
      }}
    >
      <Skeleton variant="rounded" height={24} width={300} />
      <Skeleton variant="circular" height={24} width={24} sx={{ alignSelf: "flex-end" }} />
    </Box>
  );
}
