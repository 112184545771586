import { buildSkillStep } from "@/core/skillSteps/builder";

export const createInitialPetitionSkillStep3 = buildSkillStep({
  title: "Criar Peça Inaugural - Passo 3",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o nome do cliente?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
  ],
});
