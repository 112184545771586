import { useApi } from "@/hooks/useApi";
import { PaginationPayload } from "@/hooks/useApi/types";
import { useQuery } from "@tanstack/react-query";
import { OrderRecordsBy, OrderRecordsOptions } from "../types";

export const getRecordsQueryKey = ({ threadId }: { threadId?: string }) => ["thread", threadId, "records"];

export const orderRecordsOptions: OrderRecordsOptions = {
  CREATED_AT: "Data de Criação",
  ALPHABET_ASC: "Alfabético - AZ",
  ALPHABET_DESC: "Alfabético - ZA",
  TYPE: "Tipo",
};

const getOrderOptionsParams = (order: OrderRecordsBy) => {
  switch (order) {
    case "CREATED_AT":
      return { sortBy: "date", sortDirection: "desc" };
    case "ALPHABET_ASC":
      return { sortBy: "name", sortDirection: "asc" };
    case "ALPHABET_DESC":
      return { sortBy: "name", sortDirection: "desc" };
    case "TYPE":
      return { sortBy: "type", sortDirection: "asc" };
    default:
      return { sortBy: "date", sortDirection: "desc" };
  }
};

export const useRecords = ({
  threadId,
  search,
  order,
  pagination,
}: {
  threadId?: string;
  search?: string;
  order: OrderRecordsBy;
  pagination: PaginationPayload;
}) => {
  const api = useApi();
  const params = getOrderOptionsParams(order);
  return useQuery({
    queryKey: [...getRecordsQueryKey({ threadId }), { order, search, pagination }],
    queryFn: async () => {
      if (!threadId) {
        return undefined;
      }
      return api.getRecords({ threadId, search, pagination, ...params });
    },
    enabled: !!threadId,
  });
};
