import React, { useState, useEffect } from "react";
import { Box, Checkbox, Link, Typography } from "@mui/material";

import { useEditor } from "@/hooks/useEditor";
import CelebrationSvg from "@/assets/svgs/celebration.svg?react";
import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { EXTERNAL_URLS } from "@/routes/routePaths";
import { isWordDesktop, isWordDesktopOnMac } from "@/taskpane/config";

import ReadOnlyWindowsGif from "@/assets/images/DocumentReadOnlyWindows.gif";
import ReadOnlyMacGif from "@/assets/images/DocumentReadOnlyMac.gif";

const HAS_SEEN_TUTORIAL_KEY = "hasSeenTutorial";

export const Warnings = () => {
  const [isDocumentReadOnlyOpen, setIsDocumentReadOnlyOpen] = useState<boolean>(false);
  const [checkedNotShowAgain, setCheckedNotShowAgain] = useState(false);
  const { tutorialVideoUrl, warnReadOnlyDocument } = useFeatureFlags();
  const { editor } = useEditor();

  const handleCloseTutorial = () => {
    if (checkedNotShowAgain) {
      localStorage.setItem(HAS_SEEN_TUTORIAL_KEY, "true");
    }
  };

  useEffect(() => {
    if (!warnReadOnlyDocument || !isWordDesktop()) {
      setIsDocumentReadOnlyOpen(false);
      return;
    }

    const checkDocumentReadOnly = async () => {
      const isReadOnly = await editor?.checkIfDocumentIsReadOnly();
      setIsDocumentReadOnlyOpen(Boolean(isReadOnly));
    };

    checkDocumentReadOnly();
  }, []);

  // We don't want to show the modal if we will show the read-only alert
  const showTutorialOpen = false; // TODO delete Tutorial component - not needed anymore
  // const showTutorialOpen = isTutorialOpen && !isDocumentReadOnlyOpen && !isCheckingDocumentReadOnly && !isOutsideOffice;

  return (
    <>
      <Tutorial
        open={showTutorialOpen}
        onClose={handleCloseTutorial}
        tutorialVideoUrl={tutorialVideoUrl}
        setCheckedNotShowAgain={setCheckedNotShowAgain}
      />

      <ReadOnlyAlert open={isDocumentReadOnlyOpen} onClose={() => setIsDocumentReadOnlyOpen(false)} />
    </>
  );
};

export const Tutorial = ({
  open,
  onClose,
  tutorialVideoUrl,
  setCheckedNotShowAgain,
}: {
  open: boolean;
  onClose: () => void;
  tutorialVideoUrl?: string;
  setCheckedNotShowAgain: (checked: boolean) => void;
}) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <CelebrationSvg height={70} />

      <Typography variant="body3">
        Estamos muito felizes de você estar aqui.
        {tutorialVideoUrl && " Preparamos o vídeo abaixo para te ajudar a conhecer o Assistente Lexter."}
      </Typography>

      {tutorialVideoUrl && (
        <Box
          sx={{
            position: "relative",
            paddingTop: "56.25%" /* 16:9 */,
            height: 0,
            width: "100%",
          }}
        >
          <iframe
            width="560"
            height="315"
            src={tutorialVideoUrl}
            title="Conhecendo o Assistente Lexter"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      )}

      <Typography variant="body3">
        Caso você tenha dúvidas no uso, acesse nossa{" "}
        <Link href={EXTERNAL_URLS.HELP_CENTER_URL} target="_blank">
          Central de Ajuda
        </Link>
        .
      </Typography>

      <Button variant="contained" fullWidth onClick={onClose}>
        Iniciar o uso
      </Button>

      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          width: "100%",
          justifyContent: "flex-end",
        }}
        component="label"
      >
        <Checkbox
          sx={{
            margin: "-9px",
          }}
          onChange={(e) => {
            setCheckedNotShowAgain(e.currentTarget.checked);
          }}
        />
        <Typography variant="body2">Não visualizar novamente.</Typography>
      </Box>
    </Box>
  </Modal>
);

export const ReadOnlyAlert = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const gif = isWordDesktopOnMac() ? ReadOnlyMacGif : ReadOnlyWindowsGif;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography variant="body3">
          O documento aberto em seu Microsoft Word não permite interações do Assistente Lexter. Habilite as edições do
          documento para iniciar sua utilização.
        </Typography>

        <Box
          sx={{
            marginTop: "18px",
            marginBottom: "18px",
            width: "100%",
          }}
        >
          <img src={gif} width={"100%"} />
        </Box>

        <Typography variant="body3">
          Caso você tenha dúvidas no uso, acesse nossa{" "}
          <Link href={EXTERNAL_URLS.HELP_CENTER_URL} target="_blank">
            Central de Ajuda
          </Link>
          .
        </Typography>

        <Button variant="contained" fullWidth onClick={onClose}>
          OK
        </Button>
      </Box>
    </Modal>
  );
};
