import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";
import { Box, Link, Skeleton, Typography, alpha } from "@mui/material";
import { NextRecharge } from "@/components/ConfigMenu/Credits";
import { Button } from "@/components/Button";
import { PlanChip } from "@/components/PlanChip";
import { PlanChipSkeleton } from "@/components/PlanChip/PlanChipSkeleton";
import { useCreditsContext } from "@/contexts/CreditsContext";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { RestorePlanBanner } from "../RestorePlanBanner";

interface UsageCardProps {
  onClose: () => void;
}

export const UsageCard = ({ onClose }: UsageCardProps) => {
  const {
    error,
    loading,
    planType,
    refreshCredits,
    totalCredits,
    isFreemium,
    isTrial,
    isActivePlan,
    openBilling,
    credits,
    cancellationAtFormatted,
  } = useCreditsContext();
  const navigate = useNavigate();

  useEffect(() => {
    refreshCredits();
  }, []);

  const handleClick = async () => {
    await openBilling();
  };

  if ((!loading && typeof credits !== "number") || error) return null;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "common.white",
          borderBottom: `1px solid`,
          borderColor: "common.lightShade",
        }}
      >
        <Box
          sx={{
            gap: 2,
            paddingX: 3,
            pt: 2,
            pb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="bold"
              sx={{
                textTransform: "uppercase",
              }}
            >
              Gerencie seu uso
            </Typography>

            <CloseOutlinedIcon
              onClick={onClose}
              fontSize="medium"
              sx={{
                cursor: "pointer",
                color: "common.black",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pr: 3,
              paddingTop: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 4,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: loading ? "end" : "baseline",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "common.black",
                        fontWeight: 700,
                        textTransform: "uppercase",
                      }}
                    >
                      Créditos
                    </Typography>
                  </Box>

                  <Typography variant="body3">
                    {loading ? (
                      <Skeleton width="80px" />
                    ) : (
                      <>
                        {credits}/<strong>{totalCredits}</strong>
                      </>
                    )}
                  </Typography>
                </Box>

                <Box
                  sx={(theme) => ({
                    width: "100%",
                    backgroundColor: loading
                      ? undefined
                      : alpha(
                          isActivePlan
                            ? theme.palette.primary.main
                            : (theme.palette.common.shade ?? theme.palette.common.black),
                          0.3
                        ),
                    height: "8px",
                    borderRadius: "4px",
                    overflow: "hidden",
                    marginY: "10px",
                  })}
                >
                  {loading ? (
                    <Skeleton height="8px" variant="rectangular" />
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: isActivePlan ? "primary.main" : "common.shade",
                        height: "100%",
                        width: `${((credits || 0) / (totalCredits || 1)) * 100}%`,
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Link
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => navigate(ROUTE_PATHS.CREDITS_HISTORY)}
                  >
                    Visualizar extrato de uso
                  </Link>
                  {loading ? (
                    <Skeleton
                      variant="text"
                      width="100px"
                      sx={{
                        display: "inline-block",
                        bgcolor: "common.lightShade",
                      }}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        width: "max-content",
                        borderRadius: "20px",
                        lineHeight: "22px",
                        paddingY: 0.5,
                      }}
                      onClick={handleClick}
                    >
                      {isFreemium || isTrial ? "Contratar plano" : "Gerenciar Plano"}
                    </Button>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "8px",
                  maxWidth: "280px",
                  minWidth: "280px",
                }}
              >
                {!loading ? <PlanChip planType={planType!} /> : <PlanChipSkeleton />}
                {!loading && (
                  <>
                    {!isTrial && !cancellationAtFormatted && (
                      <NextRecharge text="Sua próxima recarga será feita em:" color="common.black" />
                    )}
                    {!!cancellationAtFormatted && (
                      <Typography variant="body3" color="error.main">
                        Sua assinatura será cancelada em{" "}
                        <strong>
                          <br />
                          {cancellationAtFormatted}
                        </strong>
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {!loading && <RestorePlanBanner />}
      </Box>
    </>
  );
};
