/* eslint-disable */
import { DeleteOutlined as DeleteOutlinedIcon } from "@mui/icons-material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";
import { v4 as uuidV4 } from "uuid";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { $getRoot, EditorState } from "lexical";

import { FlowOutput, InputType } from "@/contexts/MessagesContext";
import { UPLOAD_FILE_PAGE_LIMIT, UPLOAD_FILE_SIZE_LIMIT } from "@/contexts/MessagesContext/constants";
import { useEditor } from "@/hooks/useEditor";
import { getDocxPageCount, getPdfPageCount } from "@/utils/getPageCount";
import { Toast } from "../../core/Toast";
import { InputFlowType, isFlowInputOption } from "./types";

const PDF = "application/pdf";
const DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
const VALID_FILE_TYPES = [PDF, DOCX];

export function FlowInput(props: {
  inputId?: string;
  prefixItemIds?: string;
  activeOptions: InputFlowType[];
  onChange: (input: FlowOutput | null) => void;
}) {
  const { inputId, prefixItemIds, activeOptions, onChange } = props;

  const [value, setValue] = React.useState<InputType | null>(() => {
    if (activeOptions.length === 1) {
      const option = activeOptions[0];
      return isFlowInputOption(option) ? option.type : option;
    }

    return null;
  });

  const { currentTabIsEmpty: editorIsEmpty } = useEditor();

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value as InputType);
  }, []);

  React.useEffect(() => {
    if (value === "CONTENT") {
      if (editorIsEmpty) {
        onChange(null);
      } else {
        onChange({
          source: "CONTENT",
        });
      }
    }
  }, [editorIsEmpty, value]);

  const getDefaultOptionLabel = (optionType: InputType) => {
    switch (optionType) {
      case "CONTENT":
        return "Usar as informações descritas no corpo do documento aberto";
      case "FILE":
        return "Upload de arquivo (DOCX ou PDF)";
      case "TEXT":
        return "Inserir novo texto";
      case "NONE":
        return "Não quero adicionar fatos";
      default:
        return "";
    }
  };

  return (
    <Box>
      {activeOptions.length > 1 && (
        <Box>
          <FormControl>
            <RadioGroup
              defaultValue="CONTENT"
              name="origem-caso"
              value={value}
              onChange={handleChange}
              sx={{ pl: "10px" }}
            >
              {activeOptions.map((option, index) => {
                const optionType = isFlowInputOption(option) ? option.type : option;
                const optionLabel = isFlowInputOption(option) ? option.label : undefined;

                return (
                  <FormControlLabel
                    key={index}
                    value={optionType}
                    control={
                      <Radio
                        size="small"
                        inputProps={{
                          id: prefixItemIds ? `${prefixItemIds}_${optionType}` : undefined,
                        }}
                      />
                    }
                    label={
                      <Typography variant="body" color={"text.primary"} sx={{ textWrap: "wrap" }}>
                        {optionLabel || getDefaultOptionLabel(optionType)}
                      </Typography>
                    }
                    sx={{
                      display: "flex",
                      mb: 1,
                    }}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
      )}

      <Input id={inputId} type={value} onChange={onChange} />
    </Box>
  );
}

function Input(props: { id?: string; type: InputType | null; onChange: (input: FlowOutput | null) => void }) {
  const { id, onChange, type } = props;

  React.useEffect(() => {
    if (type === "FILE" || type === "TEXT") {
      onChange(null);
    }
  }, [type]);

  const handleFileChange = (files: File[]) => {
    if (!files.length) {
      return onChange(null);
    }

    onChange({
      source: "FILE",
      file: { id: uuidV4(), file: files[0], name: files[0].name },
    });
  };

  switch (type) {
    case "CONTENT":
      return <ContentInput onChange={onChange} />;
    case "FILE":
      return <FileInput id={id} onChange={handleFileChange} />;
    case "TEXT":
      return <TextInput id={id} onChange={onChange} autoFocus={true} />;
    case "NONE":
      return <NoneInput onChange={onChange} />;
    default:
      return <span />;
  }
}

function ContentInput(props: { onChange: (input: FlowOutput | null) => void }) {
  const { onChange } = props;
  const { currentTabIsEmpty: editorIsEmpty } = useEditor();

  React.useEffect(() => {
    if (editorIsEmpty) {
      onChange(null);
    } else {
      onChange({
        source: "CONTENT",
      });
    }
  }, [editorIsEmpty]);

  const contentText = editorIsEmpty ? (
    <Box>
      <Box sx={{ mb: 1, alignItems: "center", display: "flex" }}>
        <WarningAmberOutlinedIcon sx={{ color: "common.shade", width: 16, height: 16, mr: 1 }} />
        <Typography variant="preTitle">O documento está vazio</Typography>
      </Box>
      <Typography variant="multiLineBody">Adicione informações no corpo do documento aberto ao lado.</Typography>
    </Box>
  ) : (
    <Typography variant="multiLineBody">O texto do documento aberto será utilizado.</Typography>
  );

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        width: "100%",
        mt: 2,
      }}
    >
      <Box
        sx={{
          minHeight: 64,
          width: "100%",
          backgroundColor: "background.default",
          border: "1px solid",
          borderColor: "text.secondary",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1, p: 1 }}>
          <Box
            className="editor-container"
            sx={{
              maxHeight: 300,
              textAlign: "left",
              p: 1,
            }}
          >
            {contentText}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function FileInput(props: {
  id?: string;
  onChange: (files: File[]) => void;
  multiple?: boolean;
  maxFiles?: number;
}) {
  const { id, onChange, multiple, maxFiles } = props;
  const [files, setFiles] = React.useState<File[]>();

  const checkFiles = async (filesToCheck: File[]) => {
    const errors = await Promise.all(filesToCheck.map(checkFileError));
    errors.forEach((error) => {
      if (error) {
        Toast.warn(error);
      }
    });

    const hasSomeInvalidFiles = errors.some((error) => !!error);

    const exceedsMaxFileLimit = maxFiles ? filesToCheck.length > maxFiles : false;
    if (exceedsMaxFileLimit) {
      Toast.warn(`Você pode enviar no máximo ${maxFiles} arquivos`);
    }

    return {
      error: hasSomeInvalidFiles || exceedsMaxFileLimit,
    };
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    let droppedFiles: File[];
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      droppedFiles = Array.from(event.dataTransfer.items)
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile())
        .filter((item) => !!item) as File[];
    } else {
      // Use DataTransfer interface to access the file(s)
      droppedFiles = Array.from(event.dataTransfer.files);
    }

    if (!multiple) {
      droppedFiles = droppedFiles.slice(0, 1);
    }

    const { error } = await checkFiles(droppedFiles);
    if (error) {
      return;
    }

    setFiles(droppedFiles);
  };

  React.useEffect(() => {
    if (files) {
      onChange(files);
    }
  }, [files]);

  const handleAttachment = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files || [];
    const attachedFiles = filesList.length > 0 ? Array.from(filesList) : [];

    const { error } = await checkFiles(attachedFiles);
    if (error) {
      return;
    }

    setFiles(attachedFiles);
  }, []);

  const removeFile = React.useCallback((index: number) => {
    setFiles((prev = []) => {
      const newFiles = [...prev];
      newFiles.splice(index, 1);
      return newFiles;
    });
  }, []);

  if (files?.length) {
    return (
      <>
        {files.map((file, index) => (
          <Box
            key={index}
            sx={{
              backgroundColor: "background.paper",
              width: "100%",
              mt: 2,
            }}
          >
            <Box
              sx={{
                minHeight: 64,
                width: "100%",
                backgroundColor: "background.paper",
                border: "1px solid",
                borderColor: "text.secondary",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: 1,
                p: 2,
              }}
            >
              <Typography variant="body" sx={{ ml: 1 }}>
                {file.name}
              </Typography>

              <IconButton
                aria-label="Remover arquivo"
                size="medium"
                sx={{
                  alignSelf: "flex-start",
                  ml: "auto",
                  color: "common.shade",
                }}
                onClick={() => removeFile(index)}
              >
                <DeleteOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </>
    );
  }

  return (
    <Box
      onDrop={handleDrop}
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      sx={{
        backgroundColor: "background.paper",
        width: "100%",
        mt: 2,
      }}
    >
      <Box
        sx={{
          minHeight: 150,
          width: "100%",
          backgroundColor: "background.default",
          border: "1px solid",
          borderColor: "text.secondary",
        }}
      >
        <Button
          aria-label="Anexar arquivo"
          component="label"
          sx={{
            height: 150,
            width: "100%",
          }}
        >
          <Box
            color={"text.primary"}
            sx={{
              px: "10px",
              textAlign: "center",
              fontSize: "14px",
              textTransform: "none",
              letterSpacing: "0.03em",
              lineHeight: "140%",
            }}
          >
            <Typography variant="multiLineBody" color={"text.primary"}>
              {"Arraste e solte o arquivo nesta área ou "}
            </Typography>

            <Typography
              variant="multiLineBody"
              color={"text.primary"}
              sx={{ color: "primary.main", textDecoration: "underline", display: "inline" }}
            >
              clique aqui para selecionar o arquivo
            </Typography>
          </Box>

          <input
            id={id}
            type="file"
            hidden
            multiple={multiple}
            onChange={handleAttachment}
            accept={VALID_FILE_TYPES.join(",")}
          />
        </Button>
      </Box>
    </Box>
  );
}

export function TextInput(props: {
  id?: string;
  onChange: (input: FlowOutput | null) => void;
  autoFocus?: boolean;
  placeholder?: string;
  minHeight?: number;
}) {
  const { id, onChange, autoFocus = false, placeholder, minHeight } = props;

  const handleChange = React.useCallback(
    (editorState: EditorState) => {
      editorState.read(() => {
        const root = $getRoot();
        const text = root.getTextContent();
        onChange(
          text
            ? {
                source: "TEXT",
                text,
              }
            : null
        );
      });
    },
    [onChange]
  );

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        width: "100%",
        mt: 2,
      }}
    >
      <Box
        sx={{
          minHeight: 100,
          backgroundColor: "background.paper",
          border: "1px solid",
          borderColor: "text.secondary",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1, p: 1 }}>
          <Box
            className="editor-container"
            sx={{
              maxHeight: 300,
              minHeight,
            }}
          >
            <LexicalComposer
              initialConfig={{
                namespace: "chat-input",
                theme: {
                  ltr: "ltr",
                  rtl: "rtl",
                  placeholder: "editor-placeholder",
                  paragraph: "editor-paragraph",
                },
                onError(error) {
                  throw error;
                },
                nodes: [],
              }}
            >
              <PlainTextPlugin
                contentEditable={<ContentEditable id={id} className="editor-input" />}
                placeholder={<Placeholder placeholder={placeholder} />}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <OnChangePlugin onChange={handleChange} />
              <HistoryPlugin />
              <AutoFocusPlugin enabled={autoFocus} />
            </LexicalComposer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function NoneInput(props: { onChange: (input: FlowOutput) => void }) {
  const { onChange } = props;

  React.useEffect(() => {
    onChange({
      source: "NONE",
    });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        width: "100%",
        mt: 2,
      }}
    >
      <Box
        sx={{
          minHeight: 64,
          width: "100%",
          backgroundColor: "background.default",
          border: "1px solid",
          borderColor: "text.secondary",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1, p: 1 }}>
          <Box
            className="editor-container"
            sx={{
              maxHeight: 300,
              textAlign: "center",
              px: 1,
            }}
          >
            <Typography variant="multiLineBody" color={"text.primary"}>
              Nenhum dado será utilizado.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Placeholder({ placeholder = "Escreva uma mensagem..." }: { placeholder?: string }) {
  return <div className="editor-placeholder">{placeholder}</div>;
}

function AutoFocusPlugin({ enabled = true }) {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    if (enabled) {
      // Focus the editor when the effect fires!
      editor.focus();
    }
  }, [editor]);

  return null;
}

async function checkFileError(file: File): Promise<string | null> {
  if (!VALID_FILE_TYPES.includes(file.type)) {
    return "Somente arquivos PDF e DOCX são válidos";
  }

  let pageCount: number | null = null;
  if (file.type === PDF) {
    pageCount = await getPdfPageCount(file);
  }
  if (file.type === DOCX) {
    pageCount = await getDocxPageCount(file);
  }
  if (pageCount !== null && pageCount > UPLOAD_FILE_PAGE_LIMIT) {
    return `O limite de páginas é de ${UPLOAD_FILE_PAGE_LIMIT} por arquivo.`;
  }

  if (file.size > UPLOAD_FILE_SIZE_LIMIT) {
    return `O limite de tamanho é de ${UPLOAD_FILE_SIZE_LIMIT / (1000 * 1000)}MB por arquivo.`;
  }

  if (file.size === 0) {
    return "O conteúdo do arquivo não pode ser vazio";
  }

  return null;
}
