import { ConditionalSkillStep, SkillStep } from "@/core/skillSteps/types";
import { createAppealSkillStep1 } from "@/core/skillSteps/implementations/createAppealSkill/step1";
import { createAppealSkillStep2 } from "@/core/skillSteps/implementations/createAppealSkill/step2";
import { createAppealSkillStep3 } from "@/core/skillSteps/implementations/createAppealSkill/step3";
import { createAppealSkillStep4 } from "@/core/skillSteps/implementations/createAppealSkill/step4";
import { createAppealSkillStep5 } from "@/core/skillSteps/implementations/createAppealSkill/step5";
import { createAppealSkillStep6 } from "@/core/skillSteps/implementations/createAppealSkill/step6";

export const createAppealSkillSteps: Array<SkillStep | ConditionalSkillStep[]> = [
  createAppealSkillStep1,
  createAppealSkillStep2,
  createAppealSkillStep3,
  createAppealSkillStep4,
  createAppealSkillStep5,
  createAppealSkillStep6,
];
