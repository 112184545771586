import { buildSkillStep } from "@/core/skillSteps/builder";

export const intercurrentMotionSkillStep2 = buildSkillStep({
  title: "Elaborar Petição Intercorrente - Passo 2",
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Detalhe o motivo da sua petição intercorrente:",
        placeholder:
          "Informe as provas que pretende produzir e os motivos; solicite a prorrogação de um prazo, explicando a necessidade; indique os documentos para juntada.",
        widget: "textarea",
      },
    },
  ],
  submitButtonText: "Elaborar petição",
});
