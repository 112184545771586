import {
  ArrowForward as ArrowForwardIcon,
  LockOutlined as LockOutlinedIcon,
  MailOutlineRounded as MailOutlineRoundedIcon,
} from "@mui/icons-material";
import * as React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";

import BackgroundUrl from "@/assets/svgs/login-background.svg?react";
import { Button } from "@/components/Button";
import { MicrosoftButton } from "@/components/MicrosoftButton";
import { TextField } from "@/components/TextField";
import { env } from "@/constants/environment";
import { useApi } from "@/hooks/useApi";
import { useConfigs } from "@/hooks/useConfigs";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { ReCaptchaProvider } from "@/providers/ReCaptchaProvider";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { openUrl } from "@/utils/openUrl";
import { useAuthContext } from "../../../contexts/AuthContext";
import * as logger from "../../../core/logger";
import { Brand } from "./Brand";
import { OfficeSignUpModal } from "./OfficeSignUpModal";
import { useGtag } from "@/hooks/useGtag";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { GoogleButton } from "@/components/GoogleButton";

const IS_FIRST_RENDER_KEY = "isFirstRenderExperience";

export function Login() {
  return (
    <ReCaptchaProvider>
      <Page />
    </ReCaptchaProvider>
  );
}

function Page() {
  const { isFeatureEnabled } = useConfigs();
  const isSignUpWithOfficeEnabled = isFeatureEnabled("SIGN_UP_WITH_OFFICE");
  const { loginWithEmailAndPassword, loginWithOffice } = useAuthContext();

  const [isFre, setIsFre] = React.useState(() => {
    const isFirstRenderExperienceStored = localStorage.getItem(IS_FIRST_RENDER_KEY);
    return isFirstRenderExperienceStored !== "false";
  });
  const [error, setError] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [isLoadingLogin, setIsLoadingLogin] = React.useState<boolean>(false);
  const [isLoadingSSO, setIsLoadingSSO] = React.useState<boolean>(false);
  const [signUpWithOfficeModalOpen, setSignUpWithOfficeModalOpen] = React.useState<boolean>(false);
  const { signupWithOffice } = useApi();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const flags = useFeatureFlags();
  const { sendEvent } = useGtag();

  const onForgotPassword = React.useCallback(() => {
    logger.info("Forgot password");

    let url = env.IS_PRODUCTION
      ? "https://app.lexter.ai/esqueci_minha_senha"
      : "https://app-staging.lexter.ai/esqueci_minha_senha";

    if (email) {
      url += `?email=${email}`;
    }

    openUrl(url);
  }, [email]);

  const navigate = useNavigate();

  const onSignUp = React.useCallback(() => {
    logger.info("Sign up");

    if (flags.addInSignUpEnabled) {
      sendEvent("conversion", "AW-16462471594/ALmICInBk5gZEKrD9ak9");
      return navigate(ROUTE_PATHS.SIGNUP);
    }

    const url = new URL(env.IS_PRODUCTION ? "https://cadastro.lexter.ai" : "https://cadastro-staging.lexter.ai");
    url.searchParams.append("utm_source", "word-addin");
    url.searchParams.append("utm_campaign", "organic");

    openUrl(url.href);
  }, []);

  const onSignUpWithOffice = React.useCallback(async () => {
    setSignUpWithOfficeModalOpen(false);
    setIsLoadingSSO(true);

    logger.info("Submitting sign up SSO");

    const response = await signupWithOffice();

    if (!response.success) {
      setError("Falha ao efetuar o cadastro. Tente novamente ou entre em contato através do e-mail meajuda@lexter.ai");
    } else {
      submitSSO();
    }
    setIsLoadingSSO(false);
  }, []);

  const submitLogin = React.useCallback(async () => {
    logger.info(`Submitting login for Lexter. Email: ${email}`);

    setError("");

    if (!email || !password) {
      setError("Preencha todos os campos.");
      return;
    }

    setIsLoadingLogin(true);

    const recaptchaToken = await executeRecaptcha!();
    logger.debug(`Recaptcha received. Email: ${email}`);
    const loginData = await loginWithEmailAndPassword(email, password, recaptchaToken);

    if (!loginData.success) {
      logger.debug("submitLogin success = false");
      if (loginData.requiresActivationCode) {
        return navigate(ROUTE_PATHS.ACTIVATE_ACCOUNT, {
          state: { email },
        });
      } else if (loginData.invalidCredentials) {
        setError("Email e/ou senha inválido(s). Tente novamente.");
      } else if (loginData.emailUnverified) {
        setError(
          "Seu cadastro ainda não está completo. Confira seu e-mail, incluindo a caixa de SPAM, para finalizar a inscrição."
        );
      } else {
        logger.debug("submitLogin success = false with unknown error");
        setError("Algo deu errado. Tente Novamente ou entre em contato através do e-mail meajuda@lexter.ai");
      }

      setIsLoadingLogin(false);
    }
  }, [email, password]);

  const submitSSO = React.useCallback(async () => {
    if (isLoadingLogin) {
      return;
    }

    setIsLoadingSSO(true);
    setError("");
    logger.info("Submitting login SSO");

    const loginData = await loginWithOffice();

    if (!loginData.success && isSignUpWithOfficeEnabled && loginData.status === 401) {
      setSignUpWithOfficeModalOpen(true);
    }

    setError(
      "Não foi possível logar através do Office. Tente com usuário e senha ou entre em contato através do e-mail meajuda@lexter.ai."
    );

    setIsLoadingSSO(false);
  }, [isLoadingLogin]);

  const handleFREProceed = () => {
    localStorage.setItem(IS_FIRST_RENDER_KEY, "false");
    setIsFre(false);
  };

  const isOutsideOffice = outsideOfficeClient();

  return (
    <>
      {!isOutsideOffice && (
        <OfficeSignUpModal
          open={signUpWithOfficeModalOpen}
          onClose={() => setSignUpWithOfficeModalOpen(false)}
          onSignUpWithOffice={onSignUpWithOffice}
          onRetry={() => {
            setSignUpWithOfficeModalOpen(false);
            submitSSO();
          }}
        />
      )}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "common.black",
          position: "relative",
        }}
      >
        {!isOutsideOffice && <Background />}
        <Content>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Box
              sx={{
                marginBottom: 6,
              }}
            >
              <Typography variant="subtitle" color="common.white">
                Bem-vindo ao
              </Typography>
              <Brand />
            </Box>
            {isFre ? (
              <FRE proceed={handleFREProceed} />
            ) : (
              <>
                <EmailAndPassword
                  setEmail={setEmail}
                  setPassword={setPassword}
                  error={error}
                  isLoading={isLoadingLogin}
                  submit={submitLogin}
                />
                <ForgotPassword onForgotPassword={onForgotPassword} isOutsideOffice={isOutsideOffice} />;
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1.25rem", mb: "2rem" }}>
                  <GoogleButton setError={setError} isSomethingLoading={isLoadingLogin}>
                    Entrar com o Google
                  </GoogleButton>
                  {!isOutsideOffice && (
                    <MicrosoftButton isLoading={isLoadingSSO} disabled={isLoadingLogin} submit={submitSSO}>
                      Entrar com Office
                    </MicrosoftButton>
                  )}
                </Box>
                {isOutsideOffice && <Divider sx={{ bgcolor: "common.lightShade", maxWidth: "320px", width: "100%" }} />}
                <SignUp onSignUp={onSignUp} />
              </>
            )}
          </Box>
        </Content>
      </Box>
    </>
  );
}

interface EmailAndPasswordProps {
  setEmail: (email: string) => void;
  setPassword: (email: string) => void;
  error: string | null;
  isLoading: boolean;
  submit: () => void;
}

export function EmailAndPassword({ setEmail, setPassword, error, isLoading, submit }: EmailAndPasswordProps) {
  return (
    <Box
      sx={{ width: "100%", maxWidth: "320px", mb: 2 }}
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <Box>
        <TextField
          label="Usuário"
          placeholder="Digite seu email"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
          endIcon={MailOutlineRoundedIcon}
          sx={{ mb: 3 }}
        />

        <TextField
          label="Senha"
          type="password"
          placeholder="Digite sua senha"
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
          endIcon={LockOutlinedIcon}
        />
      </Box>

      {error ? (
        <Box sx={{ mt: 1, mb: 3 }}>
          <Typography variant="multiLinePreTitle" sx={{ color: "error.main", mt: 1, height: 30, minHeight: 30 }}>
            {error}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ mb: 4 }} />
      )}

      <Button
        type="submit"
        isLoading={isLoading}
        onClick={submit}
        variant="contained"
        sx={{ height: 40, borderRadius: "5px", width: "100%" }}
        endIcon={ArrowForwardIcon}
        textAlign="center"
      >
        Entrar
      </Button>
    </Box>
  );
}

interface ForgotPasswordProps {
  onForgotPassword: () => void;
  isOutsideOffice: boolean;
}

export function ForgotPassword({ onForgotPassword, isOutsideOffice }: ForgotPasswordProps) {
  return (
    <Typography
      variant="link"
      sx={{ color: "common.white", mb: 3, textAlign: isOutsideOffice ? "center" : "left" }}
      onClick={onForgotPassword}
    >
      Esqueci minha senha
    </Typography>
  );
}

interface FREProps {
  proceed: () => void;
}

export function FRE({ proceed }: FREProps) {
  return (
    <Box>
      <Box sx={{ p: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle" color={"common.white"} sx={{ fontSize: "16px" }}>
            <li>Escreva documentos jurídicos de forma rápida e segura;</li>
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle" color={"common.white"} sx={{ fontSize: "16px" }}>
            <li>Mantenha o padrão de escrita em todos os seus documentos;</li>
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle" color={"common.white"} sx={{ fontSize: "16px" }}>
            <li>Utilize o Assistente Lexter para otimizar o seu dia a dia.</li>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button onClick={proceed} variant="contained" sx={{ height: 56, borderRadius: "5px", width: "100%" }}>
          <Box>COMECE AGORA</Box>
        </Button>
      </Box>
    </Box>
  );
}

interface SignUpProps {
  onSignUp: () => void;
}

export function SignUp({ onSignUp }: SignUpProps) {
  return (
    <Typography
      variant="link"
      sx={{ color: "common.white", mt: outsideOfficeClient() ? 4 : 2, textAlign: "center" }}
      onClick={onSignUp}
    >
      Não tem cadastro? Clique aqui
    </Typography>
  );
}

const Background = () => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url(${BackgroundUrl})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      filter: "blur(6px)",
      zIndex: 0,
    }}
  />
);

const Content = ({ children }: { children: React.ReactChild }) => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
      display: "flex",
      overflow: "auto",
    }}
  >
    {children}
  </Box>
);
