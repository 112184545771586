import { Box, TextField, Typography } from "@mui/material";
import React from "react";

export const ClientNameInput = ({
  clientName,
  onClientNameChange,
  show,
}: {
  clientName: string;
  onClientNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  show: boolean;
}) => {
  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="preTitle" color={"text.primary"} sx={{ mb: 2 }}>
          Qual é o nome do cliente?
        </Typography>
      </Box>
      <TextField
        inputProps={{
          id: "input_client_name",
        }}
        value={clientName}
        onChange={onClientNameChange}
        placeholder="Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas."
        multiline
        fullWidth
        sx={{ mb: 2 }}
      />
    </Box>
  );
};
