import React from "react";
import { Box, Divider as MUIDivider, List, ListItem, Typography, Link } from "@mui/material";
import { EXTERNAL_URLS, ROUTE_PATHS } from "@/routes/routePaths";
import { usePaymentContext } from "@/contexts/PaymentContext";
import { CouponButton } from "./CouponButton";
import { PaymentResume } from "./PaymentResume";
import { SelectPlan } from "./SelectPlan";
import { localeToPtBr } from "@/utils/transformToBRL";
import { useNavigate } from "react-router-dom";
import { Chip } from "@/components/Chip";

export const PlanInformation = () => {
  const { removeCoupon, selectedPlan } = usePaymentContext();
  const navigate = useNavigate();

  const handleViewAllPlans = () => {
    removeCoupon();
    navigate(ROUTE_PATHS.PLANS);
  };

  return (
    <Box sx={{ py: 3, minWidth: "47%", maxWidth: "47%" }}>
      {selectedPlan.recommend && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "relative",
            top: "48px",
            right: "30px",
            fontWeight: "600",
          }}
        >
          <Chip>Recomendado</Chip>
        </Box>
      )}
      <Box
        sx={{
          bgcolor: "common.white",
          border: "1px solid black",
          borderColor: "common.lightShade",
          borderRadius: "5px",
          px: 4,
          py: 4,
          mb: 3,
          gap: 2,
        }}
      >
        <SelectPlan />
        <Typography variant="body">
          Utilize o melhor da inteligência artificial para otimizar tarefas jurídicas no seu dia a dia.
        </Typography>
        <Divider />
        <PlanResume />
        <Divider />
        <CouponButton />
        <Divider />
        <PaymentResume />
      </Box>
      <Link
        sx={{
          textAlign: "center",
          textDecoration: "underline",
          cursor: "pointer",
          display: "block",
        }}
        onClick={handleViewAllPlans}
      >
        Visualizar todos os planos
      </Link>
    </Box>
  );
};

const PlanResume = () => {
  const { selectedPlan } = usePaymentContext();

  return (
    <Box>
      <Typography variant="body">
        <strong>Inclui: </strong>
      </Typography>
      <List
        sx={{
          background: "none",
          border: "none",
          listStyleType: "disc",
          pl: "18px",
          "& .MuiListItem-root": {
            p: 0,
            display: "list-item",
            "& .MuiTypography-root": {
              whiteSpace: "normal",
            },
          },
        }}
      >
        <ListItem>
          <Typography variant="body">
            {localeToPtBr(selectedPlan.credits)} créditos/mês{" "}
            <Link href={EXTERNAL_URLS.CREDITS_INFO} target="_blank">
              (Entenda como os créditos funcionam)
            </Link>{" "}
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body">
            Ações básicas como roteiro de entrevistas, sugestão de provas, entre outros.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body">Ações intermediárias como busca de jurisprudência, resumos, etc.</Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body">
            Ações avançadas como elaboração de peças judiciais, extrajudiciais, contratos, entre outras.
          </Typography>
        </ListItem>
      </List>
    </Box>
  );
};

const Divider = () => {
  return <MUIDivider sx={{ my: 3 }} />;
};
