import { Box, SxProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface TitleProps {
  children: ReactNode;
  color?: string;
  sx?: SxProps;
}

export const Title = ({ children, color = "common.black", sx }: TitleProps) => {
  return (
    <Box sx={{ mb: 1, ...sx }}>
      <Typography variant="preTitle" color={color} sx={{ textWrap: "wrap" }}>
        {children}
      </Typography>
    </Box>
  );
};
