import { ConditionalSkillStep, SkillStep } from "@/core/skillSteps/types";
import { createInitialPetitionSkillStep4 } from "./step4";
import { createInitialPetitionSkillStep3 } from "./step3";
import { createInitialPetitionSkillStep2 } from "./step2";
import { createInitialPetitionSkillStep1 } from "./step1";

export const createInitialPetitionSkillSteps: Array<SkillStep | ConditionalSkillStep[]> = [
  createInitialPetitionSkillStep1,
  createInitialPetitionSkillStep2,
  createInitialPetitionSkillStep3,
  createInitialPetitionSkillStep4,
];
