export const TEXT_SELECTION_MAX_LENGTH = 100000;
export const MESSAGE_LENGTH_LIMIT = 8000;
export const UPLOAD_FILE_PAGE_LIMIT = 50; // 50 pages
export const UPLOAD_FILE_SIZE_LIMIT = 50 * 1000 * 1000; // 50 MB

export const REPLACE_ENTIRE_DOCUMENT_ACTION_ID = "REPLACE_ENTIRE_DOCUMENT";
export const REPLACE_SELECTION_ACTION_ID = "REPLACE_SELECTION";
export const SEND_FAQ_ACTION_ID = "SEND_FAQ";
export const START_STRATEGY_FLOW_ACTION_ID = "START_STRATEGY_FLOW";
export const START_INITIAL_PETITION_FLOW_ACTION_ID = "START_INITIAL_PETITION_FLOW";
export const START_REPLICATION_CONTESTATION_ACTION_ID = "START_REPLICATION_CONTESTATION";
export const START_NEW_DOCUMENT_FLOW_ACTION_ID = "START_NEW_DOCUMENT_FLOW";
export const START_CREATE_RESPONSE_TO_CONTESTATION_STRATEGY = "START_CREATE_CONTESTATION_STRATEGY";
export const START_CREATE_RESPONSE_TO_CONTESTATION = "START_CREATE_CONTESTATION";
export const START_PETITION_SUMMARY_FLOW_ACTION_ID = "START_PETITION_SUMMARY_FLOW";
export const DOWNLOAD_ASSISTANT_ANSWER_ACTION_ID = "DOWNLOAD_ASSISTANT_ANSWER";
export const START_APPEAL_FLOW_ACTION_ID = "START_APPEAL_FLOW";
export const START_LABOR_COMPLAINT_FLOW_ACTION_ID = "START_LABOR_COMPLAINT_FLOW";
export const START_LABOR_CONTESTATION_FLOW_ACTION_ID = "START_LABOR_CONTESTATION_FLOW";
export const START_LABOR_REPLICATION_FLOW_ACTION_ID = "START_LABOR_REPLICATION_FLOW";
export const START_ORDINARY_APPEAL_FLOW_ACTION_ID = "START_ORDINARY_APPEAL_FLOW";
const DEFAULT_PAGE_SIZE_MESSAGES = 10;
export const INITIAL_PAGINATION_MESSAGES = {
  pageSize: DEFAULT_PAGE_SIZE_MESSAGES,
  page: 1,
};
