import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import * as React from "react";
import { v4 as uuidV4 } from "uuid";

import { SelectReferences } from "@/components/SelectReferences";
import { ActionId, FlowOutput, InMessageFile, useMessagesContext } from "@/contexts/MessagesContext";
import { useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { flowActionToText } from "@/utils/flowActionToText";
import * as logger from "../../core/logger";
import { FileInput, FlowInput, TextInput } from "../../taskpane/components/Chat/FlowInput";
import {
  InternalPageStructure,
  InternalPageStructureProps,
} from "../../taskpane/components/core/InternalPageStructure";

enum Step {
  PETITION,
  DEFENDANT,
  FACTS,
  SELECT_REFERENCES,
}

export function Contestation() {
  const { sendMessage } = useMessagesContext();
  const navigate = useNavigate();
  const location = useLocation();
  const withReferences = !!location.state?.withReferences;
  const [error, setError] = React.useState<string | null>(null);
  const [defenseFacts, setDefenseFacts] = React.useState<FlowOutput | null>(null);
  const [claimFile, setClaimFile] = React.useState<File>();
  const [defendant, setDefendant] = React.useState<FlowOutput | null>(null);
  const [references, setReferences] = useState<File[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const steps = useMemo(() => {
    const workflowSteps = [Step.PETITION, Step.DEFENDANT, Step.FACTS];
    if (withReferences) {
      workflowSteps.push(Step.SELECT_REFERENCES);
    }
    return workflowSteps;
  }, []);

  const dismissError = React.useCallback(() => {
    setError(null);
  }, []);

  const onClaimDocumentChange = React.useCallback((file?: File) => {
    setClaimFile(file);
    dismissError();
  }, []);

  const onDefendantChange = React.useCallback((input: FlowOutput | null) => {
    setDefendant(input);
    dismissError();
  }, []);

  const onDefenseFactsChange = React.useCallback((input: FlowOutput | null) => {
    setDefenseFacts(input);
    dismissError();
  }, []);

  const onSubmit = React.useCallback(() => {
    if (!defenseFacts || !claimFile) {
      setError("É preciso submeter todos os dados");
      return;
    }

    logger.info("Submitting defense message");

    if (defenseFacts) {
      const actionText = `Criar uma contestação para o arquivo ${claimFile.name} com os fatos ${flowActionToText(defenseFacts)}`;

      const claimFileId = uuidV4();

      const files: InMessageFile[] = [
        {
          id: claimFileId,
          type: "UPLOADING",
          file: claimFile,
          name: claimFile.name,
        },
      ];

      if (defenseFacts.file) {
        files.push({
          id: defenseFacts.file.id,
          type: "UPLOADING",
          file: defenseFacts.file.file,
          name: defenseFacts.file.name,
        });
      }

      sendMessage(
        {
          id: uuidV4(),
          type: "FLOW",
          direction: "SENT",
          author: "Current User",
          date: DateTime.now(),
          status: "READ",
          text: actionText,
          actions: [
            {
              id: ActionId.CREATE_CONTESTATION,
              initialPetition: {
                source: "FILE",
                file: { id: claimFileId, name: claimFile.name, file: claimFile },
              },
              userFacts: defenseFacts.source !== "NONE" ? defenseFacts : undefined,
              text: actionText,
              defendant: defendant ?? undefined,
            },
          ],
          files,
        },
        {
          references,
        }
      );

      navigate(ROUTE_PATHS.CHAT);
    }
  }, [defenseFacts, claimFile, defendant, references]);

  const onSubmitHandler = () => {
    const isFinalStep = currentStep === steps.length - 1;
    if (!isFinalStep) {
      setCurrentStep(currentStep + 1);
      return;
    }

    onSubmit();
  };

  const onGoBackHandler = () => {
    if (currentStep === 0) {
      navigate(-1);
      return;
    }

    setCurrentStep(currentStep - 1);
  };

  const internalPageStructureProps: Omit<InternalPageStructureProps, "children"> = useMemo(
    () => ({
      onSubmit: onSubmitHandler,
      goBack: onGoBackHandler,
      goBackButtonText: currentStep === 0 ? "Cancelar" : "Voltar",
      submitButtonText: currentStep === steps.length - 1 ? "Criar Contestação" : "Continuar",
      totalSteps: steps.length,
      currentStep,
      error,
      title: `CRIAR CONTESTAÇÃO - PASSO ${currentStep + 1}`,
    }),
    [currentStep, steps, onSubmitHandler, onGoBackHandler]
  );

  return (
    <>
      <PetitionStep
        onDocumentChange={onClaimDocumentChange}
        hidden={steps[currentStep] !== Step.PETITION}
        {...internalPageStructureProps}
        submitButtonDisabled={!claimFile}
      />

      <DefendantStep
        onDefendantChange={onDefendantChange}
        hidden={steps[currentStep] !== Step.DEFENDANT}
        {...internalPageStructureProps}
        submitButtonDisabled={!defendant}
      />

      <FactsStep
        onFactsChange={onDefenseFactsChange}
        hidden={steps[currentStep] !== Step.FACTS}
        {...internalPageStructureProps}
        submitButtonDisabled={!defenseFacts}
      />

      <SelectReferences
        onChange={setReferences}
        hidden={steps[currentStep] !== Step.SELECT_REFERENCES}
        {...internalPageStructureProps}
      />
    </>
  );
}

interface PetitionStepProps extends Omit<InternalPageStructureProps, "children"> {
  onDocumentChange: (file?: File) => void;
}

export function PetitionStep({ onDocumentChange, ...internalPageStructureProps }: PetitionStepProps) {
  const handleFileChange = (files: File[]) => {
    onDocumentChange(files[0]);
  };

  return (
    <InternalPageStructure {...internalPageStructureProps}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="multiLineBody" color={"text.primary"}>
          Para criar uma contestação, você precisa realizar o envio da petição inicial do caso.
        </Typography>
      </Box>

      <FileInput onChange={handleFileChange} />
    </InternalPageStructure>
  );
}

interface DefendantStepProps extends Omit<InternalPageStructureProps, "children"> {
  onDefendantChange: (input: FlowOutput | null) => void;
}

export function DefendantStep({ onDefendantChange, ...internalPageStructureProps }: DefendantStepProps) {
  return (
    <InternalPageStructure {...internalPageStructureProps}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="multiLineBody" color={"text.primary"}>
          Insira abaixo o nome do(s) réu(s) a serem defendidos em sua contestação:
        </Typography>

        <TextInput
          onChange={onDefendantChange}
          placeholder="Digite aqui o nome do(s) réu(s). Se mais de um, separe com vírgula os nomes."
          minHeight={94}
        />
      </Box>
    </InternalPageStructure>
  );
}

interface FactsStepProps extends Omit<InternalPageStructureProps, "children"> {
  onFactsChange: (input: FlowOutput | null) => void;
}

export function FactsStep({ onFactsChange, ...internalPageStructureProps }: FactsStepProps) {
  return (
    <InternalPageStructure {...internalPageStructureProps}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="multiLineBody" color={"text.primary"}>
          Você pode adicionar o relato dos fatos do(s) réu(s) para melhorar a qualidade de sua contestação utilizando
          uma das opções abaixo.
        </Typography>
      </Box>

      <Box>
        <FlowInput onChange={onFactsChange} activeOptions={["CONTENT", "FILE", "NONE"]} />
      </Box>
    </InternalPageStructure>
  );
}
