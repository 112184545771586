import { SkillId } from "@/core/skills/types";
import {
  createAppealSkillSteps,
  createInitialPetitionSkillSteps,
  createStrategySkillSteps,
  feeAgreementSkillSteps,
  hearingsScriptSkillSteps,
  intercurrentMotionSkillSteps,
  interviewScriptSkillSteps,
  legalAdviceSkillSteps,
  legalQuestionSkillSteps,
  settlementOfferSkillSteps,
  summarizeDocumentSkillSteps,
} from "./implementations";
import { ConditionalSkillStep, SkillStep } from "./types";

const skillStepsMap = new Map<SkillId, Array<SkillStep | ConditionalSkillStep[]>>([
  ["create_initial_petition", createInitialPetitionSkillSteps],
  ["hearings_script", hearingsScriptSkillSteps],
  ["settlement_offer", settlementOfferSkillSteps],
  ["fee_agreement", feeAgreementSkillSteps],
  ["legal_advice", legalAdviceSkillSteps],
  ["create_appeal", createAppealSkillSteps],
  ["summary_procedural_documents", summarizeDocumentSkillSteps],
  ["legal_questions", legalQuestionSkillSteps],
  ["create_case_strategy", createStrategySkillSteps],
  ["intercurrent_motion", intercurrentMotionSkillSteps],
  ["create_interview_script", interviewScriptSkillSteps],
]);

export const SkillSteps = {
  async getSkillSteps(id: SkillId) {
    const steps = skillStepsMap.get(id);
    if (!steps) {
      return null;
    }
    return steps;
  },
};
