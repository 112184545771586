import React from "react";
import { DialogModal } from "@/components/DialogModal";
import { CircularProgress, Box, Typography } from "@mui/material";

interface DeleteThreadModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  chatName: string;
  isLoading: boolean;
}

export const DeleteThreadModal = ({ isOpen, onConfirm, onCancel, chatName, isLoading }: DeleteThreadModalProps) => {
  return (
    <DialogModal
      open={isOpen}
      onClose={onCancel}
      title="EXCLUIR CHAT"
      description={
        <Box display="flex" alignItems={"center"} justifyContent={"center"} width="100%" px={1}>
          {isLoading ? (
            <Box display="flex" color="secondary" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Typography variant="body1" sx={{ color: "text.primary" }}>
              Tem certeza que deseja excluir o chat <strong>{chatName}</strong>?
            </Typography>
          )}
        </Box>
      }
      descriptionTextAlign="left"
      buttonsDirection="row"
      buttons={[
        {
          label: "EXCLUIR",
          onClick: onConfirm,
          color: "error",
          disabled: isLoading,
        },
        {
          label: "VOLTAR",
          onClick: isLoading ? undefined : onCancel,
          sx: {
            color: "text.primary",
            backgroundColor: "transparent",
            textDecoration: "underline",
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          },
        },
      ]}
      variant="error"
    />
  );
};
