import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import SmallScreenError from "@/assets/svgs/small-screen-error.svg?react";
import LexterLogoIcon from "@/assets/svgs/lexter-logo.svg?react";
import { Link } from "@mui/material";
import { EXTERNAL_URLS } from "@/routes/routePaths";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

const ScreenSizeChecker = ({ children }: React.PropsWithChildren) => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const flags = useFeatureFlags();

  const checkConditions = () => {
    const isMobile = window.innerWidth <= 721 || window.innerHeight <= 480;
    const isOutsideOfficeClient = outsideOfficeClient();
    setShowMessage(isMobile && isOutsideOfficeClient);
  };

  useEffect(() => {
    checkConditions();
    window.addEventListener("resize", checkConditions);
    return () => window.removeEventListener("resize", checkConditions);
  }, []);

  return (
    <>
      {children}
      <Modal open={showMessage} onClose={() => {}}>
        <Box
          sx={{
            overflow: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "common.black",
            textAlign: "center",
            padding: 3,
            position: "fixed",
            top: 0,
            left: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxWidth: "380px",
              height: "auto",
              margin: "auto",
            }}
          >
            <SmallScreenError />
            <Typography
              color="common.white"
              sx={{ textAlign: "center", width: "85%", maxWidth: "400px", fontSize: "26px", fontWeight: 700, mt: 3 }}
            >
              SUA TELA É MUITO PEQUENA
            </Typography>
            <Typography
              variant="body3"
              color="common.white"
              sx={{
                textAlign: "center",
                width: "85%",
                fontWeight: 400,
                lineHeight: "22.4px",
                mt: 3,
                mb: 3,
              }}
            >
              Por enquanto o Assistente Lexter funciona apenas em telas de dispositivos desktop com resolução maior que
              721px de largura. Caso esteja usando o navegador redimensionado ajuste sua dimensão para utilizar a
              plataforma. Caso tenha dúvidas, acesse nossa{" "}
              <Link
                href={EXTERNAL_URLS.HELP_CENTER_URL}
                target="_blank"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  position: "relative",
                  "::after": {
                    content: "''",
                    position: "absolute",
                    width: "100%",
                    height: 2,
                    bottom: 0,
                    left: 0,
                    backgroundColor: "white",
                  },
                }}
              >
                Central de Ajuda
              </Link>
              {flags.customerServiceWhatsApp && flags.customerServiceWhatsApp.length > 0 && (
                <>
                  {" "}
                  ou entre em contato pelo nosso{" "}
                  <Link
                    href={flags.customerServiceWhatsApp}
                    target="_blank"
                    sx={{
                      color: "white",
                      textDecoration: "none",
                      position: "relative",
                      "::after": {
                        content: "''",
                        position: "absolute",
                        width: "100%",
                        height: 2,
                        bottom: 0,
                        left: 0,
                        backgroundColor: "white",
                      },
                    }}
                  >
                    WhatsApp
                  </Link>
                </>
              )}
              .
            </Typography>
            <LexterLogoIcon height={53} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ScreenSizeChecker;
