import { ActionId, MessageDTO, ResponseMessage, UploadedFile, UploadedFlowOutput } from "@/contexts/MessagesContext";
import { FormattedText } from "@/hooks/useEditor/types";
import { Claim } from "@/types/claim";
import { EventEmitter } from "@/utils/eventEmitter";

export interface WebSocketContextData {
  connectionState: ConnectionState;
  threadConnectionState: ConnectionState;
  cleanUpStatus: boolean;
  send: ({ type, data }: WebsocketSendParams) => void;
  openNewThread: (params?: { threadId?: string }) => void;
  closeThread: () => void;
  messageReceivedEvent: EventEmitter<OnMessageReceivedParam>;
  threadConnectedEvent: EventEmitter<OnThreadConnectParams>;
  notificationReceivedEvent: EventEmitter<OnNotificationReceivedParams>;
  thread: { id: string; name: string } | undefined;
  isGeneratingThreadName: boolean;
}

export enum ConnectionState {
  CLOSED,
  CONNECTING,
  OPEN,
}

export enum WebsocketMessageType {
  MESSAGE = "MESSAGE",
  NOTIFICATION = "NOTIFICATION",
  EXTRACT_CLAIMS = "EXTRACT_CLAIMS",
  SELECT_CLAIMS = "SELECT_CLAIMS",
  THREAD_CONNECT = "THREAD_CONNECT",
  SKILLS = "SKILLS",
}

export enum PrecedentScore {
  ALTA = "Alta",
  MEDIA = "Média",
  BAIXA = "Baixa",
}

export interface Precedent {
  title: string;
  court_code: string;
  cnj_unique_number: string;
  headnote: string;
  headnote_gen?: string | null;
  full_text?: {
    location?: string;
    presigned_url_location?: string;
  };
  judgment_date?: string | null;
  publication_date?: string | null;
  release_date?: string | null;
  signature_date?: string | null;
  score: PrecedentScore;
  score_reason: string;
  url: string;
  url_court?: string;
}

export type WebsocketSendParams =
  | { type: WebsocketMessageType.MESSAGE; data: MessagePayload }
  | { type: WebsocketMessageType.EXTRACT_CLAIMS; data: ExtractClaimsPayload }
  | { type: WebsocketMessageType.SELECT_CLAIMS; data: SelectClaimsPayload }
  | { type: WebsocketMessageType.SKILLS; data: SkillsPayload<UserInputDTO> };

export interface MessagePayload {
  chat: {
    messages: MessageDTO[];
    content: string;
  };
}

export interface ExtractClaimsPayload {
  documentIds: string[];
  cortexDocumentIds: number[];
}
export interface ExtractClaimsResponse {
  success: boolean;
  claims?: Claim[];
}

export interface SelectClaimsPayload {
  claimIds: string[];
  initialPetition: UploadedFlowOutput;
}
export interface SelectClaimsResponse {
  success: boolean;
  claimIds?: string[];
}

interface JurisprudenceSearchPayload extends BaseSkillsPayload {
  skillId: ActionId.SEARCH_JURISPRUDENCE;
  payload: {
    case_breakdown: string;
    court_code?: string[];
    min_date?: string;
  };
}

interface JurisprudenceSearchResponse extends BaseSkillsResponse {
  skillId: ActionId.SEARCH_JURISPRUDENCE;
  payload: {
    precedents: Precedent[];
  };
}

interface EditDocumentPayload extends BaseSkillsPayload {
  skillId: ActionId.EDIT_DOCUMENT;
  payload: {
    to_edit_text: string;
    user_instructions: string;
    document_text: string;
  };
}
interface EditDocumentResponse extends BaseSkillsResponse {
  skillId: ActionId.EDIT_DOCUMENT;
  payload: {
    suggested_text: string;
  };
}

interface LegalQuestionPayload extends BaseSkillsPayload {
  skillId: ActionId.LEGAL_QUESTION;
  payload: {
    area: string;
    question: string;
  };
}
interface LegalQuestionResponse extends BaseSkillsResponse {
  skillId: ActionId.LEGAL_QUESTION;
  payload: {
    answer: string;
  };
}

interface HearingsScriptPayload<Input> extends BaseSkillsPayload {
  skillId: ActionId.HEARINGS_SCRIPT;
  payload: {
    reference_piece_complaint: Input;
    reference_piece_answer: Input;
    client: string;
    witness: string;
    case_breakdown: string;
  };
}
interface HearingsScriptResponse extends BaseSkillsResponse {
  skillId: ActionId.HEARINGS_SCRIPT;
}

interface SummarizeDocumentPayload<Input> extends BaseSkillsPayload {
  skillId: ActionId.SUMMARIZE_DOCUMENT;
  payload: {
    procedural_document: Input;
  };
}
interface SummarizeDocumentResponse extends BaseSkillsResponse {
  skillId: ActionId.SUMMARIZE_DOCUMENT;
}

interface CreateStrategyPayload extends BaseSkillsPayload {
  skillId: ActionId.CREATE_STRATEGY;
  payload: {
    evidences_list?: string;
    case_breakdown: string;
    client: string;
    area: string;
  };
}
interface CreateStrategyResponse extends BaseSkillsResponse {
  skillId: ActionId.CREATE_STRATEGY;
}

interface IntercurrentMotionPayload extends BaseSkillsPayload {
  skillId: ActionId.INTERCURRENT_MOTION;
  payload: {
    case_breakdown: string;
    client: string;
  };
}
interface IntercurrentMotionResponse extends BaseSkillsResponse {
  skillId: ActionId.INTERCURRENT_MOTION;
}

interface FeeAgreementPayload extends BaseSkillsPayload {
  skillId: ActionId.FEE_AGREEMENT;
  payload: {
    case_breakdown: string;
    client: string;
  };
}
interface FeeAgreementResponse extends BaseSkillsResponse {
  skillId: ActionId.FEE_AGREEMENT;
}

interface LegalAdvicePayload extends BaseSkillsPayload {
  skillId: ActionId.LEGAL_ADVICE;
  payload: {
    case_breakdown: string;
    client: string;
  };
}
export interface LegalAdviceResponse extends BaseSkillsResponse {
  skillId: ActionId.LEGAL_ADVICE;
}

export interface SettlementOfferPayload<Input> extends BaseSkillsPayload {
  skillId: ActionId.SETTLEMENT_OFFER;
  payload: {
    reference_piece: Input;
    case_breakdown?: string;
    client: string;
    area: string;
  };
}
export interface SettlementOfferResponse extends BaseSkillsResponse {
  skillId: ActionId.SETTLEMENT_OFFER;
}

interface BaseSkillsPayload {
  skillId: ActionId;
  requestId: string;
  messageToSave?: string;
}
export interface BaseSkillsResponse {
  skillId: ActionId;
  requestId: string;
  success: boolean;
  document?: SkillDocumentResponse;
  messageToUser?: string;
  insufficientCredits?: boolean;
}

interface SkillDocumentResponse {
  id: number;
  name: string;
  formattedBody: FormattedText;
}

export enum UserInputSource {
  CONTENT = "CONTENT",
  FILE = "FILE",
  TEXT = "TEXT",
}

export type UserInputContent = {
  source: UserInputSource.CONTENT;
};

export type UserInputText = {
  source: UserInputSource.TEXT;
  text: string;
};

export type UserInputFile = {
  source: UserInputSource.FILE;
  file: File;
};

export interface UserInputDTO {
  source: UserInputSource;
  text?: string;
  file?: UploadedFile;
}

export type UserInput = UserInputContent | UserInputText | UserInputFile;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUserInput = (value: any): value is UserInput => {
  return Object.values(UserInputSource).includes(value?.source);
};

export type SkillsPayload<Input = UserInput> =
  | EditDocumentPayload
  | JurisprudenceSearchPayload
  | LegalQuestionPayload
  | CreateStrategyPayload
  | SummarizeDocumentPayload<Input>
  | LegalAdvicePayload
  | IntercurrentMotionPayload
  | FeeAgreementPayload
  | SettlementOfferPayload<Input>
  | HearingsScriptPayload<Input>;
export type SkillsResponse =
  | EditDocumentResponse
  | JurisprudenceSearchResponse
  | LegalQuestionResponse
  | CreateStrategyResponse
  | SummarizeDocumentResponse
  | LegalAdviceResponse
  | IntercurrentMotionResponse
  | FeeAgreementResponse
  | SettlementOfferResponse
  | HearingsScriptResponse;

export type OnMessageReceivedParam =
  | { type: WebsocketMessageType.MESSAGE; data: ResponseMessage }
  | { type: WebsocketMessageType.EXTRACT_CLAIMS; data: ExtractClaimsResponse }
  | { type: WebsocketMessageType.SELECT_CLAIMS; data: SelectClaimsResponse }
  | { type: WebsocketMessageType.SKILLS; data: SkillsResponse };

export interface MessageFile {
  id: string;
  cortexId: string;
  url: string;
  name: string;
}

export enum MessageStatus {
  COMPLETED = "COMPLETED",
  PROCESSING = "PROCESSING",
  CANCELED = "CANCELED",
  ERROR = "ERROR",
}

export type Evaluation = "THUMBS_DOWN" | "THUMBS_UP";

export interface ThreadDocument {
  id: number;
  name: string;
  content: string;
}

export enum ThreadMessageErrorType {
  INSUFFICIENT_CREDITS = "INSUFFICIENT_CREDITS",
  UNKNOWN = "UNKNOWN",
}

export interface ThreadMessage {
  id: string;
  threadId: string;
  author: string;
  text: string;
  context: string | null;
  createdAt: string;
  evaluation?: Evaluation;
  payload: unknown;
  canceledAt: string | null;
  status: MessageStatus;
  files: MessageFile[];
  errorType: ThreadMessageErrorType | null;
  actionId: ActionId | null;
  replyToMessageId: string | null;
}

export interface OnThreadConnectParams {
  threadId: string;
  threadName: string;
  messages: ThreadMessage[];
  openDocumentsIds: number[];
}

export interface OnNotificationReceivedParams {
  id: number;
}

export enum ThreadErrorType {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  ERROR_GETTING_THREAD = "ERROR_GETTING_THREAD",
  THREAD_NOT_FOUND = "THREAD_NOT_FOUND",
  THREAD_ALREADY_CONNECTED = "THREAD_ALREADY_CONNECTED",
  THREAD_CONNECTED_IN_OTHER_INSTANCE = "THREAD_CONNECTED_IN_OTHER_INSTANCE",
  ERROR_GETTING_MESSAGES = "ERROR_GETTING_MESSAGES",
}
