import { buildSkillStep } from "@/core/skillSteps/builder";

export const feeAgreementSkillStep1 = buildSkillStep({
  title: "Elaborar contrato de honorários advocatícios - Passo 1",
  description:
    "Insira um resumo do caso e informações importantes para o contrato, como dados do cliente, valores de honorários, forma de pagamento, multas, datas de vigência, etc.",
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Detalhe o caso:",
        placeholder:
          "Exemplo: Contratação de serviços advocatícios para atuar em processo de divórcio judicial entre a Silvana e seu marido. Os honorários cobrados serão de R$ 10.000 reais somado com 10% do patrimônio total a ser partilhado no divórcio.",
        widget: "textarea",
      },
    },
  ],
});
