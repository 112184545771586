import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "../../types";
import { Type } from "@sinclair/typebox";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  case_breakdown: Type.String(),
  client: Type.String(),
});

const actionId = ActionId.FEE_AGREEMENT;

export const feeAgreementSkill: Skill<typeof inputSchema> = {
  id: "fee_agreement",
  name: "Contrato de honorários",
  credits: CreditPerSkill.INTERMEDIARY_SKILL,
  inputSchema,
  tags: ["consultative", "new"],
  actionId,
  type: "payload",
  getPayload: (input) => ({
    skillId: ActionId.FEE_AGREEMENT,
    requestId: uuidV4(),
    payload: {
      case_breakdown: input.case_breakdown,
      client: input.client,
    },
    messageToSave: "Elaborar contrato de honorários",
  }),
};
