import { FlowOutput } from "@/contexts/MessagesContext";
import { UserInput, UserInputSource } from "@/contexts/WebSocketContext";

export const flowOutputToUserInput = (input: FlowOutput | null): UserInput | null => {
  switch (input?.source) {
    case "CONTENT": {
      return {
        source: UserInputSource.CONTENT,
      };
    }
    case "TEXT": {
      return {
        source: UserInputSource.TEXT,
        text: input.text || "",
      };
    }
    case "FILE": {
      if (input.file?.file) {
        return {
          source: UserInputSource.FILE,
          file: input.file.file,
        };
      } else {
        return null;
      }
    }
    default: {
      return null;
    }
  }
};
