import { InputType } from "@/contexts/MessagesContext";

export type InputFlowType = InputType | FlowInputOption;

export interface FlowInputOption {
  type: InputType;
  label?: string;
}
export const isFlowInputOption = (item: InputFlowType): item is FlowInputOption => {
  return (item as FlowInputOption).type !== undefined;
};
