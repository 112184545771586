import {
  AddCircleOutline as AddCircleOutlineIcon,
  ArrowBack as ArrowBackIcon,
  ImportExport as ImportExportIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useState } from "react";

import { useMessagesContext } from "@/contexts/MessagesContext";
import { useWebEditorContext } from "@/contexts/WebEditorContext";
import { useEditorUI } from "@/hooks/useEditorUI";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { theme } from "@/theme";
import { formattedDateTimeFromISO } from "@/utils/dates/formattedDateTimeFromISO";
import { ArrowOutward, DeleteForever, Edit } from "@mui/icons-material";
import { OrderRecordsBy, OrderRecordsOptions, RecordType, ThreadRecord } from "@/hooks/records/types";
import { ArchiveDocumentModal } from "../ArchiveDocumentModal";
import { RenameDocumentModal } from "../RenameDocumentModal";
import { PrecedentContent } from "./PrecedentContent";
import { orderRecordsOptions, useRecords } from "@/hooks/records/useRecords";
import { useSocket } from "@/contexts/WebSocketContext";

type DocumentsProps = {
  goBack: () => void;
};

const HEADER_HEIGHT = 188;

export const Documents = ({ goBack }: DocumentsProps) => {
  const { thread } = useSocket();

  const { handleOpenDocument, openNewDocumentTab } = useWebEditorContext();
  const { waitingForResponse, sendCreatePieceMessage, currentPage } = useMessagesContext();
  const [isOpenRenameModal, setIsOpenRenameModal] = React.useState(false);
  const [isOpenArchiveModal, setIsOpenArchiveModal] = React.useState(false);
  const [currentEditingRecord, setCurrentEditingRecord] = React.useState<ThreadRecord | null>(null);
  const { openModal } = useEditorUI();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [currentListOrder, setCurrentListOrder] = React.useState<OrderRecordsBy>("CREATED_AT");
  const [orderMenuAnchorEl, setOrderMenuAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const [currentQuerySearch, setCurrentQuerySearch] = useState<string>("");
  const [query, setQuery] = React.useState<string>(currentQuerySearch);

  const { data: paginatedRecords, isLoading } = useRecords({
    pagination: { ...paginationModel, page: paginationModel.page + 1 },
    search: query,
    order: currentListOrder,
    threadId: thread?.id,
  });

  const handleUpdateOrder = (order: OrderRecordsBy) => {
    setOrderMenuAnchorEl(null);
    setCurrentListOrder(order);
  };

  const searchQuery = async (text: string = query) => {
    if (text === currentQuerySearch) return;
    setCurrentQuerySearch(text);
  };

  const clearQuery = () => {
    setQuery("");
    searchQuery("");
  };

  const handleOpenRenameModal = (record: ThreadRecord) => {
    setCurrentEditingRecord(record);
    setIsOpenRenameModal(true);
  };

  const handleCloseRenameModal = () => {
    setIsOpenRenameModal(false);
    setCurrentEditingRecord(null);
  };

  const handleOpenArchiveModal = (record: ThreadRecord) => {
    setCurrentEditingRecord(record);
    setIsOpenArchiveModal(true);
  };

  const handleCloseArchiveModal = () => {
    setIsOpenArchiveModal(false);
    setCurrentEditingRecord(null);
  };

  const handleOpenRecord = async (record: ThreadRecord) => {
    switch (record.type) {
      case RecordType.DOCUMENT: {
        handleOpenDocument(record.id);
        goBack();
        break;
      }
      case RecordType.PRECEDENT: {
        openModal(<PrecedentContent precedent={record} />);
        break;
      }
    }
  };

  const openNewDocument = () => {
    openNewDocumentTab();
    goBack();
  };

  const handleCreateNewPiece = () => {
    goBack();

    if (currentPage === "CHAT" && location.pathname !== ROUTE_PATHS.PETITION_SUMMARY && !waitingForResponse) {
      sendCreatePieceMessage();
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        padding: 3,
        width: "100%",
        backgroundColor: "background.default",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 2,
        }}
      >
        <Typography variant="h3" sx={{ fontSize: "20px" }}>
          Documentos
        </Typography>
        <GoBackButton goBack={goBack} />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
          paddingY: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Button
            onClick={openNewDocument}
            variant="contained"
            sx={{ flex: 1, justifyContent: "space-between" }}
            endIcon={<AddCircleOutlineIcon />}
          >
            <Typography variant="button">NOVO DOCUMENTO</Typography>
          </Button>
          <Box sx={{ flex: 1 }}>
            <Button
              variant="outlined"
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                width: "100%",
                color: "text.primary",
                borderColor: "text.primary",
                ":hover": {
                  borderColor: "text.primary",
                },
              }}
              endIcon={<ImportExportIcon />}
              onClick={(event) => {
                setOrderMenuAnchorEl(event.currentTarget);
              }}
              id="order-menu-button"
            >
              <Typography
                sx={{ display: "flex", gap: 2, fontFamily: "inherit", textTransform: "none" }}
                variant="button"
              >
                ORDENAR POR:
                <Typography
                  sx={{ color: "text.primary", fontFamily: "inherit", textTransform: "none" }}
                  variant="body3"
                >
                  {orderRecordsOptions[currentListOrder]}
                </Typography>
              </Typography>
            </Button>
            <OrderMenu
              listOptions={orderRecordsOptions}
              closeOrderMenu={() => setOrderMenuAnchorEl(null)}
              isOrderMenuOpen={Boolean(orderMenuAnchorEl)}
              orderMenuAnchorEl={orderMenuAnchorEl}
              handleUpdateOrder={handleUpdateOrder}
            />
          </Box>
        </Box>
        <TextField
          hiddenLabel
          sx={{
            "& .MuiInputBase-root": {
              borderColor: "common.lightShade",
              backgroundColor: "common.white",
              padding: "0 8px",
              height: "42px",
            },
            "& .MuiInputBase-input": {
              padding: "0",
            },

            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "common.lightShade",
              },
            },
          }}
          focused
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: Boolean(query) ? (
              <InputAdornment position="end">
                <IconButton onClick={clearQuery} size="small" sx={{ color: "primary.dark" }}>
                  <CloseIcon sx={{ fontSize: 16 }} />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          value={query}
          placeholder="Buscar..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchQuery();
            }
          }}
          onBlur={() => {
            searchQuery();
          }}
        />
      </Box>
      <Divider />
      <Box
        marginTop={3}
        width={"100%"}
        sx={{
          height: `calc(100% - ${HEADER_HEIGHT}px)`,
        }}
      >
        {!isLoading && !paginatedRecords?.data.length ? (
          <Box sx={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography variant="multiLineBody" sx={{ textAlign: "center" }}>
              Você ainda não possui documentos salvos.<br></br>
              <Link
                onClick={handleCreateNewPiece}
                sx={{
                  fontFamily: `${theme.typography.fontFamily} !important`,
                  fontWeight: "700",
                  ":hover": { textDecoration: "none" },
                }}
              >
                Clique aqui
              </Link>{" "}
              para começar a criar sua primeira peça.
            </Typography>
          </Box>
        ) : (
          <DataGrid
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            getRowId={(row) => `${row.type}-${row.id}`}
            loading={isLoading}
            localeText={{
              ...ptBR.components.MuiDataGrid.defaultProps.localeText,
            }}
            onRowClick={(params) => {
              if (params.row) {
                handleOpenRecord(params.row);
              }
            }}
            columns={[
              {
                field: "document",
                headerName: "Documento",
                flex: 1,
                renderCell: ({ row: threadRecord }) => <Typography variant={"body"}>{threadRecord.name}</Typography>,
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
              },
              {
                field: "createdAt",
                headerName: "Data de criação",
                flex: 1,
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: ({ row: threadRecord }) => (
                  <Typography variant={"body"}>{formattedDateTimeFromISO(threadRecord.createdAt)}</Typography>
                ),
              },
              {
                field: "type",
                headerName: "Tipo",
                flex: 1,
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: ({ row: threadRecord }) => (
                  <Typography variant={"body"}>{recordTypeToLabel(threadRecord.type)}</Typography>
                ),
              },
              {
                field: "actions",
                type: "actions",
                align: "center",
                getActions: ({ row: threadRecord }) => [
                  <GridActionsCellItem
                    key={1}
                    divider
                    icon={<ArrowOutward />}
                    onClick={() => handleOpenRecord(threadRecord)}
                    label="Abrir documento"
                    showInMenu
                  />,
                  <GridActionsCellItem
                    key={2}
                    divider
                    icon={<Edit />}
                    onClick={() => handleOpenRenameModal(threadRecord)}
                    label="Renomear"
                    showInMenu
                  />,
                  <GridActionsCellItem
                    key={3}
                    icon={<DeleteForever />}
                    onClick={() => handleOpenArchiveModal(threadRecord)}
                    label="Deletar"
                    showInMenu
                  />,
                ],
              },
            ]}
            sx={{
              "& .MuiDataGrid-main": {
                width: paginatedRecords?.data?.length ? "100%" : "96%",
                height: "100%",
              },
              "& .MuiTablePagination-select": {
                display: "flex",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-actionsCell": {
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              },
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
                backgroundColor: "grey.50",
              },
              "& .MuiMenuItem-divider": {
                color: "red",
                backgroundColor: "red",
                padding: 4,
              },
              "& .MuiMenuItem-root": {
                color: "red",
                backgroundColor: "red",
                padding: 4,
              },
              "& .MuiMenuItem": {
                color: "red",
                backgroundColor: "red",
                padding: 4,
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                height: paginatedRecords?.data?.length ? undefined : "100px !important",
              },
            }}
            rows={paginatedRecords?.data || []}
            rowSelection={false}
            rowCount={paginatedRecords?.totalResults || 0}
            hideFooterPagination={isLoading}
          ></DataGrid>
        )}
      </Box>
      <RenameDocumentModal
        isOpen={isOpenRenameModal}
        currentEditingRecord={currentEditingRecord}
        onClose={handleCloseRenameModal}
        threadId={thread?.id}
      />
      <ArchiveDocumentModal
        isOpen={isOpenArchiveModal}
        currentEditingRecord={currentEditingRecord}
        onClose={handleCloseArchiveModal}
        documentName={currentEditingRecord?.name || ""}
        threadId={thread?.id}
      />
    </Box>
  );
};

const recordTypeToLabel = (type: RecordType) => {
  switch (type) {
    case RecordType.DOCUMENT:
      return "Documento";
    case RecordType.PRECEDENT:
      return "Acórdão";
  }
};

const GoBackButton = ({ goBack }: { goBack: () => void }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
        sx={{
          color: "text.primary",
          alignSelf: "center",
        }}
      >
        <Typography variant="button">Voltar</Typography>
      </Button>
    </Box>
  );
};

const OrderMenu = ({
  listOptions,
  closeOrderMenu,
  isOrderMenuOpen,
  orderMenuAnchorEl,
  handleUpdateOrder,
}: {
  listOptions: OrderRecordsOptions;
  closeOrderMenu: () => void;
  isOrderMenuOpen: boolean;
  orderMenuAnchorEl: HTMLButtonElement | null;
  handleUpdateOrder: (order: OrderRecordsBy) => void;
}) => {
  const menuButton = document.getElementById("order-menu-button");
  const orderOptions = Object.keys(listOptions) as OrderRecordsBy[];

  return (
    <Popover
      onClose={closeOrderMenu}
      open={isOrderMenuOpen}
      anchorEl={orderMenuAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        top: "6px !important",
        "& .MuiPaper-root": {
          width: menuButton ? `${menuButton?.offsetWidth}px` : undefined,
        },
        "& .MuiModal-backdrop": {
          backgroundColor: "transparent",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
    >
      {orderOptions.map((orderOption, index) => {
        return (
          <MenuItem key={index} onClick={() => handleUpdateOrder(orderOption)}>
            <Typography variant="body1">{listOptions[orderOption]}</Typography>
          </MenuItem>
        );
      })}
    </Popover>
  );
};
