import { Box, Typography } from "@mui/material";
import { FlowOutput, InputType } from "@/contexts/MessagesContext";
import React from "react";
import { FlowInput } from "../../FlowInput";

export const DetailedInput = ({
  onChange,
  showNoFactsOption = true,
  show,
  title,
  description,
}: {
  onChange: (input: FlowOutput | null) => void;
  showNoFactsOption?: boolean;
  show: boolean;
  title: string;
  description: string;
}) => {
  const activeOptions: InputType[] = ["CONTENT", "FILE"];
  if (showNoFactsOption) {
    activeOptions.push("NONE");
  }

  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 2, display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="preTitle" sx={{ textWrap: "wrap" }}>
          {title}
        </Typography>
        <Typography variant="body" sx={{ textWrap: "wrap" }}>
          {description}
        </Typography>
      </Box>
      <Box>
        <Typography variant="preTitle" sx={{ textWrap: "wrap" }}>
          ENVIAR DETALHAMENTO DO CASO:
        </Typography>
        <Box sx={{ mt: 1 }}>
          <FlowInput
            inputId="input_details"
            prefixItemIds="option_input_details"
            onChange={onChange}
            activeOptions={activeOptions}
          />
        </Box>
      </Box>
    </Box>
  );
};
