import { buildSkillStep } from "@/core/skillSteps/builder";

export const hearingsScriptSkillStep5 = buildSkillStep({
  title: "Roteiro para audiências judiciais - Passo 5",
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Informações extras (indicação dos elementos de relevância das testemunhas para o caso):",
        placeholder:
          "Exemplo: João é testemunha ocular, tendo presenciado todos os acontecimentos; Maria é vizinha de Carlos e conhece toda a rotina do autor e de sua família; Daniel é colega de trabalho de Carlos e conhece o temperamento dele.",
        widget: "textarea",
      },
    },
  ],
  submitButtonText: "Elaborar roteiro",
});
