import { FormControl, MenuItem, Select as MuiSelect, SelectChangeEvent, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

const OTHERS_VALUE = "outros";

interface SelectOption {
  value: string;
  label: string;
}

interface SelectProps {
  id?: string;
  label: string;
  placeholder: string;
  options: SelectOption[];
  onChange?: (value: string) => void;
  othersOption?: {
    label: string;
    input: {
      label: string;
      placeholder: string;
    };
  };
}

export const Select = ({ id, label, placeholder, options, onChange, othersOption }: SelectProps) => {
  const [value, setValue] = useState("");

  const handleSelectionChange = (event: SelectChangeEvent<string>) => {
    const selected = event.target.value;
    setValue(selected);

    if (othersOption && selected === OTHERS_VALUE) {
      onChange && onChange("");
      return;
    }

    onChange && onChange(selected);
  };

  const handleOtherValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChange && onChange(value);
  };

  return (
    <Box>
      <Box sx={{ mb: 1 }}>
        <Typography variant="preTitle">{label}</Typography>
      </Box>
      <FormControl fullWidth variant="standard">
        <MuiSelect
          sx={{ fontSize: "16px", p: 1 }}
          value={value}
          onChange={handleSelectionChange}
          displayEmpty
          inputProps={{
            id,
          }}
        >
          <MenuItem value="" disabled>
            <Typography sx={{ color: "common.mediumShade" }}>{placeholder}</Typography>
          </MenuItem>
          {options.map((item) => (
            <MenuItem key={item.value} sx={{ fontSize: "16px" }} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
          {othersOption && (
            <MenuItem sx={{ fontSize: "16px" }} value={OTHERS_VALUE}>
              {othersOption.label}
            </MenuItem>
          )}
        </MuiSelect>
      </FormControl>
      {othersOption && value === OTHERS_VALUE && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="preTitle">{othersOption.input.label}</Typography>
          <TextField
            onChange={handleOtherValueChange}
            placeholder={othersOption.input.placeholder}
            multiline
            fullWidth
            sx={{ mt: 3 }}
          />
        </Box>
      )}
    </Box>
  );
};
