import axios from "axios";
import { MessageStatus, ThreadMessage, ThreadMessageErrorType } from "@/contexts/WebSocketContext";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";
import {
  Action,
  ActionDTO,
  ActionId,
  ActionMessage,
  ChatFile,
  CortexSuccessResponse,
  ErrorMessage,
  FeedbackMessage,
  FlowMessage,
  InMessageFile,
  Message,
  MessageDTO,
  UploadedFile,
} from "../types";

export async function uploadFileToS3(
  url: string,
  file: File | Blob,
  onUploadProgress?: (progress: number) => void
): Promise<void> {
  try {
    await axios.put(url, file, {
      headers: { "Content-Type": file.type },
      transformRequest: [
        (data, headers) => {
          if (headers) {
            delete headers.Authorization;
          }
          return data;
        },
      ],
      onUploadProgress: (p) => {
        if (onUploadProgress) {
          onUploadProgress((p.loaded / file.size) * 100);
        }
      },
    });
  } catch (e) {
    return Promise.reject("Erro no upload do arquivo");
  }
}

export const threadMessagesToChatMessages = (threadMessages: ThreadMessage[]): Message[] => {
  const dateTimeFromStr = (str: string) => {
    // @TODO tirar essa gambiarra retornando a data como iso string do server
    const isoString = str.trim().replace(" ", "T").replace("Z", "") + "Z";
    return DateTime.fromISO(isoString);
  };

  const handleThreadMessageError = (threadMessage: ThreadMessage) => {
    switch (threadMessage.errorType) {
      case ThreadMessageErrorType.INSUFFICIENT_CREDITS: {
        return createErrorMessage({
          text: "Créditos insuficientes",
          date: dateTimeFromStr(threadMessage.createdAt),
        });
      }
      default: {
        return createErrorMessage({
          text: "Ops! Houve um erro ao analisar os dados enviados.",
          date: dateTimeFromStr(threadMessage.createdAt),
        });
      }
    }
  };

  return threadMessages.reduce((chatMessages, threadMessage) => {
    const isUserMessage = threadMessage.author !== "Lexter.ai";

    chatMessages.push({
      id: threadMessage.id,
      type: "FLOW",
      direction: isUserMessage ? "SENT" : "RECEIVED",
      author: isUserMessage ? "Current User" : "Lexter.ai",
      date: dateTimeFromStr(threadMessage.createdAt),
      status: "READ",
      text: threadMessage.text,
      context: threadMessage.context ? threadMessage.context : undefined,
      evaluation: threadMessage.evaluation,
      files: threadMessage.files.map((file) => ({
        type: "UPLOADED",
        id: file.id,
        cortexId: file.cortexId,
        url: file.url,
        name: file.name,
      })),
      actions: [threadMessage.payload].filter((action) => action),
      actionId: threadMessage.actionId,
    } as FlowMessage);

    switch (threadMessage.status) {
      case MessageStatus.CANCELED: {
        chatMessages.push({
          id: uuidV4(),
          type: "TEXT",
          direction: "RECEIVED",
          author: "Lexter.ai",
          date: dateTimeFromStr(threadMessage.canceledAt!),
          status: "READ",
          text: "Operação cancelada com sucesso.",
        });
        break;
      }
      case MessageStatus.ERROR: {
        handleThreadMessageError(threadMessage);
        break;
      }
    }

    return chatMessages;
  }, [] as Message[]);
};

export function createErrorMessage(
  { text, date }: { text: string; date?: DateTime },
  options?: { retry?: () => void; cancel?: () => void }
): ErrorMessage {
  return {
    id: uuidV4(),
    type: "ERROR",
    direction: "RECEIVED",
    author: "Lexter.ai",
    date: date || DateTime.now(),
    status: "READ",
    text,
    actions: [],
    retry: options?.retry,
    cancel: options?.cancel,
  };
}

export function createEvidenceAndRequiredDocumentMessage({
  cortexResponse,
  showSuggestedSkillsMessage,
}: {
  cortexResponse: CortexSuccessResponse;
  showSuggestedSkillsMessage: boolean;
}): Message {
  const { payload } = cortexResponse.replyTo;
  const { case_breakdown, reference_piece } = payload;

  const payloadFiles: InMessageFile[] = [];
  if (case_breakdown?.source === "FILE" && case_breakdown.file) {
    payloadFiles.push(case_breakdown.file as InMessageFile);
  }
  if (reference_piece?.source === "FILE" && reference_piece.file) {
    payloadFiles.push(reference_piece.file as InMessageFile);
  }

  const messageText = showSuggestedSkillsMessage ? "Gerar sugestão de provas e documentos para protocolo" : "Sim";

  return {
    id: uuidV4(),
    type: "FLOW",
    direction: "SENT",
    author: "Current User",
    date: DateTime.now(),
    status: "READ",
    text: messageText,
    actions: [
      {
        ...payload,
        id: ActionId.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT,
        text: undefined,
      },
    ],
    files: payloadFiles,
    hideFiles: true,
  };
}

export function createActionMessage(text: string, actions: Action[]): ActionMessage {
  return {
    id: uuidV4(),
    type: "ACTION",
    direction: "RECEIVED",
    author: "Lexter.ai",
    date: DateTime.now(),
    status: "READ",
    text,
    actions,
  };
}

export function createFeedbackMessage(text: string): FeedbackMessage {
  return {
    id: uuidV4(),
    type: "FEEDBACK",
    direction: "RECEIVED",
    author: "Lexter.ai",
    date: DateTime.now(),
    status: "READ",
    text,
  };
}

export function messageToDTO(message: Message): MessageDTO {
  const files: ChatFile[] = [];
  if (message.type === "FILE" || message.type === "FLOW") {
    message.files.forEach((file) => {
      if (file.type === "UPLOADED") {
        files.push(file);
      }
    });
  }

  let actions: ActionDTO[] | undefined = undefined;
  if (message.type === "FLOW") {
    actions = message.actions.map((action): ActionDTO => {
      if (action.id === ActionId.CREATE_INITIAL_PETITION) {
        const caseFile = message.files.find((file) => file.id === action.case.file?.id) as UploadedFile;

        return {
          ...action,
          case: {
            ...action.case,
            file: caseFile,
          },
        };
      }

      if (action.id === "CREATE_CONTESTATION_STRATEGY") {
        if (action.initialPetition.file) {
          const uploadedFile = message.files.find((file) => file.id === action.initialPetition.file?.id);
          return {
            ...action,
            initialPetition: {
              ...action.initialPetition,
              file: uploadedFile,
            },
          } as unknown as ActionDTO;
        }

        if (action.userFacts?.file) {
          const uploadedFile = message.files.find((file) => file.id === action.userFacts?.file?.id);
          return {
            ...action,
            userFacts: {
              ...action.userFacts,
              file: uploadedFile,
            },
          } as unknown as ActionDTO;
        }
      }

      if (action.id === ActionId.CREATE_CONTESTATION) {
        const contestationAction = {
          ...action,
          initialPetition: {
            ...action.initialPetition,
            file: message.files.find((file) => file.id === action.initialPetition.file?.id),
          },
        } as unknown as ActionDTO;

        return {
          ...contestationAction,
          userFacts: action.userFacts
            ? {
                ...action.userFacts,
                file: message.files.find((file) => file.id === action.userFacts?.file?.id),
              }
            : undefined,
        } as ActionDTO;
      }

      if (action.id === ActionId.CREATE_APPEAL) {
        return {
          ...action,
          sentence: {
            ...action.sentence,
            file: message.files.find((file) => file.id === action.sentence.file?.id),
          },
        } as unknown as ActionDTO;
      }

      if (action.id === ActionId.CREATE_REPLICATION) {
        let replicationAction = {
          ...action,
          contestation: {
            ...action.contestation,
            file: message.files.find((file) => file.id === action.contestation.file?.id),
          },
        } as unknown as ActionDTO;

        if (action.userFacts?.file) {
          replicationAction = {
            ...replicationAction,
            userFacts: {
              ...action.userFacts,
              file: message.files.find((file) => file.id === action.userFacts?.file?.id),
            },
          } as ActionDTO;
        }

        return replicationAction;
      }

      if (action.id === ActionId.CREATE_LABOR_COMPLAINT && action.case.file) {
        const uploadedFile = message.files.find((file) => file.id === action.case.file?.id) as UploadedFile;
        return {
          ...action,
          case: {
            ...action.case,
            file: uploadedFile,
          },
        } as ActionDTO;
      }

      if (action.id === ActionId.CREATE_LABOR_CONTESTATION) {
        let laborContestationAction = {
          ...action,
          laborComplaint: {
            ...action.laborComplaint,
            file: message.files.find((file) => file.id === action.laborComplaint.file?.id),
          },
        } as ActionDTO;

        if (action.userFacts?.file) {
          laborContestationAction = {
            ...laborContestationAction,
            userFacts: {
              ...action.userFacts,
              file: message.files.find((file) => file.id === action.userFacts?.file?.id),
            },
          } as ActionDTO;
        }

        return laborContestationAction;
      }

      if (action.id === ActionId.CREATE_LABOR_PLAINTIFF_REPLY) {
        let replicationAction = {
          ...action,
          contestation: {
            ...action.contestation,
            file: message.files.find((file) => file.id === action.contestation.file?.id),
          },
        } as unknown as ActionDTO;

        if (action.userFacts?.file) {
          replicationAction = {
            ...replicationAction,
            userFacts: {
              ...action.userFacts,
              file: message.files.find((file) => file.id === action.userFacts?.file?.id),
            },
          } as ActionDTO;
        }

        return replicationAction;
      }

      if (action.id === ActionId.CREATE_ORDINARY_APPEAL) {
        return {
          ...action,
          sentence: {
            ...action.sentence,
            file: message.files.find((file) => file.id === action.sentence.file?.id),
          },
        } as ActionDTO;
      }

      if (action.id === ActionId.CREATE_PETITION_SUMMARY && action.petition.file) {
        const uploadedFile = message.files.find((file) => file.id === action.petition.file?.id);
        return {
          ...action,
          petition: {
            ...action.petition,
            file: uploadedFile,
          },
        } as unknown as ActionDTO;
      }

      if (action.id === ActionId.CREATE_ONE_PIECE) {
        return {
          ...action,
          case_breakdown: action.case_breakdown
            ? action.case_breakdown.file
              ? {
                  source: action.case_breakdown.source,
                  file: message.files.find((file) => file.id === action.case_breakdown?.file?.id),
                }
              : action.case_breakdown
            : undefined,
          reference_piece: action.reference_piece
            ? action.reference_piece.file
              ? {
                  source: action.reference_piece.source,
                  file: message.files.find((file) => file.id === action.reference_piece?.file?.id),
                }
              : action.reference_piece
            : undefined,
        } as unknown as ActionDTO;
      }

      if (action.id === ActionId.CREATE_INTERVIEW_SCRIPT_ACTION) {
        return {
          ...action,
          case_breakdown: {
            ...action.case_breakdown,
            file: message.files.find((file) => file.id === action.case_breakdown.file?.id),
          },
        } as unknown as ActionDTO;
      }

      if (action.id === ActionId.CREATE_NOTICE_ACTION) {
        return {
          ...action,
          case_breakdown: action.case_breakdown,
          reference_piece: action.reference_piece
            ? action.reference_piece.file
              ? {
                  source: action.reference_piece.source,
                  file: message.files.find((file) => file.id === action.reference_piece?.file?.id),
                }
              : action.reference_piece
            : undefined,
        } as unknown as ActionDTO;
      }

      return action as ActionDTO;
    });
  }

  return {
    id: message.id,
    author: message.author,
    date: message.date.toISO() || "",
    text: message.text,
    context: message.context,
    files,
    actions,
  };
}

export function removeUrlSignature(url: string) {
  const [urlWithoutSignature] = url.split("?");
  return urlWithoutSignature;
}

export function getDeviceMetadata(): string {
  const contextInfo = Office.context.diagnostics;
  if (contextInfo) {
    return `${contextInfo.host} - ${contextInfo.platform} - ${contextInfo.version} | ${navigator.userAgent}`;
  }

  return navigator.userAgent;
}
