import { env } from "@/constants/environment";
import { Plan } from "./types";

export const plans: Plan[] = [
  {
    title: "Plano Inicial",
    description:
      "Comece a utilizar o Assistente Lexter com um custo acessível. Utilize o melhor da inteligência artificial gerando até 5 peças por mês.",
    price: 59,
    credits: 1000,
    buyButtonId: env.STRIPE.BUTTONS.INITIAL,
  },
  {
    title: "Plano Básico",
    description:
      "Plano de entrada para utilizar o Assistente Lexter. Utilize o melhor da inteligência artificial gerando até 10 peças por mês.",
    price: 99,
    credits: 2000,
    buyButtonId: env.STRIPE.BUTTONS.BASIC,
  },
  {
    title: "Plano intermediário",
    description:
      "Para você que já produz um volume de peças maior, utilize o Assistente Lexter criando aproximadamente 22 peças por mês.",
    price: 199,
    credits: 4500,
    buyButtonId: env.STRIPE.BUTTONS.INTERMEDIATE,
  },
  {
    title: "Plano Avançado",
    description:
      "Para você que já tem um volume maior de peças mensal e quer utilizar o Assistente Lexter para produzir aproximadamente 35 peças por mês, este é o plano indicado.",
    price: 299,
    credits: 7000,
    buyButtonId: env.STRIPE.BUTTONS.ADVANCED,
  },
  //  {
  //    title: "Plano Profissional",
  //    description:
  //      "Para você que lida com um alto volume de peças mensais e busca otimizar com o Assistente Lexter, gerando cerca de 60 peças por mês.",
  //    price: 499,
  //    credits: 12000,
  //    buyButtonId: env.STRIPE.BUTTONS.PROFESSIONAL,
  //  },
];
