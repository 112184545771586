import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Select, MenuItem, FormControl, TextField } from "@mui/material";
import { Area, LegalPieceMacroType } from "@/contexts/MessagesContext";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { SelectInputProps } from "@mui/material/Select/SelectInput";

const areaConfig: {
  [x in Area]?: string;
} = {
  [Area.SOCIAL_SECURITY]:
    "Exemplos: Ação de concessão de benefício previdenciário, Ação de Revisão da Vida Toda, Ação de anulação de benefício previdenciário...",
  [Area.TAX]: "Exemplos: Ação Declaratória; Ação de Anulação de Débito Fiscal; Ação de Repetição de Indébito...",
  [Area.CRIMINAL]: "Exemplos: Queixa-Crime, Habeas Corpus, Mandado de Segurança...",
};

const allMenuItems = [
  { value: Area.CIVIL, label: "Cível" },
  { value: Area.LABOR, label: "Trabalhista" },
  { value: Area.SOCIAL_SECURITY, label: "Previdenciário" },
  { value: Area.TAX, label: "Tributário" },
  { value: Area.CRIMINAL, label: "Criminal" },
  { value: Area.OTHER, label: "Outros" },
];

const appealMenuItems = [
  { value: Area.CIVIL, label: "Cível" },
  { value: Area.LABOR, label: "Trabalhista" },
];

export const AreaOfLawSelection = ({
  documentMacroType,
  selectedArea,
  onSelectArea,
  customArea,
  onCustomAreaChange,
  show,
  onDocumentNameChange,
  documentName,
}: {
  documentMacroType: LegalPieceMacroType;
  selectedArea: Area | undefined;
  onSelectArea: SelectInputProps<Area | undefined>["onChange"];
  customArea: string;
  onCustomAreaChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  show: boolean;
  onDocumentNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  documentName: string;
}) => {
  const flags = useFeatureFlags();
  const menuItems =
    documentMacroType === LegalPieceMacroType.APPEAL && !flags.showAgnosticAppeal ? appealMenuItems : allMenuItems;

  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 1 }}>
        <Typography variant="preTitle">Para qual área jurídica?</Typography>
      </Box>
      <FormControl fullWidth variant="standard">
        <Select
          sx={{ fontSize: "16px", p: 1 }}
          value={selectedArea ?? ""}
          onChange={onSelectArea}
          displayEmpty
          inputProps={{
            id: "input_select_area",
          }}
        >
          <MenuItem value="" disabled>
            <Typography sx={{ color: "common.mediumShade" }}>Selecione a área jurídica</Typography>
          </MenuItem>
          {menuItems.map((item) => (
            <MenuItem id={`select_item_${item.value}`} key={item.value} sx={{ fontSize: "16px" }} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {documentMacroType !== LegalPieceMacroType.APPEAL && selectedArea && areaConfig[selectedArea] && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="preTitle">Qual é o nome da peça?</Typography>
          <TextField
            value={documentName}
            onChange={onDocumentNameChange}
            placeholder={selectedArea && areaConfig[selectedArea]}
            multiline
            fullWidth
            sx={{ mt: 3 }}
          />
        </Box>
      )}
      {selectedArea === Area.OTHER && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="preTitle">Digite a área jurídica:</Typography>
          <TextField
            value={customArea}
            onChange={onCustomAreaChange}
            placeholder="Exemplos: Direito Ambiental, Direito Administrativo..."
            multiline
            fullWidth
            sx={{ mt: 3 }}
          />
        </Box>
      )}
    </Box>
  );
};
