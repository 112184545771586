import { buildSkillStep } from "@/core/skillSteps/builder";

export const settlementOfferSkillStep3 = buildSkillStep({
  title: "Elaborar proposta de acordo - Passo 3",
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Inserir observações sobre o acordo (Opcional):",
        placeholder:
          "Exemplo: Proponho quitar meu débito de R$ [valor] com entrada de R$ [valor] e [quantidade] parcelas de R$ [valor] cada, vencendo a primeira em [data] e as demais mensalmente. Em caso de inadimplemento, todas as parcelas vencerão antecipadamente com correção monetária e juros de 1% ao mês. Solicito a notificação da parte credora para análise e homologação do acordo.",
        widget: "textarea",
      },
      required: false,
    },
  ],
  submitButtonText: "Elaborar proposta de acordo",
});
