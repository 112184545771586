import { useContext, useEffect } from "react";
import { WebSocketContext } from "./context";
import { OnMessageReceivedParam, OnThreadConnectParams, OnNotificationReceivedParams } from "./types";

interface UseSocketProps {
  onMessageReceived?: (params: OnMessageReceivedParam) => void;
  onThreadConnected?: (params: OnThreadConnectParams) => void;
  onNotificationReceived?: (params: OnNotificationReceivedParams) => void;
}

export const useSocket = (params?: UseSocketProps) => {
  const { onMessageReceived, onThreadConnected, onNotificationReceived } = params || {};
  const { messageReceivedEvent, threadConnectedEvent, notificationReceivedEvent, ...rest } =
    useContext(WebSocketContext);

  useEffect(() => {
    if (!onMessageReceived) return;

    messageReceivedEvent.addEventListener(onMessageReceived);

    return () => {
      messageReceivedEvent.removeEventListener(onMessageReceived);
    };
  }, [onMessageReceived]);

  useEffect(() => {
    if (!onThreadConnected) return;

    threadConnectedEvent.addEventListener(onThreadConnected);

    return () => {
      threadConnectedEvent.removeEventListener(onThreadConnected);
    };
  }, [onThreadConnected]);

  useEffect(() => {
    if (!onNotificationReceived) return;

    notificationReceivedEvent.addEventListener(onNotificationReceived);

    return () => {
      notificationReceivedEvent.removeEventListener(onNotificationReceived);
    };
  }, [onNotificationReceived]);

  return rest;
};
