import { buildSkillStep, createEditorContentField, createUploadFileField } from "@/core/skillSteps/builder";

export const interviewScriptSkillStep2 = buildSkillStep({
  title: "Roteiro de entrevista com o cliente - Passo 2",
  description:
    "Aqui você pode inserir a conversa que teve com o cliente para enriquecer as perguntas geradas pelo Assistente Lexter.",
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      options: [
        {
          label: "Enviar a transcrição da conversa com o cliente no WhatsApp (DOCX ou PDF)",
          value: "upload_file",
        },
        {
          label:
            "Usar as informações escritas no editor de texto ao lado (Transcrição da conversa com o cliente ou contexto do caso)",
          value: "use_editor_content",
        },
      ],
      initialValue: "use_editor_content",
      ui: {
        label: "Enviar detalhamento do caso:",
        widget: "radio",
      },
    },
    createEditorContentField({
      name: "case_breakdown_editor_content",
      overrideField: "case_breakdown",
      condition: {
        field: "case_breakdown",
        value: "use_editor_content",
      },
    }),
    createUploadFileField({
      name: "case_breakdown_file",
      overrideField: "case_breakdown",
      condition: {
        field: "case_breakdown",
        value: "upload_file",
      },
    }),
  ],
  submitButtonText: "Gerar roteiro",
});
