import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import React from "react";
import BackgroundUrl from "@/assets/images/lexterbg.png";
import { Chip } from "@/components/Chip";
import { useNavigate } from "react-router-dom";
import { ActionProps } from "@/core/actions";

interface ActionsHeaderProps {
  actions: ActionProps[];
  handleComingSoon: (action: ActionProps) => void;
}

const disabledSx = {
  color: "common.mediumShade",
  bgcolor: "common.white",
  opacity: 0.8,
};

export const ActionsHeader = ({ actions, handleComingSoon }: ActionsHeaderProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "common.black",
        backgroundSize: "cover",
        backgroundPosition: "right",
        width: "100%",
        backgroundImage: `url(${BackgroundUrl})`,
      }}
    >
      <Box
        sx={{
          px: 8,
          py: 4,
        }}
      >
        <Typography
          sx={{
            mb: "24px",
          }}
          variant="h3"
          fontSize={20}
          color="white"
        >
          Vamos começar?
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          {actions.map((action, i) => {
            return <ActionButton key={i} first={i === 0} action={action} handleComingSoon={handleComingSoon} />;
          })}
        </Box>
      </Box>
    </Box>
  );
};

interface ActionButtonProps {
  action: ActionProps;
  first?: boolean;
  handleComingSoon: (action: ActionProps) => void;
}

export const ActionButton = ({ action, first, handleComingSoon }: ActionButtonProps) => {
  const navigate = useNavigate();
  const { name, redirectTo, hidden } = action;
  const { comingSoonButtonEnabled } = useFeatureFlags();

  const handleRedirect = () => {
    if (comingSoon) {
      return handleComingSoon(action);
    }

    navigate(redirectTo.path);
  };

  const comingSoon = action.disabled;

  if (hidden) return null;

  const comingSoonSx = comingSoon ? disabledSx : {};

  return (
    <ActionToolTip text={action.tooltip}>
      <Button
        id={`header_button_${action.id.toLowerCase()}`}
        sx={{
          paddingY: "12px",
          paddingX: "20px",
          borderRadius: "8px",
          boxShadow: "0px 4px 16px 0px #00000014",
          color: first ? "common.white" : "common.black",
          bgcolor: first ? "common.primary.main" : "common.white",
          gap: "10px",
          "&:hover": {
            bgcolor: first ? "common.primary.dark" : "common.dorian",
          },
          "&:disabled": {
            ...disabledSx,
          },
          ...comingSoonSx,
        }}
        variant="contained"
        disabled={!comingSoonButtonEnabled && comingSoon}
        onClick={() => handleRedirect()}
      >
        {first && <ArrowForwardIcon />}
        {name}
        {comingSoon && (
          <Chip backgroundColor="common.mediumShade" color="common.white">
            EM BREVE
          </Chip>
        )}
      </Button>
    </ActionToolTip>
  );
};

interface ActionToolTipProps {
  text?: string;
  children: React.ReactElement;
}

const ActionToolTip = ({ children, text }: ActionToolTipProps) => {
  return (
    <Tooltip
      title={text}
      placement="right"
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -4],
              },
            },
          ],
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.mediumShade",
            "& .MuiTooltip-arrow": {
              color: "common.mediumShade",
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
