import { Box, SxProps, Typography } from "@mui/material";
import { FlowOutput } from "@/contexts/MessagesContext";
import React from "react";
import { FlowInput } from "@/taskpane/components/Chat/FlowInput";
import { InputFlowType } from "@/taskpane/components/Chat/FlowInput/types";
import { UserInput, UserInputSource } from "@/contexts/WebSocketContext";

interface MultiTypeInputProps {
  inputId?: string;
  prefixItemIds?: string;
  inputTypes: InputFlowType[];
  onChange: (input: UserInput | undefined) => void;
  label?: string;
  sx?: SxProps;
}

export const MultiTypeInput = ({
  inputId,
  prefixItemIds,
  inputTypes,
  onChange,
  label = "Enviar detalhamento do caso:",
  sx,
}: MultiTypeInputProps) => {
  const handleChange = async (input: FlowOutput | null) => {
    if (!input) {
      onChange(undefined);
    } else {
      switch (input.source) {
        case "CONTENT": {
          onChange({
            source: UserInputSource.CONTENT,
          });
          break;
        }
        case "TEXT": {
          onChange({
            source: UserInputSource.TEXT,
            text: input.text || "",
          });
          break;
        }
        case "FILE": {
          if (input.file?.file) {
            onChange({
              source: UserInputSource.FILE,
              file: input.file?.file,
            });
          } else {
            onChange(undefined);
          }
          break;
        }
        case "NONE": {
          onChange(undefined);
          break;
        }
      }
    }
  };

  return (
    <Box sx={sx}>
      <Typography variant="preTitle" sx={{ textWrap: "wrap" }}>
        {label}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <FlowInput inputId={inputId} prefixItemIds={prefixItemIds} onChange={handleChange} activeOptions={inputTypes} />
      </Box>
    </Box>
  );
};
