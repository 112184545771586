import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import * as React from "react";
import { v4 as uuidV4 } from "uuid";

import { ActionId, FlowOutput, Message } from "@/contexts/MessagesContext";
import { flowActionToText } from "@/utils/flowActionToText";
import * as logger from "../../../../core/logger";
import { InternalPageStructure } from "../../core/InternalPageStructure";
import { FlowInput } from "../FlowInput";

export function LaborComplaint(props: { goBack: () => void; sendMessage: (message: Message) => void }) {
  const { goBack, sendMessage } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [output, setOutput] = React.useState<FlowOutput | null>(null);

  const dismissError = React.useCallback(() => {
    setError(null);
  }, []);

  const onChange = React.useCallback((input: FlowOutput | null) => {
    setOutput(input);
    dismissError();
  }, []);

  const onSubmit = React.useCallback(() => {
    if (!output) {
      setError("O detalhamento precisa estar definido.");
    }

    logger.info("Submitting labor complaint");

    if (output) {
      const actionText = `Criar uma reclamação trabalhista com os dados informados ${flowActionToText(output)}`;
      sendMessage({
        id: uuidV4(),
        type: "FLOW",
        direction: "SENT",
        author: "Current User",
        date: DateTime.now(),
        status: "READ",
        text: actionText,
        actions: [
          {
            id: ActionId.CREATE_LABOR_COMPLAINT,
            case: output,
            text: actionText,
          },
        ],
        files: output.file
          ? [
              {
                id: output.file.id,
                type: "UPLOADING",
                file: output.file.file,
                name: output.file.name,
              },
            ]
          : [],
      });

      goBack();
    }
  }, [output]);

  return (
    <InternalPageStructure
      goBack={goBack}
      onSubmit={onSubmit}
      error={error}
      dismissError={dismissError}
      submitButtonText="Iniciar Criação da Peça"
      submitButtonDisabled={!output}
    >
      <Box>
        <Box sx={{ mb: 1 }}>
          <Typography variant="preTitle" color={"text.primary"}>
            CRIAR RECLAMAÇÃO TRABALHISTA
          </Typography>
        </Box>

        <Box>
          <Typography variant="multiLineBody" color={"text.primary"}>
            Para criar uma Reclamação Trabalhista, insira de forma mais detalhada possível as informações sobre o caso
            que deseja gerar a peça.
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="multiLineBody" color={"text.primary"}>
            Para começar, como deseja enviar o detalhamento do caso?
          </Typography>
        </Box>

        <Box>
          <FlowInput onChange={onChange} activeOptions={["CONTENT", "FILE"]} />
        </Box>
      </Box>
    </InternalPageStructure>
  );
}
