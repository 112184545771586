export interface EmailProps {
  value: string;
}

export class Email {
  private value: string;

  private constructor(props: EmailProps) {
    this.value = props.value;
  }

  private static isValidEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  private static format(email: string): string {
    const parts = email.trim().toLowerCase().split(" ");
    for (const p of parts) {
      if (this.isValidEmail(p)) return p;
    }
    return email.trim().toLowerCase();
  }

  public static fromString(email: string): Email {
    try {
      const cleanEmail = Email.format(email);
      if (!Email.isValidEmail(cleanEmail)) {
        throw new Error(`Email address ${email} is not valid`);
      } else {
        return new Email({ value: cleanEmail });
      }
    } catch (e) {
      throw new Error(`Email address ${email} is not valid`);
    }
  }

  match(str: string): boolean {
    if (!str || typeof str !== "string") return false;
    const regex = new RegExp(str, "i");
    return regex.test(this.value);
  }

  public toString(): string {
    return this.value;
  }
}
