import { useCallback } from "react";
import { Config } from "@/config/Config";

export const useTawk = () => {
  const config = Config.getConfig();

  const loadTawk = useCallback(() => {
    if (config.isStaging) {
      const tawkScript = document.createElement("script");
      tawkScript.innerHTML = `
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
          s1.async=true;
          s1.src='https://embed.tawk.to/66c4ae8150c10f7a009e7cc2/1i5o4jaqt';
          s1.charset='UTF-8';
          s1.setAttribute('crossorigin','*');
          s0.parentNode.insertBefore(s1,s0);
        })();
      `;
      document.head.appendChild(tawkScript);
    }
  }, []);

  return { loadTawk };
};
