import { useApi } from "@/hooks/useApi";
import React, { ReactNode, useEffect, useState } from "react";
import { ThreadsContext } from "./context";
import { LoadingState, OrderThreadsBy, OrderThreadsOptions, PaginationModel, ThreadsList } from "./types";
import { Result } from "@/core/Result";
import { useSocket } from "@/contexts/WebSocketContext";

interface ThreadsContextProviderProps {
  children: ReactNode;
}
export const ThreadsContextProvider = ({ children }: ThreadsContextProviderProps) => {
  const [loadingState, setLoadingState] = useState<LoadingState>("LOADING");
  const [threadsList, setThreadsList] = useState<ThreadsList>({ data: [], totalResults: 0 });
  const [openThread, setOpenThread] = useState<{ id?: string; name?: string }>();

  const {
    getThreads,
    deleteThread: apiDeleteThread,
    renameThread: apiRenameThread,
    toggleFavoriteThread: apiToggleFavoriteThread,
  } = useApi();

  const { thread } = useSocket();

  useEffect(() => {
    setOpenThread({ id: thread?.id, name: thread?.name });
  }, [thread]);

  const loadThreadsList = React.useCallback(async (pagination: PaginationModel) => {
    setLoadingState("LOADING");
    const threads = await getThreads({ pagination });

    setThreadsList(threads);

    setLoadingState("FINISHED");
  }, []);

  const refetchThreads = async (pagination: PaginationModel) => {
    setLoadingState("REFETCHING");
    const threads = await getThreads({ pagination });

    setThreadsList((prev) => ({ totalResults: threads.totalResults, data: [...prev.data, ...threads.data] }));

    setLoadingState("FINISHED");
  };

  const renameThread = async ({ threadId, name }: { threadId: string; name: string }): Promise<Result<string>> => {
    setLoadingState("RENAMING");
    try {
      await apiRenameThread({ threadId, name });
      setOpenThread({ id: threadId, name });
      setThreadsList((prev) => ({
        ...prev,
        data: prev.data.map((thread) => {
          if (thread.id === threadId) {
            return {
              ...thread,
              name,
            };
          }
          return thread;
        }),
      }));
      setLoadingState("FINISHED");
      return Result.ok("Chat renomeado com sucesso");
    } catch (e) {
      setLoadingState("FINISHED");
      return Result.fail("Erro ao renomear chat");
    }
  };

  const deleteThread = async (threadId: string): Promise<Result<string>> => {
    setLoadingState("DELETING");
    try {
      await apiDeleteThread(threadId);
      setThreadsList((prev) => ({
        ...prev,
        data: prev.data.filter((thread) => thread.id !== threadId),
      }));
      setLoadingState("FINISHED");
      return Result.ok("Chat excluído com sucesso");
    } catch (e) {
      setLoadingState("FINISHED");
      return Result.fail("Erro ao excluir chat");
    }
  };

  const toggleFavoriteThread = async (threadId: string): Promise<Result<string>> => {
    setLoadingState("FAVORITE");
    try {
      await apiToggleFavoriteThread(threadId);
      setThreadsList((prev) => ({
        ...prev,
        data: prev.data.map((thread) => {
          if (thread.id === threadId) {
            return {
              ...thread,
              favorite: !thread.favorite,
            };
          }
          return thread;
        }),
      }));
      setLoadingState("FINISHED");
      return Result.ok(`Status de favorito com sucesso`);
    } catch (e) {
      setLoadingState("FINISHED");
      return Result.fail("Erro ao atualizar status de favorito");
    }
  };

  const orderThreadsOptions: OrderThreadsOptions = {
    UPDATED_AT: "Data de Atualização",
    ALPHABET_ASC: "Alfabético - AZ",
    ALPHABET_DESC: "Alfabético - ZA",
    FAVORITES: "Favoritos",
  };

  const getOrderOptionsParams = (order: OrderThreadsBy) => {
    switch (order) {
      case "UPDATED_AT":
        return { sortBy: "lastEventDate", sortDirection: "desc" };
      case "ALPHABET_ASC":
        return { sortBy: "name", sortDirection: "asc" };
      case "ALPHABET_DESC":
        return { sortBy: "name", sortDirection: "desc" };
      case "FAVORITES":
        return { sortBy: "favorite", sortDirection: "desc" };
      default:
        return { sortBy: "lastEventDate", sortDirection: "desc" };
    }
  };

  const updateListOrder = async (order: OrderThreadsBy): Promise<void> => {
    setLoadingState("LOADING");
    try {
      const params = getOrderOptionsParams(order);

      const newOrder = await getThreads({ ...params, pagination: { page: 1, pageSize: 10 } });
      setThreadsList(newOrder);
      setLoadingState("FINISHED");
    } catch (e) {
      setLoadingState("FINISHED");
    }
  };

  const searchThreads = async (search: string): Promise<void> => {
    setLoadingState("LOADING");
    try {
      const queryResult = await getThreads({ search, pagination: { page: 1, pageSize: 10 } });
      setThreadsList(queryResult);
      setLoadingState("FINISHED");
    } catch (e) {
      setLoadingState("FINISHED");
    }
  };

  return (
    <ThreadsContext.Provider
      value={{
        threads: threadsList,
        deleteThread,
        renameThread,
        toggleFavoriteThread,
        loadThreadsList,
        refetchThreads,
        setThreadsList,
        loadingState,
        orderThreadsOptions,
        updateListOrder,
        searchThreads,
        openThread,
      }}
    >
      {children}
    </ThreadsContext.Provider>
  );
};
