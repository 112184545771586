import React from "react";

interface PaginationDotProps {
  active: boolean;
  index: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void;
}

const styles = {
  root: {
    height: 8,
    width: 24,
    cursor: "pointer",
    border: 0,
    background: "none",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dot: {
    backgroundColor: "#4A4A68",
    height: 8,
    width: 8,
    borderRadius: 6,
  },
  active: {
    height: 8,
    width: 24,
  },
};

const PaginationDot: React.FC<PaginationDotProps> = ({ active, index, onClick }) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick(event, index);
  };

  const styleDot = active ? { ...styles.dot, ...styles.active } : styles.dot;

  return (
    <button type="button" style={styles.root as React.CSSProperties} onClick={handleClick}>
      <div style={styleDot as React.CSSProperties} />
    </button>
  );
};

export default PaginationDot;
