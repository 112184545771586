import React, { useState } from "react";
import { applyPhoneMask } from "@/utils/applyInputMask";
import { BaseField } from "../Field";
import { TextField } from "@/components/TextField";
import { Phone } from "@/core/Phone";
import { Result } from "@/core/Result";

interface PhoneNumberFieldProps {
  onPhoneNumberChange: (phone: string) => void;
}

export const isPhoneNumberValid = (phone: string): Result<boolean> => {
  const isValid = Phone.new(phone);
  if (isValid.isFailure) return Result.fail("Telefone inválido");
  return Result.ok(true);
};

export const PhoneNumberField = ({ onPhoneNumberChange }: PhoneNumberFieldProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onPhoneBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    const isValid = isPhoneNumberValid(e.target.value);
    if (isValid.isFailure) {
      setErrorMessage(isValid.getError());
    }
    onPhoneNumberChange(e.target.value);
  };

  const onPhoneChange = () => {
    setErrorMessage(null);
  };

  return (
    <BaseField errorMessage={errorMessage} label="TELEFONE PARA CONTATO">
      <TextField
        placeholder="(XX) XXXXX-XXXX"
        InputProps={{
          onKeyUp: applyPhoneMask,
        }}
        onBlur={onPhoneBlur}
        onChange={onPhoneChange}
        placeholderColor="common.mediumShade"
        color="common.shade"
        error={!!errorMessage}
      />
    </BaseField>
  );
};
