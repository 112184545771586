import { Box, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { PageLayout } from "@/components/PageLayout";
import { HeaderVariant } from "@/components/PageLayout/Header";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <PageLayout
      headerProps={{
        variant: HeaderVariant.WELCOME,
      }}
      contentSx={{
        display: "flex",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          marginY: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="pageTitle" textAlign="center">
          Página não encontrada
        </Typography>

        <Button
          onClick={() => navigate(ROUTE_PATHS.HOME)}
          sx={{
            marginTop: 4,
          }}
          variant="contained"
        >
          Voltar para a página inicial
        </Button>
      </Box>
    </PageLayout>
  );
};
