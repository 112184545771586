import { Form, Text, TextInput, Title } from "@/components/FormComponents";
import { Select } from "@/components/FormComponents/Select";
import { ActionId, Area, useMessagesContext } from "@/contexts/MessagesContext";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import React, { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

enum Step {
  DETAILS,
  AREA,
  CLIENT_NAME,
}

export const CreateStrategy = () => {
  const [details, setDetails] = useState<string>("");
  const [clientName, setClientName] = useState<string>("");
  const [area, setArea] = useState<string>("");
  const [evidences, setEvidences] = useState<string>();

  const { goToChat, startSkillProcess } = useMessagesContext();
  const [error, setError] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const steps = useMemo(() => [Step.DETAILS, Step.AREA, Step.CLIENT_NAME], []);

  const dismissError = () => {
    setError(null);
  };

  const handleGoBack = () => {
    if (currentStep === 0) {
      goToChat();
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleClientNameChange = (value: string) => {
    setClientName(value);
    dismissError();
  };

  const handleDetailsChange = (value: string) => {
    setDetails(value);
    dismissError();
  };

  const handleEvidencesChange = (value: string) => {
    setEvidences(value);
    dismissError();
  };

  const canProceed = () => {
    switch (steps[currentStep]) {
      case Step.DETAILS:
        return !!details;
      case Step.AREA:
        return !!area;
      case Step.CLIENT_NAME:
        return !!clientName;
    }
  };

  const onSubmitHandler = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (!clientName || !details || !clientName) {
        setError("É preciso submeter todos os dados.");
        return;
      }

      startSkillProcess({
        skillId: ActionId.CREATE_STRATEGY,
        requestId: uuid(),
        payload: {
          evidences_list: evidences,
          case_breakdown: details,
          client: clientName,
          area: area,
        },
        messageToSave: "Elaborar estratégia",
      });

      goToChat();
    }
  };

  const title = `Estratégia Jurídica - Passo ${currentStep + 1}`;

  return (
    <InternalPageStructure
      goBack={handleGoBack}
      onSubmit={onSubmitHandler}
      error={error}
      dismissError={dismissError}
      goBackButtonText={currentStep === 0 ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep === steps.length - 1 ? "Elaborar estratégia" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={steps.length}
      currentStep={currentStep}
    >
      <Form show={steps[currentStep] === Step.DETAILS}>
        <Title>{title}</Title>
        <Text>
          O Assistente Lexter pode te ajudar a identificar e selecionar as estratégias mais relevantes para o caso.
        </Text>
        <TextInput
          label="Enviar detalhamento do caso:"
          placeholder="Exemplo: A influenciadora digital sofreu lesões graves após um procedimento estético mal realizado. A clínica não conseguiu tratar os ferimentos. O atendimento inadequado resultou em dor intensa e impacto psicológico, afetando seu trabalho."
          value={details}
          onChange={handleDetailsChange}
        />
      </Form>

      <Form show={steps[currentStep] === Step.AREA}>
        <Title>{title}</Title>
        <Select
          id="input_select_area"
          label="Para qual área jurídica?"
          placeholder="Selecione a área"
          options={[
            { value: Area.CIVIL, label: "Cível" },
            { value: Area.LABOR, label: "Trabalhista" },
            { value: Area.SOCIAL_SECURITY, label: "Previdenciário" },
            { value: Area.TAX, label: "Tributário" },
            { value: Area.CRIMINAL, label: "Criminal" },
          ]}
          othersOption={{
            label: "Outras",
            input: {
              label: "Digite a área jurídica:",
              placeholder: "Exemplos: Direito Ambiental, Direito Administrativo...",
            },
          }}
          onChange={setArea}
        />
      </Form>

      <Form show={steps[currentStep] === Step.CLIENT_NAME}>
        <Title>{title}</Title>
        <TextInput
          id="input_client_name"
          label="Qual é o nome do cliente?"
          placeholder="Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas."
          value={clientName}
          onChange={handleClientNameChange}
        />
        <TextInput
          label="liste quais são as evidências disponíveis (opcional):"
          placeholder="Exemplos: fotos do dano, Contrato de Prestação de Serviços, Comprovantes de despesas médicas e conversa de whatsapp."
          value={evidences}
          onChange={handleEvidencesChange}
        />
      </Form>
    </InternalPageStructure>
  );
};
