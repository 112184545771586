import React from "react";
import { Box } from "@mui/material";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { CarouselContent } from "@/taskpane/components/Login/components/CarouselContent";

const PublicLayout = ({ children }: { children: React.ReactNode }) => {
  if (outsideOfficeClient()) {
    return (
      <Box sx={{ display: "flex", height: "100vh", width: "100vw" }}>
        <Box sx={{ flex: "0 0 50%", overflowY: "auto" }}>{children}</Box>
        <Box
          sx={{
            flex: "0 0 50%",
            borderLeft: "1px solid #ccc",
            backgroundColor: "background.default",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
            overflowY: "auto",
          }}
        >
          <CarouselContent />
        </Box>
      </Box>
    );
  }
  return <>{children}</>;
};

export default PublicLayout;
