import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getRecordsQueryKey } from "../useRecords";
import { Paginated } from "@/hooks/useApi/types";
import { RecordType, ThreadRecord } from "../types";

export const useRenameRecord = ({ threadId }: { threadId?: string }) => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["thread", threadId, "renameRecord"],
    mutationFn: async ({ record, name }: { record: ThreadRecord; name: string }) => {
      switch (record.type) {
        case RecordType.PRECEDENT:
          api.renamePrecedent({
            id: record.id,
            name,
          });
          break;
        case RecordType.DOCUMENT:
          api.updateDocumentName({ documentId: record.id, documentName: name });
          break;
      }
    },
    onMutate: async ({ record, name }: { record: ThreadRecord; name: string }) => {
      await queryClient.cancelQueries({ queryKey: getRecordsQueryKey({ threadId }) });
      const previousRecordsTuple = queryClient.getQueriesData({ queryKey: getRecordsQueryKey({ threadId }) });

      for (const [queryKey] of previousRecordsTuple) {
        queryClient.setQueryData(queryKey, (old: Paginated<ThreadRecord>) => {
          return {
            ...old,
            data: old.data.map((r) => {
              if (r.id === record.id) {
                return {
                  ...r,
                  name,
                };
              }
              return r;
            }),
          };
        });
      }

      return { previousRecordsTuple };
    },
    onError: (_error, _newFavoriteSkill, context) => {
      context?.previousRecordsTuple.forEach(([queryKey, queryData]) => {
        queryClient.setQueryData(queryKey, queryData);
      });
    },
  });
};
