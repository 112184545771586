import { SkillStepsForm } from "@/components/SkillStepsForm";
import { Message } from "@/contexts/MessagesContext";
import { SkillsPayload } from "@/contexts/WebSocketContext";
import { SkillStep } from "@/core/skillSteps/types";
import { useSkill } from "@/hooks/skills/useSkill";
import { useSkillSteps } from "@/hooks/skills/useSkillSteps";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";

export const SkillForm = ({
  skillId,
  goBack,
  sendMessage,
  startSkillProcess,
}: {
  skillId: string;
  goBack: () => void;
  sendMessage: (message: Message) => void;
  startSkillProcess: (payload: SkillsPayload) => void;
}) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);

  const { data: skill } = useSkill(skillId);
  const { data: skillSteps } = useSkillSteps({ skillId: skill?.id });

  const steps = useMemo(
    () =>
      skillSteps ??
      (skill && [
        {
          schema: skill.inputSchema,
          uiSchema: undefined,
        } as SkillStep,
      ]) ??
      [],
    [skillSteps, skill]
  );

  const onGoBackHandler = () => {
    if (currentStepIndex === 0) {
      goBack();
    }
    setCurrentStepIndex((currentStepIndex) => currentStepIndex - 1);
  };

  const onNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex((currentStepIndex) => currentStepIndex + 1);
    }
  };

  const onSubmit = (data: Record<string, string>) => {
    if (!skill) return;
    if (skill.type === "message") {
      const message = skill.getMessage(data);
      sendMessage(message);
    } else {
      const payload = skill.getPayload(data);
      startSkillProcess(payload);
    }
    goBack();
  };

  return (
    <InternalPageStructure
      goBack={onGoBackHandler}
      onSubmit={() => {}}
      goBackButtonText={currentStepIndex === 0 ? "Cancelar" : "Voltar"}
      submitButtonText={""}
      showSubmitButton={false}
    >
      {skill ? (
        <SkillStepsForm steps={steps} currentStepIndex={currentStepIndex} onNextStep={onNextStep} onSubmit={onSubmit} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            gap: 1,
          }}
        >
          <Typography variant="subtitle">Skill não encontrada</Typography>
        </Box>
      )}
    </InternalPageStructure>
  );
};
