import React, { useEffect, useRef, useState } from "react";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import Popper from "@mui/material/Popper";
import { Action } from "@/contexts/MessagesContext";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { ActionButton } from "./ActionComponents/ActionButton";
import { ActionsMenu } from "./ActionComponents/ActionsMenu";

export function Footer(props: { actions: Action[]; loading: boolean }) {
  const { actions, loading } = props;

  const navigate = useNavigate();

  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const flags = useFeatureFlags();

  const handleClickActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
      setOpenActionsMenu((prev) => !prev);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseActionsMenu = () => {
    setOpenActionsMenu(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        handleCloseActionsMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>
      {openActionsMenu && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 1,
          pb: "10px",
          pl: 3,
          pr: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <WarningAmberRoundedIcon sx={{ color: "text.secondary" }} />

          <Typography
            variant="multiLineBody"
            color={"text.primary"}
            sx={{ ml: 1, color: "text.secondary", fontSize: "10px" }}
          >
            Conteúdos gerados por IA podem conter dados incorretos.
          </Typography>
        </Box>

        {flags.newActionButtonInFooter && (
          <ActionButton
            id="button_new_action"
            isOpen={openActionsMenu}
            disabled={loading}
            onClick={handleClickActionsMenu}
            buttonRef={buttonRef}
          />
        )}

        {flags.newActionButtonInFooter && (
          <Popper
            open={openActionsMenu}
            disablePortal={true}
            anchorEl={anchorEl}
            placement="left"
            modifiers={[
              {
                name: "flip",
                options: {
                  rootBoundary: "document",
                  fallbackPlacements: ["top"],
                },
              },
            ]}
            sx={{ px: 1, zIndex: 4 }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
                maxWidth: "100%",
              }}
            >
              <div ref={menuRef}>
                <ActionsMenu actions={actions} onClose={() => setOpenActionsMenu(false)} />
              </div>
            </Box>
          </Popper>
        )}

        {!flags.newActionButtonInFooter && (
          <IconButton aria-label="feedback" onClick={() => navigate(ROUTE_PATHS.FEEDBACK)}>
            <CampaignOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
}
