import axios from "axios";
import { env } from "@/constants/environment";
import { LoggedInUserData } from "@/contexts/AuthContext/types";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";

interface optionsProps {
  googleToken?: string;
}

export const getUserDataWithFirebaseToken = async (token: string, loginConfig?: string, options?: optionsProps) => {
  const headers = { headers: { Authorization: `bearer ${token}`, "x-authorize-product": !outsideOfficeClient() } };
  const loginTypeQuery = loginConfig ? `?loginType=${loginConfig}` : "";
  const response = await axios.post<LoggedInUserData>(
    `${env.API}/lexterLogin${loginTypeQuery}`,
    {
      googleToken: options?.googleToken,
    },
    headers
  );

  return response.data;
};
