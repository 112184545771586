import { FlowOutput } from "@/contexts/MessagesContext";

export const flowActionToText = (flowData: FlowOutput): string => {
  let text = "";
  switch (flowData.source) {
    case "TEXT":
      text += "no texto digitado.";
      break;
    case "FILE":
      text += `no arquivo ${flowData.file?.name}.`;
      break;
    case "CONTENT":
      text += "no corpo do documento aberto.";
      break;
    default:
      text += ".";
      break;
  }

  return text;
};
