import { Box, Link, List, ListItem, SxProps, Typography } from "@mui/material";
import { Chip } from "@/components/Chip";
import React from "react";
import { EXTERNAL_URLS } from "@/routes/routePaths";
import { Plan } from "../types";
import { NewPlan } from "@/contexts/PaymentContext";

export interface PlanCardProps {
  plan: Partial<Plan | NewPlan>;
  recommended?: boolean;
  button: React.JSX.Element;
  sx?: SxProps;
}

export const PlanCard = ({ plan, recommended, button, sx }: PlanCardProps) => (
  <Box
    sx={{
      px: 2,
      py: 5,
      position: "relative",
      backgroundColor: "common.shade",
      display: "flex",
      flexDirection: "column",
      width: (plan as NewPlan).id ? 280 : 238,
      borderRadius: "5px",
      "@media (max-width: 400px)": {
        minWidth: 200,
        flex: 1,
      },
      ...sx,
    }}
  >
    {recommended && (
      <Chip
        sx={{
          textTransform: "none",
          position: "absolute",
          top: "0.5rem",
          right: "0.75rem",
        }}
      >
        Recomendado
      </Chip>
    )}
    <Typography variant="label" color="common.white">
      {plan.title}
    </Typography>

    <Typography
      variant="multiLineBody"
      color="common.white"
      sx={{
        flex: 1,
        mt: 1,
      }}
    >
      {plan.description}
    </Typography>

    {plan.price && (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          mb: 2,
          mt: 4,
        }}
      >
        <Typography variant="body1" color="common.white" fontWeight={700} fontSize={20} textAlign="end">
          {plan.price
            .toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
            .replace(",00", "")}
        </Typography>
        <Typography variant="body2" color="common.white" ml={2}>
          por
          <br />
          mês
        </Typography>
      </Box>
    )}

    {button}

    <Box
      sx={{
        height: (plan as NewPlan).id ? 270 : 80,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        mt: 2,
      }}
    >
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          background: "none",
          border: "none",
          listStyleType: "disc",
          pl: "18px",
          "& .MuiListItem-root": {
            p: 0,
            display: "list-item",
            color: "common.white",
            "& .MuiTypography-root": {
              whiteSpace: "normal",
              color: "common.white",
            },
          },
        }}
      >
        <Typography fontSize={14} fontWeight={700} color="white">
          Inclui:{" "}
        </Typography>
        {plan.credits && (
          <ListItem>
            <Typography variant="multiLineBody" color="common.white" align="left">
              {plan.credits.toLocaleString("pt-BR")} créditos/mês
              <br />
              <Link align="left" href={EXTERNAL_URLS.CREDITS_INFO} target="_blank">
                (Como os créditos funcionam?)
              </Link>{" "}
            </Typography>
          </ListItem>
        )}
        {(plan as NewPlan).id && (
          <>
            <ListItem>
              <Typography variant="body">
                Ações básicas como roteiro de entrevistas, sugestão de provas, entre outros.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body">Ações intermediárias como busca de jurisprudência, resumos, etc.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body">
                Ações avançadas como elaboração de peças judiciais, extrajudiciais, contratos, entre outras.
              </Typography>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  </Box>
);
