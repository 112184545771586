import { ConditionalSkillStep, SkillStep } from "@/core/skillSteps/types";
import { createStrategySkillStep1 } from "./step1";
import { createStrategySkillStep2 } from "./step2";
import { createStrategySkillStep3 } from "./step3";

export const createStrategySkillSteps: Array<SkillStep | ConditionalSkillStep[]> = [
  createStrategySkillStep1,
  createStrategySkillStep2,
  createStrategySkillStep3,
];
