import { ActionId, DecisionType, LegalPieceMacroType } from "@/contexts/MessagesContext";
import { UploadingFile } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "../../types";
import { Type } from "@sinclair/typebox";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";
import { base64ToFile } from "@/utils/base64ToFile";
import { fileToUploadingFile } from "@/utils/fileToUploadingFile";

const inputSchema = Type.Object({
  client: Type.String(),
  area: Type.String(),
  document_name: Type.String(),
  case_breakdown: Type.Optional(Type.String()),
  decision_type: Type.String(),
  reference_piece: Type.String(),
});

const actionId = ActionId.CREATE_APPEAL;

export const createAppealSkill: Skill<typeof inputSchema> = {
  id: "create_appeal",
  name: "Escrever Apelação",
  credits: CreditPerSkill.ADVANCED_SKILL,
  inputSchema,
  tags: ["litigation"],
  actionId,
  type: "message",
  getMessage: (input) => {
    const caseBreakdownFile = input.case_breakdown?.startsWith("data:")
      ? fileToUploadingFile(base64ToFile(input.case_breakdown))
      : undefined;
    const referenceFile = input.reference_piece.startsWith("data:")
      ? fileToUploadingFile(base64ToFile(input.reference_piece))
      : undefined;

    return {
      id: uuidV4(),
      type: "FLOW",
      direction: "SENT",
      author: "Current User",
      date: DateTime.now(),
      status: "READ",
      text: "Criar Recurso",
      actions: [
        {
          id: ActionId.CREATE_ONE_PIECE,
          client: input.client,
          legal_piece_macro_type: LegalPieceMacroType.APPEAL,
          area: input.area,
          legal_piece_micro_type: input.document_name,
          decision_type: input.decision_type as DecisionType,
          case_breakdown: caseBreakdownFile
            ? {
                source: "FILE",
                file: caseBreakdownFile,
              }
            : input.case_breakdown
              ? {
                  source: "TEXT",
                  text: input.case_breakdown,
                }
              : undefined,
          reference_piece: {
            source: "FILE",
            file: referenceFile,
          },
          text: "Criar Recurso",
        },
      ],
      files: [caseBreakdownFile, referenceFile].filter((file): file is UploadingFile => Boolean(file)),
    };
  },
};
