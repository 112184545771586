import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";
import { Box, Button, ButtonProps, Modal, Typography } from "@mui/material";
import { Loading } from "@/components/Loading";
import React, { ReactNode } from "react";

export interface DialogModalButtonProps extends ButtonProps {
  label: string;
}

interface DialogModalProps {
  title?: string;
  description: string | ReactNode;
  descriptionTextAlign?: "center" | "left" | "right";
  buttons: DialogModalButtonProps[];
  open: boolean;
  onClose?: () => void;
  variant?: "primary" | "error";
  subTitle?: string;
  icon?: ReactNode;
  removeLeftBorder?: boolean;
  buttonsDirection?: "row" | "column";
  isLoading?: boolean;
  heightLoading?: number;
}

export const DialogModal = ({
  title,
  description,
  descriptionTextAlign = "center",
  open,
  onClose,
  buttons,
  variant = "primary",
  subTitle,
  removeLeftBorder,
  buttonsDirection = "row",
  icon,
  isLoading,
  heightLoading = 150,
}: DialogModalProps) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "calc(100% - 32px)",
        maxWidth: "400px",
        bgcolor: "common.white",
        borderColor: "common.lightShade",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "12px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          borderLeftColor: variant === "error" ? "error.main" : "primary.main",
          borderLeftWidth: removeLeftBorder ? "0px" : "8px",
          borderLeftStyle: "solid",
        }}
      >
        {(title || onClose) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "16px",
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: "common.lightShade",
            }}
          >
            <Typography variant="title">{title}</Typography>
            {onClose && (
              <CloseOutlinedIcon
                onClick={onClose}
                fontSize="medium"
                sx={{
                  cursor: "pointer",
                  color: "common.black",
                }}
              />
            )}
          </Box>
        )}
        {isLoading ? (
          <Box
            sx={{
              width: "100%",
              p: "16px",
              minHeight: heightLoading,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading isLoading={true} />
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              p: "16px",
              textAlign: "center",
            }}
          >
            {icon && <Box mb="16px">{icon}</Box>}

            {subTitle && (
              <Typography mb="16px">
                <b>{subTitle}</b>
              </Typography>
            )}

            <Typography mb="16px" sx={{ textAlign: descriptionTextAlign }}>
              {description}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: buttonsDirection,
                gap: "8px 16px",
                justifyContent: buttonsDirection === "column" ? undefined : "end",
              }}
            >
              {buttons.map(({ label, ...buttonProps }, index) => (
                <Button
                  key={index}
                  color={variant === "error" ? "error" : undefined}
                  variant="contained"
                  fullWidth={buttonsDirection === "column"}
                  {...buttonProps}
                >
                  <Typography variant="button">{label}</Typography>
                </Button>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  </Modal>
);
