import { Box } from "@mui/material";
import BackgroundUrl from "@/assets/svgs/signup-background.svg?react";
import React from "react";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  if (outsideOfficeClient()) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "common.black",
          position: "relative",
          display: "flex",
          overflow: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ContentOutsideOffice>{children}</ContentOutsideOffice>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor: "common.black",
        position: "relative",
      }}
    >
      <Background />
      <Content>{children}</Content>
    </Box>
  );
};

const Background = () => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url(${BackgroundUrl})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 0,
    }}
  />
);

const Content = ({ children }: LayoutProps) => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      py: 4,
      px: 3,
    }}
  >
    {children}
  </Box>
);

const ContentOutsideOffice = ({ children }: LayoutProps) => (
  <Box
    sx={{
      margin: "auto",
      overflow: "auto",
      width: "100%",
      maxWidth: "320px",
      maxHeight: "100%",
    }}
  >
    {children}
  </Box>
);
