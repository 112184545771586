import { ROUTE_PATHS } from "@/routes/routePaths";

export interface menuOptionsProps {
  label: string;
  options: { label: string; path?: string; withPlanChip?: boolean; openBilling?: boolean; replaceRoute?: boolean }[];
}

export const menuOptionsClient = [
  {
    label: "Meu Perfil",
    options: [
      { label: "Meus Dados", path: ROUTE_PATHS.CLIENT_CONFIG_MY_DATA, replaceRoute: true },
      { label: "Alterar Senha", path: ROUTE_PATHS.CLIENT_CONFIG_CHANGE_PASSWORD, replaceRoute: true },
    ],
  },
  {
    label: "Financeiro",
    options: [{ label: "Gerenciar Plano", withPlanChip: true, openBilling: true }],
  },
  {
    label: "Créditos",
    options: [{ label: "Assistente Lexter", path: ROUTE_PATHS.CREDITS_HISTORY }],
  },
];
