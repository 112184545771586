import { TypographyOptions } from "@mui/material/styles/createTypography";
// import { SimplePaletteColorOptions } from "@mui/material";

import { palette } from "./palette";

const fontFamily = ["lato", "kommon Grotesk Extended", "sans-serif"].join(",");

export const typography: TypographyOptions = {
  fontFamily,
  // The default font size of the Material Specification.
  fontSize: 14, // px
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  // Tell MUI what's the font-size on the html element.
  // 16px is the default font-size used by browsers.
  htmlFontSize: 16,
  //Custom Lexter variants
  h1: {
    fontFamily,
    fontWeight: "bold",
    fontSize: "64px",
    letterSpacing: "-2%",
    color: palette.common?.black,
  },
  h2: {
    fontFamily,
    fontWeight: "bold",
    fontSize: "40px",
    letterSpacing: "-2%",
    color: palette.common?.black,
  },
  h3: {
    fontFamily,
    fontWeight: "bold",
    fontSize: "24px",
    letterSpacing: "-2%",
    color: palette.common?.black,
  },
  h6: {
    fontFamily,
    fontWeight: "bold",
    fontSize: "20px",
    letterSpacing: "-2%",
    color: palette.common?.black,
  },
  button: {
    fontFamily,
    fontWeight: "700",
    letterSpacing: "0.04em",
    lineHeight: "28px",
  },
  subtitle: {
    fontFamily,
    fontWeight: "400",
    fontSize: "24px",
    letterSpacing: "-2%",
    color: palette.common?.black,
  },
  subtitle2: {
    fontFamily,
    fontWeight: "400",
    fontSize: "14px",
    letterSpacing: "-2%",
    color: palette.common?.black,
  },
  body: {
    fontFamily,
    fontWeight: "400",
    fontSize: "14px",
    color: palette.text?.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
  body1: {
    fontFamily,
    fontWeight: "400",
    fontSize: "16px",
    color: palette.text?.primary,
  },
  body2: {
    fontFamily,
    fontWeight: "400",
    fontSize: "12px",
    color: palette.text?.primary,
  },
  body3: {
    fontFamily,
    fontWeight: "400",
    fontSize: "14px",
    color: palette.common?.black,
  },
  label: {
    fontFamily,
    fontSize: "12px",
    fontWeight: "700",
    color: palette.text?.primary,
    textTransform: "uppercase",
  },
  multiLineBody: {
    fontFamily,
    fontWeight: "400",
    fontSize: "14px",
    color: palette.text?.primary,
    whiteSpace: "normal",
  },
  bold: {
    fontFamily,
    fontWeight: "bold",
    fontSize: "14px",
    color: palette.common?.black,
  },
  pageTitle: {
    fontFamily,
    fontWeight: "700",
    fontSize: "24px",
    color: palette.text?.primary,
  },
  title: {
    fontFamily,
    fontWeight: "700",
    fontSize: "14px",
    color: palette.text?.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
  },
  preTitle: {
    fontFamily,
    fontWeight: "700",
    fontSize: "12px",
    letterSpacing: "0.03em",
    lineHeight: "14px",
    color: palette.text?.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
  },
  multiLinePreTitle: {
    fontFamily,
    fontWeight: "700",
    fontSize: "12px",
    letterSpacing: "0.03em",
    lineHeight: "140%",
    color: palette.text?.primary,
    whiteSpace: "normal",
  },
  link: {
    fontFamily,
    cursor: "pointer",
    fontWeight: "400",
    fontSize: "14px",
    textDecoration: "underline",
    color: palette.common?.black,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    "&:hover": {
      fontWeight: "700",
    },
  },

  // Disabled variants
  h4: undefined,
  h5: undefined,
  subtitle1: undefined,
  caption: undefined,
  overline: undefined,
};
