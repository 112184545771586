import React from "react";
import { InternalPageHeader } from "@/taskpane/components/core/InternalPageStructure";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { useNavigate } from "react-router-dom";
import { PlanCard } from "../PlanCard";
import { Button } from "@/components/Button";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { NewPlan, newPlans, usePaymentContext } from "@/contexts/PaymentContext";
import { Box, Typography } from "@mui/material";
import { BackgroundPage } from "@/components/BackgroundPage";
import { PageLayout } from "@/components/PageLayout";

export const NewPlansPage = () => {
  if (outsideOfficeClient()) {
    return (
      <PageLayout
        contentSx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <PlansComponent />
      </PageLayout>
    );
  }

  return (
    <BackgroundPage bgColor="common.black">
      <PlansComponent />
    </BackgroundPage>
  );
};

const PlansComponent = () => {
  const navigate = useNavigate();
  const { onPlanChange } = usePaymentContext();

  const handleBuy = (plan: NewPlan) => {
    onPlanChange(plan);
    navigate(ROUTE_PATHS.INTERNAL_PAYMENT);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <InternalPageHeader hideOutsideOffice />
      <Typography variant="body1" fontSize={20} fontWeight={700} color="common.black" align="left">
        Conheça nossos planos
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 3,
        }}
      >
        {newPlans.map((plan, index) => (
          <PlanCard
            key={index}
            plan={plan}
            button={
              <Button variant="contained" onClick={() => handleBuy(plan)}>
                Assinar
              </Button>
            }
          />
        ))}
      </Box>
    </Box>
  );
};
