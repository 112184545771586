import { buildSkillStep } from "@/core/skillSteps/builder";

export const legalQuestionSkillStep1 = buildSkillStep({
  title: "Dúvida jurídica",
  description:
    "O Assistente Lexter pode te ajudar a tirar dúvidas, basta inserir a área jurídica e digitar a sua pergunta.",
  fields: [
    {
      name: "area",
      type: "string",
      options: [
        { label: "Cível", value: "civel" },
        { label: "Trabalhista", value: "trabalhista" },
        { label: "Previdenciário", value: "previdenciario" },
        { label: "Tributário", value: "tributario" },
        { label: "Criminal", value: "criminal" },
        {
          label: "Outros",
          value: "outros",
        },
      ],
      initialValue: "civel",
      ui: {
        label: "Escolha a área jurídica da qual a sua dúvida faz parte:",
        placeholder: "Selecione a área jurídica",
      },
    },
    {
      name: "custom_area",
      type: "string",
      overrideField: "area",
      ui: {
        label: "Digite a área jurídica:",
        placeholder: "Exemplos: Direito Ambiental, Direito Administrativo...",
      },
      condition: {
        field: "area",
        value: "outros",
      },
    },
    {
      name: "question",
      type: "string",
      ui: {
        label: "O que você gostaria de perguntar ao assistente?",
        placeholder:
          "Exemplo: Houve alguma alteração recente na legislação ou no Regimento Interno do STF que possa afetar o prazo para a interposição do Agravo Regimental?",
        widget: "textarea",
      },
    },
  ],
  submitButtonText: "Perguntar",
});
