import { env } from "../../constants/environment";
import * as logger from "@/core/logger";

export const setCookie = (key: string, value: string) => {
  let expires = "";
  const date = new Date();
  // Saving cookies for 30 days
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  expires = "; expires=" + date.toUTCString();

  document.cookie = key + "=" + (value || "") + expires + `; path=/;domain=.${getDomain()}; Secure; SameSite=None;`;
  logger.debug(`After setCookie - document.cookie: ${document.cookie}`);
};

export const getCookie = (key: string) => {
  const keyEQ = key + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(keyEQ) == 0) return c.substring(keyEQ.length, c.length);
  }

  logger.debug(`After getCookie empty - document.cookie: ${document.cookie}`);
  return null;
};

export const removeCookie = (key: string) => {
  document.cookie = key + `=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=.${getDomain()};`;
};

const getDomain = () => {
  return env.ENV === "development" ? "localhost" : "lexter.ai";
};
