import { buildSkillStep } from "@/core/skillSteps/builder";

const createAppealSkillCivelStep3 = buildSkillStep({
  title: "Criar Recurso - Passo 3",
  fields: [
    {
      name: "document_name",
      type: "string",
      options: [
        {
          label: "Apelação",
          value: "apelacao",
        },
        {
          value: "Outros",
        },
      ],
      initialValue: "apelacao",
      ui: {
        label: "Tipo de Peça:",
        widget: "radio",
      },
    },
    {
      name: "custom_document_name",
      type: "string",
      overrideField: "document_name",
      ui: {
        label: "Qual é o nome da peça?",
        widget: "textarea",
        placeholder: "Exemplos: Recurso Especial, Recurso Inominado, Recurso Extraordinário...",
      },
      condition: {
        field: "document_name",
        value: "Outros",
      },
    },
  ],
  condition: [
    {
      field: "area",
      value: "civel",
    },
    {
      field: "decision_type",
      value: "decisoes_terminativas",
    },
  ],
});

const createAppealSkillTrabalhistaStep3 = buildSkillStep({
  title: "Criar Recurso - Passo 3",
  fields: [
    {
      name: "document_name",
      type: "string",
      options: [
        {
          label: "Recurso ordinário",
          value: "recurso_ordinario",
        },
        {
          value: "Outros",
        },
      ],
      initialValue: "recurso_ordinario",
      ui: {
        label: "Tipo de Peça:",
        widget: "radio",
      },
    },
    {
      name: "custom_document_name",
      type: "string",
      overrideField: "document_name",
      ui: {
        label: "Qual é o nome da peça?",
        widget: "textarea",
        placeholder: "Exemplos: Recurso Especial, Recurso Inominado, Recurso Extraordinário...",
      },
      condition: {
        field: "document_name",
        value: "Outros",
      },
    },
  ],
  condition: [
    {
      field: "area",
      value: "trabalhista",
    },
    {
      field: "decision_type",
      value: "decisoes_terminativas",
    },
  ],
});

const createAppealSkillInterlocutoriaStep3 = buildSkillStep({
  title: "Criar Recurso - Passo 3",
  fields: [
    {
      name: "document_name",
      type: "string",
      ui: {
        label: "Qual é o nome da peça?",
        widget: "textarea",
        placeholder: "Exemplos: Agravo de instrumento, Agravo interno, Recurso em sentido estrito...",
      },
    },
  ],
  condition: {
    field: "decision_type",
    value: "decisoes_interlocutoria",
  },
});

const createAppealSkillTerminativasStep3 = buildSkillStep({
  title: "Criar Recurso - Passo 3",
  fields: [
    {
      name: "document_name",
      type: "string",
      ui: {
        label: "Qual é o nome da peça?",
        widget: "textarea",
        placeholder: "Exemplos: Recurso Especial, Recurso Inominado, Recurso Extraordinário...",
      },
    },
  ],
  condition: {
    field: "decision_type",
    value: "decisoes_terminativas",
  },
});

export const createAppealSkillStep3 = [
  createAppealSkillCivelStep3,
  createAppealSkillTrabalhistaStep3,
  createAppealSkillInterlocutoriaStep3,
  createAppealSkillTerminativasStep3,
];
