import * as React from "react";
import { Routes, Route, useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";

import { EditorPageLayout } from "@/components/EditorPageLayout";
import { Warnings } from "@/components/Warnings";
import { useAuthContext } from "@/contexts/AuthContext";
import { CreditsProvider } from "@/contexts/CreditsContext";
import { MessagesProvider } from "@/contexts/MessagesContext";
import { ThreadsContextProvider } from "@/contexts/ThreadsContext";
import { WebEditorContextProvider } from "@/contexts/WebEditorContext";
import { WebSocketProvider } from "@/contexts/WebSocketContext";
import { useCheckTermsOfUse } from "@/hooks/useCheckTermsOfUse";
import { useClarity } from "@/hooks/useClarity";
import { useHeap } from "@/hooks/useHeap";
import { useIdentifyUserForFlags } from "@/hooks/useIdentifyUserForFlags";
import { useLoadingHandler } from "@/hooks/useLoadingHandler";
import { ActivateAccount } from "@/pages/ActivateAccount";
import { ClientConfig } from "@/pages/ClientConfig";
import { ClientConfigChangePasswordModal } from "@/pages/ClientConfig/ClientConfigChangePasswordModal";
import { ClientConfigMyDataModal } from "@/pages/ClientConfig/ClientConfigMyDataModal";
import { Contestation } from "@/pages/Contestation";
import { CreditsHistory } from "@/pages/CreditsHistory";
import { ExtractedClaims } from "@/pages/ExtractedClaims";
import { Home } from "@/pages/Home";
import { NotFound } from "@/pages/NotFound";
import { PetitionSummary } from "@/pages/PetitionSummary";
import { JurisprudenceSearch } from "@/pages/JurisprudenceSearch";
import { Plans } from "@/pages/Plans";
import { Signup } from "@/pages/Signup";
import { SignupFailure } from "@/pages/SignupFailure";
import { TermsOfUse } from "@/pages/TermsOfUse";
import { UseWord } from "@/pages/UseWord";
import { useEffect } from "react";
import { Chat } from "@/taskpane/components/Chat";
import { Feedback } from "@/taskpane/components/Feedback";
import { Login } from "@/taskpane/components/Login";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import ConditionalLayout from "./components/ConditionalLayout";
import LoggedLayout from "./components/LoggedLayout";
import { EDITOR_ROUTES_PREFIX, LOGGED_ROUTES_PREFIX, ROUTE_PATHS } from "./routePaths";
import { EditDocumentSection } from "@/pages/EditDocumentSection";
import { SessionSettingsToast } from "@/components/SessionSettingsToast";
import { useUserGuiding } from "@/hooks/useUserGuiding";
import { SessionSettings } from "@/pages/SessionSettings";
import { InternalPaymentPage } from "@/pages/Plans/InternalPaymentPage";
import { PaymentProvider } from "@/contexts/PaymentContext";
import { NotificationsContextProvider } from "@/contexts/NotificationsContext";
import { Threads } from "@/contexts/ThreadsContext/components/Threads";
import { useTawk } from "@/hooks/useTawk";

export const IndexRoutes = () => {
  const { loading, user } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  useIdentifyUserForFlags(user);
  useLoadingHandler({ loading });

  const previousLocation = location.state?.previousLocation;

  if (loading) return null;

  const isLoggedRoute = location.pathname.startsWith(LOGGED_ROUTES_PREFIX);

  if (!isLoggedRoute && user) return <Navigate to={outsideOfficeClient() ? ROUTE_PATHS.HOME : ROUTE_PATHS.CHAT} />;

  if (isLoggedRoute && !user) return <Navigate to={ROUTE_PATHS.LOGIN} />;

  return (
    <ConditionalLayout>
      <Routes location={previousLocation || location}>
        {/* @TODO remover isso quando tirarmos o /taskpane.html como entrada inicial do addin */}
        <Route path="/taskpane.html" element={<Navigate to={ROUTE_PATHS.LOGIN} />} />

        <Route path={ROUTE_PATHS.LOGIN} element={<Login />} />
        <Route path={ROUTE_PATHS.SIGNUP} element={<Signup />} />
        <Route path={ROUTE_PATHS.ACTIVATE_ACCOUNT} element={<ActivateAccount />} />
        <Route path={ROUTE_PATHS.SIGNUP_FAILURE} element={<SignupFailure />} />

        <Route path={LOGGED_ROUTES_PREFIX} element={<LoggedWrapper />}>
          <Route
            path={ROUTE_PATHS.THREAD_HISTORY}
            element={<Threads component={"SCREEN"} onClose={() => navigate(-1)} />}
          />

          <Route path={EDITOR_ROUTES_PREFIX} element={<EditorPageLayout />}>
            <Route path={ROUTE_PATHS.CHAT} element={<Chat />} />
            <Route path={ROUTE_PATHS.NEW_CHAT} element={<Chat />} />
            <Route path={ROUTE_PATHS.THREAD} element={<Chat />} />
            <Route path={ROUTE_PATHS.DOCUMENT} element={<Chat />} />
            <Route path={ROUTE_PATHS.FEEDBACK} element={<Feedback />} />
            <Route path={ROUTE_PATHS.EXTRACTED_CLAIMS} element={<ExtractedClaims />} />
            <Route path={ROUTE_PATHS.PETITION_SUMMARY} element={<PetitionSummary />} />
            <Route path={ROUTE_PATHS.JURISPRUDENCE_SEARCH} element={<JurisprudenceSearch />} />
            <Route path={ROUTE_PATHS.CREATE_CONTESTATION} element={<Contestation />} />
            <Route path={ROUTE_PATHS.EDIT_DOCUMENT} element={<EditDocumentSection />} />
          </Route>

          <Route path={ROUTE_PATHS.CLIENT_CONFIG} element={<ClientConfig />}>
            <Route path={ROUTE_PATHS.CLIENT_CONFIG_MY_DATA} element={<ClientConfigMyDataModal />} />
            <Route path={ROUTE_PATHS.CLIENT_CONFIG_CHANGE_PASSWORD} element={<ClientConfigChangePasswordModal />} />
          </Route>

          <Route path={ROUTE_PATHS.PLANS} element={<Plans />} />
          <Route path={ROUTE_PATHS.INTERNAL_PAYMENT} element={<InternalPaymentPage />} />
          <Route path={ROUTE_PATHS.CREDITS_HISTORY} element={<CreditsHistory />} />
          <Route path={ROUTE_PATHS.TERMS_OF_USE} element={<TermsOfUse />} />
          <Route path={ROUTE_PATHS.USE_WORD} element={<UseWord />} />
          <Route path={ROUTE_PATHS.HOME} element={<Home />} />
          <Route path={ROUTE_PATHS.SESSION_SETTINGS} element={<SessionSettings />} />

          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
      {previousLocation && (
        <Routes>
          <Route
            path={ROUTE_PATHS.THREAD_HISTORY}
            element={
              <ThreadsContextProvider>
                <Threads component={"MODAL"} onClose={() => navigate(previousLocation)} />
              </ThreadsContextProvider>
            }
          />
        </Routes>
      )}
    </ConditionalLayout>
  );
};

const LoggedWrapper = () => {
  useCheckTermsOfUse();
  const { user } = useAuthContext();
  const heap = useHeap();
  const clarity = useClarity();
  const userGuiding = useUserGuiding();
  const tawk = useTawk();

  useEffect(() => {
    if (user) {
      heap.identify(user.userId);
      heap.addUserProperties({
        UserEmail: user.userEmail,
        UserID: user.userId,
        CompanyID: user.companyId,
      });
      clarity.addUserId(user.userId);
    }
    userGuiding.initialize(user);
    tawk.loadTawk();

    return () => {
      userGuiding.remove();
    };
  }, []);

  return (
    <WebSocketProvider>
      <PaymentProvider>
        <WebEditorContextProvider>
          <ThreadsContextProvider>
            <CreditsProvider>
              <MessagesProvider>
                <NotificationsContextProvider>
                  <LoggedLayout>
                    <Warnings />
                    <SessionSettingsToast />
                    <Outlet />
                  </LoggedLayout>
                </NotificationsContextProvider>
              </MessagesProvider>
            </CreditsProvider>
          </ThreadsContextProvider>
        </WebEditorContextProvider>
      </PaymentProvider>
    </WebSocketProvider>
  );
};
