import React from "react";

import "firebase/compat/auth";

import { AuthContext } from "./context";
import { useLogin } from "./hooks/useLogin";

export function AuthProvider({ children }: { children: React.ReactChild }) {
  const {
    user,
    loginType,
    loading,
    logout,
    loginWithEmailAndPassword,
    loginWithOffice,
    loginWithGoogle,
    setupFirebaseUser,
  } = useLogin();

  return (
    <AuthContext.Provider
      value={{
        user,
        loginType,
        loading,
        logout,
        loginWithEmailAndPassword,
        loginWithOffice,
        loginWithGoogle,
        setupFirebaseUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
