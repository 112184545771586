import { SearchOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { StarBorder as StarBorderIcon, Star as StarFillIcon } from "@mui/icons-material";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { palette } from "@/theme/palette";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { ActionProps, motionsType } from "@/core/actions";
import { Tab, Tabs } from "@/components/Tabs";
import { Chip } from "@/components/Chip";

interface ActionsListProps {
  actions: ActionProps[];
  handleComingSoon: (action: ActionProps) => void;
  isLoading: boolean;
  toggleFavoriteSkill: ({ skillId, isFavorite }: { skillId: string; isFavorite: boolean }) => Promise<void>;
}

type TabValue = "all" | "new" | "comingSoon" | "litigation" | "consultative" | "mostUsed";

const filteredMotions = (filteredActions: ActionProps[], value: string) => {
  const hasMotion = motionsType.find((motion) => motion.toLowerCase().includes(value.toLowerCase()));
  if (hasMotion) filteredActions = [filteredActions[0]];
  return filteredActions;
};

export const ActionsList = ({ actions, handleComingSoon, isLoading, toggleFavoriteSkill }: ActionsListProps) => {
  const navigate = useNavigate();

  const [tabValue, setTabValue] = React.useState<TabValue>("new");
  const [searchValue, setSearchValue] = useState("");

  React.useEffect(() => {
    handleTabChange(tabValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  const filteredActions = useMemo(() => {
    const filteredActions = searchValue
      ? actions.filter((action) => action.name.toLowerCase().includes(searchValue.toLowerCase()))
      : actions;

    const enabledFilteredActions = filteredActions.filter((action) => !action.disabled);
    const disabledFilteredActions = filteredActions.filter((action) => action.disabled);

    switch (tabValue) {
      case "comingSoon":
        return disabledFilteredActions;
      case "new":
        return enabledFilteredActions.filter((action) => action.tags.includes("new"));
      case "litigation":
        return enabledFilteredActions.filter((action) => action.tags.includes("litigation"));
      case "consultative":
        return enabledFilteredActions.filter((action) => action.tags.includes("consultative"));
      case "mostUsed":
        return enabledFilteredActions.filter((action) => action.tags.includes("mostUsed"));
      default:
        if (filteredActions.length !== 0) {
          return enabledFilteredActions;
        } else {
          return filteredMotions(enabledFilteredActions, searchValue);
        }
    }
  }, [actions, searchValue, tabValue]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleTabChange = (value: TabValue) => {
    setTabValue(value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
          mb: 3,
        }}
      >
        <TabsActionList value={tabValue} handleTabChange={handleTabChange} />
        <SearchButton handleSearch={handleSearch} />
      </Box>

      {filteredActions.length === 0 && (
        <Box
          sx={{
            m: 4,
            textAlign: "center",
          }}
        >
          <Typography>Não encontramos nenhuma ação disponível com o termo buscado.</Typography>
          <Typography>
            Como opção,{" "}
            <Link
              sx={{
                fontWeight: 700,
              }}
              component="button"
              onClick={() => {
                navigate(ROUTE_PATHS.CHAT);
              }}
            >
              USE O CHAT
            </Link>{" "}
            do Assistente Lexter para ajudar.
          </Typography>
        </Box>
      )}
      <List
        sx={{
          border: "1px",
        }}
      >
        {filteredActions.map((action, i) => {
          return (
            <ActionButton
              key={i}
              action={action}
              toggleFavoriteSkill={toggleFavoriteSkill}
              handleComingSoon={handleComingSoon}
              isLoading={isLoading}
            />
          );
        })}
      </List>
    </Box>
  );
};

interface ActionButtonProps {
  action: ActionProps;
  handleComingSoon: (action: ActionProps) => void;
  isLoading: boolean;
  toggleFavoriteSkill: ({ skillId, isFavorite }: { skillId: string; isFavorite: boolean }) => Promise<void>;
}

export const ActionButton = ({ action, toggleFavoriteSkill, isLoading, handleComingSoon }: ActionButtonProps) => {
  const navigate = useNavigate();
  const { comingSoonButtonEnabled } = useFeatureFlags();
  const { name, redirectTo, credits, hidden, id } = action;

  const comingSoon = action.disabled;

  const handleRedirect = () => {
    if (comingSoon) {
      return handleComingSoon(action);
    }

    navigate(redirectTo.path);
  };

  const handleFavorite = async () => {
    await toggleFavoriteSkill({ skillId: id, isFavorite: action.favorite });
  };

  if (hidden) return null;

  const FavoriteIcon = ({ isFavorite }: { isFavorite: boolean }) => {
    if (isLoading) {
      return <Skeleton variant="circular" width="24px" height="24px" />;
    }

    if (isFavorite) {
      return <StarFillIcon onClick={handleFavorite} sx={{ cursor: "default", fill: palette.common?.yellow }} />;
    }

    return <StarBorderIcon onClick={handleFavorite} sx={{ cursor: "default", color: "common.ligthShade" }} />;
  };

  return (
    <ListItem
      sx={{
        border: "1px solid black",
        borderColor: "common.lightShade",
        bgcolor: "common.white",
        marginY: 1,
        marginX: 0,
        padding: 0,

        borderRadius: "5px",
        boxShadow: "0px 4px 16px 0px #00000014",
      }}
      secondaryAction={
        <IconButton disabled={isLoading} aria-label="favorite">
          <FavoriteIcon isFavorite={action.favorite} />
        </IconButton>
      }
    >
      <ListItemButton
        id={`list_button_${id}`}
        sx={{
          borderRadius: "5px",

          "&:hover": {
            fontSize: "11px",
            outline: "1.5px solid blue",
            borderColor: "common.primary.main",
            bgcolor: "common.white",
          },

          "&:focus-visible": {
            fontSize: "11px",
            outline: "1.5px solid blue",
            borderColor: "common.primary.main",
            bgcolor: "common.white",
          },
        }}
        disabled={!comingSoonButtonEnabled && comingSoon}
        onClick={() => handleRedirect()}
      >
        <ListItemText
          sx={{
            "& .MuiListItemText-primary": {
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "2",
            },
            "& .MuiListItemText-secondary": {
              fontSize: "11px",
            },
          }}
          primary={
            <React.Fragment>
              <Typography
                sx={{ fontWeight: 700, fontSize: "14px", display: "flex", gap: 1, alignItems: "center" }}
                component="span"
                color="text.primary"
              >
                {name.toUpperCase()}
                {comingSoon && (
                  <Chip sx={{ fontSize: "10px" }} backgroundColor="common.mediumShade" color="common.white">
                    EM BREVE
                  </Chip>
                )}
                {action.tags.includes("new") && (
                  <Chip sx={{ fontSize: "10px" }} backgroundColor="common.yellow" color="common.shade">
                    Novidade
                  </Chip>
                )}
                {action.tags.includes("recommend") && (
                  <Chip sx={{ fontSize: "10px" }} backgroundColor="secondary.main" color="common.shade">
                    Recomendado
                  </Chip>
                )}
              </Typography>
            </React.Fragment>
          }
          secondary={`${credits} CRÉDITOS`}
        ></ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

interface SearchProps {
  handleSearch: (value: string) => void;
}

const SearchButton = (props: SearchProps) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleSearch(event.target.value);
  };

  return (
    <TextField
      sx={{
        bgcolor: "common.white",
        "& label.Mui-focused": {
          color: "common.mediumShade",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "common.mediumShade",
        },
        "& .MuiOutlinedInput-root": {
          pl: "8px",
          "& fieldset": {
            borderColor: "common.lightShade",
          },
          "&:hover fieldset": {
            borderColor: "common.mediumShade",
          },
          "&:focus-visible": {
            borderColor: "common.mediumShade",
          },
          "&.Mui-focused fieldset": {
            borderColor: "common.mediumShade",
          },
        },
        borderRadius: "5px",
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined sx={{ color: "common.lightShade" }} />
          </InputAdornment>
        ),
      }}
      hiddenLabel
      variant="outlined"
      placeholder="Buscar..."
      onChange={handleSearch}
      size="small"
    />
  );
};

interface TabsActionListProps {
  handleTabChange: (value: TabValue) => void;
  value: TabValue;
}

const TabsActionList = (props: TabsActionListProps) => {
  const handleChange = (_event: React.SyntheticEvent, tab: TabValue) => {
    props.handleTabChange(tab);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs value={props.value} onChange={handleChange}>
        <Tab label="Novidade" value="new" />
        <Tab label="Mais usadas" value="mostUsed" />
        <Tab label="Contencioso" value="litigation" />
        <Tab label="Consultivas" value="consultative" />
        <Tab label="Em breve" value="comingSoon" />
        <Tab label="Todas as ações" value="all" />
      </Tabs>
    </Box>
  );
};
