import { Form, MultiTypeInput, Text, TextInput, Title } from "@/components/FormComponents";
import { Select } from "@/components/FormComponents/Select";
import { ActionId, Area, useMessagesContext } from "@/contexts/MessagesContext";
import { UserInput } from "@/contexts/WebSocketContext";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import React, { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

enum Step {
  REFERENCE_UPLOAD,
  AREA_AND_CLIENT_NAME,
  DETAILS,
}

export const CreateSettlementOffer = () => {
  const [reference, setReference] = useState<UserInput>();
  const [area, setArea] = useState<string>("");
  const [clientName, setClientName] = useState<string>("");
  const [details, setDetails] = useState<string>("");

  const [currentStep, setCurrentStep] = useState(0);
  const steps = useMemo(() => [Step.REFERENCE_UPLOAD, Step.AREA_AND_CLIENT_NAME, Step.DETAILS], []);

  const { goToChat, startSkillProcess } = useMessagesContext();

  const handleGoBack = () => {
    if (currentStep === 0) {
      goToChat();
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const canProceed = () => {
    switch (steps[currentStep]) {
      case Step.REFERENCE_UPLOAD:
        return !!reference;
      case Step.AREA_AND_CLIENT_NAME:
        return !!area && !!clientName;
      case Step.DETAILS:
        return true;
    }
  };

  const handleSubmit = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (!reference || !area || !clientName) {
        return;
      }

      startSkillProcess({
        skillId: ActionId.SETTLEMENT_OFFER,
        requestId: uuid(),
        payload: {
          reference_piece: reference,
          area: area,
          client: clientName,
          case_breakdown: details,
        },
        messageToSave: `Elaborar proposta de acordo`,
      });

      goToChat();
    }
  };

  const title = `Elaborar proposta de acordo - Passo ${currentStep + 1}`;

  return (
    <InternalPageStructure
      goBack={handleGoBack}
      onSubmit={handleSubmit}
      goBackButtonText={currentStep === 0 ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep === steps.length - 1 ? "Elaborar proposta de acordo" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={steps.length}
      currentStep={currentStep}
    >
      <Form show={steps[currentStep] === Step.REFERENCE_UPLOAD}>
        <Title>{title}</Title>
        <Text>Utilize a sua peça inaugural para o Assistente Lexter elaborar uma proposta de acordo. </Text>
        <MultiTypeInput
          label="Inserir peça inaugural (petição inicial, reclamação trabalhista):"
          onChange={setReference}
          inputTypes={["FILE"]}
        />
      </Form>

      <Form show={steps[currentStep] === Step.AREA_AND_CLIENT_NAME}>
        <Title>{title}</Title>
        <Select
          id="input_select_area"
          label="Para qual área jurídica?"
          placeholder="Selecione a área"
          options={[
            { value: Area.CIVIL, label: "Cível" },
            { value: Area.LABOR, label: "Trabalhista" },
            { value: Area.SOCIAL_SECURITY, label: "Previdenciário" },
            { value: Area.TAX, label: "Tributário" },
            { value: Area.CRIMINAL, label: "Criminal" },
          ]}
          othersOption={{
            label: "Outras",
            input: {
              label: "Digite a área jurídica:",
              placeholder: "Exemplos: Direito Ambiental, Direito Administrativo...",
            },
          }}
          onChange={setArea}
        />
        <TextInput
          id="input_client_name"
          label="Qual é o Nome do cliente (QUE IRÁ PROPOR O ACORDO)?"
          placeholder="Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas."
          value={clientName}
          onChange={setClientName}
        />
      </Form>

      <Form show={steps[currentStep] === Step.DETAILS}>
        <Title>{title}</Title>
        <TextInput
          label="INSERIR OBSERVAÇÕES SOBRE O ACORDO (Opcional):"
          placeholder="Exemplo: Proponho quitar meu débito de R$ [valor] com entrada de R$ [valor] e [quantidade] parcelas de R$ [valor] cada, vencendo a primeira em [data] e as demais mensalmente. Em caso de inadimplemento, todas as parcelas vencerão antecipadamente com correção monetária e juros de 1% ao mês. Solicito a notificação da parte credora para análise e homologação do acordo."
          value={details}
          onChange={setDetails}
        />
      </Form>
    </InternalPageStructure>
  );
};
