import {
  BookmarkBorder as BookmarkBorderIcon,
  Close as CloseIcon,
  ExpandLessRounded as ExpandLessRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Collapse, IconButton, InputAdornment, Link, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";

import { Button } from "@/components/Button";
import { DocumentSearchModal } from "@/components/DocumentSearchModal";
import { GoBackButton } from "@/components/GoBackButton";
import { ActionId, useMessagesContext } from "@/contexts/MessagesContext";
import {
  ConnectionState,
  Precedent,
  PrecedentScore,
  useSocket,
  WebsocketMessageType,
} from "@/contexts/WebSocketContext";
import { useApi } from "@/hooks/useApi";
import { useEditorUI } from "@/hooks/useEditorUI";
import { TextInput } from "@/taskpane/components/Chat/FlowInput";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";

import { Chip } from "@/components/Chip";
import { Loading } from "@/components/Loading";
import * as logger from "@/core/logger";
import { useEditor } from "@/hooks/useEditor";
import { ParagraphStyle } from "@/hooks/useEditor/types";
import { WebToast } from "@/taskpane/components/core/Toast";
import { formattedDateFromISO } from "@/utils/dates/formattedDateFromISO";
import { Filters } from "./components/Filters";
import { FiltersState } from "./components/Filters/types";
import { useHeap } from "@/hooks/useHeap";

export const JurisprudenceSearch = () => {
  const { threadConnectionState, connectToThread } = useMessagesContext();
  const { cancelExecution } = useApi();
  const [caseBreakdown, setCaseBreakdown] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [precedents, setPrecedents] = React.useState<Precedent[] | null>(null);
  const [requestId, setRequestId] = React.useState<string>();
  const [filters, setFilters] = useState<FiltersState>();

  const startNewSearch = () => {
    if (loading && requestId) {
      cancelExecution({ executionId: requestId });
    }

    setPrecedents(null);
    setRequestId(undefined);
    setLoading(false);
  };

  React.useEffect(() => {
    if (threadConnectionState === ConnectionState.CLOSED) {
      connectToThread();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const socket = useSocket({
    onMessageReceived: ({ type, data }) => {
      if (
        type !== WebsocketMessageType.SKILLS ||
        data.skillId !== ActionId.SEARCH_JURISPRUDENCE ||
        data.requestId !== requestId
      )
        return;

      if (data.success) {
        setPrecedents(data.payload.precedents);
      } else {
        WebToast.error("A busca falhou. Tente novamente.");
      }
      setLoading(false);
    },
  });

  const onSubmit = React.useCallback(() => {
    logger.info("Submitting jurisprudence search message");
    setLoading(true);

    try {
      const newRequestId = uuid();

      const courtCodes = filters?.courts?.map((c) => c.code);

      socket.send({
        type: WebsocketMessageType.SKILLS,
        data: {
          skillId: ActionId.SEARCH_JURISPRUDENCE,
          requestId: newRequestId,
          payload: {
            case_breakdown: caseBreakdown,
            court_code: courtCodes?.length ? courtCodes : undefined,
            min_date: filters?.minDate?.toJSDate().toISOString(),
          },
        },
      });
      setRequestId(newRequestId);
    } catch (e) {
      logger.error("Error sending jurisprudence search message", e);
      WebToast.error("A busca falhou");
      setLoading(false);
    }
  }, [caseBreakdown, filters, socket]);

  if (loading) {
    return <LoadingWrapper onNewSearch={() => startNewSearch()} />;
  }

  if (requestId && precedents) {
    return <PrecedentsList precedents={precedents} onNewSearch={() => startNewSearch()} requestId={requestId} />;
  }

  return (
    <InternalPageStructure
      onSubmit={onSubmit}
      error={null}
      submitButtonText={threadConnectionState !== ConnectionState.OPEN ? "Carregando..." : "Buscar"}
      submitButtonDisabled={threadConnectionState !== ConnectionState.OPEN || !caseBreakdown || loading}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box>
            <Typography variant="preTitle" color={"text.primary"}>
              BUSCAR JURISPRUDÊNCIA
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box>
              <Typography variant="preTitle" color={"text.primary"}>
                Selecionar opção para direcionamento da busca:
              </Typography>
            </Box>

            <Box>
              <InputOptions />
            </Box>
          </Box>
        </Box>

        <Box>
          <Box>
            <Typography variant="preTitle" color={"text.primary"}>
              Digite os fatos do caso:
            </Typography>
          </Box>

          <TextInput
            onChange={(input) => setCaseBreakdown(input?.text || "")}
            placeholder="Insira os fatos do caso que servirão de base para a busca de jurisprudência."
            minHeight={140}
          />
        </Box>

        <Filters onChange={setFilters} />
      </Box>
    </InternalPageStructure>
  );
};

function InputOptions() {
  {
    /* TODO: usar o componente de FlowInput ou algo semelhante */
  }

  return (
    <FormControl>
      <RadioGroup defaultValue="CONTENT" name="jurisprudence-search" value="TEXT" sx={{ pl: "10px" }}>
        <InputOption value="SELECT" text="Selecionar texto no documento aberto" disabled={true} />
        <InputOption value="UPLOAD" text="Extrair fatos através do upload de nova peça" disabled={true} />
        <InputOption value="TEXT" text="Inserir texto dos fatos" />
      </RadioGroup>
    </FormControl>
  );
}

function InputOption({ value, text, disabled }: { value: string; text: string; disabled?: boolean }) {
  {
    /* TODO: use FlowInput component or something like it */
  }

  return (
    <FormControlLabel
      value={value}
      control={<Radio size="small" />}
      disabled={disabled}
      label={
        <Typography
          variant="body"
          color={disabled ? "action.disabled" : "text.primary"}
          sx={{ textWrap: "wrap", display: "flex" }}
        >
          {text}
          {disabled && (
            <Chip backgroundColor="action.disabled" color="common.black" sx={{ ml: 1 }}>
              EM BREVE
            </Chip>
          )}
        </Typography>
      }
      sx={{ display: "flex" }}
    />
  );
}

function LoadingWrapper({ onNewSearch }: { onNewSearch: () => void }) {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "48px",
          backgroundColor: "common.white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          pt: 1,
          pl: 2,
          pr: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <GoBackButton onClick={onNewSearch}>Cancelar</GoBackButton>
      </Box>

      <Loading isLoading={true} />
    </Box>
  );
}

function PrecedentsList({
  precedents,
  onNewSearch,
  requestId,
}: {
  precedents: Precedent[];
  onNewSearch: () => void;
  requestId: string;
}) {
  const [search, setSearch] = React.useState<string>("");
  const [filteredPrecedents, setFilteredPrecedents] = React.useState<Precedent[]>(precedents);

  const onChangeSearch = (value: string) => {
    setSearch(value);

    if (!value) {
      setFilteredPrecedents(precedents);
    }

    setFilteredPrecedents(
      precedents.filter((p) => {
        return (
          p.title?.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          p.headnote?.toLowerCase().indexOf(value.toLowerCase()) > -1
        );
      })
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "48px",
          backgroundColor: "common.white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          pt: 1,
          pl: 2,
          pr: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <GoBackButton onClick={onNewSearch}>Nova Busca</GoBackButton>
      </Box>

      <Box
        sx={{
          width: "100%",
          py: "16px",
          px: "16px",
          mb: "8px",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="preTitle" color={"text.primary"}>
            BUSCAR JURISPRUDÊNCIA
          </Typography>
        </Box>

        <Box sx={{ mt: 1, mb: 2 }}>
          <Typography variant="multiLineBody" color={"text.primary"}>
            Abaixo você pode visualizar os acórdãos mais próximos ao teor dos fatos enviados.
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="preTitle" color={"text.primary"}>
            BUSCAR
          </Typography>
        </Box>

        <Box sx={{ mb: 2, width: "100%" }}>
          <TextField
            hiddenLabel
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                borderColor: "common.lightShade",
                backgroundColor: "common.dorian",
                padding: "0 8px",
                height: "38px",
                width: "100%",
              },
              "& .MuiInputBase-input": {
                padding: "0",
                width: "100%",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "common.lightShade",
                  width: "100%",
                },
                width: "100%",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: Boolean(search) ? (
                <InputAdornment position="end">
                  <IconButton onClick={() => onChangeSearch("")} size="small" sx={{ color: "primary.dark" }}>
                    <CloseIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
            value={search}
            placeholder="Buscar..."
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     onChangeSearch('');
            //   }
            // }}
            // onBlur={() => {
            //   onChangeSearch('');
            // }}
          />
        </Box>

        {!filteredPrecedents.length && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="multiLineBody" color={"text.primary"}>
              Nenhum resultado encontrado.
            </Typography>
          </Box>
        )}

        {filteredPrecedents.map((precedent, idx) => {
          return (
            <Box key={idx} sx={{ mb: 1 }}>
              <PrecedentCard
                precedent={precedent}
                metadata={{
                  id: precedent.cnj_unique_number,
                  position: idx + 1,
                  requestId,
                }}
                key={idx}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export const PrecedentCard = ({
  precedent,
  metadata,
}: {
  precedent: Precedent;
  metadata: { id: string; position: number; requestId: string };
}) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const ExpandIcon = isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon;
  const [loadingSave, setLoadingSave] = useState(false);
  const [saved, setSaved] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const { openModal, closeModal } = useEditorUI();
  const [precedentFullText, setPrecedentFullText] = useState<string | null>(null);
  const { savePrecedent } = useApi();
  const { thread } = useSocket();
  const { editor } = useEditor();
  const heap = useHeap();

  const handleOpen = async () => {
    heap.track("Abrir acórdão", metadata);
    if (!precedent.full_text?.presigned_url_location) return;

    try {
      setLoadingFetch(true);

      let fullText = precedentFullText;
      if (!fullText) {
        const response = await axios.get<string>(precedent.full_text.presigned_url_location);
        fullText = response.data;
        setPrecedentFullText(fullText);
      }

      openModal(<DocumentSearchModal title={precedent.title} documentText={fullText} onClose={closeModal} />);
    } catch (error) {
      logger.error("Error fetching full_text precedent", error);
      WebToast.error("Erro ao buscar acórdão");
    } finally {
      setLoadingFetch(false);
    }
  };

  const handleSave = async () => {
    heap.track("Salvar acórdão", metadata);
    if (saved || loadingSave) return;

    try {
      if (!thread) {
        throw new Error("Not connected to a thread");
      }

      setLoadingSave(true);
      await savePrecedent({
        threadId: thread.id,
        title: precedent.title,
        courtCode: precedent.court_code,
        cnjUniqueNumber: precedent.cnj_unique_number,
        headnote: precedent.headnote,
        headnoteGen: precedent.headnote_gen || undefined,
        fullTextLocation: precedent.full_text?.location,
        judgmentDate: precedent.judgment_date || undefined,
        publicationDate: precedent.publication_date || undefined,
        releaseDate: precedent.release_date || undefined,
        signatureDate: precedent.signature_date || undefined,
        score: precedent.score,
        scoreReason: precedent.score_reason,
        url: precedent.url,
      });
      setSaved(true);
    } catch (error) {
      logger.error("Error saving precedent", error);
      WebToast.error("Erro ao salvar acórdão");
    } finally {
      setLoadingSave(false);
    }
  };

  const handleWriteParagraph = async () => {
    heap.track("Redigir parágrafo", metadata);
    if (!editor) return;

    const judgmentDate = precedent.judgment_date ? formattedDateFromISO(precedent.judgment_date) : "";
    const publicationDate = precedent.publication_date ? formattedDateFromISO(precedent.publication_date) : "";

    const judgmentInfo = judgmentDate ? `, j. ${judgmentDate}` : "";
    const publicationInfo = publicationDate ? `, DJE ${publicationDate}` : "";

    const precedentInfo = `${precedent.court_code}, Apelação n° ${precedent.cnj_unique_number}${judgmentInfo}${publicationInfo}`;

    const precedentStyle: ParagraphStyle = {
      alignment: "justify",
      italic: true,
      leftIndent: 90,
    };

    await editor.insertParagraphs([
      {
        text: "Nesse sentido, essa é a jurisprudência observada nos tribunais:",
      },
      {
        text: precedent.headnote,
        style: precedentStyle,
      },
      {
        text: `(${precedentInfo})`,
        style: precedentStyle,
      },
    ]);
  };

  const fullTextTemporarilyDisabled = true;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        borderColor: "divider",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "minmax(0, 1fr) min-content",
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
            <strong>Título:</strong>
            {" " + precedent.title}
          </Typography>

          <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
            <strong>Tribunal:</strong>
            {" " + precedent.court_code}
          </Typography>

          <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
            <strong>ID:</strong>
            {" " + precedent.cnj_unique_number}
          </Typography>

          <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
            <strong>Data de Publicação:</strong>{" "}
            {`${precedent.publication_date ? formattedDateFromISO(precedent.publication_date) : "Não consta"}`}
          </Typography>

          <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
            <strong>Data de Julgamento:</strong>{" "}
            {`${precedent.judgment_date ? formattedDateFromISO(precedent.judgment_date) : "Não consta"}`}
          </Typography>

          <Typography variant="multiLineBody" sx={{ display: "flex" }}>
            <Box sx={{ mr: 0.5 }}>
              <strong>Compatibilidade:</strong>
            </Box>
            <ScoreChip score={precedent.score} />
          </Typography>
        </Box>

        <IconButton onClick={() => setIsExpanded((prev) => !prev)} sx={{ m: -1 }}>
          <ExpandIcon fontSize="medium" />
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
          <strong>Racional de Compatibilidade:</strong>
          <br />
          {precedent.score_reason}
        </Typography>

        <Typography variant="multiLineBody" sx={{ display: "block", mb: 1 }}>
          <strong>Ementa:</strong>
          <br />
          {precedent.headnote || precedent.headnote_gen}
        </Typography>

        {!fullTextTemporarilyDisabled && (
          <>
            {precedent.full_text?.presigned_url_location ? (
              <Button
                id={`button_open_precedent_${metadata.position}`}
                disabled={loadingFetch}
                onClick={handleOpen}
                variant="text"
                sx={{
                  textTransform: "none",
                  textDecoration: "underline",
                }}
              >
                {loadingFetch ? "Carregando acórdão..." : "Abrir acórdão na íntegra"}
              </Button>
            ) : (
              !!precedent.url_court && (
                <Link
                  id={`link_open_precedent_${metadata.position}`}
                  target="_blank"
                  href={precedent.url_court}
                  sx={{
                    fontWeight: 700,
                    color: "primary.main",
                  }}
                >
                  Abrir acórdão na íntegra
                </Link>
              )
            )}
          </>
        )}
      </Collapse>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          pt: 3,
        }}
      >
        <Button
          id={`button_write_paragraph_${metadata.position}`}
          onClick={handleWriteParagraph}
          borderVariant="rounded"
          variant="outlined"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          Redigir parágrafo complementar
        </Button>
        {!fullTextTemporarilyDisabled && (
          <Button
            id={`button_save_precedent_${metadata.position}`}
            disabled={loadingSave || !precedent.full_text?.location}
            onClick={handleSave}
            bgcolor={saved ? "common.shade" : undefined}
            startIcon={BookmarkBorderIcon}
            variant={saved ? "contained" : "outlined"}
            borderVariant="rounded"
            sx={{
              color: saved ? "common.white" : undefined,
              whiteSpace: "nowrap",
            }}
          >
            {loadingSave ? "Salvando..." : saved ? "Acórdão salvo" : "Salvar acórdão"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export const ScoreChip = ({ score }: { score: PrecedentScore }) => {
  const { text, color, backgroundColor } = React.useMemo(() => {
    switch (score) {
      case PrecedentScore.ALTA: {
        return {
          backgroundColor: "score.high.background",
          color: "score.high.text",
          text: PrecedentScore.ALTA,
        };
      }
      case PrecedentScore.MEDIA: {
        return {
          backgroundColor: "score.medium.background",
          color: "score.medium.text",
          text: PrecedentScore.MEDIA,
        };
      }
      case PrecedentScore.BAIXA: {
        return {
          backgroundColor: "score.low.background",
          color: "score.low.text",
          text: PrecedentScore.BAIXA,
        };
      }
    }
  }, [score]);

  return (
    <Chip backgroundColor={backgroundColor} color={color}>
      {text}
    </Chip>
  );
};
