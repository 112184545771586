import { SearchRounded as SearchIcon } from "@mui/icons-material";
import { Box, TextField, Typography } from "@mui/material";
import { ClaimWithCheck, useMessagesContext } from "@/contexts/MessagesContext";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import { Claims } from "./components/Claims";

export const ExtractedClaims = () => {
  const navigate = useNavigate();
  const [searchInputText, setSearchInputText] = useState("");
  const { claims: allClaims, createContestationWithClaims } = useMessagesContext();
  const allSortedClaims: ClaimWithCheck[] = useMemo(
    () => allClaims.sort((a, b) => (a.checked === b.checked ? 0 : a.checked ? -1 : 1)),
    [allClaims]
  );
  const [checkedStates, setCheckedStates] = useState<Record<string, boolean>>(() =>
    allClaims.reduce(
      (acc, claim) => ({
        ...acc,
        [claim.claim_id]: claim.checked || false,
      }),
      {}
    )
  );
  const [claims, setClaims] = useState(allSortedClaims);

  const getUpdatedClaims = () => {
    return allSortedClaims.map((claim) => ({
      ...claim,
      checked: checkedStates[claim.claim_id] || false,
    }));
  };

  useEffect(() => {
    const updatedClaims = getUpdatedClaims().filter(
      (claim) =>
        !searchInputText ||
        claim.txt_title.toLowerCase().includes(searchInputText.toLowerCase()) ||
        claim.document_name.toLowerCase().includes(searchInputText.toLowerCase()) ||
        claim.txt_summary.toLowerCase().includes(searchInputText.toLowerCase())
    );

    setClaims(updatedClaims);
  }, [searchInputText, checkedStates, allSortedClaims]);

  const submit = () => {
    const updatedClaims = getUpdatedClaims();
    const checkedClaims = updatedClaims.filter((claim) => claim.checked);

    const checkedClaimIds = checkedClaims.map((claim) => claim.claim_id);

    createContestationWithClaims({
      claimIds: checkedClaimIds,
      messageText: "Gerar peça",
    });

    navigate(ROUTE_PATHS.CHAT);
  };

  const handleCheck = (id: string, checked: boolean) => {
    setCheckedStates((prev) => ({ ...prev, [id]: checked }));
  };

  return (
    <InternalPageStructure onSubmit={submit} goBackButtonText="Voltar" submitButtonText="Gerar peça">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="preTitle"
          color={"text.primary"}
          sx={{
            mb: 1,
          }}
        >
          Teses extraídas
        </Typography>

        <Typography
          variant="multiLineBody"
          color={"text.primary"}
          sx={{
            mb: 3,
          }}
        >
          Abaixo você pode visualizar todas as teses extraídas das peças de referência enviadas.
        </Typography>

        <Typography
          variant="preTitle"
          color={"text.primary"}
          sx={{
            mb: 1,
          }}
        >
          Buscar
        </Typography>

        <TextField
          onChange={(e) => setSearchInputText(e.target.value)}
          sx={{
            width: "100%",
            mb: 2,
          }}
          InputProps={{
            startAdornment: (
              <SearchIcon
                fontSize="medium"
                sx={{
                  mr: 1,
                }}
              />
            ),
          }}
        />

        <Claims onCheck={handleCheck} claims={claims} highlight={searchInputText} />
      </Box>
    </InternalPageStructure>
  );
};
