import { ConditionalSkillStep, SkillStep } from "@/core/skillSteps/types";
import { removeUndefinedValues } from "@/utils/removeUndefinedValues";
import { UiSchema } from "@rjsf/utils";

type BuildSkillStepFieldOption<OptionValue extends string | null = string | null> = {
  value: OptionValue;
  label?: string;
  description?: string;
};

type BuildSkillStepUi = {
  label?: string | null;
  widget?: "input" | "textarea" | "radio";
  field?: string;
  placeholder?: string;
};

type BuildSkillStepCondition<Name extends string = string, OptionValue extends string = string> = {
  field: Name;
  value: OptionValue;
};

type BuildSkillStepFieldType = "string" | "boolean" | "editor_content" | "file";

type BaseBuildSkillStepField<Name extends string = string, OptionValue extends string = string> = {
  name: Name;
  overrideField?: Name;
  type: BuildSkillStepFieldType;
  initialValue?: OptionValue | string;
  ui: BuildSkillStepUi;
  condition?: BuildSkillStepCondition<Name, OptionValue>;
};

type BuildSkillStepField<Name extends string = string, OptionValue extends string = string> =
  | (BaseBuildSkillStepField<Name, OptionValue> & {
      required?: true | undefined;
      options?: BuildSkillStepFieldOption<OptionValue>[];
    })
  | (BaseBuildSkillStepField<Name, OptionValue> & {
      required: false;
      options?: BuildSkillStepFieldOption<OptionValue | null>[];
    });

type BuildSkillStepOptions<Name extends string, OptionValue extends string> = {
  title: string;
  description?: string;
  fields: BuildSkillStepField<Name, OptionValue>[];
  submitButtonText?: string;
};

type BuildConditionalSkillStepOptions<Name extends string, OptionValue extends string> = BuildSkillStepOptions<
  Name,
  OptionValue
> & {
  condition: BuildSkillStepCondition<Name, OptionValue> | Array<BuildSkillStepCondition<Name, OptionValue>>;
};

const optionToProperty = (option: BuildSkillStepFieldOption) => {
  return removeUndefinedValues({
    type: option.value ? "string" : "null",
    enum: [option.value],
    title: option.label ?? option.value,
    description: option.description,
  });
};

const fieldToPropertyName = ({ field }: { field: BuildSkillStepField }) =>
  "overrideField" in field ? `override:${field.type}:${field.overrideField}` : field.name;

const fieldToProperty = ({ field }: { field: BuildSkillStepField }) => {
  if (Boolean(field.options)) {
    const definitionName = `#${fieldToPropertyName({ field })}`;
    return {
      $ref: `#/definitions/${definitionName}`,
      uniqueItems: true,
    };
  }
  return fieldToPropertyType({ field });
};

const fieldToPropertyType = ({ field }: { field: BuildSkillStepField }) => {
  switch (field.type) {
    case "string":
    case "editor_content":
      return {
        type: field.required ? "string" : ["null", "string"],
      };
    case "boolean":
      return {
        type: field.required ? "boolean" : ["null", "boolean"],
      };
    case "file":
      return {
        type: field.required ? "string" : ["null", "string"],
        format: "data-url",
      };
    default:
      return {};
  }
};

const fieldToCondition = ({ field }: { field: BuildSkillStepField }) => {
  if (field.condition) {
    const fieldName = fieldToPropertyName({ field });
    const fieldProperty = fieldToProperty({ field });

    return {
      fieldName,
      condition: {
        if: {
          properties: {
            [field.condition.field]: {
              const: field.condition.value,
            },
          },
        },
        then: {
          properties: {
            [fieldName]: fieldProperty,
          },
          required: [fieldName],
        },
      },
      fieldUi: field.ui,
    };
  }

  return undefined;
};

const fieldToDefinition = ({ field }: { field: BuildSkillStepField }) => {
  if (field.options) {
    return {
      ...fieldToPropertyType({ field }),
      anyOf: field.options.map(optionToProperty),
    };
  }
  return undefined;
};

const buildSkillStepUiToUiSchema = (ui?: BuildSkillStepUi): UiSchema => {
  return ui
    ? removeUndefinedValues({
        "ui:title": ui.label ?? undefined,
        "ui:placeholder": ui.placeholder,
        "ui:widget": ui.widget,
        "ui:field": ui.field,
        "ui:label": ui.label !== null,
      })
    : {};
};

function buildSkillStep<Name extends string, OptionValue extends string>(
  props: BuildSkillStepOptions<Name, OptionValue>
): SkillStep;
// eslint-disable-next-line no-redeclare
function buildSkillStep<Name extends string, OptionValue extends string>(
  props: BuildConditionalSkillStepOptions<Name, OptionValue>
): ConditionalSkillStep;
// eslint-disable-next-line no-redeclare
function buildSkillStep<Name extends string, OptionValue extends string>(
  props: BuildSkillStepOptions<Name, OptionValue> | BuildConditionalSkillStepOptions<Name, OptionValue>
): SkillStep | ConditionalSkillStep {
  const { title, description, fields, submitButtonText } = props;
  const baseStep: SkillStep = {
    schema: removeUndefinedValues({
      title,
      description,
      type: "object",
      properties: {},
      required: [],
      allOf: [],
    }),
    uiSchema: {
      "ui:enableMarkdownInDescription": true,
      "ui:order": fields.map((field) => fieldToPropertyName({ field })),
    },
    submitButtonText,
  };
  return fields.reduce(
    (acc, field) => {
      const isRequired = field.required ?? true;

      const propertyName = fieldToPropertyName({ field });
      const property = fieldToProperty({ field });
      const condition = fieldToCondition({ field });
      const definition = fieldToDefinition({ field });

      const schema = {
        ...acc.schema,
        definitions: definition
          ? {
              ...acc.schema.definitions,
              [`#${propertyName}`]: definition,
            }
          : acc.schema.definitions,
        properties: condition
          ? {
              ...acc.schema.properties,
            }
          : {
              ...acc.schema.properties,
              [propertyName]: property,
            },
        required: condition
          ? acc.schema.required
          : isRequired
            ? [...acc.schema.required, propertyName]
            : acc.schema.required,
        allOf: condition ? [...acc.schema.allOf, condition.condition] : acc.schema.allOf,
      };

      const uiSchema = condition
        ? {
            ...acc.uiSchema,
            [condition.fieldName]: buildSkillStepUiToUiSchema(condition.fieldUi),
          }
        : {
            ...acc.uiSchema,
            [propertyName]: buildSkillStepUiToUiSchema(field.ui),
          };

      return {
        ...acc,
        schema,
        uiSchema,
        initialData: field.initialValue
          ? {
              ...acc.initialData,
              [propertyName]: field.initialValue,
            }
          : acc.initialData,
      } as SkillStep;
    },
    "condition" in props
      ? {
          ...baseStep,
          condition: props.condition,
        }
      : baseStep
  );
}

export const createEditorContentField = <Name extends string = string, OptionValue extends string = string>(
  field: Omit<BuildSkillStepField<Name, OptionValue>, "type" | "ui" | "initialValue" | "options">
) => {
  return {
    ...field,
    type: "editor_content",
    ui: {
      label: null,
      field: "EditorContentField",
    },
  } as BuildSkillStepField<Name, OptionValue>;
};

export const createUploadFileField = <Name extends string = string, OptionValue extends string = string>(
  field: Omit<BuildSkillStepField<Name, OptionValue>, "type" | "ui" | "initialValue" | "options"> & {
    ui?: BuildSkillStepUi;
  }
) => {
  return {
    ...field,
    type: "file",
    ui: field.ui ?? {
      label: null,
    },
  } as BuildSkillStepField<Name, OptionValue>;
};

export { buildSkillStep };
