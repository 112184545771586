export const LOGGED_ROUTES_PREFIX = "/app";

export const EDITOR_ROUTES_PREFIX = `${LOGGED_ROUTES_PREFIX}/editor`;

export const ROUTE_PATHS = {
  LOGIN: "/",
  SIGNUP: "/signup",
  SIGNUP_FAILURE: "/signup/failure",
  ACTIVATE_ACCOUNT: "/signup/activate",
  USE_WORD: `${LOGGED_ROUTES_PREFIX}/use-word`,

  CLIENT_CONFIG: `${LOGGED_ROUTES_PREFIX}/client-config`,
  CLIENT_CONFIG_MY_DATA: `${LOGGED_ROUTES_PREFIX}/client-config/mydata`,
  CLIENT_CONFIG_CHANGE_PASSWORD: `${LOGGED_ROUTES_PREFIX}/client-config/changepassword`,

  THREAD_HISTORY: `${EDITOR_ROUTES_PREFIX}/history`,
  CHAT: `${EDITOR_ROUTES_PREFIX}/chat`,
  NEW_CHAT: `${EDITOR_ROUTES_PREFIX}/chat/new`,
  THREAD: `${EDITOR_ROUTES_PREFIX}/chat/:threadId`,
  FEEDBACK: `${EDITOR_ROUTES_PREFIX}/feedback`,
  EXTRACTED_CLAIMS: `${EDITOR_ROUTES_PREFIX}/extracted-claims`,
  CREATE_CONTESTATION: `${EDITOR_ROUTES_PREFIX}/create-contestation`,
  PETITION_SUMMARY: `${EDITOR_ROUTES_PREFIX}/petition-summary`,
  JURISPRUDENCE_SEARCH: `${EDITOR_ROUTES_PREFIX}/jurisprudence-search`,
  DOCUMENT: `${EDITOR_ROUTES_PREFIX}/chat/documents/:documentId`,
  EDIT_DOCUMENT: `${EDITOR_ROUTES_PREFIX}/document-editor`,

  SESSION_SETTINGS: `${LOGGED_ROUTES_PREFIX}/session-settings`,
  CREDITS_HISTORY: `${LOGGED_ROUTES_PREFIX}/credits-history`,
  TERMS_OF_USE: `${LOGGED_ROUTES_PREFIX}/terms-of-use`,

  PLANS: `${LOGGED_ROUTES_PREFIX}/plans`,
  INTERNAL_PAYMENT: `${LOGGED_ROUTES_PREFIX}/payment`,
  HOME: `${LOGGED_ROUTES_PREFIX}/home`,
} as const;

export const EXTERNAL_URLS = {
  PRIVACY_POLICY: "https://lexter.ai/politicadeprivacidade",
  TERMS_OF_USE: "https://lexter.ai/termosdeuso",
  HELP_CENTER_URL: "https://ajuda.lexter.ai/",
  CONTACT: "https://lexter.ai/contato",
  CREDITS_INFO: "https://ajuda.lexter.ai/o-que-sao-e-como-funcionam-os-creditos",
  WHATSAPP_URL: "https://wa.me/5511914150170",
} as const;
