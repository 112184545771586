import { Config } from "@/config/Config";
import { env } from "@/constants/environment";
import { getDeviceMetadata } from "@/contexts/MessagesContext/utils";
import * as logger from "@/core/logger";
import { useAlertModal } from "@/hooks/useAlertModal";
import { EventEmitter } from "@/utils/eventEmitter";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validate } from "uuid";
import { useAuthContext } from "../../contexts/AuthContext";
import { WebSocketContext } from "./context";
import {
  ConnectionState,
  OnMessageReceivedParam,
  OnNotificationReceivedParams,
  OnThreadConnectParams,
  SkillsPayload,
  ThreadErrorType,
  UserInputDTO,
  WebsocketMessageType,
  WebsocketSendParams,
} from "./types";
import { useApi } from "@/hooks/useApi";
import { ActionDTO } from "../MessagesContext";
import { AuthService } from "@/services/auth";

interface WebSocketProviderProps {
  children: ReactNode;
}

const DEFAULT_THREAD_NAME = "Nova Sessão";

export const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const { loginType, logout } = useAuthContext();
  const [thread, setThread] = useState<{ id: string; name: string } | undefined>();

  const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.CLOSED);
  const [threadConnectionState, setThreadConnectionState] = useState<ConnectionState>(ConnectionState.CLOSED);
  const [cleanUpStatus, setCleanUpStatus] = useState(false);
  const socketRef = useRef<WebSocket>();
  const [connectionRetries, setConnectionRetries] = useState(0);
  const threadConnectedEvent = useMemo(() => new EventEmitter<OnThreadConnectParams>(), []);
  const messageReceivedEvent = useMemo(() => new EventEmitter<OnMessageReceivedParam>(), []);
  const notificationReceivedEvent = useMemo(() => new EventEmitter<OnNotificationReceivedParams>(), []);
  const navigate = useNavigate();
  const { openAlertModal } = useAlertModal();
  const [isGeneratingThreadName, setIsGeneratingThreadName] = useState<boolean>(false);

  const { generateThreadName } = useApi();

  const handleGenerateThreadName = async ({
    threadId,
    message,
  }: {
    threadId: string;
    message: WebsocketSendParams;
  }) => {
    if (message.type !== WebsocketMessageType.MESSAGE && message.type !== WebsocketMessageType.SKILLS) return;

    setIsGeneratingThreadName(true);
    let newName: string | undefined;
    let data: ActionDTO | SkillsPayload<UserInputDTO> | undefined;
    if (message.type === WebsocketMessageType.MESSAGE) {
      const messages = message.data.chat.messages;
      const lastMessage = messages[messages.length - 1];
      if (lastMessage && lastMessage.actions?.length) {
        const action = lastMessage.actions[0];
        data = action;
      }
    } else if (message.type === WebsocketMessageType.SKILLS) {
      data = message.data;
    }

    if (data) {
      try {
        const response = await generateThreadName({
          threadId,
          data,
        });
        newName = response.name;
      } catch (e) {
        logger.error(`Error generating thread name`, e);
      }
    }

    if (newName) {
      setThread((prev) => {
        if (prev?.id === threadId) return { ...prev, name: newName };

        return prev;
      });
    }
    setIsGeneratingThreadName(false);
  };

  const config = Config.getConfig();

  const retryConnect = () => {
    setConnectionRetries((prev) => prev + 1);
  };

  const handleMessage = (event: MessageEvent<string>) => {
    if (event.data === "Pong") {
      return;
    }

    const message = JSON.parse(event.data);

    if (message.type === WebsocketMessageType.THREAD_CONNECT) {
      const { error } = message.data;

      if (error) {
        logger.error(`Thread connect error`, error);

        switch (error) {
          case ThreadErrorType.THREAD_NOT_FOUND:
            openAlertModal({
              title: "Sessão de chat não encontrada",
              description: "Não conseguimos localizar a sessão de chat desejada. Gostaria de abrir uma nova?",
              primaryButton: {
                label: "Sim",
                onClick: openNewThread,
              },
            });
            break;
          case ThreadErrorType.THREAD_ALREADY_CONNECTED:
            openAlertModal({
              title: "Sessão de chat já ativa",
              description:
                "Você já está com esta sessão de chat aberta em outra janela. Deseja continuar aqui e fechar a anterior?",
              primaryButton: {
                label: "Continuar",
                onClick: () => {
                  const { threadId } = message.data;
                  socketRef.current?.send(
                    JSON.stringify({
                      action: "sendmessage",
                      type: WebsocketMessageType.THREAD_CONNECT,
                      data: { threadId: threadId, closeOthers: true },
                    })
                  );
                },
              },
              secondaryButton: {
                label: "Cancelar",
                onClick: () => {
                  navigate("/");
                },
              },
            });
            break;
          case ThreadErrorType.THREAD_CONNECTED_IN_OTHER_INSTANCE:
            close();
            openAlertModal({
              title: "Sessão de chat desconectada",
              description: "Você foi desconectado desta sessão de chat porque entrou na mesma em outra janela.",
              primaryButton: {
                label: "Ok",
                onClick: () => navigate("/"),
              },
            });
            break;
          default:
            openNewThread();
            break;
        }

        return;
      }

      const { threadId, threadName, messages, openDocumentsIds } = message.data;
      threadConnectedEvent.emit({
        threadId,
        threadName,
        messages,
        openDocumentsIds,
      });

      setThread({ id: threadId, name: threadName });
      setThreadConnectionState(ConnectionState.OPEN);
      return;
    }

    if (message.type === WebsocketMessageType.NOTIFICATION) {
      const { notification } = message.data;
      notificationReceivedEvent.emit(notification);
      return;
    }

    try {
      messageReceivedEvent.emit(message);
      logger.info(`Successfully handling message from websocket`, message);
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.error(`Error handling message from websocket`, e.message);
      }
      throw e;
    }
  };

  // Create WebSocket connection.
  const connect = () => {
    let socket: WebSocket | undefined;
    let pingIntervalId: ReturnType<typeof setInterval>;
    let retryTimeoutId: ReturnType<typeof setTimeout>;
    let closed = false;
    setConnectionState(ConnectionState.CONNECTING);
    setCleanUpStatus(false);

    function onOpen() {
      socketRef.current = socket;

      pingIntervalId = setInterval(
        async () => {
          socket?.send(JSON.stringify({ action: "ping" }));
        },
        3 * 60 * 1000 // 3 minutes
      );

      logger.debug("Connected to websocket. Waiting thread id");
      setConnectionState(ConnectionState.OPEN);
    }

    function onCloseOrError() {
      logger.debug("Disconnected from websocket");

      cleanUp();

      //Will try to reconnect when the socket is closed
      retryTimeoutId = setTimeout(retryConnect, 5000);
    }

    AuthService.getAuthToken()
      .then(({ success, token }) => {
        if (closed) return;

        if (!success) {
          logger.debug("connect websocket getAuthToken success = false");
          throw new Error("Token not found");
        }

        const url = `${env.WEBSOCKET_URL}?token=${token}&loginType=${loginType}`;
        socket = new WebSocket(url);

        socket.addEventListener("open", onOpen);
        socket.addEventListener("close", onCloseOrError);
        socket.addEventListener("error", onCloseOrError);
        socket.addEventListener("message", handleMessage);
      })
      .catch((e) => {
        logger.debug(`connect websocket getAuthToken error: ${e.message}`);
        logout();
      });

    function cleanUp() {
      setCleanUpStatus(true);
      logger.debug("Cleaning up socket");

      closed = true;

      clearInterval(pingIntervalId);
      clearTimeout(retryTimeoutId);

      socket?.removeEventListener("close", onCloseOrError);
      socket?.removeEventListener("error", onCloseOrError);

      socket?.close();

      socketRef.current = undefined;
      closeThread();
      setConnectionState(ConnectionState.CLOSED);
    }
    return cleanUp;
  };

  useEffect(() => {
    return connect();
  }, [connectionRetries]);

  const isOutsideOfficeClient = outsideOfficeClient();

  const send = useCallback(
    ({ type, data }: WebsocketSendParams) => {
      if (!socketRef.current) return;

      if (thread?.id && thread?.name === DEFAULT_THREAD_NAME) {
        handleGenerateThreadName({ threadId: thread?.id, message: { type, data } as WebsocketSendParams });
      }

      socketRef.current.send(
        JSON.stringify({
          action: "sendmessage",
          type,
          data,
          metadata: {
            platform: isOutsideOfficeClient ? "assistente-web" : "add-in",
            device: getDeviceMetadata(),
            sessionSettings: config.sessionSettings.toHeaders(),
          },
        })
      );
    },
    [thread]
  );

  const openNewThread = (params?: { threadId?: string }) => {
    if (!socketRef.current) return;

    setThreadConnectionState(ConnectionState.CONNECTING);

    let { threadId } = params || {};
    const isValidId = validate(threadId || "");
    threadId = isValidId ? threadId : undefined;

    socketRef.current.send(
      JSON.stringify({
        action: "sendmessage",
        type: WebsocketMessageType.THREAD_CONNECT,
        data: { threadId },
      })
    );
  };

  const closeThread = () => {
    setThread(undefined);
    setThreadConnectionState(ConnectionState.CLOSED);
  };

  return (
    <WebSocketContext.Provider
      value={{
        connectionState,
        threadConnectionState,
        send,
        cleanUpStatus,
        openNewThread,
        closeThread,
        thread,
        messageReceivedEvent,
        threadConnectedEvent,
        notificationReceivedEvent,
        isGeneratingThreadName,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
