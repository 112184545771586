import { buildSkillStep } from "@/core/skillSteps/builder";

export const createAppealSkillStep4 = buildSkillStep({
  title: "Criar Recurso - Passo 4",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o nome do cliente?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
  ],
});
