import { DialogModal } from "@/components/DialogModal";
import { PageLayout } from "@/components/PageLayout";
import { HeaderVariant } from "@/components/PageLayout/Header";
import { useAuthContext } from "@/contexts/AuthContext";
import { useCreditsContext } from "@/contexts/CreditsContext";
import { useMessagesContext } from "@/contexts/MessagesContext";
import { ActionProps, buildActionsButtons } from "@/core/actions";
import { useAddFavoriteSkill } from "@/hooks/skills/useAddFavoriteSkill";
import { useDeleteFavoriteSkill } from "@/hooks/skills/useDeleteFavoriteSkill";
import { useSkills } from "@/hooks/skills/useSkills";
import { useFavoriteSkills } from "@/hooks/skills/useFavoriteSkills";
import { useApi } from "@/hooks/useApi";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useOnboarding } from "@/hooks/useOnboarding";
import { EXTERNAL_URLS, ROUTE_PATHS } from "@/routes/routePaths";
import { Toast } from "@/taskpane/components/core/Toast";
import { getCookie, setCookie } from "@/utils/cookie";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ActionCard } from "./ActionCard";
import { ActionsHeader } from "./components/ActionsHeader";
import { ActionsList } from "./components/ActionsList";
import { UsageCard } from "./components/UsageCard";
import { logger } from "@/core/logger";

const USAGE_CARD_OPEN_STORAGE_KEY = "usageCardOpen";

export const Home = () => {
  const { closeThreadConnection } = useMessagesContext();
  const { openOnboarding } = useOnboarding();
  const { user } = useAuthContext();
  const { newHomeEnabled, creditsUsageBanner } = useFeatureFlags();

  const { isFreemium, isTrial, getFilteredCommittedActions } = useCreditsContext();

  const [usageCardOpen, setUsageCardOpen] = useState(() => {
    return sessionStorage.getItem(USAGE_CARD_OPEN_STORAGE_KEY) !== "false";
  });

  const closeUsageCard = () => {
    setUsageCardOpen(false);
    sessionStorage.setItem(USAGE_CARD_OPEN_STORAGE_KEY, "false");
  };

  useEffect(() => {
    if (!user?.profileCompleted && outsideOfficeClient()) {
      const seenOnboarding = getCookie("seenOnboarding");
      if ((!seenOnboarding && isFreemium) || (!seenOnboarding && isTrial)) {
        setCookie("seenOnboarding", "true");
        getFilteredCommittedActions().then((response) => {
          if (response.length > 0) {
            openOnboarding({
              firstTimeInOnboarding: true,
            });
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    closeThreadConnection();
  }, []);

  return (
    <PageLayout
      headerProps={{
        variant: HeaderVariant.WELCOME,
        extraContent: usageCardOpen && creditsUsageBanner ? <UsageCard onClose={closeUsageCard} /> : undefined,
      }}
      contentSx={{
        width: newHomeEnabled ? "100%" : "clamp(612px, 50%, 100%)",
        mt: !newHomeEnabled ? "42px" : "",
      }}
      sx={{
        ".page-layout-container": {
          p: 0,
        },
      }}
    >
      {newHomeEnabled ? <NewHome /> : <OldHome />}
    </PageLayout>
  );
};

const OldHome = () => {
  const navigate = useNavigate();

  const goToChat = () => {
    navigate(ROUTE_PATHS.NEW_CHAT);
  };

  const goToPetitionSummary = () => {
    navigate(ROUTE_PATHS.PETITION_SUMMARY);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Typography variant="pageTitle">Vamos começar?</Typography>

      <ActionCard
        title="Escrever peça"
        description="Escreva peças jurídicas seguindo as melhores práticas do mercado jurídico."
        onClick={() => goToChat()}
      />

      <ActionCard
        title="Resumir documento processual"
        description="Resuma documentos processuais para um entendimento rápido ou para facilitar o envio de informações para seu cliente."
        onClick={goToPetitionSummary}
      />

      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
        <ActionCard
          title="O que mais posso fazer?"
          description="Utilize nosso chat para saber o que mais você pode fazer em sua rotina."
          onClick={() => goToChat()}
        />

        <Link
          href={EXTERNAL_URLS.HELP_CENTER_URL}
          target="_blank"
          sx={{
            textDecoration: "none",
          }}
        >
          <ActionCard
            title="Central de ajuda"
            description="Caso tenha dúvidas sobre a utilização do Assistente Lexter, clique aqui e acesse nossa Central de Ajuda com tutoriais e respostas das dúvidas mais frequentes entre os usuários."
          />
        </Link>
      </Box>
    </Box>
  );
};

const NewHome = () => {
  const { comingSoonButtonEnabled } = useFeatureFlags();

  const flags = useFeatureFlags();

  const { data: favoriteSkills = [], isLoading: isLoadingFavoriteSkills } = useFavoriteSkills();
  const { data: skills, isLoading: isLoadingSkills } = useSkills();

  const { mutateAsync: deleteFavoriteSkill } = useDeleteFavoriteSkill();
  const { mutateAsync: addFavoriteSkill } = useAddFavoriteSkill();

  const defaultActions = React.useMemo(() => {
    return buildActionsButtons({
      flags,
      favoriteSkills,
      skills,
    });
  }, [flags, favoriteSkills, skills]);

  const isLoading = isLoadingFavoriteSkills || isLoadingSkills;

  const comingSoonRef = useRef<{
    handleComingSoon: (action: ActionProps) => void;
  }>();

  const handleComingSoon = (action: ActionProps) => {
    if (comingSoonRef.current) {
      comingSoonRef.current.handleComingSoon(action);
    }
  };

  const toggleFavoriteSkill = useCallback(
    async ({ skillId, isFavorite }: { skillId: string; isFavorite: boolean }) => {
      if (isFavorite) {
        await deleteFavoriteSkill({ skillId });
      } else {
        await addFavoriteSkill({ skillId });
      }
    },
    [addFavoriteSkill, deleteFavoriteSkill]
  );

  return (
    <>
      {comingSoonButtonEnabled && <ComingSoonModal ref={comingSoonRef} />}
      <ActionsHeader handleComingSoon={handleComingSoon} actions={defaultActions.slice(0, 8)} />
      <Box
        sx={{
          px: 8,
          py: 4,
        }}
      >
        <Typography
          sx={{
            mb: "8px",
          }}
        >
          Faça mais com o Assistente Lexter
        </Typography>
        <ActionsList
          handleComingSoon={handleComingSoon}
          actions={defaultActions}
          toggleFavoriteSkill={toggleFavoriteSkill}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
};

const ComingSoonModal = forwardRef(function ComingSoonModal(_props, ref) {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<ActionProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { addActionOfInterest } = useApi();
  const { user } = useAuthContext();

  const onClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleComingSoon(action: ActionProps) {
      setAction(action);
      setOpen(true);
    },
  }));

  const handleActionInterest = async () => {
    try {
      setIsLoading(true);
      if (action && user) {
        await addActionOfInterest({
          userId: user?.userId,
          companyId: user?.companyId,
          actionOfInterest: action?.id.toUpperCase(),
        });
        Toast.success("Interesse cadastrado com sucesso!");
      } else {
        Toast.error("Erro ao cadastrar interesse, tente novamente mais tarde");
      }
    } catch (err) {
      Toast.error("Erro ao cadastrar interesse");
      logger.error("handleActionInterest", err);
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <DialogModal
      open={open}
      title={action?.name}
      isLoading={isLoading}
      heightLoading={160}
      description={
        <Typography variant="body1" sx={{ color: "text.primary" }}>
          A ação de <strong>{action?.name.toUpperCase()}</strong> ainda não está disponível. Cadastre-se abaixo e seja
          informado assim que ela estiver pronta para uso.
        </Typography>
      }
      descriptionTextAlign="left"
      buttons={[
        {
          label: "Avise-me",
          onClick: handleActionInterest,
        },
        {
          label: "Cancelar",
          onClick: onClose,
          variant: "text",
          color: "primary",
          sx: {
            color: "text.primary",
            textDecoration: "underline",
          },
        },
      ]}
      removeLeftBorder
    />
  );
});
ComingSoonModal.displayName = "ComingSoonModal";
