import { Form, Text, TextInput, Title } from "@/components/FormComponents";
import { ActionId, useMessagesContext } from "@/contexts/MessagesContext";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import React, { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

enum Step {
  CLIENT_NAME,
  DETAILS,
}

export const CreateIntercurrentMotion = () => {
  const [clientName, setClientName] = useState<string>("");
  const [details, setDetails] = useState<string>("");

  const { goToChat, startSkillProcess } = useMessagesContext();
  const [error, setError] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const steps = useMemo(() => [Step.CLIENT_NAME, Step.DETAILS], []);

  const dismissError = () => {
    setError(null);
  };

  const handleGoBack = () => {
    if (currentStep === 0) {
      goToChat();
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const handleClientNameChange = (value: string) => {
    setClientName(value);
    dismissError();
  };

  const handleDetailsChange = (value: string) => {
    setDetails(value);
    dismissError();
  };

  const canProceed = () => {
    switch (steps[currentStep]) {
      case Step.CLIENT_NAME:
        return !!clientName;
      case Step.DETAILS:
        return !!details;
    }
  };

  const onSubmitHandler = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (!clientName || !details || !clientName) {
        setError("É preciso submeter todos os dados.");
        return;
      }

      startSkillProcess({
        skillId: ActionId.INTERCURRENT_MOTION,
        requestId: uuid(),
        payload: {
          case_breakdown: details,
          client: clientName,
        },
        messageToSave: "Elaborar petição intercorrente",
      });

      goToChat();
    }
  };

  const title = `Elaborar Petição Intercorrente - Passo ${currentStep + 1}`;

  return (
    <InternalPageStructure
      goBack={handleGoBack}
      onSubmit={onSubmitHandler}
      error={error}
      dismissError={dismissError}
      goBackButtonText={currentStep === 0 ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep === steps.length - 1 ? "Elaborar petição" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={steps.length}
      currentStep={currentStep}
    >
      <Form show={steps[currentStep] === Step.CLIENT_NAME}>
        <Title>{title}</Title>
        <Text>
          Elabore petições intercorrentes para juntada de documentos, produção de provas, prorrogação de prazo,
          baseando-se ou não em uma decisão judicial.
        </Text>
        <TextInput
          id="input_client_name"
          label="Qual é o nome do cliente?"
          placeholder="Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas."
          value={clientName}
          onChange={handleClientNameChange}
        />
      </Form>

      <Form show={steps[currentStep] === Step.DETAILS}>
        <Title>{title}</Title>
        <TextInput
          id="input_details"
          label="Detalhe o motivo da sua petição intercorrente:"
          placeholder="Informe as provas que pretende produzir e os motivos; solicite a prorrogação de um prazo, explicando a necessidade; indique os documentos para juntada."
          value={details}
          onChange={handleDetailsChange}
        />
      </Form>
    </InternalPageStructure>
  );
};
