import { Header, HeaderVariant } from "@/components/PageLayout/Header";
import { useWebEditorContext } from "@/contexts/WebEditorContext";
import { useSocket } from "@/contexts/WebSocketContext";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { EditorModal } from "./components/EditorModal";
import { Resizer } from "./components/Resizer";
import { EditorUIProvider } from "./contexts/EditorUIContext/provider";
import { useResizable } from "./useResizable";
import { Outlet } from "react-router-dom";
import { Documents } from "../Documents";

type TabsType = "EDITOR" | "DOCUMENTS";

export const EditorPageLayout = () => {
  const { width: chatWidth, containerRef, handleMouseDown, isResizing } = useResizable();
  const [currentTab, setCurrentTab] = React.useState<TabsType>("EDITOR");

  const { thread } = useSocket();

  useEffect(() => {
    setCurrentTab("EDITOR");
  }, [thread]);

  if (outsideOfficeClient()) {
    return (
      <EditorUIProvider>
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: "min-content minmax(0, 1fr)",
            height: "100%",
            width: "100%",
          }}
        >
          <Header
            variant={HeaderVariant.EDITOR}
            chatWidth={chatWidth}
            onClickDocuments={() => setCurrentTab("DOCUMENTS")}
            onClickEditor={() => setCurrentTab("EDITOR")}
          />

          <Box
            ref={containerRef}
            sx={{
              display: "grid",
              gridTemplateColumns: `${chatWidth}px minmax(0, 1fr)`,
              gridTemplateRows: "minmax(0, 1fr)",
              height: "100%",
              width: "100%",
            }}
          >
            <Box sx={{ width: "100%", display: "flex", position: "relative" }}>
              <Box sx={{ width: "100%", overflow: "auto", display: "flex" }}>
                <Outlet />
              </Box>
              <Resizer onMouseDown={handleMouseDown} isResizing={isResizing} />
            </Box>
            <Box
              sx={{ width: "100%", borderLeft: "1px solid", borderColor: "common.lightShade", position: "relative" }}
            >
              {currentTab === "DOCUMENTS" ? <Documents goBack={() => setCurrentTab("EDITOR")} /> : <WebEditor />}

              <EditorModal />
            </Box>
          </Box>
        </Box>
      </EditorUIProvider>
    );
  }

  return <Outlet />;
};

const WebEditor = () => {
  const { editorElement } = useWebEditorContext();

  return editorElement;
};
