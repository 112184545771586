import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormContextType, getTemplate, RJSFSchema, StrictRJSFSchema, WidgetProps } from "@rjsf/utils";
import { Toast } from "@/taskpane/components/core/Toast";
import { DeleteOutlined as DeleteOutlinedIcon } from "@mui/icons-material";
import { getDocxPageCount, getPdfPageCount } from "@/utils/getPageCount";
import { UPLOAD_FILE_PAGE_LIMIT, UPLOAD_FILE_SIZE_LIMIT } from "@/contexts/MessagesContext/constants";

const PDF = "application/pdf";
const DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
const VALID_FILE_TYPES = [PDF, DOCX];

function addNameToDataURL(dataURL: string, name: string) {
  if (dataURL === null) {
    return null;
  }
  return dataURL.replace(";base64", `;name=${encodeURIComponent(name)};base64`);
}

type FileInfoType = {
  dataURL?: string | null;
  name: string;
  size: number;
  type: string;
};

function processFile(file: File): Promise<FileInfoType> {
  const { name, size, type } = file;
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = reject;
    reader.onload = (event) => {
      if (typeof event.target?.result === "string") {
        resolve({
          dataURL: addNameToDataURL(event.target.result, name),
          name,
          size,
          type,
        });
      } else {
        resolve({
          dataURL: null,
          name,
          size,
          type,
        });
      }
    };
    reader.readAsDataURL(file);
  });
}

function processFiles(files: File[]) {
  return Promise.all(files.map(processFile));
}

async function checkFileError(file: File): Promise<string | null> {
  if (!VALID_FILE_TYPES.includes(file.type)) {
    return "Somente arquivos PDF e DOCX são válidos";
  }

  let pageCount: number | null = null;
  if (file.type === PDF) {
    pageCount = await getPdfPageCount(file);
  }
  if (file.type === DOCX) {
    pageCount = await getDocxPageCount(file);
  }
  if (pageCount !== null && pageCount > UPLOAD_FILE_PAGE_LIMIT) {
    return `O limite de páginas é de ${UPLOAD_FILE_PAGE_LIMIT} por arquivo.`;
  }

  if (file.size > UPLOAD_FILE_SIZE_LIMIT) {
    return `O limite de tamanho é de ${UPLOAD_FILE_SIZE_LIMIT / (1000 * 1000)}MB por arquivo.`;
  }

  return null;
}

const checkFiles = async ({ files, maxFiles }: { files: File[]; maxFiles?: number }) => {
  const errors = await Promise.all(files.map(checkFileError));
  errors.forEach((error) => {
    if (error) {
      Toast.warn(error);
    }
  });

  const hasSomeInvalidFiles = errors.some((error) => !!error);

  const exceedsMaxFileLimit = maxFiles ? files.length > maxFiles : false;
  if (exceedsMaxFileLimit) {
    Toast.warn(`Você pode enviar no máximo ${maxFiles} arquivos`);
  }

  return {
    error: hasSomeInvalidFiles || exceedsMaxFileLimit,
  };
};

export const FileWidget = <
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = Record<string, unknown>,
>(
  props: WidgetProps<T, S, F>
) => {
  const { disabled, readonly, required, multiple, onChange, value, options, registry, maxFiles } = props;
  const BaseInputTemplate = getTemplate<"BaseInputTemplate", T, S, F>("BaseInputTemplate", registry, options);

  const [files, setFiles] = React.useState<File[]>();

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    let droppedFiles: File[];
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      droppedFiles = Array.from(event.dataTransfer.items)
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile())
        .filter((item) => !!item) as File[];
    } else {
      // Use DataTransfer interface to access the file(s)
      droppedFiles = Array.from(event.dataTransfer.files);
    }

    if (!multiple) {
      droppedFiles = droppedFiles.slice(0, 1);
    }

    const { error } = await checkFiles({ files: droppedFiles, maxFiles });
    if (error) {
      return;
    }

    setFiles(droppedFiles);
  };

  const handleAttachment = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const filesList = event.target.files || [];
      const attachedFiles = filesList.length > 0 ? Array.from(filesList) : [];

      const { error } = await checkFiles({ files: attachedFiles, maxFiles });
      if (error) {
        return;
      }

      setFiles(attachedFiles);
    },
    [maxFiles]
  );

  const removeFile = React.useCallback((index: number) => {
    setFiles((prev = []) => {
      const newFiles = [...prev];
      newFiles.splice(index, 1);
      return newFiles;
    });
  }, []);

  useEffect(() => {
    if (files?.length) {
      processFiles(files).then((filesInfoEvent) => {
        const newValue = filesInfoEvent.map((fileInfo) => fileInfo.dataURL);
        onChange(newValue[0]);
      });
    }
  }, [files, onChange]);

  if (files?.length) {
    return (
      <>
        {files.map((file, index) => (
          <Box
            key={index}
            sx={{
              backgroundColor: "background.paper",
              width: "100%",
            }}
          >
            <Box
              sx={{
                minHeight: 64,
                width: "100%",
                backgroundColor: "background.paper",
                border: "1px solid",
                borderColor: "text.secondary",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: 1,
                p: 2,
              }}
            >
              <Typography variant="body" sx={{ ml: 1 }}>
                {file.name}
              </Typography>

              <IconButton
                aria-label="Remover arquivo"
                size="medium"
                sx={{
                  alignSelf: "flex-start",
                  ml: "auto",
                  color: "common.shade",
                }}
                onClick={() => removeFile(index)}
              >
                <DeleteOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </>
    );
  }

  return (
    <Box
      onDrop={handleDrop}
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      sx={{
        backgroundColor: "background.paper",
        width: "100%",
      }}
    >
      <Box
        sx={{
          minHeight: 150,
          width: "100%",
          backgroundColor: "background.default",
          border: "1px solid",
          borderColor: "text.secondary",
        }}
      >
        <Button
          aria-label="Anexar arquivo"
          component="label"
          sx={{
            height: 150,
            width: "100%",
          }}
        >
          <Box
            color={"text.primary"}
            sx={{
              px: "10px",
              textAlign: "center",
              fontSize: "14px",
              textTransform: "none",
              letterSpacing: "0.03em",
              lineHeight: "140%",
            }}
          >
            <Typography variant="multiLineBody" color={"text.primary"}>
              {"Arraste e solte o arquivo nesta área ou "}
            </Typography>

            <Typography
              variant="multiLineBody"
              color={"text.primary"}
              sx={{ color: "primary.main", textDecoration: "underline", display: "inline" }}
            >
              clique aqui para selecionar o arquivo
            </Typography>
          </Box>
          <BaseInputTemplate
            {...props}
            disabled={disabled || readonly}
            type="file"
            required={value ? false : required} // this turns off HTML required validation when a value exists
            onChangeOverride={handleAttachment}
            value=""
            hidden
            accept={VALID_FILE_TYPES.join(",")}
          />
        </Button>
      </Box>
    </Box>
  );
};
