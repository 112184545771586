import React, { useState, useMemo } from "react";
import { ActionId, Message, InMessageFile, FlowOutput } from "@/contexts/MessagesContext";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import { Box, RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import { v4 as uuidV4 } from "uuid";
import { DateTime } from "luxon";
import { FileInput } from "../FlowInput";
import { Form, Text, TextInput, Title } from "@/components/FormComponents";

enum Step {
  CLIENT_INFO_CASE_DETAILS,
  DOCUMENT_UPLOAD,
}

enum DocumentOption {
  UPLOAD = "UPLOAD",
  NO_UPLOAD = "NO_UPLOAD",
}

export function ExtrajudicialNotice(props: { goBack: () => void; sendMessage: (message: Message) => void }) {
  const { goBack, sendMessage } = props;
  const [error, setError] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [clientName, setClientName] = useState<string>("");
  const [caseDetails, setCaseDetails] = useState<string>("");
  const [referenceFile, setReferenceFile] = useState<File | null>(null);
  const [documentOption, setDocumentOption] = useState<DocumentOption | null>(null);

  const steps = useMemo(() => {
    return [Step.CLIENT_INFO_CASE_DETAILS, Step.DOCUMENT_UPLOAD];
  }, []);

  const dismissError = () => {
    setError(null);
  };

  const onGoBackHandler = () => {
    if (currentStep === 0) {
      goBack();
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const onClientNameChange = (value: string) => {
    setClientName(value);
    dismissError();
  };

  const onCaseDetailsChange = (value: string) => {
    setCaseDetails(value);
    dismissError();
  };

  const onReferenceFileChange = (files: File[]) => {
    setReferenceFile(files[0]);
    dismissError();
  };

  const onDocumentOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentOption(event.target.value as DocumentOption);
    dismissError();
  };

  const canProceed = () => {
    switch (steps[currentStep]) {
      case Step.CLIENT_INFO_CASE_DETAILS:
        return !!clientName && !!caseDetails;
      case Step.DOCUMENT_UPLOAD:
        return !!documentOption;
      default:
        throw new Error("Invalid step");
    }
  };

  const onSubmitHandler = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (!clientName || !caseDetails || !documentOption) {
        setError("É preciso submeter todos os dados.");
        return;
      }
      const messageFiles: InMessageFile[] = [];

      const referenceFileId = uuidV4();
      if (referenceFile) {
        messageFiles.push({
          id: referenceFileId,
          type: "UPLOADING",
          file: referenceFile,
          name: referenceFile.name,
        });
      }
      const referencePieceFormatted: FlowOutput | undefined = referenceFile
        ? {
            source: "FILE",
            file: {
              id: referenceFileId,
              name: referenceFile.name,
              file: referenceFile,
            },
          }
        : undefined;
      sendMessage({
        id: uuidV4(),
        type: "FLOW",
        direction: "SENT",
        author: "Current User",
        date: DateTime.now(),
        status: "READ",
        text: "Elaborar Notificação Extrajudicial",
        actions: [
          {
            id: ActionId.CREATE_NOTICE_ACTION,
            client: clientName,
            case_breakdown: caseDetails,
            reference_piece: referencePieceFormatted,
          },
        ],
        files: messageFiles,
      });
      goBack();
    }
  };

  const title = `Notificação extrajudicial - Passo ${currentStep + 1}`;

  return (
    <InternalPageStructure
      goBack={onGoBackHandler}
      onSubmit={onSubmitHandler}
      error={error}
      dismissError={dismissError}
      goBackButtonText={currentStep === 0 ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep === steps.length - 1 ? "Gerar Notificação" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={steps.length}
      currentStep={currentStep}
    >
      <Box>
        <Form show={steps[currentStep] === Step.CLIENT_INFO_CASE_DETAILS}>
          <Title>{title}</Title>
          <TextInput
            label="Insira abaixo o nome do cliente que enviará a notificação:"
            placeholder="Exemplo: João da Silva.

Se tiver mais de um nome, separe com vírgulas."
            value={clientName}
            onChange={onClientNameChange}
          />

          <TextInput
            label="Faça um detalhamento do caso:"
            placeholder="Detalhe o caso, insira informações essenciais e um relato dos fatos."
            value={caseDetails}
            onChange={onCaseDetailsChange}
          />
        </Form>

        <Form show={steps[currentStep] === Step.DOCUMENT_UPLOAD}>
          <Title>{title}</Title>
          <Text>
            <Typography variant="preTitle">Atenção:</Typography> Quanto mais detalhadas as informações inseridas, maior
            será a assertividade do Assistente Lexter.
          </Text>
          <Title sx={{ mb: 1 }}>Inserir um contrato ou outro documento (Opcional):</Title>
          <RadioGroup value={documentOption} onChange={onDocumentOptionChange}>
            <FormControlLabel
              value={DocumentOption.UPLOAD}
              control={<Radio />}
              label="Upload de arquivo (DOCX ou PDF)"
            />
            <FormControlLabel
              value={DocumentOption.NO_UPLOAD}
              control={<Radio />}
              label="Não desejo inserir documentos do caso"
            />
          </RadioGroup>
          {documentOption === DocumentOption.UPLOAD && <FileInput onChange={onReferenceFileChange} />}
        </Form>
      </Box>
    </InternalPageStructure>
  );
}
