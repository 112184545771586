import { buildSkillStep } from "@/core/skillSteps/builder";

export const createAppealSkillStep2 = buildSkillStep({
  title: "Criar Recurso - Passo 2",
  fields: [
    {
      name: "decision_type",
      type: "string",
      options: [
        {
          label: "Decisões terminativas",
          value: "decisoes_terminativas",
        },
        {
          label: "Decisoes interlocutórias/monocráticas",
          value: "decisoes_interlocutoria",
        },
      ],
      initialValue: "decisoes_terminativas",
      ui: {
        label: "Tipo de Decisão:",
        widget: "radio",
      },
    },
  ],
});
