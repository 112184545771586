import * as logger from "@/core/logger";
import { isWordApiVersionSupported } from "@/taskpane/config";
import {
  Editor,
  EditorEvent,
  FormattedText,
  Line,
  OpenFormattedDocumentParams,
  Paragraph,
  StyleAlignment,
  StylesMap,
} from "../types";
import { Alignment, Underline } from "./types";

export class WordEditor implements Editor {
  async getSelection() {
    let selectionText = "";
    await Word.run(async (context: Word.RequestContext) => {
      const selection = context.document.getSelection();
      selection.load("text");
      await context.sync();
      selectionText = selection.text;
    });

    return selectionText;
  }

  async clearSelection() {
    await Word.run(async (context: Word.RequestContext) => {
      const range = context.document.getSelection();
      range.select("Start");
      await context.sync();
    });
  }

  async getBody() {
    let bodyText = "";
    await Word.run(async (context: Word.RequestContext) => {
      const body = context.document.body;
      body.load("text");
      await context.sync();
      bodyText = body.text;
    });

    return bodyText;
  }

  async openFormattedText({ document: { formattedText }, wordOptions = {} }: OpenFormattedDocumentParams) {
    const { openInNewWindow = false } = wordOptions;
    if (openInNewWindow) {
      return this.openFormattedTextInNewWindow(formattedText);
    }

    const { lines, stylesMap } = formattedText;

    const errorMessage = await Word.run(async (context: Word.RequestContext) => {
      try {
        await this.clearDocumentBody(context);

        await this.addStyles(context, stylesMap);

        for (const line of lines) {
          await this.addLineToBody(context, line, stylesMap);
        }

        await context.sync();

        return undefined;
      } catch (e) {
        if (e instanceof Object && "message" in e) {
          logger.warn(`Error replaceBodyWithFormattedText: ${e.message}`, e);
        }
        return "Erro ao inserir texto formatado no documento.";
      }
    });

    return { errorMessage };
  }

  private async clearDocumentBody(context: Word.RequestContext) {
    const body = context.document.body;
    body.insertText("", "Replace");
    await context.sync();
  }

  private async addStyles(
    context: Word.RequestContext,
    styles: StylesMap,
    document?: Word.Document | Word.DocumentCreated
  ) {
    const doc = document || context.document;
    for (const name in styles) {
      try {
        if (name === "Requerido" || name === "Requerente") {
          continue;
        }

        const currentStyle = styles[name];
        const wordStyle = doc.addStyle(name, "Paragraph");
        wordStyle.set({
          paragraphFormat: {
            alignment: this.getAlignment(currentStyle.alignment),
            lineUnitAfter: currentStyle.lines_after || 0,
          },
          font: {
            size: currentStyle.font_size || 12,
            name: currentStyle.font_name || "Arial",
            bold: !!currentStyle.bold,
            underline: this.getUnderline(currentStyle.underline),
          },
        });

        await context.sync();
      } catch (e) {
        if (e instanceof Object && "message" in e) {
          logger.warn(`Error addStyles: ${e.message}`, e);
        }
      }
    }
  }

  private getAlignment(alignment: StyleAlignment): Alignment {
    switch (alignment) {
      case StyleAlignment.CENTER: {
        return Alignment.CENTERED;
      }
      case StyleAlignment.JUSTIFY: {
        return Alignment.JUSTIFIED;
      }
      default: {
        return Alignment.LEFT;
      }
    }
  }

  private getUnderline(underline?: boolean): Underline {
    return underline ? Underline.SINGLE : Underline.NONE;
  }

  private async addLineToBody(context: Word.RequestContext, line: Line, styles: StylesMap) {
    const body = context.document.body;
    if (["PageBreak", "page_break"].includes(line.type)) {
      body.insertBreak("Page", "End");
    } else {
      const currentStyle = styles[line.type] || { uppercase: false };
      const text = currentStyle.uppercase ? line.text?.toUpperCase() : line.text;
      const paragraph = body.insertParagraph(text || "", "End");
      await this.addStyle(context, paragraph, line.type);
    }
  }

  private async addStyle(context: Word.RequestContext, paragraph: Word.Paragraph, styleName: string) {
    try {
      const style = context.document.getStyles().getByNameOrNullObject(styleName);
      style.load();
      await context.sync();

      if (style.isNullObject) {
        logger.warn(`There's no existing style with the name '${styleName}'.`);
      } else if (style.type != Word.StyleType.paragraph) {
        logger.debug(`The '${styleName}' style isn't a paragraph style.`);
      } else {
        paragraph.style = style.nameLocal;
      }
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.warn(`Error addStyle: ${e.message}`, e);
      }
    }
  }

  private async openFormattedTextInNewWindow({ lines, stylesMap }: FormattedText) {
    const errorMessage = await Word.run(async (context) => {
      try {
        const newDocument = context.application.createDocument();
        await context.sync();

        const newDocumentBody = newDocument.body;
        newDocumentBody.load("text");
        await context.sync();

        await this.addStyles(context, stylesMap, newDocument);

        for (const line of lines) {
          await this.addLineToRangeToNewDocument(context, newDocument, newDocumentBody, line, stylesMap);
        }

        await context.sync();
        newDocument.open();
        await context.sync();

        return undefined;
      } catch (e) {
        if (e instanceof Object && "message" in e) {
          logger.warn(`Error createAndOpenFormattedDocument: ${e.message}`, e);
        }
        return "Erro ao criar e abrir documento com texto formatado.";
      }
    });

    return { errorMessage };
  }

  private async addLineToRangeToNewDocument(
    context: Word.RequestContext,
    document: Word.DocumentCreated,
    documentBody: Word.Body,
    line: Line,
    styles: StylesMap
  ) {
    const range = documentBody.insertText("", Word.InsertLocation.end);

    if (["PageBreak", "page_break"].includes(line.type)) {
      range.insertBreak("Page", "After");
    } else {
      const currentStyle = styles[line.type] || {};
      const text = currentStyle.uppercase ? line.text?.toUpperCase() : line.text;
      range.insertParagraph(text || "", "After");
      await context.sync();
      await this.addStyleToNewDocument(context, document, range, line.type);
    }
  }

  private async addStyleToNewDocument(
    context: Word.RequestContext,
    document: Word.DocumentCreated,
    range: Word.Range,
    styleName: string
  ) {
    try {
      const style = document.getStyles().getByNameOrNullObject(styleName);
      style.load();
      await context.sync();

      if (style.isNullObject) {
        logger.warn(`There's no existing style with the name '${styleName}'.`);
      } else if (style.type != Word.StyleType.paragraph) {
        logger.debug(`The '${styleName}' style isn't a paragraph style.`);
      } else {
        range.style = style.nameLocal;
      }
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.warn(`Error addStyleToNewDocument: ${e.message}`, e);
      }
    }
  }

  async replaceBody(text: string) {
    const errorMessage = await Word.run(async (context: Word.RequestContext) => {
      try {
        const body = context.document.body;
        body.insertText(text, "Replace");
        await context.sync();
        return undefined;
      } catch (e) {
        if (e instanceof Object && "message" in e) {
          logger.error(`Error replaceBody: ${e.message}`, e);
        }
        return "Erro ao substituir conteúdo do documento.";
      }
    });

    return { errorMessage };
  }

  async replaceSelection(text: string) {
    const errorMessage = await Word.run(async (context: Word.RequestContext) => {
      try {
        const selection = context.document.getSelection();
        selection.insertText(text, "Replace");
        await context.sync();
        return undefined;
      } catch (e) {
        if (e instanceof Object && "message" in e) {
          logger.error(`Error replaceSelection: ${e.message}`, e);
        }
        return "Erro ao substituir seleção do documento.";
      }
    });

    return { errorMessage };
  }

  async checkIfDocumentIsReadOnly(): Promise<boolean> {
    if (isWordApiVersionSupported("1.3")) {
      return new Promise<boolean>((resolve) => {
        Word.run(async (context) => {
          try {
            const document = context.document;
            document.load("properties");
            await context.sync();

            if (document.properties.security > 0) {
              // Only security = 0 means we can edit the document
              resolve(true);
              return;
            }

            resolve(false);
          } catch (error) {
            // We weren't able to check, so we assume it's not read-only
            resolve(false);
          }
        });
      });
    }

    return new Promise<boolean>((resolve) => {
      return Word.run(async (context) => {
        try {
          const document = context.document;
          document.body.insertText(" ", Word.InsertLocation.end);
          await context.sync();
          // We can edit the document, so it's not read-only
          resolve(false);
        } catch (error) {
          // We can't edit the document, so it's read-only
          resolve(true);
        }
      });
    });
  }

  async exportToDocx() {
    logger.error("Not implemented");
  }

  async insertParagraphs(paragraphs: Paragraph[]) {
    if (!paragraphs.length) return;

    await Word.run(async (context: Word.RequestContext) => {
      const selection = context.document.getSelection();

      let currentParagraph: Word.Range | Word.Paragraph = selection;

      for (const paragraph of paragraphs) {
        currentParagraph = currentParagraph.insertParagraph(paragraph.text, "After");

        if (paragraph.style) {
          const leftIndent = paragraph.style.leftIndent;
          if (leftIndent) {
            currentParagraph.leftIndent = leftIndent;
          }

          if (paragraph.style.italic) {
            currentParagraph.font.italic = true;
          }

          switch (paragraph.style.alignment) {
            case "justify": {
              currentParagraph.alignment = "Justified";
            }
          }
        }
      }

      await context.sync();
    });
  }

  addEventListener(event: EditorEvent, listener: () => void) {
    switch (event) {
      case EditorEvent.SELECTION_CHANGE: {
        Office.context.document?.addHandlerAsync(Office.EventType.DocumentSelectionChanged, listener);
        break;
      }
      default: {
        throw new Error(`Event not supported: ${event}`);
      }
    }
  }
}
