import React, { useMemo, useState } from "react";
import { InternalPageStructure } from "../../core/InternalPageStructure";
import { ActionId, useMessagesContext } from "@/contexts/MessagesContext";
import { Form, Text, TextInput, Title } from "@/components/FormComponents";
import { WebToast } from "../../core/Toast";
import { v4 as uuid } from "uuid";
import { SkillsPayload } from "@/contexts/WebSocketContext";

enum Step {
  CLIENT_NAME,
  CASE_BREAKDOWN,
}

export function LegalAdvice() {
  const { goToChat, startSkillProcess } = useMessagesContext();
  const [error, setError] = useState<string | null>("");
  const [clientName, setClientName] = useState<string>("");
  const [caseBreakdown, setCaseBreakdown] = useState<string>("");
  const [currentStep, setCurrentStep] = useState(0);
  const steps = useMemo(() => [Step.CLIENT_NAME, Step.CASE_BREAKDOWN], []);

  const dismissError = () => {
    setError(null);
  };

  const handleGoBack = () => {
    if (currentStep === 0) {
      goToChat();
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const canProceed = () => {
    switch (steps[currentStep]) {
      case Step.CLIENT_NAME:
        return !!clientName;
      case Step.CASE_BREAKDOWN:
        return !!caseBreakdown;
      default:
        throw new Error("Invalid step");
    }
  };

  const handleClientNameChange = (value: string) => {
    setClientName(value);
    dismissError();
  };

  const handleCaseBreakdownChange = (value: string) => {
    setCaseBreakdown(value);
    dismissError();
  };

  const handleSubmit = () => {
    const isFinalStep = currentStep === steps.length - 1;
    if (!isFinalStep) {
      setCurrentStep(currentStep + 1);
      return;
    }
    if (!clientName) {
      setError("Adicione o nome do cliente");
      return;
    }
    if (!caseBreakdown) {
      setError("Adicione o resumo dos fatos");
      return;
    }
    try {
      const newRequestId = uuid();

      const chatMessage = `Elaborar parecer jurídico.`;

      const payload: SkillsPayload = {
        skillId: ActionId.LEGAL_ADVICE,
        requestId: newRequestId,
        payload: {
          client: clientName,
          case_breakdown: caseBreakdown,
        },
        messageToSave: chatMessage,
      };

      startSkillProcess(payload);
      goToChat();
    } catch (e) {
      WebToast.error("Erro ao enviar as informações. Tente novamente mais tarde.");
    }
  };

  const title = `Elaborar parecer jurídico - Passo ${currentStep + 1}`;

  return (
    <InternalPageStructure
      goBack={handleGoBack}
      onSubmit={handleSubmit}
      error={error}
      dismissError={dismissError}
      submitButtonText={currentStep === steps.length - 1 ? "Elaborar parecer jurídico" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={steps.length}
    >
      <Form show={steps[currentStep] === Step.CLIENT_NAME}>
        <Title>{title}</Title>
        <Text>
          Emita opiniões jurídicas fundamentadas e seguras com o parecer jurídico gerado pelo Assistente Lexter. Forneça
          os dados necessários e obtenha análises técnicas e recomendações legais precisas para a tomada de decisões
          informadas e seguras.
        </Text>
        <TextInput
          id="input_client_name"
          label="Qual é o nome do cliente?"
          placeholder="Exemplo: João da Silva. 

Se tiver mais de um nome, separe com vírgulas."
          value={clientName}
          onChange={handleClientNameChange}
        />
      </Form>
      <Form show={steps[currentStep] === Step.CASE_BREAKDOWN}>
        <Title>{title}</Title>
        <TextInput
          label="Resumo dos fatos:"
          placeholder="Exemplo: A Fazenda Santa Clara Ltda. precisa instituir uma servidão de passagem para acessar uma estrada pública. Solicita um parecer jurídico sobre a viabilidade, procedimentos, obstáculos e estratégias para obter a autorização necessária."
          value={caseBreakdown}
          onChange={handleCaseBreakdownChange}
        />
      </Form>
    </InternalPageStructure>
  );
}
