import { ActionId, Message } from "@/contexts/MessagesContext";
import type { TObject, Static } from "@sinclair/typebox";
import { SkillsPayload } from "@/contexts/WebSocketContext";
import { Tag } from "../actions";

export type SkillId =
  | "create_new_document"
  | "create_initial_petition"
  | "create_labor_complaint"
  | "create_contract"
  | "create_evidence_and_required_documents"
  | "create_notice"
  | "create_interview_script"
  | "search_jurisprudence"
  | "create_contestation"
  | "create_labor_contestation"
  | "create_replication"
  | "create_labor_plaintiff_reply"
  | "create_appeal"
  | "create_ordinary_appeal"
  | "use_chat"
  | "hearings_script"
  | "fee_agreement"
  | "settlement_offer"
  | "summary_procedural_documents"
  | "legal_questions"
  | "create_case_strategy"
  | "intercurrent_motion"
  | "legal_advice";

type SkillType = "message" | "payload";

interface BaseSkill<SkillInputSchema> {
  id: SkillId;
  name: string;
  description?: string;
  credits: CreditPerSkill;
  inputSchema: SkillInputSchema;
  actionId: ActionId;
  tags?: Tag[];
  disabled?: boolean;
  type: SkillType;
}

export type Skill<SkillInputSchema extends TObject = TObject> =
  | (BaseSkill<SkillInputSchema> & { type: "message"; getMessage: (input: Static<SkillInputSchema>) => Message })
  | (BaseSkill<SkillInputSchema> & { type: "payload"; getPayload: (input: Static<SkillInputSchema>) => SkillsPayload });

export enum CreditPerSkill {
  ESSENTIAL_SKILL = 20,
  BASIC_SKILL = 50,
  INTERMEDIARY_SKILL = 100,
  ADVANCED_SKILL = 200,
}
