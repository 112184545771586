import { Box } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useWebEditorContext } from "@/contexts/WebEditorContext";
import { Tabs } from "@/contexts/WebEditorContext/components/Tabs";
import { useEditorState } from "@/contexts/WebEditorContext/hooks/useEditorState";
import { ConnectionState, useSocket } from "@/contexts/WebSocketContext";
import { useApi } from "@/hooks/useApi";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { Toast } from "@/taskpane/components/core/Toast";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { useLocation } from "react-router-dom";
import { Editor as TinyMCEEditor } from "tinymce";
import { v4 as uuid } from "uuid";
import { EditorLoading } from "./components/EditorLoading";
import { WebEditorContext } from "./context";
import { exportDocx, importDocx, pageBreak } from "./plugins";
import { EditorState, EditorTab } from "./types";
import * as logger from "@/core/logger";

const BASE_DOCUMENT_PATH = ROUTE_PATHS.DOCUMENT.replace(":documentId", "");

const initialEditorState: EditorState = {
  activeTab: "1",
  tabs: [
    {
      id: "1",
      label: "Novo Documento",
      content: undefined,
    },
  ],
};

interface WebEditorProviderProps {
  children: ReactNode;
}

export const WebEditorContextProvider = ({ children }: WebEditorProviderProps) => {
  const location = useLocation();
  const { getDocumentById, saveEditedDocument, saveNewDocument, saveOpenDocument, deleteOpenDocument } = useApi();

  const [editor, setEditor] = useState<TinyMCEEditor>();
  const [isRefetch, setIsRefetch] = React.useState(false);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [loadingSaveTabs, setLoadingSaveTabs] = useState<string[]>([]);

  const {
    editorState,
    currentTab,
    areTabsEmpty,
    resetEditorState,
    addEditorTab,
    selectEditorTab,
    removeEditorTab,
    updateCurrentEditorTab,
    updateEditorTab,
    setEditorTabs,
  } = useEditorState(initialEditorState);

  const {
    thread,
    connectionState: socketConnectionState,
    threadConnectionState,
  } = useSocket({
    onThreadConnected: ({ openDocumentsIds }) => {
      if (openDocumentsIds.length > 0) {
        setEditorTabs([]);
        openDocumentsIds.map((documentId) => {
          handleOpenDocument(documentId);
        });
        return;
      }
      resetEditorState();
    },
  });

  const loading =
    loadingDocument ||
    socketConnectionState === ConnectionState.CONNECTING ||
    threadConnectionState === ConnectionState.CONNECTING;

  useEffect(() => {
    if (!editor) return;

    setActiveTabContent();
  }, [editor, editorState.activeTab]);

  const setActiveTabContent = () => {
    if (!editor) return;

    const activeTab = editorState.tabs.find((tab) => tab.id === editorState.activeTab);
    editor.setContent(activeTab?.content || "");
  };

  useEffect(() => {
    const isDocumentRoute = location.pathname.startsWith(BASE_DOCUMENT_PATH);
    if (isDocumentRoute) {
      const currentPath = location.pathname.split("/");
      const pathDocumentId = currentPath.pop();
      if (pathDocumentId && editor && !isRefetch) {
        handleOpenDocument(Number(pathDocumentId));
        setIsRefetch(true);
      }
    }
  }, [location.pathname, editor]);

  const handleSaveOpenDocument = async ({ documentId, threadId }: { threadId: string; documentId: number }) => {
    try {
      await saveOpenDocument({ threadId, documentId });
    } catch (e) {
      logger.error(`Erro ao salvar documento como aberto - ${documentId} `, e);
    }
  };

  const handleOpenDocument = async (documentId: number) => {
    setLoadingDocument(true);
    try {
      const alreadyOpenTab = editorState.tabs.find((tab) => tab.documentId === documentId);

      if (alreadyOpenTab) {
        selectEditorTab(alreadyOpenTab.id);

        setLoadingDocument(false);
        return;
      }

      const document = await getDocumentById(documentId);

      if (thread) {
        handleSaveOpenDocument({ threadId: thread.id, documentId });
      }

      const newTabId = uuid();

      addEditorTab({
        id: newTabId,
        documentId,
        label: document.name,
        content: document.content,
        lastSavedContent: document.content,
      });
    } catch (e) {
      logger.error(`Erro ao carregar documento - ${documentId}`, e);
      Toast.error("Erro ao carregar o documento", 3000, "bottom-right");
    }
    setLoadingDocument(false);
  };

  const openNewDocumentTab = () => {
    const newTabId = uuid();

    addEditorTab({ id: newTabId, label: "Novo Documento" });
  };

  const handleEditorChange = (content: string) => {
    updateCurrentEditorTab({ content });
  };

  useEffect(() => {
    const saveChangesId = setTimeout(saveAllDocuments, 5000);

    return () => clearTimeout(saveChangesId);
  }, [editorState]);

  const saveTabDocument = async (tab: EditorTab) => {
    if (!thread?.id || tab.content === tab.lastSavedContent) return;

    try {
      setLoadingSaveTabs((prev) => [...prev, tab.id]);

      if (tab.documentId) {
        await saveEditedDocument(tab.documentId, tab.content || "");
        updateEditorTab({ id: tab.id, lastSavedContent: tab.content });
      } else {
        const documentSaved = await saveNewDocument({
          name: tab.label,
          content: tab.content || "",
          threadId: thread.id,
        });
        updateEditorTab({ id: tab.id, documentId: documentSaved.id, lastSavedContent: tab.content });
      }
    } finally {
      setLoadingSaveTabs((prev) => prev.filter((tabId) => tabId !== tab.id));
    }
  };

  const handleSaveDocument = async () => {
    if (!currentTab) return;

    try {
      await saveTabDocument(currentTab);
      Toast.success("Documento salvo com sucesso!", 3000, "bottom-right");
    } catch {
      Toast.error("Erro ao salvar o documento", 3000, "bottom-right");
    }
  };

  const closeTab = async (tabId: string) => {
    const { activeTab, tabs } = editorState;
    const tab = tabs.find((tab) => tab.id === tabId);

    if (thread && tab && tab.documentId) {
      deleteOpenDocument({ threadId: thread.id, documentId: tab.documentId });
    }

    const newTabs = tabs.filter((tab) => tab.id !== tabId);

    removeEditorTab(tabId);

    if (!newTabs.length) {
      openNewDocumentTab();
      return;
    }

    let newActiveTab: string | undefined;

    if (activeTab === tabId) {
      const removedTabIndex = tabs.findIndex((tab) => tab.id === tabId);

      const wasLastTab = removedTabIndex === tabs.length - 1;
      if (wasLastTab) {
        newActiveTab = newTabs[newTabs.length - 1].id;
      } else {
        newActiveTab = newTabs[removedTabIndex].id;
      }
    }

    selectEditorTab(newActiveTab ? newActiveTab : activeTab);
  };

  const closeTabByDocumentId = async (documentId: number) => {
    const tab = editorState.tabs.find((tab) => tab.documentId === documentId);
    if (tab) {
      await closeTab(tab.id);
    }
  };

  const renameTabByDocumentId = (documentId: number, newName: string) => {
    const tab = editorState.tabs.find((tab) => tab.documentId === documentId);
    if (tab) {
      updateEditorTab({ id: tab.id, label: newName });
    }
  };

  const saveAllDocuments = async () => {
    await Promise.all(editorState.tabs.map((tab) => saveTabDocument(tab)));
  };

  const saveAllDocumentsRef = useRef(saveAllDocuments);
  useEffect(() => {
    saveAllDocumentsRef.current = saveAllDocuments;
  }, [saveAllDocuments]);
  useEffect(() => {
    return () => {
      saveAllDocumentsRef.current();
    };
  }, [location.pathname]);

  return (
    <WebEditorContext.Provider
      value={{
        editorElement: (
          <TextEditor
            onReady={setEditor}
            onChange={handleEditorChange}
            onClose={() => setEditor(undefined)}
            editorState={editorState}
            addEditorTab={addEditorTab}
            selectEditorTab={selectEditorTab}
            setEditorTabs={setEditorTabs}
            onSave={handleSaveDocument}
            loading={loading}
            loadingSaveTabs={loadingSaveTabs}
            closeTab={closeTab}
          />
        ),
        editor,
        allTabsAreEmpty: areTabsEmpty,
        currentTabIsEmpty: !currentTab?.content,
        currentTabContent: currentTab?.content,
        openNewDocumentTab,
        handleOpenDocument,
        closeTabByDocumentId,
        renameTabByDocumentId,
      }}
    >
      {children}
    </WebEditorContext.Provider>
  );
};

interface TextEditorProps {
  onReady: (editor: TinyMCEEditor) => void;
  onChange: (content: string) => void;
  onClose: () => void;
  editorState: EditorState;
  addEditorTab: (tab: EditorTab) => void;
  selectEditorTab: (tabId: string) => void;
  setEditorTabs: (tabs: EditorTab[]) => void;
  onSave: () => void;
  loading: boolean;
  loadingSaveTabs: string[];
  closeTab: (tabId: string) => void;
}

const TextEditor = ({
  onReady,
  onChange,
  onClose,
  editorState: { tabs, activeTab },
  addEditorTab,
  selectEditorTab,
  setEditorTabs,
  onSave,
  loading,
  loadingSaveTabs,
  closeTab,
}: TextEditorProps) => {
  const { openNewDocumentTab } = useWebEditorContext();

  const onInit = (editor: TinyMCEEditor) => {
    editor.tabs = {
      addTab: (newTab: EditorTab) => {
        addEditorTab({ ...newTab, lastSavedContent: newTab.content });
      },
    };
    onReady(editor);
  };

  const openNewTab = () => {
    openNewDocumentTab();
  };

  const handleTabChange = (tabId: string) => {
    selectEditorTab(tabId);
  };

  const handleReorderTabs = (reorderedTabs: EditorTab[]) => {
    setEditorTabs(reorderedTabs);
  };

  useEffect(() => {
    return () => {
      onClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveRef = React.useRef(onSave);
  useEffect(() => {
    onSaveRef.current = onSave;
  }, [onSave]);

  if (!outsideOfficeClient()) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loading && <EditorLoading />}

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        loadingSaveTabs={loadingSaveTabs}
        onNewTab={openNewTab}
        onTabChange={handleTabChange}
        onTabClose={closeTab}
        onTabsReorder={handleReorderTabs}
      />

      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        licenseKey="gpl"
        onInit={(_evt, editor) => {
          onInit(editor);
        }}
        init={{
          plugins:
            "autosave save anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount ",
          toolbar:
            "undo redo | fontfamily fontsize blocks | bold underline | align lineheight | numlist bullist indent outdent",
          menu: {
            file: {
              title: "Arquivo",
              items: `newdocument save restoredraft | exportdocx importdocx | print`,
            },
          },
          mergetags_list: [
            { value: "First.Name", title: "First Name" },
            { value: "Email", title: "Email" },
          ],
          entity_encoding: "raw",
          language: "pt_BR",
          language_url: "/tinymce/langs/pt_BR.js",
          resize: false,
          height: "100%",
          width: "100%",
          content_style: "* { line-height: 1.1; position: relative;}",
          default_font_stack: ["-apple-system", "Arial"],
          spellchecker_language: "pt", // SpellChecker configurado como padrão para português brasil
          paste_as_text: true, // Copia o texto como texto puro
          branding: false, // Remove nome tinymce do rodapé
          promotion: false, // Remove botão de upgrade tinymce
          content_css: "document", // Deixa o editor no formato de páginas
          autosave_prefix: `lexter-saved-editor-content-`, // Prefix para o nome da variavel que será salva no localstorage
          autosave_interval: "5s", // Intervalo de tempo para salvar o draft no localstorage
          elementpath: false, // Remove o caminho do elemento selecionado do footer
          // Formatação para o export para word funcionar corretamente!
          formats: {
            underline: { inline: "u" },
            italic: { inline: "i" },
          },
          // Configuração de botões customizados para o tinymce
          setup: (editor) => {
            editor.ui.registry.addMenuItem("save", {
              icon: "save",
              text: "Salvar",
              onAction: () => onSaveRef.current(),
            });

            editor.ui.registry.addMenuItem("exportdocx", {
              icon: "export-word",
              text: "Exportar",
              onAction: () => exportDocx(editor),
            });

            editor.ui.registry.addMenuItem("importdocx", {
              icon: "import-word",
              text: "Importar",
              onAction: () => importDocx(editor),
            });
            editor.ui.registry.addMenuItem("pagebreak", {
              icon: "page-break",
              text: "Quebra de Página",
              onAction: () => pageBreak(editor),
            });
          },
          save_onsavecallback: () => onSaveRef.current(),
        }}
        initialValue=""
        onEditorChange={onChange}
      />
    </Box>
  );
};
