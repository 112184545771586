import { AuthService } from "@/services/auth";
import { AxiosInstance } from "axios";

export const withAuthorization = ({
  instance,
  withLoginType = true,
}: {
  instance: AxiosInstance;
  withLoginType?: boolean;
}) => {
  instance.interceptors.request.use(async (reqConfig) => {
    const authorizationHeader = await AuthService.getAuthorizationHeader({ withLoginType });
    if (authorizationHeader) {
      reqConfig.headers.setAuthorization(authorizationHeader);
    }
    return reqConfig;
  });
  return instance;
};
