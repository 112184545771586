import { Box, Skeleton, SxProps, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { CreditsError } from "@/components/CreditsError";
import { PlanChip } from "@/components/PlanChip";
import { PlanChipSkeleton } from "@/components/PlanChip/PlanChipSkeleton";
import { useAuthContext } from "@/contexts/AuthContext";
import { useCreditsContext } from "@/contexts/CreditsContext";
import React, { useEffect } from "react";

export const Credits = ({ onClose }: { onClose: () => void }) => {
  const { credits, planType, refreshCredits, loading, error, openBilling, isTrial, isActivePlan } = useCreditsContext();
  const { user } = useAuthContext();

  const handleOpenBilling = () => {
    openBilling();
    onClose();
  };

  useEffect(() => {
    refreshCredits();
  }, []);

  return (
    <Box sx={{ backgroundColor: "common.shade", p: "16px", cursor: "default" }}>
      {error ? (
        <CreditsError />
      ) : (
        <>
          <Typography
            variant="body1"
            color="common.white"
            mb="4px"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {user?.userName}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              mb: 2,
            }}
          >
            <Typography variant="body" color={isActivePlan ? "common.white" : "common.mediumShade"}>
              <strong>Créditos:</strong>{" "}
              {loading ? (
                <Skeleton
                  variant="text"
                  width="50px"
                  sx={{
                    display: "inline-block",
                    bgcolor: "common.lightShade",
                  }}
                />
              ) : (
                credits?.toLocaleString("pt-BR") || "--"
              )}
            </Typography>
            {isTrial ? <DaysToEndTrial color="common.white" /> : <NextRecharge />}
          </Box>

          {loading ? (
            <PlanChipSkeleton />
          ) : (
            !!planType && (
              <>
                <PlanChip planType={planType} />
                <GetMoreCredits onOpenBilling={handleOpenBilling} sx={{ mt: "16px" }} />
              </>
            )
          )}
        </>
      )}
    </Box>
  );
};

export const GetMoreCredits = ({ onOpenBilling, sx }: { onOpenBilling: () => void; sx?: SxProps }) => {
  const { isFreemium, isTrial } = useCreditsContext();

  return (
    <Typography
      color="common.white"
      sx={{
        cursor: "pointer",
        letterSpacing: "0.5px",
        fontSize: "14px",
        alignSelf: "flex-end",
        border: "1px solid",
        borderColor: "common.white",
        padding: "4px 20px",
        borderRadius: "16px",
        textAlign: "center",
        ...sx,
      }}
      onClick={onOpenBilling}
    >
      {isFreemium || isTrial ? "Contratar plano" : "Gerenciar plano"}
    </Typography>
  );
};

export const NextRecharge = ({ text, color }: { text?: string; color?: string }) => {
  const { loading, nextBillingDate } = useCreditsContext();

  const nextBillingDateFormatted = nextBillingDate
    ? DateTime.fromISO(nextBillingDate).setLocale("pt-BR").toFormat("dd 'de' MMMM 'de' yyyy")
    : nextBillingDate;

  return (
    <Typography variant="body3" color={color ? color : "common.white"}>
      <strong>{text ? text : "Próxima recarga:"}</strong>{" "}
      {loading ? (
        <Skeleton
          variant="text"
          width="60px"
          sx={{
            display: "inline-block",
            bgcolor: "common.lightShade",
          }}
        />
      ) : (
        nextBillingDateFormatted
      )}
    </Typography>
  );
};

export const DaysToEndTrial = ({ color, completeSentence }: { color?: string; completeSentence?: boolean }) => {
  const { loading, nextBillingDate, isActivePlan } = useCreditsContext();
  const [daysToEnd, setDaysToEnd] = React.useState<number>(0);

  useEffect(() => {
    if (isActivePlan) calculateDaysToEnd();
  }, [nextBillingDate]);

  const calculateDaysToEnd = () => {
    const now = DateTime.now();
    if (nextBillingDate) {
      const diffDays = DateTime.fromISO(nextBillingDate).diff(now, "days");
      if (diffDays.days > 0 && diffDays.days < 1) {
        setDaysToEnd(1);
      } else if (diffDays.days >= 1) {
        setDaysToEnd(Number(diffDays.days.toFixed()));
      }
    }
  };

  return (
    <Typography variant="body3" color={color ? color : "common.black"}>
      {loading ? (
        <Skeleton
          variant="text"
          width="60px"
          sx={{
            display: "inline-block",
            bgcolor: "common.lightShade",
          }}
        />
      ) : (
        <>
          {isActivePlan ? (
            <span>
              Você ainda tem{" "}
              <strong>
                {daysToEnd} {daysToEnd === 1 ? <>dia</> : <>dias</>}
              </strong>{" "}
              para utilizar seus créditos de forma gratuita.
            </span>
          ) : (
            <span>
              <strong>Seu período de testes chegou ao fim. </strong>
              {completeSentence && <span>Contrate agora para continuar usando.</span>}
            </span>
          )}
        </>
      )}
    </Typography>
  );
};
