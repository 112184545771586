import * as skills from "./implementations";
import { Skill, SkillId } from "./types";

const skillsMap = new Map<SkillId, Skill>(Object.values(skills).map((skill) => [skill.id, skill as unknown as Skill]));

export const Skills = {
  async getSkills() {
    return Array.from(skillsMap.values());
  },

  async getSkill(id: SkillId) {
    const skill = skillsMap.get(id);
    if (!skill) {
      return null;
    }
    return skill;
  },
};
