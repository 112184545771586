import { Settings as LuxonSettings } from "luxon";
import * as React from "react";
import { createRoot } from "react-dom/client";

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { datadogRum } from "@datadog/browser-rum";
import * as logger from "../core/logger";
import { App } from "./App";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { env } from "@/constants/environment";
import { IS_OLD_WEBVIEW } from "@/constants/isOldWebview";
import { defaultFlagValues } from "@/hooks/useFeatureFlags/defaultFlagValues";
import { BrowserRouter } from "react-router-dom";
import * as apm from "../core/apm";

logger.init();
apm.init();

LuxonSettings.defaultZone = "America/Sao_Paulo";
LuxonSettings.defaultLocale = "pt-BR";

const render = async (Component: React.ComponentType) => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: env.LAUNCH_DARKLY_CLIENT_SIDE_ID,
    flags: defaultFlagValues,
    options: {
      inspectors: [
        {
          type: "flag-used",
          name: "dd-inspector",
          method: (key, detail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });

  const containerElement = document.getElementById("container");

  if (!containerElement) {
    throw new Error("Container element not found");
  }

  const rootContainer = createRoot(containerElement);

  rootContainer.render(
    <React.StrictMode>
      <LDProvider>
        <BrowserRouter>
          <Component />
        </BrowserRouter>
      </LDProvider>
    </React.StrictMode>
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  if (IS_OLD_WEBVIEW) return;

  render(App);
});
