import React, { useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { CourtsFilter } from "./CourtsFilter";
import { FiltersContainer } from "./FiltersContainer";
import { PeriodFilter } from "./PeriodFilter";
import { Court, FiltersState } from "./types";

export interface FiltersProps {
  onChange: (filters: FiltersState) => void;
}

export const Filters = ({ onChange }: FiltersProps) => {
  const [courts, setCourts] = useState<Court[]>([]);
  const [minDate, setMinDate] = useState<DateTime>();

  const filtersState: FiltersState = useMemo(
    () => ({
      courts,
      minDate,
    }),
    [courts, minDate]
  );

  useEffect(() => {
    onChange(filtersState);
  }, [filtersState, onChange]);

  return (
    <FiltersContainer>
      <PeriodFilter onChange={setMinDate} />
      <CourtsFilter onChange={setCourts} />
    </FiltersContainer>
  );
};
