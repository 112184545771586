import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { TextField } from "@/components/TextField";
import React from "react";
import { brazilStates, numberOfEmployees } from "../defaultValues";

interface UserOfficeProps {
  userOffice: UserOfficeItem;
  onUserOfficeChange: (value: UserOfficeItem) => void;
  show: boolean;
}

export interface UserOfficeItem {
  name: string;
  numberOfEmployees: string;
  state: string;
}

export const UserOffice = ({ userOffice, onUserOfficeChange, show }: UserOfficeProps) => {
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUserOfficeChange({
      ...userOffice,
      name: event.target.value,
    });
  };

  const handleChangeState = (event: SelectChangeEvent<string>) => {
    onUserOfficeChange({
      ...userOffice,
      state: event.target.value,
    });
  };

  const handleChangeNumberOfEmployees = (event: SelectChangeEvent<string>) => {
    onUserOfficeChange({
      ...userOffice,
      numberOfEmployees: event.target.value,
    });
  };

  if (!show) return null;

  return (
    <Box sx={{ minWidth: "710px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          color="common.shade"
          label="EMPRESA ou escritório"
          value={userOffice.name}
          placeholder="Digite o nome do seu local de trabalho"
          onChange={handleChangeName}
          placeholderColor="common.mediumShade"
        />
        <FormControl variant="standard">
          <InputLabel shrink={true} id="select-state-label">
            Número de funcionários
          </InputLabel>
          <Select
            labelId="select-state-label"
            value={userOffice.numberOfEmployees}
            onChange={handleChangeNumberOfEmployees}
            label="Número de funcionários"
            displayEmpty
            renderValue={
              userOffice.numberOfEmployees !== ""
                ? undefined
                : () => "Selecione o número de funcionários de sua empresa"
            }
            sx={{
              color: userOffice.numberOfEmployees === "" ? "common.mediumShade" : "common.shade",
            }}
          >
            {numberOfEmployees.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink={true} id="select-state-label">
            Localização
          </InputLabel>
          <Select
            labelId="select-state-label"
            value={userOffice.state}
            onChange={handleChangeState}
            label="Localização"
            displayEmpty
            renderValue={
              userOffice.state !== "" ? undefined : () => "Selecione o estado onde sua empresa está localizada"
            }
            sx={{
              color: userOffice.state === "" ? "common.mediumShade" : "common.shade",
            }}
          >
            {brazilStates.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
