import { buildSkillStep, createEditorContentField, createUploadFileField } from "@/core/skillSteps/builder";

export const createAppealSkillStep6 = buildSkillStep({
  title: "Criar Recurso - Passo 6",
  description: `# Defenda o ponto de vista do seu cliente incluindo mais informações sobre o caso:\nExemplo: "Refutar a alegação de responsabilidade civil, pois não há ligação direta entre a ação do fornecedor e o dano ao consumidor. Além disso, a responsabilidade de notificar alteração de voos é das companhias aéreas, segundo a Resolução ANAC 400."`,
  fields: [
    {
      name: "case_breakdown",
      type: "string",
      required: false,
      options: [
        {
          label: "Usar as informações descritas no corpo do documento aberto",
          value: "use_editor_content",
        },
        {
          label: "Upload de arquivo (DOCX ou PDF)",
          value: "upload_file",
        },
        {
          label: "Não quero adicionar fatos",
          value: null,
        },
      ],
      initialValue: "use_editor_content",
      ui: {
        label: "Enviar detalhamento do caso:",
        widget: "radio",
      },
    },
    createEditorContentField({
      name: "case_breakdown_editor_content",
      overrideField: "case_breakdown",
      condition: {
        field: "case_breakdown",
        value: "use_editor_content",
      },
    }),
    createUploadFileField({
      name: "case_breakdown_file",
      overrideField: "case_breakdown",
      condition: {
        field: "case_breakdown",
        value: "upload_file",
      },
    }),
  ],
  submitButtonText: "Criar Documento",
});
