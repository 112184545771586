import { FormControl, FormHelperText, FormLabel, Typography } from "@mui/material";
import { StrictRJSFSchema, RJSFSchema, FormContextType, FieldTemplateProps } from "@rjsf/utils";
import React from "react";

export function FieldTemplate<
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = Record<string, unknown>,
>(props: FieldTemplateProps<T, S, F>) {
  const { children, displayLabel, hidden, label, required, rawErrors = [], help, description, rawDescription } = props;

  if (hidden) {
    return <div style={{ display: "none" }}>{children}</div>;
  }

  return (
    <FormControl fullWidth={true} error={rawErrors.length ? true : false} required={required}>
      {displayLabel && label ? (
        <Typography variant="preTitle" sx={{ mb: 1, whiteSpace: "normal" }} component={FormLabel}>
          {label}
        </Typography>
      ) : null}
      {children}
      <FormHelperText sx={{ "&:first-letter": { textTransform: "uppercase" } }}>
        {rawErrors.length ? rawErrors[0] : undefined}
      </FormHelperText>
      {displayLabel && rawDescription ? <Typography variant="preTitle">{description}</Typography> : null}
      {help}
    </FormControl>
  );
}
