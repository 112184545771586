import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Modal } from "@/components/Modal";
import React, { useState } from "react";
import LexterLogoIcon from "@/assets/svgs/lexter-logo.svg?react";
import { Divider } from "@mui/material";
import { Button, LinkButton } from "@/components/Button";
import { Stepper } from "@/taskpane/components/core/Stepper";
import { UserActivityAreaSelect } from "./steps/UserActivityAreaSelect";
import { UserActivityDailySelect } from "./steps/UserActivityDailySelect";
import { UserNumberOfMotionsPerWeekSelect } from "./steps/UserNumberOfMotionsPerWeekSelect";
import { UserOffice, UserOfficeItem } from "./steps/UserOffice";
import { UserSourceOfReferralSelect } from "./steps/UserSourceOfReferralSelect";
import { MultiSelectChipItem } from "@/components/MultiSelectChip";
import {
  activitiesDailyDefaultValues,
  activityAreasDefaultValues,
  sourceOfReferralDefaultValues,
} from "./defaultValues";
import WomanInComputerPNG from "@/assets/images/womanHappyInComputer.png";
import WelcomeSVG from "@/assets/svgs/welcome.svg?react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { openUrl } from "@/utils/openUrl";
import { ArrowOutwardOutlined } from "@mui/icons-material";
import { ShortCutAction } from "@/contexts/MessagesContext";

export interface OnboardingData {
  activitiesDaily: MultiSelectChipItem[];
  activityAreas: MultiSelectChipItem[];
  numberOfMotionsPerWeek: string;
  userOffice: UserOfficeItem;
  sourceOfReferral: MultiSelectChipItem[];
}

enum Step {
  ACTIVITYDAILY,
  ACTIVITYAREA,
  NUMBEROFMOTIONSPERWEEK,
  OFFICE,
  SOURCEOFREFERRAL,
  WELCOME,
}

interface OnboardingProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: OnboardingData) => void;
  firstTimeInOnboarding?: boolean;
}

export const Onboarding = ({ open, onClose, onSubmit, firstTimeInOnboarding }: OnboardingProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [activitiesDaily, setActivityDaily] = useState<MultiSelectChipItem[]>(activitiesDailyDefaultValues);
  const [activityAreas, setActivityAreas] = useState<MultiSelectChipItem[]>(activityAreasDefaultValues);
  const [numberOfMotionsPerWeek, setNumberOfMotionsPerWeek] = useState<string>("");
  const [userOffice, setUserOffice] = useState<UserOfficeItem>({
    name: "",
    numberOfEmployees: "",
    state: "",
  });
  const [sourceOfReferral, setSourceOfReferral] = useState<MultiSelectChipItem[]>(sourceOfReferralDefaultValues);

  const steps = [
    Step.ACTIVITYDAILY,
    Step.ACTIVITYAREA,
    Step.NUMBEROFMOTIONSPERWEEK,
    Step.OFFICE,
    Step.SOURCEOFREFERRAL,
  ];
  if (firstTimeInOnboarding) steps.push(Step.WELCOME);

  const onUserActivitiesDailyChange = (options: MultiSelectChipItem[]) => {
    setActivityDaily([...options]);
  };

  const onUserActivityAreasChange = (options: MultiSelectChipItem[]) => {
    setActivityAreas([...options]);
  };

  const onUserNumberOfMotionsPerWeekChange = (value: string) => {
    setNumberOfMotionsPerWeek(value);
  };

  const onUserOfficeChange = (value: UserOfficeItem) => {
    setUserOffice(value);
  };

  const onUserSourceOfReferralChange = (options: MultiSelectChipItem[]) => {
    setSourceOfReferral([...options]);
  };

  const onGoBackHandler = () => {
    setCurrentStep(currentStep - 1);
  };

  const onSkipHandler = () => {
    if (currentStep === 5) {
      resetOnboarding();
    }
    setCurrentStep(currentStep + 1);
  };

  const onSubmitHandler = () => {
    setCurrentStep(currentStep + 1);
    if (currentStep === 4) {
      onSubmit({
        activitiesDaily,
        activityAreas,
        numberOfMotionsPerWeek,
        userOffice,
        sourceOfReferral,
      });

      if (!firstTimeInOnboarding) {
        resetOnboarding();
      }
    }
  };

  const resetOnboarding = () => {
    setCurrentStep(0);
    setActivityDaily(activitiesDailyDefaultValues);
    setActivityAreas(activityAreasDefaultValues);
    setNumberOfMotionsPerWeek("");
    setUserOffice({
      name: "",
      numberOfEmployees: "",
      state: "",
    });
    setSourceOfReferral(sourceOfReferralDefaultValues);
    onClose();
  };

  const onCloseWelcomeModal = () => {
    resetOnboarding();
  };

  if (currentStep === Step.WELCOME) {
    return <WelcomeModal open={open} onClose={onCloseWelcomeModal} />;
  }

  return (
    <Modal
      open={open}
      sx={{
        minWidth: "800px",
        minHeight: "480px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        mt: 0,
        borderRadius: "12px",
        ".modal-box-content": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: "3",
        },
      }}
      onClose={() => resetOnboarding()}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          flexGrow: "1",
        }}
      >
        <OnboardingHeader firstTimeInOnboarding={firstTimeInOnboarding} steps={steps} currentStep={currentStep} />
        <Divider sx={{ mb: 4, width: "800px", borderColor: "#C5C5D0" }} component="div" />
        <Box
          sx={{
            flexGrow: 1,
            mx: 3,
            alignSelf: "start",
          }}
        >
          <UserActivityDailySelect
            show={currentStep === Step.ACTIVITYDAILY}
            activityDaily={activitiesDaily}
            onActivityDailyChange={onUserActivitiesDailyChange}
          />
          <UserActivityAreaSelect
            show={currentStep === Step.ACTIVITYAREA}
            activityAreas={activityAreas}
            onActivityAreasChange={onUserActivityAreasChange}
          />
          <UserNumberOfMotionsPerWeekSelect
            show={currentStep === Step.NUMBEROFMOTIONSPERWEEK}
            numberOfMotionsPerWeek={numberOfMotionsPerWeek}
            onNumberOfMotionsPerWeekChange={onUserNumberOfMotionsPerWeekChange}
          />
          <UserOffice
            show={currentStep === Step.OFFICE}
            userOffice={userOffice}
            onUserOfficeChange={onUserOfficeChange}
          />
          <UserSourceOfReferralSelect
            show={currentStep === Step.SOURCEOFREFERRAL}
            sourceOfReferral={sourceOfReferral}
            onSourceOfReferralChange={onUserSourceOfReferralChange}
          />
        </Box>
        <Divider sx={{ mt: 4, width: "800px", borderColor: "#C5C5D0" }} component="div" />
        <OnboardingFooter
          onSkipHandler={onSkipHandler}
          onGoBackHandler={onGoBackHandler}
          onSubmitHandler={onSubmitHandler}
          currentStep={currentStep}
        />
      </Box>
    </Modal>
  );
};

interface OnboardingHeaderProps {
  steps: Step[];
  currentStep: Step;
  firstTimeInOnboarding?: boolean;
}

const OnboardingHeader = ({ steps, currentStep, firstTimeInOnboarding }: OnboardingHeaderProps) => {
  const numberFromStepper = firstTimeInOnboarding ? steps.length - 1 : steps.length;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "fit-content",
        gap: "8px",
      }}
    >
      <LexterLogoIcon height={53} />
      <Typography variant="h6">Configurando sua conta</Typography>
      <Typography variant="subtitle2">Finalize seu cadastro para começar a utilizar o Assistente Lexter.</Typography>
      <Stepper steps={numberFromStepper} activeStep={currentStep} sx={{ pb: "16px" }} />
    </Box>
  );
};

const OnboardingFooter = ({
  currentStep,
  onSkipHandler,
  onGoBackHandler,
  onSubmitHandler,
}: {
  currentStep: Step;
  onSkipHandler: () => void;
  onGoBackHandler: () => void;
  onSubmitHandler: () => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        mt: 2,
        p: "10px 20px",
      }}
    >
      <LinkButton capitalize onClick={onSkipHandler}>
        {currentStep === 4 ? "Fechar" : "Pular"}
      </LinkButton>
      <Box
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        {currentStep !== 0 && <LinkButton onClick={onGoBackHandler}>Voltar</LinkButton>}
        <Button variant="contained" onClick={onSubmitHandler}>
          {currentStep < 4 ? "Prosseguir" : "Finalizar"}
        </Button>
      </Box>
    </Box>
  );
};

const WelcomeModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const navigate = useNavigate();

  const goToPetitionSummary = () => {
    onClose();
    navigate(ROUTE_PATHS.PETITION_SUMMARY);
  };

  const goToInitialPetition = () => {
    onClose();
    navigate({
      pathname: ROUTE_PATHS.CHAT,
      search: new URLSearchParams({ skill: ShortCutAction.CREATE_NEW_DOCUMENT }).toString(),
    });
  };

  const goToHelpCenterCreditsPage = () => {
    onClose();
    openUrl("https://ajuda.lexter.ai/o-que-sao-e-como-funcionam-os-creditos");
  };

  return (
    <Modal
      open={open}
      sx={{
        minWidth: "800px",
        minHeight: "387px",
        padding: "15px 15px 0 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        mt: 0,
        borderRadius: "12px",
        ".modal-box-content": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: "3",
          marginTop: 0,
        },
        ".modal-box-header": {
          zIndex: 1,
        },
      }}
      onClose={onClose}
    >
      <Box>
        <Box sx={{ maxWidth: "401px", position: "absolute", top: 0, left: 0 }}>
          <WelcomeSVG
            style={{
              borderRadius: "12px 0px 0px 12px",
            }}
          />
        </Box>
        <Box sx={{ position: "absolute", top: 0, right: "0", width: "399px", pl: "32px" }}>
          <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
            Comece a usar
          </Typography>
          <Box sx={{ display: "flex", gap: "16px", mb: 2, img: { borderRadius: "5px", objectFit: "cover" } }}>
            <img alt="Woman in computer" src={WomanInComputerPNG} width="168" height="128" />
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                cursor: "pointer",
                "&:hover": {
                  textDecorationLine: "underline",
                  textUnderlineOffset: "2px",
                },
              }}
              onClick={goToInitialPetition}
            >
              <Box
                sx={{
                  width: "127px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                  variant="subtitle2"
                >
                  ESCREVA PEÇAS JURÍDICAS
                </Typography>
                <div>Reduza o tempo de até 70% na elaboração de peças.</div>
              </Box>
              <ArrowOutwardOutlined />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mr: "20px" }}>
            <Typography sx={{ fontWeight: 700 }} variant="subtitle2">
              SAIBA MAIS
            </Typography>
            <Button
              sx={{
                textTransform: "none",
                fontWeight: "normal",
                color: "common.shade",
                borderColor: "common.lightShade",
                pl: "20px",
                justifyContent: "start",
                "&:hover": {
                  borderColor: "common.lightShade",
                },
              }}
              variant="outlined"
              endIcon={ArrowOutwardOutlined}
              onClick={goToPetitionSummary}
            >
              Como resumir um documento processual
            </Button>
            <Button
              sx={{
                textTransform: "none",
                fontWeight: "normal",
                color: "common.shade",
                borderColor: "common.lightShade",
                pl: "20px",
                justifyContent: "start",
                "&:hover": {
                  borderColor: "common.lightShade",
                },
              }}
              variant="outlined"
              endIcon={ArrowOutwardOutlined}
              onClick={goToHelpCenterCreditsPage}
            >
              Como funcionam os créditos
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
