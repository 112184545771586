import { Text, TextInput, Title } from "@/components/FormComponents";
import { Select } from "@/components/FormComponents/Select";
import { ActionId, Area, useMessagesContext } from "@/contexts/MessagesContext";
import { SkillsPayload } from "@/contexts/WebSocketContext";
import { WebToast } from "@/taskpane/components/core/Toast";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { InternalPageStructure } from "../../core/InternalPageStructure";

export function LegalQuestion(props: { goBack: () => void }) {
  const { goBack } = props;
  const { startSkillProcess } = useMessagesContext();
  const [error, setError] = useState<string | null>("");
  const [userQuestion, setUserQuestion] = useState<string>("");
  const [area, setArea] = useState<string>("");

  const menuItems = [
    { value: Area.CIVIL, label: "Cível" },
    { value: Area.LABOR, label: "Trabalhista" },
    { value: Area.SOCIAL_SECURITY, label: "Previdenciário" },
    { value: Area.TAX, label: "Tributário" },
    { value: Area.CRIMINAL, label: "Criminal" },
  ];

  const dismissError = () => {
    setError(null);
  };

  const handleUserQuestionChange = (value: string) => {
    setUserQuestion(value);
    dismissError();
  };

  const handleSubmit = () => {
    if (!area) {
      setError("Selecione uma área jurídica");
      return;
    }
    if (!userQuestion) {
      setError("Digite a sua pergunta");
      return;
    }
    try {
      const newRequestId = uuid();

      const areaOption = menuItems.find((item) => item.value === area);

      const chatMessage = `Área: ${areaOption?.label ?? area}.
        Pergunta: ${userQuestion}`;

      const payload: SkillsPayload = {
        skillId: ActionId.LEGAL_QUESTION,
        requestId: newRequestId,
        payload: {
          area: area,
          question: userQuestion,
        },
        messageToSave: chatMessage,
      };

      startSkillProcess(payload);
      goBack();
    } catch (e) {
      WebToast.error("Erro ao enviar a pergunta. Tente novamente mais tarde.");
    }
  };

  return (
    <InternalPageStructure
      goBack={goBack}
      onSubmit={handleSubmit}
      error={error}
      dismissError={() => setError(null)}
      submitButtonText="PERGUNTAR"
      submitButtonDisabled={!area || !userQuestion}
    >
      <Title sx={{ mb: 4 }}>Dúvida jurídica</Title>
      <Text sx={{ mb: 4 }}>
        O Assistente Lexter pode te ajudar a tirar dúvidas, basta inserir a área jurídica e digitar a sua pergunta.
      </Text>
      <Box sx={{ mb: 4 }}>
        <Select
          label="Escolha a área jurídica da qual a sua dúvida faz parte:"
          placeholder="Selecione a área jurídica"
          options={menuItems}
          othersOption={{
            label: "Outras",
            input: {
              label: "Digite a área jurídica:",
              placeholder: "Exemplos: Direito Ambiental, Direito Administrativo...",
            },
          }}
          onChange={setArea}
        />
      </Box>
      <TextInput
        label="O que você gostaria de perguntar ao assistente?"
        placeholder="Exemplo: Houve alguma alteração recente na legislação ou no Regimento Interno do STF que possa afetar o prazo para a interposição do Agravo Regimental?"
        value={userQuestion}
        onChange={handleUserQuestionChange}
      />
    </InternalPageStructure>
  );
}
