import { ptBR } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";

import { palette } from "./palette";
import { typography } from "./typography";

export const theme = createTheme(
  {
    palette,
    typography,
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
      },
      MuiSelect: {
        defaultProps: {
          sx: {
            ".MuiSelect-select": {
              boxSizing: "border-box",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: typography.fontFamily,
            fontWeight: typography.body?.fontWeight,
            fontSize: typography.body?.fontSize,
            color: typography.body?.color,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontFamily: typography.fontFamily,
            fontWeight: typography.body?.fontWeight,
            fontSize: typography.body?.fontSize,
            color: typography.body?.color,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            fontFamily: typography.fontFamily,
            fontWeight: typography.body?.fontWeight,
            fontSize: typography.body?.fontSize,
            color: typography.body?.color,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            fontFamily: typography.fontFamily,
            fontWeight: typography.body?.fontWeight,
            fontSize: typography.body?.fontSize,
            color: typography.body?.color,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: palette.common?.backdrop,
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            body3: "p",
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: "400",
            color: "inherit",
            textDecoration: "underline",
            textDecorationColor: "inherit",
            cursor: "pointer",
            ":hover": {
              fontWeight: "700",
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 0,
          },
          main: {
            overflow: "auto",
          },
          row: {
            backgroundColor: palette.background?.paper,
          },
          cell: {
            outline: "0 !important",
            ...typography.body,
          },
          columnHeaders: {
            position: "sticky",
            top: "-16px",
            zIndex: 2,
            backgroundColor: palette.background?.paper,
          },
          columnHeaderTitle: {
            ...typography.bold,
          },
          menuList: {
            padding: 4,
          },
          menuIconButton: {
            padding: 4,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: 16,
            color: palette.text?.primary,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
            backgroundColor: palette.common?.dorian,
            borderRadius: 4,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: palette.common?.lightShade,
          },
        },
      },
    },
  },
  ptBR
);
