import PaywallTrialSvg from "@/assets/svgs/paywallTrial.svg?react";
import PaywallSvg from "@/assets/svgs/paywall.svg?react";
import { DialogModal } from "@/components/DialogModal";
import { useCreditsContext } from "@/contexts/CreditsContext/useCreditsContext";
import React from "react";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";

interface PaywallModalProps {
  open: boolean;
  onClose: () => void;
  onOpenBilling: () => void;
}

export const PaywallModal = ({ open, onClose, onOpenBilling }: PaywallModalProps) => {
  const { isFreemium, isTrial, nextBillingDateFormatted } = useCreditsContext();

  const nextBillingDateElement = <span style={{ whiteSpace: "nowrap" }}>{nextBillingDateFormatted}</span>;

  if (isTrial) return <TrialPaywallModal open={open} onClose={onClose} onOpenBilling={onOpenBilling} />;

  return (
    <DialogModal
      title="Quer mais créditos?"
      subTitle={"SEUS CRÉDITOS ACABARAM"}
      description={
        <>
          Os créditos disponíveis no seu plano atual chegaram ao fim, e sua próxima recarga acontece em:{" "}
          {nextBillingDateElement}.<br />
          Você pode contratar um plano com mais créditos para seguir utilizando o assistente Lexter agora, sem esperas.
        </>
      }
      open={open}
      onClose={onClose}
      buttons={[
        {
          label: !isFreemium ? "Contratar novo plano" : "Contrate Agora",
          onClick: onOpenBilling,
        },
      ]}
      removeLeftBorder={true}
      buttonsDirection="column"
      icon={<PaywallSvg />}
    />
  );
};

export const TrialPaywallModal = ({ open, onClose, onOpenBilling }: PaywallModalProps) => {
  const { isActivePlan } = useCreditsContext();

  return (
    <DialogModal
      title="Não fique sem usar"
      description={
        <p>
          {isActivePlan ? (
            <span>Os créditos que você tinha disponível no seu período de testes acabaram.</span>
          ) : (
            <span>Seu período de testes de 7 dias chegou ao fim.</span>
          )}
          <br />
          Você pode contratar um novo plano com mais créditos para seguir utilizando o assistente Lexter agora, sem
          esperas.
        </p>
      }
      open={open}
      onClose={onClose}
      buttons={[
        {
          label: "Contrate Agora",
          onClick: onOpenBilling,
        },
      ]}
      buttonsDirection="column"
      removeLeftBorder={true}
      icon={outsideOfficeClient() && <PaywallTrialSvg />}
    />
  );
};
