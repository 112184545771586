import { Result } from "./Result";
import { ValueObject } from "./ValueObject";

interface CNPJProps {
  value: string;
}

/**
 * Represents CNPJ
 *
 * CNPJ is a brazilian registration number for companies used to uniquely identify
 * a company and its branches
 */
export class CNPJ extends ValueObject<CNPJProps> {
  private constructor(props: CNPJProps) {
    super(props);
  }

  /**
   * Format the CNPJ for the internal representation
   * @param cnpj valid cnpj string
   */
  private static format(cnpj: string): string {
    return cnpj.replace(/\D/g, "");
  }

  /**
   * Calculate the first verification digit based on the main part of the CNPJ
   *
   * The algorithm to be applied was obtained in: http://www.macoratti.net/alg_cnpj.htm
   *
   * The follow algorithm is used to calculate the first verification digit:
   *   1. Multiply each digit by its corresponding value in the array
   *      [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2].
   *   2. Sum the multiplication results
   *   3. Get the remainder of the integer division by 11
   *   4. If the rest of the division is less than 2, the digit is 0
   *   5. Otherwise, subtract the rest of the division from 11
   * @param inscriptionNumber CNPJ inscriptionNumber
   * @param numberOfBranches CNPJ number of branches
   */
  private static calculateFirstVerificationDigit(inscriptionNumber: string, numberOfBranches: string): number {
    const multiplicationNumbers = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const cnpjNumbersArray = `${inscriptionNumber}${numberOfBranches}`.split("").map((n) => parseInt(n, 10));

    const reducedValue = multiplicationNumbers.reduce((acc, currentValue, currentIndex) => {
      const equivalentCNPJNumber = cnpjNumbersArray[currentIndex];
      return acc + currentValue * equivalentCNPJNumber;
    }, 0);

    const remainder = reducedValue % 11;

    if (remainder < 2) {
      return 0;
    } else {
      return 11 - remainder;
    }
  }

  /**
   * Calculate the second verification digit based on the main part of the CNPJ
   *
   * The algorithm to be applied was obtained in: http://www.macoratti.net/alg_cnpj.htm
   *
   * The follow algorithm is used to calculate the second verification digit:
   *   1. Add the first verification digit to the CNPJ main part
   *   2. Multiply each digit by its corresponding value in the array
   *      [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2].
   *   2. Sum the multiplication results
   *   3. Get the remainder of the integer division by 11
   *   4. If the rest of the division is less than 2, the digit is 0
   *   5. Otherwise, subtract the rest of the division from 11
   * @param inscriptionNumber CNPJ inscriptionNumber
   * @param numberOfBranches CNPJ number of branches
   */
  private static calculateSecondVerificationDigit(
    inscriptionNumber: string,
    numberOfBranches: string,
    firstVerificationDigit: number
  ): number {
    const multiplicationNumbers = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const cnpjNumbersArray = `${inscriptionNumber}${numberOfBranches}${firstVerificationDigit}`
      .split("")
      .map((n) => parseInt(n, 10));

    const reducedValue = multiplicationNumbers.reduce((acc, currentValue, currentIndex) => {
      const equivalentCNPJNumber = cnpjNumbersArray[currentIndex];
      return acc + currentValue * equivalentCNPJNumber;
    }, 0);

    const remainder = reducedValue % 11;

    if (remainder < 2) {
      return 0;
    } else {
      return 11 - remainder;
    }
  }

  /**
   * Validate a CNPJ string
   * @param cnpj a string that may be a CNPJ
   */
  private static isValid(cnpjRaw: string): boolean {
    try {
      //Guarantee that we will have always a digits only string
      const cnpj = cnpjRaw.replace(/\D/g, "");

      const hasCorrectLength = /^\d{14}$/.test(cnpj);
      if (!hasCorrectLength) {
        return false;
      }

      //Remove known invalid CNPJ that would pass the verification digit test
      if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      ) {
        return false;
      }

      //Separate the CNPJ in its components
      const inscriptionNumber = cnpj.substr(0, 8);
      const numberOfBranches = cnpj.substr(8, 4);
      const verificationDigits = cnpj.substr(12, 2);

      //Pass the CNPJ through the verification digit algorithm
      const firstVerificationDigit = this.calculateFirstVerificationDigit(inscriptionNumber, numberOfBranches);

      const secondVerificationDigit = this.calculateSecondVerificationDigit(
        inscriptionNumber,
        numberOfBranches,
        firstVerificationDigit
      );

      const calculatedVerificationDigits = `${firstVerificationDigit}${secondVerificationDigit}`;

      return verificationDigits === calculatedVerificationDigits;
    } catch (e) {
      return false;
    }
  }

  /**
   * Creates a new CNPJ instance
   * @param cnpj CNPJ string representation
   */
  static fromString(cnpj: string): Result<CNPJ> {
    if (!CNPJ.isValid(cnpj)) {
      return Result.fail<CNPJ>("Invalid CNPJ");
    }
    return Result.ok<CNPJ>(new CNPJ({ value: this.format(cnpj) }));
  }

  /**
   * Transform current CNPJ to the persistance format
   */
  toPersistence(): string {
    return this.props.value;
  }

  /**
   * Transform current CNPJ to a user friendly format
   */
  toString(): string {
    const cnpj = this.props.value;
    const p1 = cnpj.substr(0, 2);
    const p2 = cnpj.substr(2, 3);
    const p3 = cnpj.substr(5, 3);
    const p4 = cnpj.substr(8, 4);
    const p5 = cnpj.substr(12, 2);
    return `${p1}.${p2}.${p3}/${p4}-${p5}`;
  }
}
