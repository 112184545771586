import { buildSkillStep } from "@/core/skillSteps/builder";

export const interviewScriptSkillStep1 = buildSkillStep({
  title: "Roteiro de entrevista com o cliente - Passo 1",
  description:
    "O Assistente Lexter pode te ajudar a elaborar questões para a entrevista com o seu cliente, baseado no contexto prévio que você já possui sobre o caso.",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Qual é o nome do cliente?",
        placeholder: "Exemplo: João da Silva. Se tiver mais de um nome, separe com vírgulas.",
      },
    },
  ],
});
