import { useFlags, useLDClientError } from "launchdarkly-react-client-sdk";
import { defaultFlagValues } from "./defaultFlagValues";
import { Flags } from "./types";

export const useFeatureFlags = () => {
  const flags = useFlags<Flags>();
  const ldClientError = useLDClientError();

  return ldClientError ? defaultFlagValues : flags;
};
