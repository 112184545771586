import { ValueObject } from "@/core/ValueObject";
import { Result } from "@/core/Result";

const hourInSeconds = 60 * 60;
const minuteInSeconds = 60;
const hoursToSeconds = (hours: number): number => hours * hourInSeconds;
const minutesToSeconds = (min: number): number => min * minuteInSeconds;

function paddingZero(n: number): string {
  const str = n.toString();
  if (str.length === 1) return `0${str}`;
  return str;
}

export interface DurationProps {
  hours: number;
  minutes: number;
  seconds: number;
}

/**
 * Represents a time duration
 */
export class Duration extends ValueObject<DurationProps> {
  /**
   * Transform duration to a string
   *
   * @returns string the duration string in the format HH:MM:SS, we may have
   * more than two digits in the hours field
   */
  public toString(): string {
    const hours = paddingZero(this.props.hours);
    const minutes = paddingZero(this.props.minutes);
    const seconds = paddingZero(this.props.seconds);
    return `${hours}:${minutes}:${seconds}`;
  }

  /**
   * Transform duration to a user presentable format
   */
  public toDisplay(): string {
    const hours = paddingZero(this.props.hours);
    const minutes = paddingZero(this.props.minutes);
    return `${hours}h ${minutes}m`;
  }

  /**
   * Transforms a duration to seconds
   *
   * @return number of seconds represented by current duration
   */
  public toSeconds(): number {
    return hoursToSeconds(this.props.hours) + minutesToSeconds(this.props.minutes) + this.props.seconds;
  }

  /**
   * Transforms a duration to seconds
   *
   * @return number of seconds represented by current duration
   */
  public toDays(): number {
    const seconds = this.toSeconds();
    return seconds / (24 * 60 * 60);
  }

  private constructor(props: DurationProps) {
    super(props);
  }

  /**
   * Create empty duration
   */
  static empty(): Duration {
    return new Duration({
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  }

  /**
   * Create a duration from seconds
   * @param durationInSeconds number of seconds of the duration, will be round
   * down if it is not an integer
   */
  static fromSeconds(durationInSeconds: number): Result<Duration> {
    if (typeof durationInSeconds !== "number" || durationInSeconds < 0) {
      return Result.fail<Duration>("Seconds input should be a positive number");
    }

    const hours = Math.floor(durationInSeconds / hourInSeconds);
    const remainingTime = durationInSeconds % hourInSeconds;
    const minutes = Math.floor(remainingTime / minuteInSeconds);
    const seconds = Math.floor(remainingTime % minuteInSeconds);

    return Result.ok<Duration>(
      new Duration({
        hours,
        minutes,
        seconds,
      })
    );
  }

  /**
   * Create a duration from hours
   * @param durationInHours number of hours of the duration, seconds will be
   * rounded down if they are not integer.
   */
  static fromHours(durationInHours: number): Result<Duration> {
    if (typeof durationInHours !== "number" || durationInHours < 0) {
      return Result.fail<Duration>("Hours input should be a positive number");
    }

    const hours = Math.floor(durationInHours);

    const remainingTimeInSeconds = (durationInHours - hours) * 3600;
    const minutes = Math.floor(remainingTimeInSeconds / minuteInSeconds);
    const seconds = Math.floor(remainingTimeInSeconds % minuteInSeconds);

    return Result.ok<Duration>(
      new Duration({
        hours,
        minutes,
        seconds,
      })
    );
  }

  /**
   * Create a duration from days
   * @param durationInHours number of days of the duration, seconds will be
   * rounded down if they are not integer.
   */
  static fromDays(durationInDays: number): Result<Duration> {
    if (typeof durationInDays !== "number" || durationInDays < 0) {
      return Result.fail<Duration>("Days input should be a positive number");
    }

    return this.fromHours(durationInDays * 24);
  }

  private static isValidString(str: string): boolean {
    if (typeof str !== "string") return false;
    return /^\d{2,}:\d{2}:\d{2}$/.test(str);
  }

  /**
   * Create a duration from a sting
   * @param durationString A duration string, with the format HH:MM:SS. Hours
   * may have more than 2 digits. Minutes and seconds should always have exactly
   * 2 digits.
   */
  static fromString(durationString: string): Result<Duration> {
    if (!this.isValidString(durationString)) {
      return Result.fail<Duration>("Invalid string input");
    }

    const [hours, minutes, seconds] = durationString.split(":").map((str) => parseInt(str, 10));

    return Result.ok<Duration>(
      new Duration({
        hours,
        minutes,
        seconds,
      })
    );
  }

  /**
   * Verify is a Duration is empty, that is, it is equal to 00:00:00
   */
  public isEmpty(): boolean {
    return this.props.hours === 0 && this.props.minutes === 0 && this.props.seconds === 0;
  }
}
