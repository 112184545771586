import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React from "react";
import { Area, LegalPieceMacroType, LegalPieceMicroType } from "@/contexts/MessagesContext";

interface DocumentTypeSelectionProps {
  documentMacroType: LegalPieceMacroType;
  selectedArea: Area | string;
  documentType: LegalPieceMicroType | string;
  onDocumentTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  documentName: string;
  onDocumentNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  show: boolean;
}

const placeholders: Record<string, Record<string, string>> = {
  [LegalPieceMacroType.INAUGURAL_DOCUMENT]: {
    [Area.CIVIL]:
      "Exemplos: Ação Monitória, Mandado de Segurança, Abertura de Inventário, Protesto Interruptivo de Prescrição...",
    [Area.LABOR]: "Exemplos: Dissídio Coletivo, Inquérito para Apuração de Falta Grave, Mandado de Segurança...",
    [Area.OTHER]: "Exemplos: Mandado de Segurança, Ação Rescisória, Ação Direta de Inconstitucionalidade...",
  },
  [LegalPieceMacroType.RESPONSE]: {
    [Area.CIVIL]: "Exemplos: Contrarrazões de Apelação, Embargos à ação monitória, Defesa Administrativa...",
    [Area.LABOR]: "Exemplos: Contrarrazões de Apelação, Embargos à ação monitória, Defesa Administrativa...",
    [Area.OTHER]: "Exemplos: Mandado de Segurança, Ação Rescisória, Ação Direta de Inconstitucionalidade...",
  },
  [LegalPieceMacroType.APPEAL]: {
    [Area.CIVIL]: "Exemplos: Recurso Especial, Recurso Inominado, Recurso Extraordinário...",
    [Area.LABOR]: "Exemplos: Recurso Especial, Recurso Inominado, Recurso Extraordinário...",
  },
};

const options: Record<string, Record<string, { value: string; label: string; info?: string }[]>> = {
  [LegalPieceMacroType.INAUGURAL_DOCUMENT]: {
    [Area.CIVIL]: [
      {
        value: LegalPieceMicroType.INITIAL,
        label: "Petição Inicial Geral",
        info: "(Ex.: Ação de obrigação de fazer, ação de indenização por danos materiais...)",
      },
      { value: LegalPieceMicroType.OTHER, label: "Outros", info: "(Peças específicas)" },
    ],
    [Area.LABOR]: [
      { value: LegalPieceMicroType.INITIAL, label: "Reclamação Trabalhista" },
      { value: LegalPieceMicroType.OTHER, label: "Outros", info: "(Peças específicas)" },
    ],
  },
  [LegalPieceMacroType.RESPONSE]: {
    [Area.CIVIL]: [
      { value: LegalPieceMicroType.CONTESTATION, label: "Contestação" },
      { value: LegalPieceMicroType.REPLICATION, label: "Réplica" },
      { value: LegalPieceMicroType.OTHER, label: "Outros" },
    ],
    [Area.LABOR]: [
      { value: LegalPieceMicroType.CONTESTATION, label: "Contestação" },
      { value: LegalPieceMicroType.REPLICATION, label: "Réplica" },
      { value: LegalPieceMicroType.OTHER, label: "Outros" },
    ],
  },
  [LegalPieceMacroType.APPEAL]: {
    [Area.CIVIL]: [
      { value: LegalPieceMicroType.APPEAL, label: "Apelação" },
      { value: LegalPieceMicroType.OTHER, label: "Outros" },
    ],
    [Area.LABOR]: [
      { value: LegalPieceMicroType.ORDINARY_APPEAL, label: "Recurso ordinário" },
      { value: LegalPieceMicroType.OTHER, label: "Outros" },
    ],
  },
};

export const DocumentTypeSelection = ({
  documentMacroType,
  selectedArea,
  documentType,
  onDocumentTypeChange,
  documentName,
  onDocumentNameChange,
  show,
}: DocumentTypeSelectionProps) => {
  return (
    <Box hidden={!show}>
      {selectedArea != Area.OTHER && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="preTitle">Tipo de peça:</Typography>
          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset">
              <RadioGroup value={documentType} onChange={onDocumentTypeChange}>
                {options[documentMacroType]?.[selectedArea]?.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={
                      <Radio
                        inputProps={{
                          id: `input_document_type_${option.value}`,
                        }}
                      />
                    }
                    label={
                      <>
                        {option.label}
                        {option.info && <span className="info">{option.info}</span>}
                      </>
                    }
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      "& .info": {
                        marginLeft: 1,
                        color: "text.secondary",
                      },
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      )}
      {(selectedArea === Area.OTHER || documentType === LegalPieceMicroType.OTHER) && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="preTitle">Qual é o nome da peça?</Typography>
          <TextField
            value={documentName}
            onChange={onDocumentNameChange}
            placeholder={placeholders[documentMacroType]?.[selectedArea]}
            multiline
            fullWidth
            sx={{ mt: 3 }}
          />
        </Box>
      )}
    </Box>
  );
};
