import { actionCreditMap, ShortCutAction, Area, DecisionType, LegalPieceMicroType } from "@/contexts/MessagesContext";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { Flags } from "@/hooks/useFeatureFlags/types";
import { Skill } from "@/core/skills/types";
import { FavoriteSkill } from "@/hooks/skills/types";

export type Tag = "litigation" | "consultative" | "recommend" | "new" | "mostUsed";

export interface ActionProps {
  id: string;
  name: string;
  redirectTo: {
    path: string;
    skillId?: string;
  };
  credits: CreditPerSkill;
  tooltip?: string;
  tags: Array<Tag>;
  hidden?: boolean;
  favorite: boolean;
  disabled?: boolean;
}

enum CreditPerSkill {
  ESSENTIAL_SKILL = 20,
  BASIC_SKILL = 50,
  INTERMEDIARY_SKILL = 100,
  ADVANCED_SKILL = 200,
}

function skillToActionButton({
  skill,
  favoriteSkills,
}: {
  skill: Skill;
  favoriteSkills: FavoriteSkill[];
}): ActionProps {
  return {
    id: skill.id,
    name: skill.name,
    redirectTo: {
      path: skillRoute(skill.id),
    },
    credits: skill.credits,
    tags: (skill.tags ?? []) as Tag[],
    favorite: favoriteSkills.some((favoriteSkill) => favoriteSkill.skillId === skill.id),
    disabled: skill.disabled,
  } satisfies ActionProps;
}

const skillRoute = (skillId: string) => `${ROUTE_PATHS.CHAT}?skill=${skillId}`;

export function buildActionsButtons({
  flags,
  favoriteSkills,
  skills,
}: {
  flags: Flags;
  favoriteSkills: FavoriteSkill[];
  skills?: Skill[];
}): ActionProps[] {
  const actionButtons: ActionProps[] = [
    {
      id: ShortCutAction.CREATE_NEW_DOCUMENT,
      name: "Escrever peça judicial",
      tooltip:
        "Selecione o tipo de peça (Inaugural para começar o processo ou incidente, Resposta para responder a petição ou recurso da parte contrária ou Recurso para recorrer de uma decisão judicial), em seguida selecione a área jurídica para elaborar a sua peça.",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_NEW_DOCUMENT),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_NEW_DOCUMENT],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_NEW_DOCUMENT),
      tags: ["recommend", "litigation"],
    },
    {
      id: ShortCutAction.CREATE_INTERVIEW_SCRIPT_ACTION,
      name: "Elaborar Roteiro de entrevista",
      tooltip:
        "Desenvolva rapidamente um roteiro de entrevistas para diversas áreas jurídicas garantindo um melhor entendimento do caso de seus clientes.",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_INTERVIEW_SCRIPT_ACTION),
      },
      disabled: !flags.interviewScriptEnabled,
      credits: actionCreditMap[ShortCutAction.CREATE_INTERVIEW_SCRIPT_ACTION],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_INTERVIEW_SCRIPT_ACTION),
      tags: ["litigation", "consultative", "new"],
    },
    {
      id: ShortCutAction.SUMMARIZE_DOCUMENT,
      name: "Resumir documento processual",
      redirectTo: { path: ROUTE_PATHS.PETITION_SUMMARY },
      tooltip:
        "Gere resumo de documentos processuais em poucos minutos, facilitando o entendimento de casos e o envio de informações para seus clientes.",
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.SUMMARIZE_DOCUMENT),
      credits: actionCreditMap[ShortCutAction.SUMMARIZE_DOCUMENT],
      tags: ["litigation"],
    },
    {
      id: ShortCutAction.CREATE_INITIAL_PETITION,
      name: "Escrever Petição Inicial",
      tooltip: "Elabore petições iniciais de casos cíveis.",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_INITIAL_PETITION),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_INITIAL_PETITION],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_INITIAL_PETITION),
      tags: ["litigation"],
    },
    {
      id: ShortCutAction.CREATE_LABOR_COMPLAINT,
      name: "Escrever Reclamação Trabalhista",
      tooltip: "Crie reclamações trabalhistas utilizando as informações passadas pelo seu cliente.",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_LABOR_COMPLAINT),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_LABOR_COMPLAINT],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_LABOR_COMPLAINT),
      tags: ["litigation"],
    },
    {
      id: ShortCutAction.CREATE_CONTRACT,
      name: "Elaborar contrato",
      tooltip:
        "Reduza o tempo gasto na confecção de contratos jurídicos em até 50% do tempo utilizando o Assistente Lexter.",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_CONTRACT),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_CONTRACT],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_CONTRACT),
      tags: ["consultative", "new"],
      disabled: !flags.contractCreationEnabled,
    },
    {
      id: ShortCutAction.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT,
      name: "Sugestor de provas",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT],
      hidden: true,
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT),
      tags: ["new"],
      disabled: !flags.createEvidenceAndRequiredDocumentsEnabled,
    },
    {
      id: ShortCutAction.CREATE_NOTICE_ACTION,
      name: "Elaborar Notificação Extrajudicial",
      tooltip: "Crie notificações extrajudiciais em poucos minutos utilizando as informações do caso.",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_NOTICE_ACTION),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_NOTICE_ACTION],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_NOTICE_ACTION),
      tags: ["consultative", "new"],
      disabled: !flags.extrajudicialNoticeEnabled,
    },
    {
      id: ShortCutAction.SEARCH_JURISPRUDENCE,
      name: "Buscar jurisprudência",
      redirectTo: {
        path: skillRoute(ShortCutAction.SEARCH_JURISPRUDENCE),
      },
      credits: actionCreditMap[ShortCutAction.SEARCH_JURISPRUDENCE],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.SEARCH_JURISPRUDENCE),
      tags: ["new"],
      disabled: !flags.jurisprudenceSearch,
    },
    {
      id: ShortCutAction.CREATE_CONTESTATION,
      name: "Escrever Contestação Cível",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_CONTESTATION),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_CONTESTATION],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_CONTESTATION),
      tags: ["litigation"],
    },
    {
      id: ShortCutAction.CREATE_LABOR_CONTESTATION,
      name: "Escrever Contestação Trabalhista",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_LABOR_CONTESTATION),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_LABOR_CONTESTATION],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_LABOR_CONTESTATION),
      tags: ["litigation"],
    },
    {
      id: ShortCutAction.CREATE_REPLICATION,
      name: "Escrever Réplica Cível",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_REPLICATION),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_REPLICATION],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_REPLICATION),
      tags: ["litigation"],
    },
    {
      id: ShortCutAction.CREATE_LABOR_PLAINTIFF_REPLY,
      name: "Escrever Réplica Trabalhista",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_LABOR_PLAINTIFF_REPLY),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_LABOR_PLAINTIFF_REPLY],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_LABOR_PLAINTIFF_REPLY),
      tags: ["litigation"],
    },
    {
      id: ShortCutAction.CREATE_APPEAL,
      name: "Escrever Apelação",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_APPEAL),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_APPEAL],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_APPEAL),
      tags: ["litigation"],
    },
    {
      id: ShortCutAction.CREATE_ORDINARY_APPEAL,
      name: "Escrever Recurso Ordinário Trabalhista",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_ORDINARY_APPEAL),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_ORDINARY_APPEAL],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_ORDINARY_APPEAL),
      tags: ["litigation"],
    },
    {
      id: ShortCutAction.LEGAL_QUESTION,
      name: "Dúvida jurídica",
      redirectTo: {
        path: skillRoute(ShortCutAction.LEGAL_QUESTION),
      },
      credits: actionCreditMap[ShortCutAction.LEGAL_QUESTION],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.LEGAL_QUESTION),
      tags: ["litigation", "consultative", "new"],
      disabled: !flags.legalQuestionEnabled,
    },
    {
      id: ShortCutAction.CREATE_STRATEGY,
      name: "Estratégia Jurídica",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_STRATEGY),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_STRATEGY],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_STRATEGY),
      tags: ["litigation", "new"],
      disabled: !flags.createStrategyEnabled,
    },
    {
      id: ShortCutAction.HEARINGS_SCRIPT,
      name: "Roteiro para audiências",
      redirectTo: {
        path: skillRoute(ShortCutAction.HEARINGS_SCRIPT),
      },
      credits: actionCreditMap[ShortCutAction.HEARINGS_SCRIPT],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.HEARINGS_SCRIPT),
      tags: ["litigation", "new"],
      disabled: !flags.hearingsScriptEnabled,
    },
    {
      id: ShortCutAction.FEE_AGREEMENT,
      name: "Contrato de honorários",
      redirectTo: {
        path: skillRoute(ShortCutAction.FEE_AGREEMENT),
      },
      credits: actionCreditMap[ShortCutAction.FEE_AGREEMENT],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.FEE_AGREEMENT),
      tags: ["consultative", "new"],
      disabled: !flags.feeAgreementEnabled,
    },
    {
      id: ShortCutAction.INTERCURRENT_MOTION,
      name: "Petição intercorrente",
      redirectTo: {
        path: skillRoute(ShortCutAction.INTERCURRENT_MOTION),
      },
      credits: actionCreditMap[ShortCutAction.INTERCURRENT_MOTION],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.INTERCURRENT_MOTION),
      tags: ["litigation", "new"],
      disabled: !flags.intercurrentMotionEnabled,
    },
    {
      id: ShortCutAction.LEGAL_ADVICE,
      name: "Elaborar parecer jurídico",
      redirectTo: {
        path: skillRoute(ShortCutAction.LEGAL_ADVICE),
      },
      credits: actionCreditMap[ShortCutAction.LEGAL_ADVICE],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.LEGAL_ADVICE),
      tags: ["consultative", "new"],
      disabled: !flags.legalAdviceEnabled,
    },
    {
      id: ShortCutAction.SETTLEMENT_OFFER,
      name: "Elaborar proposta de acordo",
      redirectTo: {
        path: skillRoute(ShortCutAction.SETTLEMENT_OFFER),
      },
      credits: actionCreditMap[ShortCutAction.SETTLEMENT_OFFER],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.SETTLEMENT_OFFER),
      tags: ["litigation", "new"],
      disabled: !flags.settlementOfferEnabled,
    },
    {
      id: ShortCutAction.USE_CHAT,
      name: "Usar o chat",
      redirectTo: {
        path: skillRoute(ShortCutAction.USE_CHAT),
      },
      credits: actionCreditMap[ShortCutAction.USE_CHAT],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.USE_CHAT),
      tags: ["litigation", "consultative"],
    },
    {
      id: ShortCutAction.CREATE_CIVIL_REPLY,
      name: "Escrever Réplica Cível",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_CIVIL_REPLY),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_CIVIL_REPLY],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_CIVIL_REPLY),
      tags: ["mostUsed"],
    },
    {
      id: ShortCutAction.CREATE_CIVIL_INITIAL_PETITION,
      name: "Escrever Petição Inicial Cível",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_CIVIL_INITIAL_PETITION),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_CIVIL_INITIAL_PETITION],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_CIVIL_INITIAL_PETITION),
      tags: ["mostUsed"],
    },
    {
      id: ShortCutAction.CREATE_CIVIL_APPEAL,
      name: "Escrever Apelação Cível",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_CIVIL_APPEAL),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_CIVIL_APPEAL],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_CIVIL_APPEAL),
      tags: ["mostUsed"],
    },
    {
      id: ShortCutAction.CREATE_CRIMINAL_APPEAL,
      name: "Escrever Apelação Criminal",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_CRIMINAL_APPEAL),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_CRIMINAL_APPEAL],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_CRIMINAL_APPEAL),
      tags: ["mostUsed"],
    },
    {
      id: ShortCutAction.CREATE_LABOR_APPEAL,
      name: "Escrever Apelação Trabalhista",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_LABOR_APPEAL),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_LABOR_APPEAL],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_LABOR_APPEAL),
      tags: ["mostUsed"],
    },
    {
      id: ShortCutAction.CREATE_CRIMINAL_REPLY,
      name: "Escrever Réplica Criminal",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_CRIMINAL_REPLY),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_CRIMINAL_REPLY],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_CRIMINAL_REPLY),
      tags: ["mostUsed"],
    },
    {
      id: ShortCutAction.CREATE_CRIMINAL_INITIAL_PETITION,
      name: "Escrever Petição Inicial Criminal",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_CRIMINAL_INITIAL_PETITION),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_CRIMINAL_INITIAL_PETITION],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_CRIMINAL_INITIAL_PETITION),
      tags: ["mostUsed"],
    },
    {
      id: ShortCutAction.CREATE_SOCIAL_SECURITY_INITIAL_PETITION,
      name: "Escrever Petição Inicial Previdenciária",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_SOCIAL_SECURITY_INITIAL_PETITION),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_SOCIAL_SECURITY_INITIAL_PETITION],
      favorite: favoriteSkills.some(
        (skill) => skill.skillId === ShortCutAction.CREATE_SOCIAL_SECURITY_INITIAL_PETITION
      ),
      tags: ["mostUsed"],
    },
    {
      id: ShortCutAction.CREATE_LABOR_INITIAL_PETITION,
      name: "Escrever Petição Inicial Trabalhista",
      redirectTo: {
        path: skillRoute(ShortCutAction.CREATE_LABOR_INITIAL_PETITION),
      },
      credits: actionCreditMap[ShortCutAction.CREATE_LABOR_INITIAL_PETITION],
      favorite: favoriteSkills.some((skill) => skill.skillId === ShortCutAction.CREATE_LABOR_INITIAL_PETITION),
      tags: ["mostUsed"],
    },
  ];

  const skillButtons = skills?.map((skill) => skillToActionButton({ skill, favoriteSkills })) || [];

  const allActionIds = Array.from(new Set([...actionButtons, ...skillButtons].map((button) => button.id)));

  const allActionButtons = allActionIds.map((actionId) => {
    const skillButton = skillButtons.find((skillButton) => skillButton.id === actionId);
    const actionButton = actionButtons.find((actionButton) => actionButton.id === actionId);

    if (skillButton) {
      if (actionButton && skillButton.disabled) {
        return actionButton;
      }

      return skillButton;
    }

    return actionButton!;
  });

  return allActionButtons.sort((a, b) => {
    if (a.favorite && !b.favorite) return -1;
    else if (!a.favorite && b.favorite) return 1;
    else if (a.favorite && b.favorite) {
      const aCreatedAt = favoriteSkills.find((action) => action.skillId === a.id)?.createdAt ?? "";
      const bCreatedAt = favoriteSkills.find((action) => action.skillId === a.id)?.createdAt ?? "";
      return aCreatedAt > bCreatedAt ? -1 : 1;
    }
    return 0;
  }) as ActionProps[];
}

export const motionsType = [
  "Ação de Anulação de Débito Fiscal",
  "Ação Declaratória",
  "Ação de Concessão de Benefício Previdenciário",
  "Contrarrazões de Apelação",
  "Embargos à Ação Monitória",
  "Defesa Administrativa",
  "Recurso Especial",
  "Recurso em Sentido Estrito",
  "Recurso Inonimado",
  "Agravo de Instrumento",
  "Recurso",
  "Ação Monitória",
  "Mandado de Segurança",
  "Abertura de Inventário",
  "Dissídio Coletivo",
  "Contestação",
  "Réplica",
  "Apelação",
  "Contestação Trabalhista",
  "Réplica Trabalhista",
  "Recurso Ordinário Trabalhista",
  "Resposta",
];

type ActionsState = {
  selectedArea: Area;
  documentType: LegalPieceMicroType;
  decisionType?: DecisionType;
  currentStep: number;
};

export const actionsStateMap: Partial<Record<ShortCutAction, ActionsState>> = {
  [ShortCutAction.CREATE_LABOR_COMPLAINT]: {
    selectedArea: Area.LABOR,
    documentType: LegalPieceMicroType.INITIAL,
    currentStep: 2,
  },
  [ShortCutAction.CREATE_INITIAL_PETITION]: {
    selectedArea: Area.CIVIL,
    documentType: LegalPieceMicroType.INITIAL,
    currentStep: 2,
  },
  [ShortCutAction.CREATE_CONTESTATION]: {
    selectedArea: Area.CIVIL,
    documentType: LegalPieceMicroType.CONTESTATION,
    currentStep: 2,
  },
  [ShortCutAction.CREATE_LABOR_CONTESTATION]: {
    selectedArea: Area.LABOR,
    documentType: LegalPieceMicroType.CONTESTATION,
    currentStep: 2,
  },
  [ShortCutAction.CREATE_REPLICATION]: {
    selectedArea: Area.CIVIL,
    documentType: LegalPieceMicroType.REPLICATION,
    currentStep: 2,
  },
  [ShortCutAction.CREATE_LABOR_PLAINTIFF_REPLY]: {
    selectedArea: Area.LABOR,
    documentType: LegalPieceMicroType.REPLICATION,
    currentStep: 2,
  },
  [ShortCutAction.CREATE_APPEAL]: {
    selectedArea: Area.CIVIL,
    documentType: LegalPieceMicroType.APPEAL,
    decisionType: DecisionType.TERMINATIVE_DECISIONS,
    currentStep: 3,
  },
  [ShortCutAction.CREATE_ORDINARY_APPEAL]: {
    selectedArea: Area.LABOR,
    documentType: LegalPieceMicroType.ORDINARY_APPEAL,
    decisionType: DecisionType.TERMINATIVE_DECISIONS,
    currentStep: 3,
  },
  [ShortCutAction.CREATE_SOCIAL_SECURITY_INITIAL_PETITION]: {
    selectedArea: Area.SOCIAL_SECURITY,
    documentType: LegalPieceMicroType.INITIAL,
    currentStep: 0,
  },
  [ShortCutAction.CREATE_CRIMINAL_INITIAL_PETITION]: {
    selectedArea: Area.CRIMINAL,
    documentType: LegalPieceMicroType.INITIAL,
    currentStep: 0,
  },
  [ShortCutAction.CREATE_CRIMINAL_APPEAL]: {
    selectedArea: Area.CRIMINAL,
    documentType: LegalPieceMicroType.APPEAL,
    currentStep: 0,
  },
  [ShortCutAction.CREATE_CRIMINAL_REPLY]: {
    selectedArea: Area.CRIMINAL,
    documentType: LegalPieceMicroType.REPLICATION,
    currentStep: 0,
  },
  [ShortCutAction.CREATE_LABOR_INITIAL_PETITION]: {
    selectedArea: Area.LABOR,
    documentType: LegalPieceMicroType.INITIAL,
    currentStep: 1,
  },
  [ShortCutAction.CREATE_LABOR_APPEAL]: {
    selectedArea: Area.LABOR,
    documentType: LegalPieceMicroType.APPEAL,
    currentStep: 1,
  },
  [ShortCutAction.CREATE_CIVIL_INITIAL_PETITION]: {
    selectedArea: Area.CIVIL,
    documentType: LegalPieceMicroType.INITIAL,
    currentStep: 0,
  },
  [ShortCutAction.CREATE_CIVIL_REPLY]: {
    selectedArea: Area.CIVIL,
    documentType: LegalPieceMicroType.REPLICATION,
    currentStep: 2,
  },
  [ShortCutAction.CREATE_CIVIL_APPEAL]: {
    selectedArea: Area.CIVIL,
    documentType: LegalPieceMicroType.APPEAL,
    currentStep: 1,
  },
};
