import { Box } from "@mui/material";
import React, { ReactNode } from "react";

interface FormProps {
  children?: ReactNode;
  show?: boolean;
}

export const Form = ({ children, show = true }: FormProps) => {
  return (
    <Box hidden={!show}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
