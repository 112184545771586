import React from "react";

import { Box, Button, IconButton, InputAdornment, MenuItem, Popover, TextField, Typography } from "@mui/material";
import {
  AddCircleOutline,
  ImportExport as ImportExportIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import { OrderThreadsBy, OrderThreadsOptions } from "@/contexts/ThreadsContext/types";

export const ThreadsHeader = ({
  goToNewChat,
  orderThreadsOptions,
  currentListOrder,
  updateOrder,
  currentQuerySearch,
  handleSearch,
  setCurrentQuerySearch,
}: {
  goToNewChat: () => void;
  orderThreadsOptions: OrderThreadsOptions;
  currentListOrder: OrderThreadsBy;
  updateOrder: (order: OrderThreadsBy) => void;
  currentQuerySearch: string | null;
  setCurrentQuerySearch: (query: string) => void;
  handleSearch: (query: string) => void;
}) => {
  const [orderMenuAnchorEl, setOrderMenuAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const [query, setQuery] = React.useState<string>(currentQuerySearch || "");
  const isOrderMenuOpen = Boolean(orderMenuAnchorEl);

  const orderOptions = Object.keys(orderThreadsOptions) as OrderThreadsBy[];

  const handleUpdateOrder = (orderOption: OrderThreadsBy) => {
    updateOrder(orderOption);
    setOrderMenuAnchorEl(null);
  };

  const closeOrderMenu = () => {
    setOrderMenuAnchorEl(null);
  };

  const searchQuery = async (text: string = query) => {
    handleSearch(text);
    setCurrentQuerySearch(text);
  };

  const clearQuery = () => {
    setQuery("");
    searchQuery("");
  };

  const OrderMenu = () => {
    return (
      <Popover
        onClose={closeOrderMenu}
        open={isOrderMenuOpen}
        anchorEl={orderMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "6px !important",
          "& .MuiPaper-root": {
            width: "332px",
          },
          "& .MuiModal-backdrop": {
            backgroundColor: "transparent",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        {orderOptions.map((orderOption, index) => {
          return (
            <MenuItem key={index} onClick={() => handleUpdateOrder(orderOption)}>
              <Typography variant="body1">{orderThreadsOptions[orderOption]}</Typography>
            </MenuItem>
          );
        })}
      </Popover>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Button
          onClick={goToNewChat}
          variant="contained"
          sx={{ flex: 1, justifyContent: "space-between" }}
          endIcon={<AddCircleOutline />}
        >
          NOVA SESSÃO
        </Button>
        <Box sx={{ flex: 1, width: "100%" }}>
          <Button
            onClick={(event) => {
              setOrderMenuAnchorEl(event.currentTarget);
            }}
            variant="outlined"
            sx={{
              flex: 1,
              width: "100%",
              justifyContent: "space-between",
              color: "primary.main",
              borderColor: "primary.main",
              ":hover": {
                borderColor: "primary.main",
              },
            }}
            endIcon={<ImportExportIcon />}
          >
            <Typography sx={{ display: "flex", gap: 2, textTransform: "none" }} variant="button">
              ORDENAR POR:
              <Typography sx={{ color: "primary.main", textTransform: "none" }} variant="body3">
                {orderThreadsOptions[currentListOrder]}
              </Typography>
            </Typography>
          </Button>
          <OrderMenu />
        </Box>
        <TextField
          hiddenLabel
          sx={{
            "& .MuiInputBase-root": {
              borderColor: "common.lightShade",
              backgroundColor: "common.dorian",
              padding: "0 8px",
              height: "38px",
            },
            "& .MuiInputBase-input": {
              padding: "0",
            },

            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "common.lightShade",
              },
            },
          }}
          focused
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: Boolean(query) ? (
              <InputAdornment position="end">
                <IconButton onClick={clearQuery} size="small" sx={{ color: "primary.dark" }}>
                  <CloseIcon sx={{ fontSize: 16 }} />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          value={query}
          placeholder="Buscar..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchQuery();
            }
          }}
          onBlur={() => {
            searchQuery();
          }}
        />
      </Box>
    </Box>
  );
};
