import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import * as React from "react";
import { v4 as uuidV4 } from "uuid";

import { ActionId, FlowOutput, InMessageFile, Message } from "@/contexts/MessagesContext";
import * as logger from "../../../../core/logger";
import { InternalPageStructure } from "../../core/InternalPageStructure";
import { FileInput, TextInput } from "../FlowInput";

interface Step {
  name: string;
  idx: number;
}

const STEPS: {
  [key: string]: Step;
} = {
  SENTENCE: {
    name: "SENTENCE",
    idx: 0,
  },
  APPELLANT: {
    name: "APPELLANT",
    idx: 1,
  },
};
const TOTAL_STEPS = Object.keys(STEPS).length;

export function LaborOrdinaryAppeal(props: { goBack: () => void; sendMessage: (message: Message) => void }) {
  const { goBack, sendMessage } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [sentenceFile, setSentenceFile] = React.useState<File>();
  const [appellant, setAppellant] = React.useState<FlowOutput | null>(null);
  const [currentStep, setCurrentStep] = React.useState<Step>(STEPS.SENTENCE);

  const dismissError = React.useCallback(() => {
    setError(null);
  }, []);

  const onSentenceDocumentChange = React.useCallback(
    (file?: File) => {
      setSentenceFile(file);
      dismissError();
    },
    [dismissError]
  );

  const onAppellantChange = React.useCallback(
    (input: FlowOutput | null) => {
      setAppellant(input);
      dismissError();
    },
    [dismissError]
  );

  const onSubmit = React.useCallback(() => {
    if (!appellant || !sentenceFile) {
      setError("É preciso submeter todos os dados.");
      return;
    }

    logger.info("Submitting appeal message");

    const actionText = `Criar um recurso ordinário para o arquivo ${sentenceFile.name}`;

    const sentenceId = uuidV4();

    const files: InMessageFile[] = [
      {
        id: sentenceId,
        type: "UPLOADING",
        file: sentenceFile,
        name: sentenceFile.name,
      },
    ];

    sendMessage({
      id: uuidV4(),
      type: "FLOW",
      direction: "SENT",
      author: "Current User",
      date: DateTime.now(),
      status: "READ",
      text: actionText,
      actions: [
        {
          id: ActionId.CREATE_ORDINARY_APPEAL,
          sentence: {
            source: "FILE",
            file: {
              id: sentenceId,
              name: sentenceFile.name,
              file: sentenceFile,
            },
          },
          text: actionText,
          appellant,
        },
      ],
      files,
    });

    goBack();
  }, [sentenceFile, appellant]);

  const onSubmitHandler = () => {
    switch (currentStep.name) {
      case STEPS.SENTENCE.name:
        setCurrentStep(STEPS.APPELLANT);
        break;
      case STEPS.APPELLANT.name:
        onSubmit();
        break;
      default:
        throw new Error("Invalid step");
    }
  };

  const onGoBackHandler = () => {
    switch (currentStep.name) {
      case STEPS.SENTENCE.name:
        goBack();
        break;
      case STEPS.APPELLANT.name:
        setCurrentStep(STEPS.SENTENCE);
        break;
      default:
        throw new Error("Invalid step");
    }
  };

  const canProceed = () => {
    switch (currentStep.name) {
      case STEPS.SENTENCE.name:
        return !!sentenceFile;
      case STEPS.APPELLANT.name:
        return !!appellant;
      default:
        throw new Error("Invalid step");
    }
  };

  return (
    <InternalPageStructure
      goBack={onGoBackHandler}
      onSubmit={onSubmitHandler}
      error={error}
      dismissError={dismissError}
      goBackButtonText={!currentStep.idx ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep.idx === TOTAL_STEPS - 1 ? "Criar Recurso" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={TOTAL_STEPS}
      currentStep={currentStep.idx}
    >
      <Box>
        <Box sx={{ mb: 1, pb: 1 }}>
          <Typography variant="preTitle" color={"text.primary"}>
            {`CRIAR RECURSO ORDINÁRIO - PASSO ${currentStep.idx + 1}`}
          </Typography>
        </Box>

        <SentenceStep onDocumentChange={onSentenceDocumentChange} show={currentStep.name === STEPS.SENTENCE.name} />

        <AppellantStep onAppellantChange={onAppellantChange} show={currentStep.name === STEPS.APPELLANT.name} />
      </Box>
    </InternalPageStructure>
  );
}

export function SentenceStep({ onDocumentChange, show }: { onDocumentChange: (file?: File) => void; show: boolean }) {
  const handleFileChange = (files: File[]) => {
    onDocumentChange(files[0]);
  };

  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="multiLineBody" color={"text.primary"}>
          Para criar um recurso ordinário, você precisa realizar o envio da sentença a ser recorrida.
        </Typography>
      </Box>

      <FileInput onChange={handleFileChange} />
    </Box>
  );
}

export function AppellantStep({
  onAppellantChange,
  show,
}: {
  onAppellantChange: (input: FlowOutput | null) => void;
  show: boolean;
}) {
  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="multiLineBody" color={"text.primary"}>
          Insira abaixo o nome do(s) recorrente(s):
        </Typography>

        <TextInput
          onChange={onAppellantChange}
          placeholder="Insira o nome do(s) recorrente(s). Se mais de um, separe com vírgula os nomes."
          minHeight={94}
        />
      </Box>
    </Box>
  );
}
