import { PaginationPayload } from "@/hooks/useApi/types";
import { ConnectionState, Evaluation, SkillsPayload } from "@/contexts/WebSocketContext";
import { Line, StylesMap } from "@/hooks/useEditor/types";
import { Claim } from "@/types/claim";
import { DateTime } from "luxon";
import React, { Dispatch, SetStateAction } from "react";
import { CreditPerSkill, SkillId } from "@/core/skills/types";

export interface MessagesContextData {
  currentPage: Page;
  setCurrentPage: (page: Page) => void;
  messages: Message[];
  error: string;
  clearError: () => void;
  loading: boolean;
  sendMessage: (
    message: Message,
    options?: {
      references?: File[];
      claims?: string[];
    }
  ) => void;
  addMessage: (message: Message) => void;
  actions: Action[];
  goToChat: () => void;
  waitingForResponse?: WaitingForResponse;
  uploadingFiles: { [fileId: string]: number };
  clearContext: () => Promise<void>;
  inputContext: string;
  textActions: Action[];
  claims: ClaimWithCheck[];
  createContestationWithClaims: (params: { messageText: string; claimIds: string[] }) => void;
  clearChatAndStartNewSession: () => void;
  cancelOngoingExecution: (executionId?: string) => Promise<void>;
  connectionState: ConnectionState;
  threadConnectionState: ConnectionState;
  connectToThread: (params?: { threadId?: string }) => void;
  closeThreadConnection: () => void;
  inputVisible: boolean;
  setInputVisible: Dispatch<SetStateAction<boolean>>;
  sendCreatePieceMessage: () => void;
  startSkillProcess: (skillPayload: SkillsPayload) => void;
  loadingState: LoadingState;
  totalResults: number;
  refetchMessages: ({ threadId, pagination }: { threadId: string; pagination: PaginationPayload }) => Promise<void>;
  paginationModel: PaginationPayload;
  setPaginationModel: Dispatch<SetStateAction<PaginationPayload>>;
  handleEvaluation: (props: HandleEvaluation) => Promise<void>;
  currentSkillId: string | null;
  startSkill: ({ skillId, actionId }: { skillId: string; actionId: ActionId }) => Promise<void>;
  documentCreationText: string;
}

export type HandleEvaluation = {
  messageId: string;
  newEvaluation?: Evaluation;
  currentEvaluation?: Evaluation;
};

export type MessageType = "TEXT" | "FILE" | "ACTION" | "ERROR" | "FLOW" | "FEEDBACK";

export type MessageDirection = "SENT" | "RECEIVED";

export type MessageStatus = "PENDING" | "SENT" | "DELIVERED" | "READ";

export type Action = {
  id?: string;
  text: string;
  onClick: (message: Message) => unknown;
  disabled?: boolean;
  hidden?: boolean;
};

export type TextMessage = {
  id: string;
  type: "TEXT";
  direction: MessageDirection;
  author: string;
  date: DateTime;
  status: MessageStatus;
  text: string;
  context?: string;
  copyable?: boolean;
  evaluation?: Evaluation;
};

export type UploadingFile = {
  id: string;
  type: "UPLOADING";
  file: File;
  name: string; //Nome do documento original
};

export type UploadedFile = {
  id: string; //CortexId
  cortexId: string; //CortexId
  type: "UPLOADED";
  url: string; //URL do S3
  name: string; //Nome do documento original
};

export type ErrorFile = {
  id: string; //CortexId
  type: "ERROR";
  name: string; //Nome do documento original
  error: string;
};

export type InMessageFile = UploadingFile | UploadedFile | ErrorFile;

export type FileMessage = {
  id: string;
  type: "FILE";
  direction: MessageDirection;
  author: string;
  date: DateTime;
  status: MessageStatus;
  text: string;
  context?: string;
  files: InMessageFile[];
};

export type UploadedFileMessage = {
  id: string;
  type: "FILE";
  direction: MessageDirection;
  author: string;
  date: DateTime;
  status: MessageStatus;
  text: string;
  context?: string;
  files: (UploadedFile | ErrorFile)[];
};

export type ActionMessage = {
  id: string;
  type: "ACTION" | "INITIAL";
  direction: MessageDirection;
  author: string;
  date: DateTime;
  status: MessageStatus;
  text: string | React.JSX.Element;
  context?: string;
  actions: Action[];
};

export type ErrorMessage = {
  id: string;
  type: "ERROR";
  direction: "RECEIVED";
  author: "Lexter.ai";
  date: DateTime;
  status: MessageStatus;
  text: string;
  context?: string;
  actions: Action[];
  retry?: () => void;
  cancel?: () => void;
};

export type InputType = "CONTENT" | "FILE" | "TEXT" | "NONE";

export type FlowOutput = {
  source: InputType;
  text?: string;
  file?: {
    id: string;
    name: string;
    file: File;
  };
};

export enum ActionId {
  CREATE_NEW_DOCUMENT = "CREATE_NEW_DOCUMENT",
  CREATE_INITIAL_PETITION = "CREATE_INITIAL_PETITION",
  CREATE_CONTESTATION = "CREATE_CONTESTATION",
  CREATE_PETITION_SUMMARY = "CREATE_PETITION_SUMMARY",
  CREATE_APPEAL = "CREATE_APPEAL",
  CREATE_REPLICATION = "CREATE_REPLICATION",
  CREATE_LABOR_COMPLAINT = "CREATE_LABOR_COMPLAINT",
  CREATE_LABOR_CONTESTATION = "CREATE_LABOR_CONTESTATION",
  CREATE_ORDINARY_APPEAL = "CREATE_ORDINARY_APPEAL",
  CREATE_LABOR_PLAINTIFF_REPLY = "CREATE_LABOR_PLAINTIFF_REPLY",
  CREATE_ONE_PIECE = "CREATE_ONE_PIECE",
  CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT = "CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT",
  CREATE_INTERVIEW_SCRIPT_ACTION = "CREATE_INTERVIEW_SCRIPT_ACTION",
  CREATE_CONTRACT = "CREATE_CONTRACTS_ACTION",
  CREATE_NOTICE_ACTION = "CREATE_NOTICE_ACTION",
  SEARCH_JURISPRUDENCE = "precedent",
  EDIT_DOCUMENT = "edit_document",
  LEGAL_QUESTION = "legal_questions",
  CREATE_STRATEGY = "create_case_strategy",
  SUMMARIZE_DOCUMENT = "summary_procedural_documents",
  LEGAL_ADVICE = "legal_advice",
  INTERCURRENT_MOTION = "intercurrent_motion",
  FEE_AGREEMENT = "fee_agreement",
  SETTLEMENT_OFFER = "settlement_offer",
  HEARINGS_SCRIPT = "hearings_script",
  CHAT = "chat",
}

export enum ShortCutAction {
  CREATE_NEW_DOCUMENT = "create_new_document",
  CREATE_APPEAL = "create_appeal",
  CREATE_INITIAL_PETITION = "create_initial_petition",
  CREATE_CONTESTATION = "create_contestation",
  CREATE_REPLICATION = "create_replication",
  CREATE_ORDINARY_APPEAL = "create_ordinary_appeal",
  CREATE_INTERVIEW_SCRIPT_ACTION = "create_interview_script",
  CREATE_CONTRACT = "create_contract",
  CREATE_NOTICE_ACTION = "create_notice",
  CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT = "create_evidence_and_required_documents",
  CREATE_LABOR_PLAINTIFF_REPLY = "create_labor_plaintiff_reply",
  CREATE_LABOR_APPEAL = "create_labor_appeal",
  CREATE_LABOR_INITIAL_PETITION = "create_labor_initial_petition",
  CREATE_LABOR_COMPLAINT = "create_labor_complaint",
  CREATE_LABOR_CONTESTATION = "create_labor_contestation",
  CREATE_CIVIL_INITIAL_PETITION = "create_civil_initial_petition",
  CREATE_CIVIL_REPLY = "create_civil_reply",
  CREATE_CIVIL_APPEAL = "create_civil_appeal",
  CREATE_CRIMINAL_INITIAL_PETITION = "create_criminal_initial_petition",
  CREATE_CRIMINAL_REPLY = "create_criminal_reply",
  CREATE_CRIMINAL_APPEAL = "create_criminal_appeal",
  CREATE_SOCIAL_SECURITY_INITIAL_PETITION = "create_pension_initial_petition",
  USE_CHAT = "use_chat",
  LEGAL_QUESTION = "legal_questions",
  CREATE_STRATEGY = "create_case_strategy",
  SUMMARIZE_DOCUMENT = "summary_procedural_documents",
  INTERCURRENT_MOTION = "intercurrent_motion",
  SEARCH_JURISPRUDENCE = "search_jurisprudence",
  LEGAL_ADVICE = "legal_advice",
  FEE_AGREEMENT = "fee_agreement",
  SETTLEMENT_OFFER = "settlement_offer",
  HEARINGS_SCRIPT = "hearings_script",
  EDIT_DOCUMENT = "edit_document",
}

export const actionCreditMap: Record<string, CreditPerSkill> = {
  [ShortCutAction.CREATE_NEW_DOCUMENT]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_INTERVIEW_SCRIPT_ACTION]: CreditPerSkill.ESSENTIAL_SKILL,
  [ShortCutAction.SUMMARIZE_DOCUMENT]: CreditPerSkill.INTERMEDIARY_SKILL,
  [ShortCutAction.CREATE_INITIAL_PETITION]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_LABOR_COMPLAINT]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_CONTRACT]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT]: CreditPerSkill.ESSENTIAL_SKILL,
  [ShortCutAction.CREATE_NOTICE_ACTION]: CreditPerSkill.INTERMEDIARY_SKILL,
  [ShortCutAction.SEARCH_JURISPRUDENCE]: CreditPerSkill.BASIC_SKILL,
  [ShortCutAction.CREATE_CONTESTATION]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_LABOR_CONTESTATION]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_REPLICATION]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_LABOR_PLAINTIFF_REPLY]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_APPEAL]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_ORDINARY_APPEAL]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.LEGAL_QUESTION]: CreditPerSkill.ESSENTIAL_SKILL,
  [ShortCutAction.CREATE_STRATEGY]: CreditPerSkill.INTERMEDIARY_SKILL,
  [ShortCutAction.HEARINGS_SCRIPT]: CreditPerSkill.ESSENTIAL_SKILL,
  [ShortCutAction.FEE_AGREEMENT]: CreditPerSkill.INTERMEDIARY_SKILL,
  [ShortCutAction.INTERCURRENT_MOTION]: CreditPerSkill.INTERMEDIARY_SKILL,
  [ShortCutAction.LEGAL_ADVICE]: CreditPerSkill.INTERMEDIARY_SKILL,
  [ShortCutAction.SETTLEMENT_OFFER]: CreditPerSkill.BASIC_SKILL,
  [ShortCutAction.USE_CHAT]: CreditPerSkill.ESSENTIAL_SKILL,
  [ShortCutAction.CREATE_CIVIL_REPLY]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_CIVIL_INITIAL_PETITION]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_CIVIL_APPEAL]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_CRIMINAL_APPEAL]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_LABOR_APPEAL]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_CRIMINAL_REPLY]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_CRIMINAL_INITIAL_PETITION]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_SOCIAL_SECURITY_INITIAL_PETITION]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.CREATE_LABOR_INITIAL_PETITION]: CreditPerSkill.ADVANCED_SKILL,
  [ShortCutAction.EDIT_DOCUMENT]: CreditPerSkill.ESSENTIAL_SKILL,
};

export type UploadedFlowOutput = {
  source: InputType;
  text?: string;
  file?: UploadedFile;
};

type CreateDefenseStrategyAction = {
  id: "CREATE_CONTESTATION_STRATEGY";
  initialPetition: FlowOutput;
  userFacts?: FlowOutput;
  text: string;
};

export type CreateDefenseAction = {
  id: ActionId.CREATE_CONTESTATION;
  initialPetition: FlowOutput;
  userFacts?: FlowOutput;
  defendant?: FlowOutput;
  text: string;
  claims_ids?: string[];
};

type CreateAppealAction = {
  id: ActionId.CREATE_APPEAL;
  sentence: FlowOutput;
  appellant: FlowOutput;
  text: string;
};

type CreateReplicationAction = {
  id: ActionId.CREATE_REPLICATION;
  contestation: FlowOutput;
  userFacts?: FlowOutput;
  author: FlowOutput;
  text: string;
};

type CreateLaborComplaintAction = {
  id: ActionId.CREATE_LABOR_COMPLAINT;
  case: FlowOutput;
  text: string;
};

type UploadedCreateLaborComplaintAction = {
  id: ActionId.CREATE_LABOR_COMPLAINT;
  case: UploadedFlowOutput;
  text: string;
};

type CreateLaborDefenseAction = {
  id: ActionId.CREATE_LABOR_CONTESTATION;
  laborComplaint: FlowOutput;
  userFacts?: FlowOutput;
  defendant?: FlowOutput;
  text: string;
};

type UploadedCreateLaborDefenseAction = {
  id: ActionId.CREATE_LABOR_CONTESTATION;
  laborComplaint: UploadedFlowOutput;
  userFacts?: UploadedFlowOutput;
  defendant?: FlowOutput;
  text: string;
};

type CreateLaborOrdinaryAppealAction = {
  id: ActionId.CREATE_ORDINARY_APPEAL;
  sentence: FlowOutput;
  appellant: FlowOutput;
  text: string;
};

type UploadedCreateLaborOrdinaryAppealAction = {
  id: ActionId.CREATE_ORDINARY_APPEAL;
  sentence: UploadedFlowOutput;
  appellant: FlowOutput;
  text: string;
};

type CreateLaborReplicationAction = {
  id: ActionId.CREATE_LABOR_PLAINTIFF_REPLY;
  contestation: FlowOutput;
  userFacts?: FlowOutput;
  plaintiff?: FlowOutput;
  text: string;
};

type UploadedCreateStrategyAction = {
  id: "CREATE_STRATEGY";
  processData: UploadedFlowOutput;
  text: string;
};

type CreateInitialPetitionAction = {
  id: ActionId.CREATE_INITIAL_PETITION;
  case: FlowOutput;
  text: string;
};

type UploadedCreateInitialPetitionAction = {
  id: ActionId.CREATE_INITIAL_PETITION;
  case: UploadedFlowOutput;
  // template: UploadedFlowOutput;
  text: string;
};

type CreatePetitionSummaryAction = {
  id: ActionId.CREATE_PETITION_SUMMARY;
  petition: FlowOutput;
  text: string;
};

export type FlowMessage = {
  id: string;
  type: "FLOW";
  direction: MessageDirection;
  author: string;
  date: DateTime;
  status: MessageStatus;
  text: string;
  context?: string;
  actions: (
    | CreateInitialPetitionAction
    | CreateDefenseStrategyAction
    | CreateDefenseAction
    | CreateAppealAction
    | CreateReplicationAction
    | CreateLaborComplaintAction
    | CreateLaborDefenseAction
    | CreateLaborReplicationAction
    | CreateLaborOrdinaryAppealAction
    | CreatePetitionSummaryAction
    | CreatePieceAction
    | CreateEvidenceAndRequiredDocumentAction
    | CreateInterviewScriptAction
    | CreateContractsAction
    | SearchJurisprudenceAction
    | CreateExtrajudicialNoticeAction
  )[];
  files: InMessageFile[];
  hideFiles?: boolean;
  actionId?: ActionId | null;
  evaluation?: Evaluation;
};

export type UploadedFlowMessage = {
  id: string;
  type: "FLOW";
  direction: MessageDirection;
  author: string;
  date: DateTime;
  status: MessageStatus;
  text: string;
  context?: string;
  actions: (
    | CreateInitialPetitionAction
    | CreateDefenseStrategyAction
    | CreateDefenseAction
    | CreateAppealAction
    | CreateReplicationAction
    | CreateLaborComplaintAction
    | CreateLaborDefenseAction
    | CreateLaborReplicationAction
    | CreateLaborOrdinaryAppealAction
    | CreatePetitionSummaryAction
    | CreatePieceAction
    | CreateContractsAction
    | CreateExtrajudicialNoticeAction
  )[];
  files: (UploadedFile | ErrorFile)[];
};

export type FeedbackMessage = {
  id: string;
  type: "FEEDBACK";
  direction: "RECEIVED";
  author: string;
  date: DateTime;
  status: "READ";
  text: string;
  //Context will never be used here, we only defined it to make the type
  ///compatible with the Message type
  context?: string;
};

export type Message = TextMessage | FileMessage | ActionMessage | ErrorMessage | FlowMessage | FeedbackMessage;

//Os IDs definem qual função o chat vai executar ao realizar essa mensagem. Isso tem que ser previamente combinado
export type ActionDTO =
  | {
      id: "REPLACE_ENTIRE_DOCUMENT" | "REPLACE_SELECTION";
      type: "TEXT";
      text?: string;
    }
  | {
      id: "REPLACE_ENTIRE_DOCUMENT" | "REPLACE_SELECTION" | "DOWNLOAD_ASSISTANT_ANSWER";
      type: "FORMATTED";
      lines: Line[];
      stylesMap: StylesMap;
    }
  | {
      id:
        | "SEND_FAQ"
        | "START_STRATEGY_FLOW"
        | "START_INITIAL_PETITION_FLOW"
        | "START_NEW_DOCUMENT_FLOW"
        | "START_APPEAL_FLOW"
        | "START_REPLICATION_CONTESTATION"
        | "START_LABOR_COMPLAINT_FLOW"
        | "START_LABOR_CONTESTATION_FLOW"
        | "START_LABOR_REPLICATION_FLOW"
        | "START_ORDINARY_APPEAL_FLOW"
        | "START_CREATE_CONTESTATION_STRATEGY"
        | "START_CREATE_CONTESTATION"
        | "START_PETITION_SUMMARY_FLOW";
    }
  | UploadedCreateStrategyAction
  | UploadedCreateInitialPetitionAction
  | UploadedCreateLaborComplaintAction
  | UploadedCreateLaborDefenseAction
  | UploadedCreateLaborOrdinaryAppealAction;

export type ChatFile = {
  id: string;
  cortexId: string; //CortexId
  url: string; //URL do S3
  name: string; //Nome do documento original
};

export interface MessageDTO {
  id: string;
  author: "Lexter.ai" | string;
  date: string;
  text: string | React.JSX.Element;
  context?: string; //Texto grifado atualmente
  files?: ChatFile[];
  actions?: ActionDTO[];
}

export interface SendingErrorMessage {
  text: string;
  insufficientCredits?: boolean;
}

export const isCortexResponseMessage = (response: ResponseMessage): response is CortexResponseMessage => {
  return (response as CortexResponseMessage).replyTo !== undefined;
};

export interface RepliedMessage {
  id: string;
  actionId: ActionId;
  payload: CreatePieceAction;
  editorContent?: string;
}

export interface BaseCortexResponse {
  document?: {
    id?: number;
    name: string;
  };
  replyTo: RepliedMessage;
}

export interface CortexSuccessResponse extends BaseCortexResponse {
  success: true;
  messages: MessageDTO[];
  requestId?: string;
}

export interface CortexErrorResponse extends BaseCortexResponse {
  success: false;
}

export type CortexResponseMessage = CortexSuccessResponse | CortexErrorResponse;

export type ResponseMessage = CortexResponseMessage | SendingErrorMessage;

export type Page =
  | "CHAT"
  | "INITIAL_PETITION_STRATEGY"
  | "DEFENSE_STRATEGY"
  | "APPEAL"
  | "LABOR_COMPLAINT"
  | "LABOR_CONTESTATION"
  | "LABOR_ORDINARY_APPEAL"
  | "REPLICATION"
  | "LABOR_REPLICATION"
  | "INAUGURAL_DOCUMENT"
  | "RESPONSE_DOCUMENT"
  | "APPEAL_DOCUMENT"
  | "INTERVIEW_SCRIPT"
  | "CREATE_STRATEGY"
  | "CONTRACT_CREATION"
  | "EXTRAJUDICIAL_NOTICE"
  | "LEGAL_QUESTION"
  | "LEGAL_ADVICE"
  | "INTERCURRENT_MOTION"
  | "FEE_AGREEMENT"
  | "SETTLEMENT_OFFER"
  | "HEARINGS_SCRIPT";

export interface ChatOption {
  id?: ShortCutAction;
  skillId?: SkillId;
  label: string;
  action?: () => void;
  response?: ChatFlow;
  hidden?: boolean;
  disabled?: boolean;
}

export interface ChatFlow {
  message: string | React.JSX.Element;
  options?: ChatOption[];
}

export type SuggestedSkillsMap = Record<ActionId, ChatFlow>;

export interface CreateDocumentProps {
  withReferences?: boolean;
}

export interface WaitingForResponse {
  type: WaitingForResponseType;
  executionId?: string;
}

export enum WaitingForResponseType {
  GENERIC = 1,
  INTERACTION_WITH_CHAT,
  CREATE_MOTION,
  CREATE_DOCUMENT,
  CREATE_SUMMARY,
  EXTRACT_CLAIMS,
  SELECT_CLAIMS,
  LEGAL_QUESTION,
  DOCUMENT_UPLOAD,
  CONTRACT,
}

export interface ClaimWithCheck extends Claim {
  checked: boolean;
}

export enum Area {
  CIVIL = "civel",
  LABOR = "trabalhista",
  SOCIAL_SECURITY = "previdenciario",
  TAX = "tributario",
  CRIMINAL = "criminal",
  OTHER = "outros",
}

export enum LegalPieceMacroType {
  INAUGURAL_DOCUMENT = "peca_inaugural",
  RESPONSE = "resposta",
  APPEAL = "recurso",
}

export enum LegalPieceMicroType {
  INITIAL = "inicial",
  CONTESTATION = "contestacao",
  REPLICATION = "replica",
  APPEAL = "apelacao",
  ORDINARY_APPEAL = "recurso_ordinario",
  OTHER = "outros",
}

export enum DecisionType {
  TERMINATIVE_DECISIONS = "decisoes_terminativas",
  INTERLOCUTORY_DECISIONS = "decisoes_interlocutoria",
}

export type UploadingFlowOutput = {
  source: InputType;
  text?: string;
  file?: UploadingFile;
};

export interface CreatePieceAction {
  id: ActionId.CREATE_ONE_PIECE;
  client: string;
  legal_piece_macro_type: LegalPieceMacroType;
  area: Area | string;
  legal_piece_micro_type: LegalPieceMicroType | string;
  decision_type?: DecisionType;
  case_breakdown?: UploadedFlowOutput | UploadingFlowOutput | FlowOutput | null;
  reference_piece?: UploadedFlowOutput | UploadingFlowOutput | FlowOutput | null;
  text: string;
}

export interface CreateEvidenceAndRequiredDocumentAction {
  id: ActionId.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT;
  client: string;
  legal_piece_macro_type?: LegalPieceMacroType;
  area: Area | string;
  legal_piece_micro_type: LegalPieceMicroType | string;
  decision_type?: DecisionType;
  case_breakdown?: UploadedFlowOutput | UploadingFlowOutput | FlowOutput | null;
  reference_piece?: UploadedFlowOutput | UploadingFlowOutput | FlowOutput | null;
  text?: string;
}

export interface CreateInterviewScriptAction {
  id: ActionId.CREATE_INTERVIEW_SCRIPT_ACTION;
  client: string;
  case_breakdown: FlowOutput;
}

export interface CreateContractsAction {
  id: ActionId.CREATE_CONTRACT;
  party_1: string;
  party_2: string;
  case_breakdown: string;
  contract_micro_type: string;
}

export interface SearchJurisprudenceAction {
  id: ActionId.SEARCH_JURISPRUDENCE;
  case_breakdown: string;
}

export interface CreateExtrajudicialNoticeAction {
  id: ActionId.CREATE_NOTICE_ACTION;
  client: string;
  case_breakdown: string;
  reference_piece: FlowOutput | undefined;
}

export type LoadingState = "FINISHED" | "LOADING" | "REFETCHING";
