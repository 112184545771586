import React from "react";
import { Typography, Box } from "@mui/material";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { Carousel } from "@/components/Carousel";

interface Slide {
  title: string;
  subtitle: string;
  image: string;
}

export const CarouselContent: React.FC = () => {
  const flags = useFeatureFlags();

  const slides: Slide[] = flags.loginCarouselTexts.slides;

  if (!slides.length) return null;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "300px" }}>
        <Carousel autoPlay loop interval={10000}>
          {slides.map((slide, idx) => (
            <Box
              key={idx}
              sx={{
                padding: "15px",
                minHeight: "100px",
                textAlign: "center",
              }}
            >
              {slide.image && <img alt={slide.title} src={slide.image} width="60%" />}
              <Box sx={{ mb: "24px" }} />
              <Typography
                sx={{
                  color: "#16222c",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "25.6px",
                  mb: "24px",
                }}
              >
                {slide.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22.4px",
                }}
              >
                {slide.subtitle}
              </Typography>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};
