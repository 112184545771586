import { useAuthContext } from "@/contexts/AuthContext";
import { Flags, featureFlags } from "./featureFlags";

export const useConfigs = () => {
  const { user } = useAuthContext();
  const isAdmin = user?.isAdmin;

  const isFeatureEnabled = (feature: Flags) => {
    if (isAdmin) return true;

    return featureFlags[feature];
  };

  return {
    isFeatureEnabled,
  };
};
