import { Skills } from "@/core/skills";
import { SkillId } from "@/core/skills/types";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useQuery } from "@tanstack/react-query";

export const useSkill = (skillId?: string) => {
  const { newSkillsFlow } = useFeatureFlags();
  return useQuery({
    queryKey: ["skill", skillId, `flags:${newSkillsFlow.join(";")}`],
    queryFn: async () => {
      const skill = await Skills.getSkill(skillId as SkillId);

      if (skill) {
        const skillEnabled = newSkillsFlow.includes(skill.id);

        skill.disabled = !skillEnabled;

        return skill;
      }

      return undefined;
    },
    enabled: Boolean(skillId),
  });
};
