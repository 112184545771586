import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import * as React from "react";
import { v4 as uuidV4 } from "uuid";

import { ActionId, FlowOutput, InMessageFile, Message } from "@/contexts/MessagesContext";
import { useMemo } from "react";
import * as logger from "../../../../core/logger";
import { InternalPageStructure } from "../../core/InternalPageStructure";
import { FileInput, TextInput } from "../FlowInput";

enum Step {
  SENTENCE,
  APPELLANT,
}

export function Appeal(props: { goBack: () => void; sendMessage: (message: Message) => void }) {
  const { goBack, sendMessage } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [sentenceFile, setSentenceFile] = React.useState<File>();
  const [appellant, setAppellant] = React.useState<FlowOutput | null>(null);
  const steps = useMemo(() => [Step.SENTENCE, Step.APPELLANT], []);
  const [currentStep, setCurrentStep] = React.useState(0);

  const dismissError = React.useCallback(() => {
    setError(null);
  }, []);

  const onSentenceDocumentChange = React.useCallback((file?: File) => {
    setSentenceFile(file);
    dismissError();
  }, []);

  const onAppellantChange = React.useCallback((input: FlowOutput | null) => {
    setAppellant(input);
    dismissError();
  }, []);

  const onSubmit = React.useCallback(() => {
    if (!appellant || !sentenceFile) {
      setError("É preciso submeter todos os dados.");
      return;
    }

    logger.info("Submitting appeal message");

    const actionText = `Criar uma apelação para o arquivo ${sentenceFile.name}`;

    const sentenceId = uuidV4();

    const files: InMessageFile[] = [
      {
        id: sentenceId,
        type: "UPLOADING",
        file: sentenceFile,
        name: sentenceFile.name,
      },
    ];

    sendMessage({
      id: uuidV4(),
      type: "FLOW",
      direction: "SENT",
      author: "Current User",
      date: DateTime.now(),
      status: "READ",
      text: actionText,
      actions: [
        {
          id: ActionId.CREATE_APPEAL,
          sentence: {
            source: "FILE",
            file: {
              id: sentenceId,
              name: sentenceFile.name,
              file: sentenceFile,
            },
          },
          text: actionText,
          appellant,
        },
      ],
      files,
    });

    goBack();
  }, [sentenceFile, appellant]);

  const onSubmitHandler = () => {
    switch (steps[currentStep]) {
      case Step.SENTENCE:
        setCurrentStep(Step.APPELLANT);
        break;
      case Step.APPELLANT:
        onSubmit();
        break;
      default:
        throw new Error("Invalid step");
    }
  };

  const onGoBackHandler = () => {
    if (currentStep === 0) {
      goBack();
      return;
    }

    setCurrentStep(currentStep - 1);
  };

  const canProceed = () => {
    switch (steps[currentStep]) {
      case Step.SENTENCE:
        return !!sentenceFile;
      case Step.APPELLANT:
        return !!appellant;
      default:
        throw new Error("Invalid step");
    }
  };

  return (
    <InternalPageStructure
      goBack={onGoBackHandler}
      onSubmit={onSubmitHandler}
      error={error}
      dismissError={dismissError}
      goBackButtonText={currentStep === 0 ? "Cancelar" : "Voltar"}
      submitButtonText={currentStep === steps.length - 1 ? "Criar Apelação" : "Continuar"}
      submitButtonDisabled={!canProceed()}
      totalSteps={steps.length}
      currentStep={currentStep}
    >
      <Box>
        <Box sx={{ mb: 1, pb: 1 }}>
          <Typography variant="preTitle" color={"text.primary"}>
            {`CRIAR APELAÇÃO - PASSO ${currentStep + 1}`}
          </Typography>
        </Box>

        <SentenceStep onDocumentChange={onSentenceDocumentChange} show={steps[currentStep] === Step.SENTENCE} />

        <AppellantStep onAppellantChange={onAppellantChange} show={steps[currentStep] === Step.APPELLANT} />
      </Box>
    </InternalPageStructure>
  );
}

export function SentenceStep({ onDocumentChange, show }: { onDocumentChange: (file?: File) => void; show: boolean }) {
  const handleFileChange = (files: File[]) => {
    onDocumentChange(files[0]);
  };

  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="multiLineBody" color={"text.primary"}>
          Para criar uma apelação, você precisa realizar o envio da sentença a ser apelada.
        </Typography>
      </Box>

      <FileInput id="input_upload_file" onChange={handleFileChange} />
    </Box>
  );
}

export function AppellantStep({
  onAppellantChange,
  show,
}: {
  onAppellantChange: (input: FlowOutput | null) => void;
  show: boolean;
}) {
  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="multiLineBody" color={"text.primary"}>
          Insira abaixo o nome do(s) apelante(s):
        </Typography>

        <TextInput
          onChange={onAppellantChange}
          placeholder="Insira o nome do(s) apelante(s). Se mais de um, separe com vírgula os nomes."
          minHeight={94}
        />
      </Box>
    </Box>
  );
}
