import { Box, MenuItem as MuiMenuItem, Select, Typography } from "@mui/material";
import React from "react";

type OptionValue = string | number;

interface SelectOption {
  value: OptionValue;
  label: string;
}

interface FilterSelectProps {
  label: string;
  options: SelectOption[];
  defaultValue: OptionValue;
  onChange: (value: OptionValue) => void;
  disabled?: boolean;
}

export const FilterSelect = ({ label: selectLabel, options, defaultValue, onChange, disabled }: FilterSelectProps) => (
  <Select
    fullWidth
    disabled={disabled}
    variant="outlined"
    displayEmpty
    defaultValue={defaultValue}
    renderValue={(value) => {
      const selectedOption = options.find((option) => option.value === value);

      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="preTitle">{selectLabel}</Typography>
          <Typography
            variant="body"
            sx={{
              textDecoration: "underline",
            }}
          >
            {selectedOption?.label || ""}
          </Typography>
          <Box />
        </Box>
      );
    }}
    onChange={(event) => onChange(event.target.value as string)}
  >
    {options.map(({ value, label }) => (
      <MuiMenuItem key={value} value={value}>
        {label}
      </MuiMenuItem>
    ))}
  </Select>
);
