import { Button } from "@/components/Button";
import { TextField } from "@/components/TextField";
import { useAuthContext } from "@/contexts/AuthContext";
import { usePaymentContext } from "@/contexts/PaymentContext";
import { logger } from "@/core/logger";
import { useApi } from "@/hooks/useApi";
import { getParam } from "@/utils/getParams";
import { Box } from "@mui/material";
import React, { useEffect } from "react";

export const CouponButton = () => {
  const { loading, success, onCouponApplied, removeCoupon } = usePaymentContext();
  const [couponCode, setCouponCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const { checkCoupon } = useApi();
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const couponCode = getParam("cupom");
    if (couponCode) {
      setCouponCode(couponCode);
      handleApplyCoupon(couponCode);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(event.target.value);
  };

  const handleApplyCoupon = async (couponFromParam?: string) => {
    setErrorMessage("");
    setIsLoading(true);
    removeCoupon();

    try {
      const code = couponFromParam ?? couponCode;
      const coupon = await checkCoupon({ couponCode: code, companyId: user?.companyId });
      if (coupon && coupon.active) {
        onCouponApplied({
          code: couponCode,
          durationType: coupon.durationType,
          durationInMonths: coupon.durationInMonths,
          percentOff: coupon.percentOff,
          amountOff: coupon.amountOff,
        });
      }
    } catch (err) {
      logger.error("handleApplyCoupon", err);
      setErrorMessage("Cupom inválido");
    }

    setIsLoading(false);
  };

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}>
      <TextField
        disabled={loading || isLoading || success}
        onChange={handleChange}
        sx={{
          flexGrow: 1,
          "& .MuiInputBase-root": {
            padding: 0,
          },
          label: {
            fontSize: "14px",
            fontWeight: "700",
            color: !!errorMessage ? "error.dark" : "common.mediumShade",
          },
          p: 0,
        }}
        value={couponCode}
        label="CUPOM DE DESCONTO"
        error={!!errorMessage}
        helperText={errorMessage}
        placeholderColor="common.mediumShade"
        color="common.shade"
      />
      <Button
        isLoading={isLoading}
        loaderStyle={{ maxWidth: "93px" }}
        disabled={loading || couponCode === "" || isLoading}
        onClick={() => handleApplyCoupon()}
        variant="outlined"
      >
        Aplicar
      </Button>
    </Box>
  );
};
