import { LoginType } from "@/contexts/AuthContext";
import { getFromStorages, removeFromStorages, setInStorages } from "@/utils/storage";
import * as logger from "@/core/logger";
import { FirebaseService } from "@/services/firebase";

const LOGIN_TYPE_STORAGE_KEY = "loginType";

export const AuthService = {
  getLoginType() {
    return getFromStorages(LOGIN_TYPE_STORAGE_KEY) as LoginType;
  },
  saveLoginType(loginType: LoginType) {
    setInStorages(LOGIN_TYPE_STORAGE_KEY, loginType);
  },
  deleteLoginType() {
    removeFromStorages(LOGIN_TYPE_STORAGE_KEY);
  },
  async getAuthToken() {
    const loginType = this.getLoginType();
    if (!loginType || loginType !== LoginType.FIREBASE) {
      logger.debug(`Login type not found: ${loginType}`);
      return { success: false };
    }

    const token = await FirebaseService.getToken();
    if (!token || token === "") {
      logger.debug(`Firebase token not found: ${token}`);
      return { success: false };
    }

    return { success: true, token };
  },
  async getAuthorizationHeader({ withLoginType = true }: { withLoginType?: boolean } = {}) {
    const loginType = AuthService.getLoginType();
    const authToken = await AuthService.getAuthToken();
    if (authToken.success) {
      const apiToken = withLoginType ? `${loginType}:${authToken.token}` : authToken.token;
      return `bearer ${apiToken}`;
    } else {
      logger.error("Error getting auth token");
      return undefined;
    }
  },
};
