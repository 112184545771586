import { Skills } from "@/core/skills";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useQuery } from "@tanstack/react-query";

export const useSkills = () => {
  const { newSkillsFlow } = useFeatureFlags();
  return useQuery({
    queryKey: ["skills", `flags:${newSkillsFlow.join(";")}`],
    queryFn: async () => {
      const skills = await Skills.getSkills();
      return skills.map((skill) => {
        const skillEnabled = newSkillsFlow.includes(skill.id);

        skill.disabled = !skillEnabled;

        return skill;
      });
    },
  });
};
