import { SkillId } from "@/core/skills/types";
import { SkillSteps } from "@/core/skillSteps";
import { useQuery } from "@tanstack/react-query";

export const useSkillSteps = ({ skillId }: { skillId?: SkillId }) => {
  return useQuery({
    queryKey: ["skillSteps", skillId],
    queryFn: () => (skillId ? SkillSteps.getSkillSteps(skillId) : undefined),
    enabled: Boolean(skillId),
  });
};
