import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DialogModal } from "@/components/DialogModal";

import React, { useState } from "react";
import { FileInput } from "@/taskpane/components/Chat/FlowInput";
import { InternalPageStructure, InternalPageStructureProps } from "@/taskpane/components/core/InternalPageStructure";

interface SelectReferencesProps extends Omit<InternalPageStructureProps, "children"> {
  onChange: (files: File[]) => void;
}

export const SelectReferences = ({ onChange, onSubmit, ...internalPageStructureProps }: SelectReferencesProps) => {
  const [references, setReferences] = useState<File[]>([]);
  const [modalWithoutReferencesOpen, setModalWithoutReferencesOpen] = useState(false);

  const handleSubmit = () => {
    if (!references.length) {
      setModalWithoutReferencesOpen(true);
      return;
    }

    setModalWithoutReferencesOpen(false);
    onSubmit();
  };

  const handleChange = (files: File[]) => {
    setReferences(files);
    onChange(files);
  };

  return (
    <>
      <InternalPageStructure onSubmit={handleSubmit} {...internalPageStructureProps}>
        <Box sx={{ mb: 1 }}>
          <Typography variant="preTitle" color={"text.primary"}>
            Selecionar peças referências
          </Typography>
        </Box>

        <Box>
          <Typography variant="multiLineBody" color={"text.primary"}>
            Faça o upload de até 5 peças de referências que serão utilizadas para análise e extração das teses para a
            produção da nova peça.
            <br />
            <br />
            <strong>ATENÇÃO:</strong> Neste momento, aceitamos arquivos nos formatos PDF e DOCX com até 40mb.
          </Typography>
        </Box>

        <Box>
          <FileInput onChange={handleChange} multiple maxFiles={5} />
        </Box>
      </InternalPageStructure>

      <DialogModal
        description="Deseja continuar a criação da peça sem utilizar peças de referência? Sua nova peça será criada através da redação de novas teses."
        open={modalWithoutReferencesOpen}
        buttons={[
          {
            label: "Continuar",
            onClick: onSubmit,
          },
          {
            label: "Cancelar",
            onClick: () => setModalWithoutReferencesOpen(false),
            variant: "text",
            color: "primary",
            sx: {
              color: "text.primary",
              textDecoration: "underline",
            },
          },
        ]}
        variant="error"
      />
    </>
  );
};
