import React from "react";
import { Box, Stepper as MuiStepper, StepperProps as MuiStepperProps, Step, StepIcon, useTheme } from "@mui/material";

export interface StepperProps extends MuiStepperProps {
  steps: number;
  activeStep?: number;
  onClickStep?: (step: number) => void;
}

export const Stepper = ({ steps, activeStep, onClickStep, ...rest }: StepperProps) => {
  const theme = useTheme();

  return (
    <MuiStepper
      {...rest}
      sx={{
        width: "100%",
        ...rest.sx,
      }}
    >
      {Array.from(Array(steps).keys()).map((_, index) => {
        const isClickable = onClickStep && activeStep && index < activeStep;

        return (
          <Step
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              height: "24px",
              cursor: isClickable ? "pointer" : undefined,
            }}
            onClick={isClickable ? () => onClickStep(index) : undefined}
          >
            <StepIcon
              icon={
                index === activeStep ? (
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                      backgroundColor: theme.palette.common.white,
                      border: `1px solid ${theme.palette.primary.main}`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: theme.palette.primary.main,
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: theme.palette.text.primary,
                    }}
                  />
                )
              }
            />
          </Step>
        );
      })}
    </MuiStepper>
  );
};
