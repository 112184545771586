import React from "react";
import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { newPlans, usePaymentContext } from "@/contexts/PaymentContext";
import { localeToPtBr, transformToBRL } from "@/utils/transformToBRL";
import { PlanType } from "@/contexts/CreditsContext";

export const SelectPlan = () => {
  const { selectedPlan, onPlanChange, loading, success } = usePaymentContext();

  const handleSelectPlan = (event: SelectChangeEvent<PlanType>) => {
    const planOrNull = newPlans.find((plan) => plan.id === event.target.value);
    if (planOrNull) onPlanChange(planOrNull);
  };

  return (
    <Select
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiSelect-select": {
          paddingLeft: "0",
          span: {
            display: "none",
          },
        },
        width: "100%",
      }}
      value={selectedPlan.id}
      onChange={handleSelectPlan}
      displayEmpty
      disabled={loading || success}
    >
      {newPlans.map((item) => (
        <MenuItem sx={{ display: "flex", justifyContent: "space-between" }} key={item.id} value={item.id}>
          <Typography sx={{ minWidth: "200px", fontWeight: "700", color: "common.black" }}>
            {item.title.toUpperCase()}
          </Typography>
          <Typography component="span">{localeToPtBr(item.credits)} créditos</Typography>
          <Typography
            sx={{
              minWidth: "80px",
              textAlign: "end",
            }}
            component="span"
          >
            {transformToBRL(item.price)}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
