import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { Action, Message, useMessagesContext } from "@/contexts/MessagesContext";

export function ActionsMenu(props: { onClose: () => void; actions: Action[] }) {
  const { onClose, actions } = props;
  const { setInputVisible } = useMessagesContext();

  const availableActions = actions.filter((action: Action) => !action.hidden && !action.disabled);
  return (
    <Box>
      {availableActions.map((action: Action, idx: number) => (
        <Button
          id={action.id ? `footer_action_${action.id}` : undefined}
          key={idx}
          onClick={(props: React.MouseEvent<HTMLButtonElement, MouseEvent> & Message) => {
            setInputVisible(false);
            action.onClick(props);
            onClose();
          }}
          sx={{
            m: 0.5,
            textTransform: "none",
            borderRadius: "20px",
            padding: "4px 12px",
            backgroundColor: "background.default",
            ":hover": {
              backgroundColor: "background.default",
              opacity: 0.8,
            },
          }}
          variant="contained"
        >
          <Typography variant="body" color={"common.black"}>
            {action.text}
          </Typography>
        </Button>
      ))}
    </Box>
  );
}
