import { Box, Button, TextField } from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import { useAuthContext } from "@/contexts/AuthContext";
import { Email } from "@/core/Email";
import { useApi } from "@/hooks/useApi";
import { UserProfile } from "@/hooks/useApi/types";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { WebToast } from "@/taskpane/components/core/Toast";
import { typography } from "@/theme/typography";

type Form = {
  name: string;
  email: string;
};

export function ClientConfigMyDataModal() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { updateUserProfile } = useApi();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<Form>({
    name: "",
    email: "",
  });
  //const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false);

  // const closeConfirmationModal = () => {
  //   setIsConfirmationModalOpen(false);
  // };

  // const openConfirmationModal = () => {
  //   setIsConfirmationModalOpen(true);
  // };

  const getUserData = React.useCallback(() => {
    setIsLoading(true);

    if (!user || !user.userEmail) {
      return;
    }
    setForm({
      name: user.userName,
      email: user.userEmail,
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const updateUser = async (userProfile: UserProfile) => {
    setIsLoading(true);
    try {
      await updateUserProfile(userProfile);

      setIsLoading(false);

      goBackToSettings();
      return WebToast.success("Alteração feita com sucesso");
    } catch (err) {
      return WebToast.error("Falha ao alterar o perfil do usuário");
    }
  };

  const goBackToSettings = () => {
    navigate(ROUTE_PATHS.CLIENT_CONFIG, { replace: true });
  };

  // const handleRemoveUser = async () => {
  //   if (!user) {
  //     WebToast.error("Usuário não encontrado", undefined, "top-center");
  //     return;
  //   }

  //   try {
  //     await removeCompany(user.companyId);
  //     logout();
  //     history.push("/");
  //   } catch (err) {
  //     WebToast.error("Não foi possível excluir a conta, tente novamente mais tarde", undefined, "top-center");
  //   }
  //   closeConfirmationModal();
  // };

  const handleUpdateUserProfile = () => {
    try {
      if (!user || !user.userEmail) {
        return;
      }

      user.userName = form.name;

      updateUser({
        userId: user.userId,
        name: user.userName,
        email: user.userEmail,
        companies: [],
        reviewConfig: {
          includeDefaultText: true,
        },
      });
    } catch (e) {
      WebToast.error("Não foi possível alterar as informações do usuário");
    }
  };

  const handleChangeForm = <K extends keyof Form>(prop: K) => {
    return (value: string) =>
      setForm((prevState) => ({
        ...prevState,
        [prop]: value,
      }));
  };

  const isValidEmail = useMemo(() => {
    try {
      Email.fromString(form.email);

      return true;
    } catch (e) {
      return false;
    }
  }, [form.email]);

  const saveButtonDisabled = form?.name === "" || form?.email === "" || isLoading || !isValidEmail;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Meus Dados"}
        size={"SMALL"}
        onClose={goBackToSettings}
        footer={
          user &&
          !isLoading && (
            <ModalFooter
              confirmLabel={"SALVAR ALTERAÇÕES"}
              onConfirm={handleUpdateUserProfile}
              cancelLabel="CANCELAR"
              onCancel={goBackToSettings}
              saveButtonDisabled={saveButtonDisabled}
            />
          )
        }
        open={true}
      >
        <>
          <Loading isLoading={isLoading}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                }}
              >
                {user && (
                  <>
                    <InputField
                      placeholder="Nome Completo"
                      onChange={handleChangeForm("name")}
                      value={form.name}
                      label="NOME"
                      hasError={false}
                    />
                    <InputField
                      placeholder="E-mail de trabalho"
                      onChange={() => {}}
                      value={form.email}
                      label="E-MAIL"
                      hasError={!isValidEmail}
                      disabled={true}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Loading>
          {/* <DialogModal
            title={`EXCLUIR USUÁRIO`}
            description={
              <Box textAlign="left">
                Sua conta será excluída após a confirmação deste alerta. Esta é uma ação que não poderá ser desfeita.
              </Box>
            }
            onClose={closeConfirmationModal}
            open={isConfirmationModalOpen}
            variant="error"
            buttons={[
              {
                label: "EXCLUIR",
                onClick: handleRemoveUser,
              },
              {
                label: "Cancelar",
                sx: {
                  color: "text.primary",
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                  "&:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  },
                },
                onClick: closeConfirmationModal,
              },
            ]}
          /> */}
        </>
      </ModalRight>
    </Box>
  );
}

const ModalFooter = ({
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  saveButtonDisabled,
}: {
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  saveButtonDisabled: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "8px",
        margin: "0 auto",
      }}
    >
      <Button
        disabled={saveButtonDisabled}
        onClick={onConfirm}
        sx={{
          height: "48px",
          color: "primary.contrastText",
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.main",
          },
          ":disabled": {
            backgroundColor: "text.secondary",
            color: "common.white",
          },
          borderRadius: "8px",
        }}
      >
        {confirmLabel}
      </Button>
      <Button onClick={onCancel}>{cancelLabel}</Button>
    </Box>
  );
};

const InputField = ({
  label,
  placeholder,
  hasError,
  onChange,
  value,
  disabled,
}: {
  label: string;
  placeholder: string;
  hasError: boolean;
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
}) => {
  return (
    <TextField
      sx={{
        width: "100%",
        "& label.Mui-focused": {
          color: hasError ? "error.main" : "text.primary",
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: typography.fontFamily,
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: hasError ? "error.main" : "text.primary",
        },
      }}
      disabled={disabled}
      id="outlined-basic"
      label={label}
      variant="standard"
      type={"text"}
      focused
      error={hasError}
      placeholder={placeholder}
      InputProps={{
        style: { fontSize: "16px", padding: "6px", color: "common.black" },
      }}
      onChange={(e) => onChange(e.target.value)}
      value={value}
    />
  );
};

// TODO: Feature de Excluir Conta
// const RemoveAccountButton = ({ openConfirmationModal }: { openConfirmationModal: () => void }) => {
//   return (
//     <Box display="flex" flexDirection={"column"} gap="16px">
//       <Typography sx={{ color: "text.primary" }} variant="preTitle">
//         EXCLUIR CONTA
//       </Typography>
//       <Button
//         sx={{
//           width: "100%",
//           height: "40px",
//           color: "error.main",
//           "&:hover": {
//             backgroundColor: "background.paper",
//           },
//           borderColor: "error.main",
//           borderWidth: "1px",
//           borderStyle: "solid",
//           borderRadius: "6px",
//         }}
//         onClick={openConfirmationModal}
//       >
//         Excluir conta
//       </Button>
//     </Box>
//   );
// };
