import React, { useState } from "react";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { StripeCardNumberElementChangeEvent } from "@stripe/stripe-js";
import { BaseField, fieldOption } from "../Field";
import MasterCardIcon from "@/assets/svgs/mastercard.svg?react";
import VisaIcon from "@/assets/svgs/visa.svg?react";
import { usePaymentContext } from "@/contexts/PaymentContext";

export const CardNumberField = () => {
  const [cardBrand, setCardBrand] = useState("unknown");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { onLoadingChange } = usePaymentContext();

  const handleCardNumberChange = (e: StripeCardNumberElementChangeEvent) => {
    setErrorMessage(null);
    setCardBrand(e.brand);
    if (e.error) {
      setErrorMessage(e.error.message);
    }
  };

  return (
    <BaseField errorMessage={errorMessage} label="NUMERO DO CARTÃO">
      <CardNumberElement
        onChange={handleCardNumberChange}
        options={{
          placeholder: "",
          ...fieldOption,
        }}
        className="credit-card-field-stripe"
        onReady={() => onLoadingChange(false)}
      />
      {!errorMessage && (
        <>
          {cardBrand !== "unknown" ? (
            <span>{cardBrand === "visa" ? <VisaIcon /> : <MasterCardIcon />}</span>
          ) : (
            <span>
              <MasterCardIcon />
              <VisaIcon />
            </span>
          )}
        </>
      )}
    </BaseField>
  );
};
