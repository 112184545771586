import { ConditionalSkillStep, SkillStep } from "@/core/skillSteps/types";
import { settlementOfferSkillStep1 } from "./step1";
import { settlementOfferSkillStep2 } from "./step2";
import { settlementOfferSkillStep3 } from "./step3";

export const settlementOfferSkillSteps: Array<SkillStep | ConditionalSkillStep[]> = [
  settlementOfferSkillStep1,
  settlementOfferSkillStep2,
  settlementOfferSkillStep3,
];
