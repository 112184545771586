import ChatIcon from "@/assets/svgs/chat.svg?react";
import ConfigIcon from "@/assets/svgs/config.svg?react";
import LexterLogoIcon from "@/assets/svgs/lexter-logo.svg?react";
import WordIcon from "@/assets/svgs/word.svg?react";
import { ExitConfirmDialog } from "@/components/ExitConfirmDialog";
import { useAuthContext } from "@/contexts/AuthContext";
import { useMessagesContext } from "@/contexts/MessagesContext";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { Restore as RestoreIcon, SvgIconComponent } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { Avatar } from "./Avatar";
import { Help } from "./HelpMenu";

type MenuItem = {
  name: string;
  label: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
  location: LinkProps["to"];
  state?: LinkProps["state"];
};

export const LateralMenu = () => {
  const location = useLocation();
  const [exitModalParams, setExitModalParams] = useState<{
    title: string;
    action: () => void;
    actionType: "NEW" | "RESTART" | "EXISTING" | "EXIT";
  }>();
  const { waitingForResponse } = useMessagesContext();
  const { logout } = useAuthContext();

  const shouldDisplayExitConfirmation = !!waitingForResponse;

  const handleLogout = () => {
    if (!shouldDisplayExitConfirmation) {
      logout();
      return;
    }

    setExitModalParams({
      title: "SAIR DA CONTA",
      action: logout,
      actionType: "EXIT",
    });
  };

  const items = [
    {
      name: "home",
      label: "Início",
      icon: ChatIcon,
      location: ROUTE_PATHS.HOME,
    },
    {
      name: "threads",
      label: "Histórico",
      icon: RestoreIcon,
      location: ROUTE_PATHS.THREAD_HISTORY,
      state: location.state?.previousLocation ? undefined : { previousLocation: location.pathname },
    },
    {
      name: "word",
      label: "Word",
      icon: WordIcon,
      location: ROUTE_PATHS.USE_WORD,
    },
    {
      name: "settings",
      label: "Configurações",
      icon: ConfigIcon,
      location: ROUTE_PATHS.CLIENT_CONFIG,
    },
  ] satisfies MenuItem[];

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "3.5rem",
          height: "100%",
          backgroundColor: "common.black",
          paddingX: 1.5,
          paddingTop: 2,
          paddingBottom: 3,
          zIndex: 1,
          boxShadow: "none",
        }}
      >
        <Link to={ROUTE_PATHS.HOME}>
          <LexterLogoIcon height={32} />
        </Link>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 4,
          }}
        >
          {items.map(({ location, state, label, icon: Icon }, index) => (
            <Tooltip
              title={label}
              arrow
              placement="right"
              key={index}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 10],
                      },
                    },
                  ],
                },
              }}
            >
              <Link to={location} state={state}>
                <Icon
                  height={24}
                  style={{
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </Link>
            </Tooltip>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Avatar onLogout={handleLogout} />
          <Help />
        </Box>
      </Box>

      <ExitConfirmDialog
        open={!!exitModalParams}
        title={exitModalParams?.title || ""}
        onConfirm={() => {
          setExitModalParams(undefined);
          exitModalParams?.action();
        }}
        action="RESTART"
        onCancel={() => setExitModalParams(undefined)}
      />
    </>
  );
};
