import { ActionId } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "../../types";
import { Type } from "@sinclair/typebox";
import { v4 as uuidV4 } from "uuid";
import { skillInputToUserInput } from "@/utils/skillInputToUserInput";
import { userInputToText } from "@/utils/userInputToText";

const inputSchema = Type.Object({
  procedural_document: Type.String(),
});

const actionId = ActionId.SUMMARIZE_DOCUMENT;

export const summarizeDocumentSkill: Skill<typeof inputSchema> = {
  id: "summary_procedural_documents",
  name: "Resumir documento processual",
  credits: CreditPerSkill.INTERMEDIARY_SKILL,
  inputSchema,
  tags: ["litigation"],
  actionId,
  type: "payload",
  getPayload: (input) => {
    const procedural_document = skillInputToUserInput(input.procedural_document);

    return {
      skillId: ActionId.SUMMARIZE_DOCUMENT,
      requestId: uuidV4(),
      payload: {
        procedural_document,
      },
      messageToSave: `Criar um resumo com os dados informados no ${userInputToText(procedural_document)}.`,
    };
  },
};
