import React from "react";

export const Brand = () => (
  <div>
    <svg width="298" height="53" viewBox="0 0 299 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.947 14.6132C103.719 11.8033 107.224 10.3958 111.463 10.3958C115.4 10.3958 118.715 11.7817 121.413 14.5565L121.413 14.5568L121.413 14.557C124.149 17.2943 125.518 20.6843 125.518 24.7351C125.518 25.0699 125.462 25.7437 125.349 26.7594L125.349 26.7603L125.244 27.5012H103.782L103.792 27.5643C104.094 29.6069 104.946 31.2574 106.348 32.5082L106.349 32.5083C107.753 33.7592 109.459 34.3842 111.465 34.3842C112.978 34.3842 114.267 34.0627 115.331 33.4169L115.331 33.4166L115.332 33.4163C116.377 32.7478 117.077 32.0367 117.427 31.2829H124.657C124.465 31.9736 124.098 32.7542 123.554 33.6251L123.554 33.6253L123.554 33.6256C123.03 34.4865 122.261 35.4615 121.245 36.5528C120.272 37.5988 118.927 38.4787 117.2 39.1912C115.477 39.8653 113.564 40.2028 111.463 40.2028C107.224 40.2028 103.719 38.8161 100.947 36.0418C98.2123 33.23 96.8439 29.6521 96.8439 25.2993C96.8439 20.9464 98.2124 17.3873 100.947 14.6132ZM111.426 16.2145C109.661 16.2145 108.201 16.7387 107.052 17.7878C105.941 18.8037 105.191 20.1621 104.798 21.8576L104.782 21.925H117.579L117.567 21.8604C117.273 20.1956 116.587 18.8369 115.506 17.7892C114.456 16.7392 113.093 16.2145 111.426 16.2145Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.474 14.6132C198.246 11.8033 201.751 10.3958 205.99 10.3958C209.927 10.3958 213.242 11.7817 215.94 14.5565L215.94 14.5568L215.94 14.557C218.675 17.2943 220.045 20.6843 220.045 24.7351C220.045 25.0699 219.987 25.7436 219.876 26.7595L219.876 26.7604L219.77 27.5012H198.309L198.318 27.5643C198.621 29.6069 199.473 31.2574 200.875 32.5082L200.875 32.5083C202.28 33.7592 203.986 34.3842 205.992 34.3842C207.504 34.3842 208.794 34.0627 209.857 33.4169L209.858 33.4166L209.858 33.4163C210.904 32.7478 211.604 32.0367 211.954 31.2829H219.184C218.992 31.9736 218.625 32.7542 218.081 33.6251L218.08 33.6253L218.08 33.6256C217.556 34.4865 216.788 35.4615 215.772 36.5528C214.798 37.5988 213.454 38.4787 211.727 39.1912C210.004 39.8653 208.09 40.2028 205.99 40.2028C201.751 40.2028 198.246 38.8161 195.474 36.0418C192.739 33.23 191.371 29.6521 191.371 25.2993C191.371 20.9464 192.739 17.3873 195.474 14.6132ZM205.952 16.2145C204.187 16.2145 202.727 16.7387 201.579 17.7878C200.468 18.8037 199.717 20.1621 199.324 21.8576L199.309 21.925H212.105L212.094 21.8604C211.8 20.1956 211.114 18.8369 210.032 17.7892C208.982 16.7392 207.62 16.2145 205.952 16.2145Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.48 25.0164L127.202 10.9614H134.974L140.9 19.9933H143.728L149.655 10.9614H157.425L148.147 25.0164L157.707 39.638H149.937L143.729 30.0418H140.898L134.689 39.638H126.919L136.48 25.0164Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.077 32.3265V10.7915H70.6003V32.1731L78.2346 39.8074H94.5778V32.3307H78.6558V32.3265H78.077Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M232.453 18.2138H244.354V10.7915H232.607L225.027 18.3693V39.5984H232.449V18.7885H232.453V18.2138Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.036 18.0947H178.169V39.6193H170.866V18.0947H160.208V10.7915H189.036V18.0947Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.751 39.4867V10.8302H298.609V39.4867H291.751Z"
        fill="#9DEFBF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M270.049 40.0533C272.371 40.0533 274.425 39.4918 276.218 38.3713C277.04 37.8473 277.692 37.2885 278.172 36.696L278.205 36.6552H278.632L279.198 39.4869H284.945V21.1944C284.945 18.092 283.825 15.4985 281.582 13.4047L281.582 13.4044L281.582 13.4041C279.378 11.3142 276.481 10.2642 272.88 10.2642C269.275 10.2642 266.453 11.1664 264.404 12.9559C262.381 14.7221 261.192 16.8655 260.834 19.3875H267.693C267.866 18.4518 268.387 17.6104 269.241 16.8671L269.242 16.8663L269.243 16.8655C270.174 16.091 271.39 15.7075 272.88 15.7075C274.452 15.7075 275.71 16.2054 276.641 17.2133C277.606 18.2197 278.085 19.5505 278.085 21.1965V21.8728H271.464C267.629 21.8728 264.714 22.7369 262.701 24.4504C260.691 26.1639 259.683 28.4724 259.683 31.3883C259.683 33.809 260.613 35.8548 262.478 37.5337C264.378 39.2108 266.897 40.0533 270.049 40.0533ZM278.085 27.9904V27.0319H272.03C270.228 27.0319 268.857 27.3395 267.934 27.9711C267.003 28.6079 266.541 29.5644 266.541 30.822C266.541 31.9836 266.948 32.9202 267.768 33.6222L267.77 33.6232L267.771 33.6242C268.591 34.2868 269.731 34.6121 271.181 34.6121C273.351 34.6121 275.043 34.0403 276.24 32.8821L276.24 32.882C277.474 31.6868 278.085 30.0509 278.085 27.9904Z"
        fill="#9DEFBF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M246.511 39.4869V31.7804H254.217V39.4869H246.511Z"
        fill="#9DEFBF"
      />
      <path
        d="M38.1579 33.3111L48.9996 44.1528C50.7059 41.8536 52.0674 39.2842 53.0095 36.5195L44.4188 27.9287L44.4231 27.9243L43.9861 27.4873L53.0139 18.4595C52.0718 15.6947 50.7103 13.1254 49.004 10.824L38.1623 21.6657L38.1579 33.3111Z"
        fill="#9DEFBF"
      />
      <path
        d="M26.0884 45.3872L26.5254 44.9502L35.562 53.9867C38.3245 53.0424 40.8917 51.6787 43.1887 49.9702L32.3448 39.1263H20.6994L9.83572 49.99C12.1349 51.6963 14.7065 53.0578 17.4712 53.9999L26.0862 45.385L26.0884 45.3872Z"
        fill="#9DEFBF"
      />
      <path
        d="M14.8867 21.6663L4.0165 10.796C2.308 13.093 0.944284 15.6602 0 18.4228L8.62812 27.0509L8.62372 27.0553L9.06073 27.4923L0.00219601 36.5552C0.94648 39.3178 2.3124 41.8849 4.02089 44.1819L14.8889 33.3139L14.8867 21.6663Z"
        fill="#9DEFBF"
      />
      <path
        d="M20.6995 15.8555H32.345L43.191 5.00941C40.894 3.30092 38.3269 1.93719 35.5643 0.990715L26.9581 9.59687L26.9538 9.59248L26.5168 10.0295L17.467 0.977539C14.7022 1.91963 12.1329 3.28115 9.83148 4.98745L20.6995 15.8555Z"
        fill="#9DEFBF"
      />
    </svg>
  </div>
);
