import { ConditionalSkillStep, SkillStep } from "@/core/skillSteps/types";
import { hearingsScriptSkillStep1 } from "./step1";
import { hearingsScriptSkillStep2 } from "./step2";
import { hearingsScriptSkillStep3 } from "./step3";
import { hearingsScriptSkillStep4 } from "./step4";
import { hearingsScriptSkillStep5 } from "./step5";

export const hearingsScriptSkillSteps: Array<SkillStep | ConditionalSkillStep[]> = [
  hearingsScriptSkillStep1,
  hearingsScriptSkillStep2,
  hearingsScriptSkillStep3,
  hearingsScriptSkillStep4,
  hearingsScriptSkillStep5,
];
