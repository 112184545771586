import { Box, Typography } from "@mui/material";
import { Area, LegalPieceMacroType, LegalPieceMicroType } from "@/contexts/MessagesContext";
import React, { useMemo } from "react";
import { FileInput } from "../../FlowInput";

type LegalPieceTextMap = {
  [x in LegalPieceMacroType]?: {
    [x in Area]?: {
      [x in LegalPieceMicroType]?: string;
    };
  } & {
    default?: string;
  };
};

const titles: LegalPieceTextMap = {
  [LegalPieceMacroType.RESPONSE]: {
    [Area.CIVIL]: {
      [LegalPieceMicroType.CONTESTATION]: "Para criar a peça de resposta envie a petição inicial:",
      [LegalPieceMicroType.REPLICATION]: "Para criar a peça de resposta envie a Contestação:",
    },
    [Area.LABOR]: {
      [LegalPieceMicroType.CONTESTATION]: "Para criar a peça de resposta envie a reclamação trabalhista:",
      [LegalPieceMicroType.REPLICATION]: "Para criar a peça de resposta envie a Contestação:",
    },
    default: "Envie a peça que pretende responder:",
  },
  [LegalPieceMacroType.APPEAL]: {
    default: "Enviar a decisão judicial do Caso:",
  },
};

const descriptions: LegalPieceTextMap = {
  [LegalPieceMacroType.RESPONSE]: {
    [Area.CIVIL]: {
      [LegalPieceMicroType.CONTESTATION]: "",
      [LegalPieceMicroType.REPLICATION]: "",
    },
    [Area.LABOR]: {
      [LegalPieceMicroType.CONTESTATION]: "",
      [LegalPieceMicroType.REPLICATION]: "",
    },
    default:
      "Para criar uma resposta, você precisa realizar o envio da peça que será respondida. Como a petição inicial ou a contestação do caso.",
  },
};

export const ReferenceUpload = ({
  onFileChange,
  show,
  documentMacroType,
  area,
  documentMicroType,
}: {
  onFileChange: (files: File[]) => void;
  show: boolean;
  documentMacroType: LegalPieceMacroType;
  area: Area;
  documentMicroType: LegalPieceMicroType;
}) => {
  const title = useMemo((): string | undefined => {
    const macroTypeTitle = titles[documentMacroType];
    return macroTypeTitle?.[area]?.[documentMicroType] ?? macroTypeTitle?.default;
  }, [documentMacroType, area, documentMicroType]);

  const description = useMemo((): string | undefined => {
    const macroTypeDescription = descriptions[documentMacroType];
    return macroTypeDescription?.[area]?.[documentMicroType] ?? macroTypeDescription?.default;
  }, [documentMacroType, area, documentMicroType]);

  return (
    <Box hidden={!show}>
      <Box sx={{ mb: 1, pb: 1 }}>
        <Typography variant="preTitle" sx={{ whiteSpace: "normal" }}>
          {title}
        </Typography>
        {description && (
          <Box sx={{ mt: 1 }}>
            <Typography variant="multiLineBody">{description}</Typography>
          </Box>
        )}
      </Box>
      <FileInput id="input_upload_file" onChange={onFileChange} />
    </Box>
  );
};
