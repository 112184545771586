import React, { useEffect } from "react";

import {
  Edit,
  MoreHoriz as MoreHorizIcon,
  StarBorder as StarBorderIcon,
  Star as StarFillIcon,
} from "@mui/icons-material";
import { Box, CircularProgress, Divider, Input, Popover, Typography } from "@mui/material";

import { useMessagesContext } from "@/contexts/MessagesContext";
import { ExitConfirmDialog } from "@/components/ExitConfirmDialog";
import { Loading } from "@/components/Loading";
import { ModalLeft } from "@/components/Modal/ModalLeft";
import { LoadingState, OrderThreadsBy, Thread } from "@/contexts/ThreadsContext/types";
import { useThreadsContext } from "@/contexts/ThreadsContext/useThreadsContext";
import { formatDateShortMonth } from "@/contexts/ThreadsContext/utils";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { WebToast } from "@/taskpane/components/core/Toast";
import { palette } from "@/theme/palette";
import { DeleteThreadModal } from "../DeleteThreadModal";
import { OptionsMenu } from "../OptionsMenu";
import { ThreadsHeader } from "../ThreadsHeader";
import { ThreadsScreen } from "../ThreadsScreen";

const DEFAULT_PAGE_SIZE = 10;
const MAX_SCROLL_POSITION = 56;

export const Threads = ({ onClose, component }: { onClose: () => void; component: "MODAL" | "SCREEN" }) => {
  const {
    threads,
    loadThreadsList,
    refetchThreads,
    loadingState,
    deleteThread,
    renameThread,
    toggleFavoriteThread,
    orderThreadsOptions,
    updateListOrder,
    searchThreads,
    openThread: currentOpenThread,
  } = useThreadsContext();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false);
  const [currentEditingEditingThreadName, setCurrentEditingThreadName] = React.useState("");
  const [currentEditingEditingThreadId, setCurrentEditingThreadId] = React.useState<string | null>(null);
  const [currentListOrder, setCurrentListOrder] = React.useState<OrderThreadsBy>("UPDATED_AT");
  const [newChatModalOpen, setNewChatModalOpen] = React.useState(false);
  const [openThreadsModalOpen, setOpenThreadsModalOpen] = React.useState(false);
  const [querySearch, setQuerySearch] = React.useState<string>("");
  const { waitingForResponse } = useMessagesContext();
  const shouldDisplayExitConfirmation = !!waitingForResponse;
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
  });
  const hasFullList = threads.data.length === threads.totalResults;
  const navigate = useNavigate();

  const fetchThreads = async () => {
    if (loadingState === "REFETCHING" || hasFullList) return;

    await refetchThreads({
      page: paginationModel.page + 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });

    setPaginationModel((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  useEffect(() => {
    loadThreadsList(paginationModel);
  }, []);

  const goToNewChat = async () => {
    onClose();
    navigate(ROUTE_PATHS.NEW_CHAT, { replace: true });
  };

  const handleOpenNewChatModal = () => {
    if (location.pathname.startsWith(ROUTE_PATHS.CHAT) && shouldDisplayExitConfirmation) {
      setNewChatModalOpen(true);
    } else {
      goToNewChat();
    }
  };

  const handleRenameThread = async (threadName: string) => {
    if (!currentEditingEditingThreadId) return WebToast.error("Erro ao renomear chat");

    const response = await renameThread({ threadId: currentEditingEditingThreadId, name: threadName });
    if (response.isFailure) {
      handleCloseRenameModal();
      return WebToast.error(response.getValue());
    }
    WebToast.success(response.getValue());
    handleCloseRenameModal();
  };

  const handleOpenRenameModal = (threadId: string, name: string) => {
    setCurrentEditingThreadName(name);
    setCurrentEditingThreadId(threadId);
  };

  const handleCloseRenameModal = () => {
    setCurrentEditingThreadId(null);
  };

  const handleDeleteThread = async () => {
    if (!currentEditingEditingThreadId) return WebToast.error("Erro ao excluir chat");

    const response = await deleteThread(currentEditingEditingThreadId);
    if (response.isFailure) {
      handleCloseDeleteModal();
      return WebToast.error(response.getValue());
    }
    WebToast.success(response.getValue());
    if (currentOpenThread?.id === currentEditingEditingThreadId) {
      navigate("/");
    }
    handleCloseDeleteModal();
  };

  const handleOpenDeleteModal = (threadId: string, name: string) => {
    setIsOpenDeleteModal(true);
    setCurrentEditingThreadName(name);
    setCurrentEditingThreadId(threadId);
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
    setCurrentEditingThreadId(null);
  };

  const handleFavorite = async (threadId: string) => {
    setCurrentEditingThreadId(threadId);
    await toggleFavoriteThread(threadId);
    setCurrentEditingThreadId(null);
  };

  const handleUpdateOrder = async (order: OrderThreadsBy) => {
    setCurrentListOrder(order);
    await updateListOrder(order);
  };

  const openThread = async (threadId: string) => {
    handleCloseThreadModal();
    onClose();
    navigate(ROUTE_PATHS.THREAD.replace(":threadId", threadId));
  };

  const handleOpenThreadModal = (threadId: string, name: string) => {
    setCurrentEditingThreadName(name);
    setCurrentEditingThreadId(threadId);
    if (location.pathname.startsWith(ROUTE_PATHS.CHAT) && shouldDisplayExitConfirmation) {
      setOpenThreadsModalOpen(true);
    } else {
      openThread(threadId);
    }
  };

  const handleCloseThreadModal = () => {
    setCurrentEditingThreadName("");
    setCurrentEditingThreadId(null);
    setOpenThreadsModalOpen(false);
  };

  const handleSearch = async (query: string) => {
    await searchThreads(query);
  };

  const ThreadsComponent = component === "MODAL" ? ModalLeft : ThreadsScreen;
  const handleNewChat = component === "MODAL" ? handleOpenNewChatModal : goToNewChat;
  const listComponentId = component === "MODAL" ? "ThreadsList" : "ThreadsScreenList";

  return (
    <>
      <ThreadsComponent
        title="Histórico"
        header={
          <ThreadsHeader
            goToNewChat={handleNewChat}
            orderThreadsOptions={orderThreadsOptions}
            currentListOrder={currentListOrder}
            updateOrder={handleUpdateOrder}
            currentQuerySearch={querySearch}
            setCurrentQuerySearch={setQuerySearch}
            handleSearch={handleSearch}
          />
        }
        open
        onClose={onClose}
        onScroll={() => {
          const listComponent = document.getElementById(listComponentId);
          if (!listComponent) return;

          const scrollPosition = listComponent?.scrollHeight - listComponent?.offsetHeight - listComponent?.scrollTop;

          if (scrollPosition < MAX_SCROLL_POSITION) {
            fetchThreads();
          }
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingBottom: "16px",
          }}
        >
          <Loading isLoading={loadingState === "LOADING"}>
            <Box
              sx={{
                paddingBottom: "16px",
              }}
            >
              {Boolean(threads.data.length) ? (
                threads.data.map((thread, index) => {
                  return (
                    <Box key={index}>
                      <ThreadItem
                        thread={thread}
                        handleOpenRenameModal={handleOpenRenameModal}
                        handleOpenDeleteModal={handleOpenDeleteModal}
                        handleRenameThread={handleRenameThread}
                        toggleFavorite={handleFavorite}
                        openThread={handleOpenThreadModal}
                        loadingState={loadingState}
                        currentEditingEditingThreadId={currentEditingEditingThreadId}
                        menuPosition={component === "MODAL" ? "left" : "right"}
                      />
                      <Divider />
                    </Box>
                  );
                })
              ) : (
                <Box
                  sx={{ minHeight: 200, padding: "20px" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="multiLineBody" sx={{ textAlign: "center" }}>
                    {Boolean(querySearch)
                      ? "Nenhuma sessão encontrada com os termos buscados."
                      : "Você ainda não possui sessões salvas.\n Realize o primeiro uso do Assistente Lexter para criar sua primeira sessão."}
                  </Typography>
                </Box>
              )}
            </Box>
          </Loading>
          <Box
            sx={{
              display: loadingState === "LOADING" ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "48px !important",
            }}
          >
            <Loading isLoading={loadingState === "REFETCHING"}>
              {hasFullList ? <Typography variant="body2">Fim da lista</Typography> : <CircularProgress />}
            </Loading>
          </Box>
        </Box>
      </ThreadsComponent>
      <DeleteThreadModal
        isOpen={isOpenDeleteModal}
        onCancel={handleCloseDeleteModal}
        onConfirm={handleDeleteThread}
        chatName={currentEditingEditingThreadName}
        isLoading={loadingState === "DELETING"}
      />
      <ExitConfirmDialog
        open={newChatModalOpen}
        title="ABRIR NOVA SESSÃO"
        action="NEW"
        onConfirm={goToNewChat}
        onCancel={() => setNewChatModalOpen(false)}
      />
      <ExitConfirmDialog
        open={openThreadsModalOpen}
        title={"ABRIR SESSÃO JÁ EXISTENTE"}
        chatName={currentEditingEditingThreadName}
        action="EXISTING"
        onConfirm={() => openThread(currentEditingEditingThreadId!)}
        onCancel={handleCloseThreadModal}
      />
    </>
  );
};

const ThreadItem = ({
  thread,
  handleOpenDeleteModal,
  handleOpenRenameModal,
  handleRenameThread,
  toggleFavorite,
  openThread,
  loadingState,
  currentEditingEditingThreadId,
  menuPosition,
}: {
  thread: Thread;
  handleOpenDeleteModal: (id: string, name: string) => void;
  handleOpenRenameModal: (id: string, name: string) => void;
  handleRenameThread: (name: string) => void;
  toggleFavorite: (id: string) => void;
  openThread: (id: string, name: string) => void;
  loadingState: LoadingState;
  currentEditingEditingThreadId: string | null;
  menuPosition: "left" | "right";
}) => {
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const [renameAnchorEl, setRenameAnchorEl] = React.useState<null | HTMLElement>(null);
  const [newThreadName, setNewThreadName] = React.useState(thread.name);
  const isOptionsOpen = Boolean(optionsMenuAnchorEl);
  const isRenameOpen = Boolean(renameAnchorEl);
  const loadingItem = ["RENAMING", "FAVORITE"];
  const isLoading = loadingItem.includes(loadingState) && currentEditingEditingThreadId === thread.id;

  const openOptionsMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setOptionsMenuAnchorEl(event.currentTarget);
  };

  const closeOptionsMenu = () => {
    setOptionsMenuAnchorEl(null);
  };

  const closeRename = () => {
    setRenameAnchorEl(null);
  };

  const openDelete = () => {
    handleOpenDeleteModal(thread.id, thread.name);
    closeOptionsMenu();
  };

  const openRename = () => {
    const newRenameAnchorEl = document.getElementById(`thread-item-rename-el-${thread.id}`);
    setRenameAnchorEl(newRenameAnchorEl);
    handleOpenRenameModal(thread.id, thread.name);
    closeOptionsMenu();
  };

  const renameThread = () => {
    if (newThreadName === thread.name) {
      closeRename();
      return;
    }
    handleRenameThread(newThreadName);
    closeRename();
  };

  const handleFavorite = () => {
    toggleFavorite(thread.id);
  };

  const handleOpenThread = () => {
    if (isRenameOpen) return;
    openThread(thread.id, thread.name);
  };

  const formattedDate = formatDateShortMonth(thread.lastEventDate ?? thread.createdAt);

  const FavoriteIcon = () => {
    if (thread.favorite) {
      return <StarFillIcon onClick={handleFavorite} sx={{ cursor: "pointer", fill: palette.common?.yellow }} />;
    }
    return <StarBorderIcon onClick={handleFavorite} sx={{ cursor: "pointer" }} />;
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        ":hover": {
          backgroundColor: "common.softBlue",
        },
        "& .hidden-button": {
          visibility: "hidden",
        },
        "&:hover .hidden-button": {
          visibility: "visible",
        },
        paddingY: isLoading ? "24px" : undefined,
      }}
    >
      {isLoading ? (
        <Box sx={{ display: "flex", height: "48px", width: "100%", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress size="24px" />
        </Box>
      ) : (
        <>
          <Box
            onClick={handleOpenThread}
            sx={{
              padding: "24px 0px 24px 24px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "calc(100% - 48px)",
            }}
          >
            <Box sx={{ height: "48px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <Typography id={`thread-item-rename-el-${thread.id}`} variant="title">
                {thread.name}
              </Typography>
              <Popover
                open={isRenameOpen}
                onClose={() => {
                  renameThread();
                }}
                anchorEl={renameAnchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{
                  top: "-8px !important",
                  "& .MuiPaper-root": {
                    boxShadow: "none",
                    borderRadius: "0",
                  },
                  "& .MuiModal-backdrop": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiBackdrop-root": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Input
                  sx={{
                    width: "332px",
                    padding: "0",
                    backgroundColor: "common.softBlue",
                  }}
                  onChange={(e) => {
                    setNewThreadName(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      renameThread();
                    }
                  }}
                  value={newThreadName}
                  endAdornment={<Edit fontSize="small" />}
                />
              </Popover>
              <Typography sx={{ justifySelf: "baseline" }} variant="body2">
                <strong>ÚLTIMA ATUALIZAÇÃO:</strong> {formattedDate}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "24px 16px 24px 0px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MoreHorizIcon
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
              onClick={openOptionsMenu}
              className="hidden-button"
            />
            <FavoriteIcon />
          </Box>
        </>
      )}
      <OptionsMenu
        open={isOptionsOpen}
        anchorEl={optionsMenuAnchorEl!}
        onClose={closeOptionsMenu}
        openDelete={openDelete}
        openRename={openRename}
        openThread={handleOpenThread}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuPosition,
        }}
        sx={{ left: menuPosition === "left" ? "24px !important" : undefined }}
      />
    </Box>
  );
};
