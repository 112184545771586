import { PaletteOptions } from "@mui/material";

const legalBlack = "#16222C";

export const palette: PaletteOptions = {
  // The palette mode, can be light or dark.
  mode: "light",
  // A collection of common colors.
  common: {
    black: legalBlack,
    white: "#fff",
    shade: "#4A4A68",
    lightShade: "#C5C5D0",
    mediumShade: "#8C8CA1",
    dorian: "#ECF1F4",
    softBlue: "#EAEFFC",
    dorianHover: "#d4d8df",
    backdrop: "rgba(22, 34, 44, 0.6)",
    yellow: "#FFC700",
  },
  // The colors used to represent primary interface elements for a user.
  primary: {
    main: "#2457DB",
    light: "#E9EEFB",
    dark: "#2C50AB",
    contrastText: "#fff",
  },
  // The colors used to represent secondary interface elements for a user
  secondary: {
    main: "#9DEFBF",
    light: "#E7FBEF",
    dark: "#96D9B1",
    contrastText: legalBlack,
  },
  // The colors used to represent interface elements that the user should be made aware of.
  error: {
    main: "#FF0000",
    light: "#ffbfbf",
    dark: "#D32F2F",
    contrastText: "#fff",
  },
  // The colors used to represent potentially dangerous actions or important messages.
  warning: {
    main: "#FFC700",
    light: "#FFE174",
    dark: "#E6B917",
    contrastText: legalBlack,
  },
  // The colors used to present information to the user that is neutral and not necessarily important.
  info: {
    main: "#9DEFBF",
    light: "#E7FBEF",
    dark: "#96D9B1",
    contrastText: legalBlack,
  },
  // The colors used to indicate the successful completion of an action that user triggered.
  success: {
    main: "#31D0AA",
    light: "#98E8D4",
    dark: "#3EBD9E",
    contrastText: "#fff",
  },
  // The grey colors.
  grey: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
    A100: "#f5f5f5",
    A200: "#eeeeee",
    A400: "#bdbdbd",
    A700: "#616161",
  },
  // The colors used to style the text.
  text: {
    // The most important text.
    // primary: 'rgba(0, 0, 0, 0.87)',
    primary: "#4A4A68",
    // Secondary text.
    // secondary: 'rgba(0, 0, 0, 0.6)',
    secondary: "#8C8CA1",
    // Disabled text have even lower visual prominence.
    // disabled: 'rgba(0, 0, 0, 0.38)',
    disabled: "#C5C5D0",
  },
  // The color used to divide different elements.
  divider: "rgba(0, 0, 0, 0.12)",
  // The background colors used to style the surfaces.
  // Consistency between these values is important.
  background: {
    paper: "#fff",
    default: "#ECF1F4",
  },
  // The colors used to style the action elements.
  action: {
    // The color of an active action like an icon button.
    active: "rgba(0, 0, 0, 0.54)",
    // The color of an hovered action.
    hover: "rgba(0, 0, 0, 0.04)",
    hoverOpacity: 0.04,
    // The color of a selected action.
    selected: "rgba(0, 0, 0, 0.08)",
    selectedOpacity: 0.08,
    // The color of a disabled action.
    disabled: "rgba(0, 0, 0, 0.26)",
    // The background color of a disabled action.
    disabledBackground: "rgba(0, 0, 0, 0.12)",
    disabledOpacity: 0.38,
    focus: "rgba(0, 0, 0, 0.12)",
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  plan: {
    trial: {
      background: "#31D0AA",
      text: "#FFFFFF",
    },
    free: {
      background: "#31D0AA",
      text: "#FFFFFF",
    },
    initial: {
      background: "#FDDBC7",
      text: "#4A4A68",
    },
    basic: {
      background: "#FFC700",
      text: "#4A4A68",
    },
    intermediary: {
      background: "#2457DB",
      text: "#FFFFFF",
    },
    advanced: {
      background: "#8C8CA1",
      text: "#FFFFFF",
    },
    professional: {
      background: "#C5C5D0",
      text: "#4A4A68",
    },
    custom: {
      background: "#FDDBC7",
      text: "#4A4A68",
    },
  },
  score: {
    high: {
      background: "#31D0AA",
      text: "#4A4A68",
    },
    medium: {
      background: "#FFC700",
      text: "#4A4A68",
    },
    low: {
      background: "#FDDBC7",
      text: "#4A4A68",
    },
  },
  slider: {
    rail: "#DDE7EE",
  },
};
