import { buildSkillStep, createUploadFileField } from "@/core/skillSteps/builder";

export const createAppealSkillStep5 = buildSkillStep({
  title: "Criar Recurso - Passo 5",
  fields: [
    createUploadFileField({
      name: "reference_piece",
      ui: {
        label: "Enviar a decisão judicial do Caso:",
      },
    }),
  ],
});
