import { SvgIconComponent } from "@mui/icons-material";
import { MenuItem as MuiMenuItem, Tooltip, Typography } from "@mui/material";
import React from "react";

export interface MenuItemProps {
  label: string;
  icon: SvgIconComponent | React.VFC<React.SVGProps<SVGSVGElement>>;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
}

export const MenuItem = ({ label, icon: Icon, onClick, disabled, tooltip }: MenuItemProps) => {
  return (
    <Tooltip title={tooltip || ""} placement="bottom" arrow disableHoverListener={!disabled}>
      <div>
        <MuiMenuItem
          sx={{
            p: "16px",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: "common.lightShade",
            "&:last-child": { borderBottom: "none" },
            ":hover": {
              backgroundColor: "common.dorianHover",
            },
            "&:hover span": {
              fontWeight: "700",
              textDecorationLine: "underline",
            },
          }}
          onClick={onClick}
          disabled={disabled}
        >
          <Icon fontSize="medium" height={24} width={24} style={{ marginRight: "16px", color: "text.primary" }} />
          <Typography variant="body1" component="span">
            {label}
          </Typography>
        </MuiMenuItem>
      </div>
    </Tooltip>
  );
};
