import { env } from "@/constants/environment";
import { ActionId } from "@/contexts/MessagesContext";
import { useApi } from "@/hooks/useApi";
import { CreditsApiResponse, CreditsHistoryApiResponseItem } from "@/hooks/useApi/types";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useHeap } from "@/hooks/useHeap";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { openUrl } from "@/utils/openUrl";
import { DateTime } from "luxon";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaywallModal } from "./components/PaywallModal";
import { CreditsContext } from "./context";
import { PlanType } from "./types";
import { useUserGuiding } from "@/hooks/useUserGuiding";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import { useAuthContext } from "@/contexts/AuthContext";
import { logger } from "@/core/logger";

interface CreditsProviderProps {
  children: ReactNode;
}
export const CreditsProvider = ({ children }: CreditsProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [credits, setCredits] = useState<CreditsApiResponse>();
  const [error, setError] = useState(false);
  const { getCredits, checkCredits, getCreditsHistory, getPaymentUserPortal } = useApi();
  const userGuiding = useUserGuiding();
  const heap = useHeap();
  const { user } = useAuthContext();
  const isFreemium = useMemo(() => credits?.companyPlan?.plan === PlanType.FREEMIUM, [credits?.companyPlan?.plan]);
  const isTrial = useMemo(() => credits?.companyPlan?.plan === PlanType.TRIAL, [credits?.companyPlan?.plan]);
  const isActivePlan = useMemo(() => credits?.companyPlan?.status === "ACTIVE", [credits?.companyPlan?.status]);
  const [paywallModalOpen, setPaywallModalOpen] = useState(false);
  const navigate = useNavigate();
  const nextBillingDate = credits?.companyPlan?.nextBillingDate;
  const flags = useFeatureFlags();
  const nextBillingDateFormatted = useMemo(() => {
    if (!nextBillingDate) return undefined;

    return DateTime.fromISO(nextBillingDate)
      .toFormat("dd LLL yyyy")
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .replace(".", "");
  }, [nextBillingDate]);

  const cancellationAtFormatted = useMemo(() => {
    if (!credits?.companyPlan?.cancellingAt) return undefined;

    return DateTime.fromISO(credits.companyPlan.cancellingAt).toFormat("dd 'de' MMMM 'de' yyyy");
  }, [credits?.companyPlan?.cancellingAt]);

  const signalInsufficientCredits = () => {
    setPaywallModalOpen(true);
  };

  const refreshCredits = async () => {
    setLoading(true);
    setError(false);

    try {
      const response = await getCredits();
      const planType = response.companyPlan?.plan.toString();
      userGuiding.identify(user, { credits: response.availableCredit, plan: planType });
      heap.addUserProperties({ CurrentPlan: planType });
      setCredits(response);
    } catch (error) {
      logger.error("refreshCredits", error);
      setError(true);
    }

    setLoading(false);
  };

  const getFilteredCommittedActions = async () => {
    const filterActionsAndCommitted = (response: CreditsHistoryApiResponseItem[]) => {
      return response.filter((item) => item.action?.startsWith("ESCREVER") && item.status === "COMMITTED");
    };

    return getCreditsHistory().then((response) => {
      return filterActionsAndCommitted(response);
    });
  };

  const openBilling = React.useCallback(async () => {
    if (isFreemium || isTrial) {
      if (flags.internalPaymentPageEnabled) {
        if (outsideOfficeClient()) {
          navigate(ROUTE_PATHS.INTERNAL_PAYMENT);
        } else {
          openBillingForFreeUsers();
        }
      } else {
        navigate(ROUTE_PATHS.PLANS);
      }
    } else {
      if (user && flags.paymentUserPortalEnabled) {
        const response = await getPaymentUserPortal({ companyId: user.companyId });
        openUrl(response.paymentUserPortal, true);
      } else {
        openClientBillingPortal(user?.userEmail);
      }
    }
  }, [user?.userEmail, isFreemium, isTrial, flags.internalPaymentPageEnabled, flags.paymentUserPortalEnabled]);

  useEffect(() => {
    refreshCredits();
  }, []);

  const checkCreditAvailability = async (actionId: ActionId) => {
    try {
      const { hasCredits } = await checkCredits({ action: { id: actionId } });

      if (!hasCredits) {
        signalInsufficientCredits();
        navigate(ROUTE_PATHS.CHAT);
      }
    } catch (error) {
      logger.error("checkCreditAvailability", error);
    }
  };

  const calculateTotalCredits = () => {
    if (!credits) return 0;
    return credits?.availableCredit + credits?.reservedCredit + credits?.periodSpend;
  };

  return (
    <CreditsContext.Provider
      value={{
        credits: credits?.availableCredit,
        totalCredits: calculateTotalCredits(),
        periodSpend: credits?.periodSpend,
        planType: credits?.companyPlan?.plan,
        nextBillingDate,
        nextBillingDateFormatted,
        refreshCredits,
        signalInsufficientCredits,
        openBilling,
        loading,
        error,
        isFreemium,
        isTrial,
        isActivePlan,
        checkCreditAvailability,
        getFilteredCommittedActions,
        cancellationAtFormatted,
      }}
    >
      {children}

      <PaywallModal
        open={paywallModalOpen}
        onClose={() => setPaywallModalOpen(false)}
        onOpenBilling={() => {
          openBilling();
          setPaywallModalOpen(false);
        }}
      />
    </CreditsContext.Provider>
  );
};

const openBillingForFreeUsers = () => {
  const url = env.IS_PRODUCTION
    ? "https://assistente.lexter.ai/app/payment"
    : "https://assistente-staging.lexter.ai/app/payment";

  openUrl(url);
};

export const openClientBillingPortal = (userEmail?: string) => {
  let url = env.IS_PRODUCTION
    ? "https://billing.stripe.com/p/login/4gw6qSaKc7DT5Ww288"
    : "https://billing.stripe.com/p/login/test_eVaaGQe186vQb3adQQ";

  if (userEmail) {
    url = `${url}?prefilled_email=${encodeURIComponent(userEmail)}`;
  }

  openUrl(url);
};
