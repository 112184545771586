import { logger } from "@/core/logger";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.mjs";

export async function getPdfPageCount(file: File): Promise<number | null> {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await pdfjsLib.getDocument(arrayBuffer).promise;

    return pdfDoc.numPages;
  } catch (e) {
    logger.error("Error getting pdf page count", e);
    return null;
  }
}
