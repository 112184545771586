import React, { useMemo } from "react";

import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/Loading";
import { useNavigate } from "react-router-dom";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { ModalRight } from "@/components/Modal/ModalRight";
import { useLogin } from "@/contexts/AuthContext/hooks/useLogin";
import { LoginPassword } from "@/core/LoginPassword";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { WebToast } from "@/taskpane/components/core/Toast";
import { typography } from "@/theme/typography";
import { UpdatePasswordErrors } from "@/contexts/AuthContext";

type Form = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export function ClientConfigChangePasswordModal() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<Form>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isValidOldPassword, setIsValidOldPassword] = React.useState<boolean>(true);
  const [showOldPassword, setShowOldPassword] = React.useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState<boolean>(false);
  const { updatePassword } = useLogin();

  async function updateUserProfile() {
    setIsLoading(true);

    if (!form?.oldPassword || !form?.newPassword || !form?.confirmPassword) {
      return;
    }

    const result = await updatePassword(form.oldPassword, form.newPassword);

    setIsLoading(false);
    if (result.isLeft()) {
      switch (result.value) {
        case UpdatePasswordErrors.INVALID_PASSWORD:
          setIsValidOldPassword(false);
          return WebToast.error("Senha atual inválida");
        case UpdatePasswordErrors.GENERIC:
          return WebToast.error("Falha ao alterar a senha do usuário");
        default:
          return WebToast.error("Falha ao alterar a senha do usuário");
      }
    }

    goBackToSettings();
    return WebToast.success("Alteração feita com sucesso");
  }

  const goBackToSettings = () => {
    navigate(ROUTE_PATHS.CLIENT_CONFIG, { replace: true });
  };

  function handleUpdateUserProfile() {
    updateUserProfile();
  }

  function handleChangeForm<K extends keyof Form>(prop: K) {
    return (value: string) =>
      setForm((prevState) => ({
        ...prevState,
        [prop]: value,
      }));
  }

  const passwordErrors = useMemo(() => {
    const response = LoginPassword.fromString(form.newPassword);

    if (response.isLeft()) {
      return response.value.errors;
    }
    return [];
  }, [form.newPassword]);

  const isValidConfirmPassword = useMemo(() => {
    return form.newPassword === form.confirmPassword;
  }, [form.newPassword, form.confirmPassword]);

  const allowSaveButton = useMemo(() => {
    const isNotEmpty = (value: string) => value.length > 0;

    return (
      isNotEmpty(form.oldPassword) &&
      isNotEmpty(form.newPassword) &&
      isNotEmpty(form.confirmPassword) &&
      isValidConfirmPassword
    );
  }, [form.confirmPassword, form.newPassword, form.oldPassword, isValidConfirmPassword]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Alterar Senha"}
        size={"SMALL"}
        onClose={goBackToSettings}
        footer={
          form &&
          !isLoading && (
            <ModalFooter
              confirmLabel={"ALTERAR SENHA"}
              onConfirm={handleUpdateUserProfile}
              cancelLabel="CANCELAR"
              onCancel={goBackToSettings}
              saveButtonDisabled={!allowSaveButton}
            />
          )
        }
        open={true}
      >
        <Loading isLoading={isLoading}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              {form && (
                <>
                  <PasswordField
                    hasError={!isValidOldPassword}
                    isVisible={showOldPassword}
                    label="SENHA ATUAL"
                    placeholder="Digite a senha atual"
                    onChange={handleChangeForm("oldPassword")}
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    value={form?.oldPassword.toString()}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <PasswordField
                      hasError={form?.newPassword.length > 0 && passwordErrors.length > 0}
                      isVisible={showNewPassword}
                      label="SENHA"
                      placeholder="Crie uma senha para acesso"
                      onChange={handleChangeForm("newPassword")}
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      value={form?.newPassword.toString()}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        paddingLeft: "4px",
                      }}
                    >
                      <Step
                        title="uma letra"
                        isValid={form?.newPassword.length === 0 || !passwordErrors.includes("MUST_HAVE_LETTER")}
                      />
                      <Step
                        title="um número ou um caracter especial"
                        isValid={
                          form?.newPassword.length === 0 ||
                          !passwordErrors.includes("MUST_HAVE_NUMBER_OR_SPECIAL_CHARACTER")
                        }
                      />
                      <Step
                        title="mínimo de 8 caracteres"
                        isValid={form?.newPassword.length === 0 || !passwordErrors.includes("MUST_HAVE_MINIMUM_LENGTH")}
                      />
                    </Box>
                  </Box>

                  <PasswordField
                    hasError={!isValidConfirmPassword}
                    isVisible={showConfirmPassword}
                    label="CONFIRMAR SENHA"
                    placeholder="Confirme sua senha de acesso"
                    onChange={handleChangeForm("confirmPassword")}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    value={form?.confirmPassword.toString()}
                  />
                </>
              )}
            </Box>
          </Box>
        </Loading>
      </ModalRight>
    </Box>
  );
}

function ModalFooter({
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  saveButtonDisabled,
}: {
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  saveButtonDisabled: boolean;
}) {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "8px",
        margin: "0 auto",
      }}
    >
      <Button
        disabled={saveButtonDisabled}
        onClick={onConfirm}
        sx={{
          height: "48px",
          color: "primary.contrastText",
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.main",
          },
          ":disabled": {
            backgroundColor: "text.secondary",
            color: "common.white",
          },
          borderRadius: "8px",
        }}
      >
        {confirmLabel}
      </Button>
      <Button onClick={onCancel}>{cancelLabel}</Button>
    </Box>
  );
}

function Step({ title, isValid }: { title: string; isValid: boolean }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
      <CircleIcon
        sx={{
          width: "8px",
          height: "8px",
          color: isValid ? "text.secondary" : "error.main",
        }}
      />
      <Typography sx={{ color: isValid ? "text.secondary" : "error.main" }}>{title}</Typography>
    </Box>
  );
}

function PasswordField({
  label,
  placeholder,
  onClick,
  isVisible,
  hasError,
  onChange,
  value,
}: {
  label: string;
  placeholder: string;
  onClick: () => void;
  isVisible: boolean;
  hasError: boolean;
  onChange: (value: string) => void;
  value: string;
}) {
  return (
    <TextField
      sx={{
        width: "100%",
        "& label.Mui-focused": {
          color: hasError ? "error.main" : "text.primary",
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: typography.fontFamily,
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: hasError ? "error.main" : "text.primary",
        },
      }}
      label={label}
      variant="standard"
      type={isVisible ? "text" : "password"}
      focused
      error={hasError}
      placeholder={placeholder}
      InputProps={{
        style: { fontSize: "16px", padding: "6px" },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={onClick} edge="end">
              {isVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(e) => onChange(e.target.value)}
      value={value}
    />
  );
}
