import { Box } from "@mui/material";
import { ClaimWithCheck } from "@/contexts/MessagesContext";
import React from "react";
import { ClaimCard } from "../ClaimCard";

interface ClaimsProps {
  claims: ClaimWithCheck[];
  onCheck?: (id: string, checked: boolean) => void;
  highlight?: string;
}

export const Claims = ({ claims, onCheck, highlight }: ClaimsProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {claims.map((claim) => (
        <ClaimCard
          key={claim.claim_id}
          onCheck={(checked) => onCheck && onCheck(claim.claim_id, checked)}
          highlight={highlight}
          {...claim}
        />
      ))}
    </Box>
  );
};
