import { FlowOutput } from "@/contexts/MessagesContext";
import { v4 as uuidV4 } from "uuid";
import { UserInput, UserInputSource } from "@/contexts/WebSocketContext";

export const userInputToFlowOutput = (input?: UserInput): FlowOutput => {
  switch (input?.source) {
    case UserInputSource.CONTENT: {
      return {
        source: UserInputSource.CONTENT,
      };
    }
    case UserInputSource.TEXT: {
      return {
        source: UserInputSource.TEXT,
        text: input.text,
      };
    }
    case UserInputSource.FILE: {
      return {
        source: UserInputSource.FILE,
        file: {
          id: uuidV4(),
          name: input.file.name,
          file: input.file,
        },
      };
    }
    default: {
      return {
        source: "NONE",
      };
    }
  }
};
