import "firebase/compat/auth";
import { LoginWithEmailAndPassword } from "./hooks/useLogin/types";
import { User as FirebaseUser } from "firebase/auth";

export interface AuthContextData {
  user?: LoggedInUserData;
  loginType?: LoginType;
  logout: () => void;
  loading: boolean;
  loginWithEmailAndPassword: LoginWithEmailAndPassword;
  loginWithOffice: () => Promise<{ success: boolean; status?: number }>;
  loginWithGoogle: () => Promise<{
    success: boolean;
    needSignUp?: { user?: FirebaseUser };
  }>;
  setupFirebaseUser: (firebaseUser?: FirebaseUser | null, loginType?: string) => Promise<void>;
}

export enum LoginType {
  FIREBASE = "firebase",
}

interface AcceptedTermsOfUseDTO {
  version: string;
  date: string;
}

export interface LoggedInUserData {
  userId: string;
  companyId: string;
  userName: string;
  userEmail?: string;
  userAcceptedTermsOfUse: AcceptedTermsOfUseDTO[];
  isAdmin: boolean;
  profileCompleted?: boolean;
}

export interface CompanyData {
  companyId: string;
  name: string;
  corporateName: string;
  cnpj?: string;
  cpf?: string;
  type: string;
  activityAreas?: string[];
  university?: string;
  signUpUtm?: string | unknown;
  gclid?: string;
  numberOfEmployees?: string;
  addresses: string[] | unknown;
  comments?: string;
  products: string[];
  hybridDocumentTypes: string[];
  automatedDocumentTypes: string[];
  loginConfig: string[];
  isActive: boolean;
  serviceLevelAgreement: string;
  samlProviderName?: string;
  externalCustomerId?: string;
  signUpProvider?: string;
}

export enum UpdatePasswordErrors {
  "INVALID_PASSWORD" = "INVALID_PASSOWRD",
  "GENERIC" = "GENERIC",
}
