/* eslint-disable */
import { ActionId, FlowOutput, useMessagesContext } from "@/contexts/MessagesContext";
import { ConnectionState, SkillsPayload } from "@/contexts/WebSocketContext";
import * as logger from "@/core/logger";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { FlowInput } from "@/taskpane/components/Chat/FlowInput";
import { InternalPageStructure } from "@/taskpane/components/core/InternalPageStructure";
import { WebToast } from "@/taskpane/components/core/Toast";
import { flowActionToText } from "@/utils/flowActionToText";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import { flowOutputToUserInput } from "./flowOutputToUserInput";

export const PetitionSummary = () => {
  const { sendMessage, threadConnectionState, connectToThread, startSkillProcess } = useMessagesContext();
  const navigate = useNavigate();
  const { documentSummaryAsSkillEnabled } = useFeatureFlags();

  useEffect(() => {
    if (threadConnectionState === ConnectionState.CLOSED) {
      connectToThread();
    }
  }, []);

  const [error, setError] = React.useState<string | null>(null);
  const [output, setOutput] = React.useState<FlowOutput | null>(null);

  const description = outsideOfficeClient()
    ? "Resuma peças e decisões judiciais."
    : "Resuma peças e decisões judiciais. Ao finalizar o resumo, você receberá uma mensagem com o botão para abri-lo como um novo documento.";

  const dismissError = React.useCallback(() => {
    setError(null);
  }, []);

  const onChange = React.useCallback((input: FlowOutput | null) => {
    setOutput(input);
    dismissError();
  }, []);

  const onSubmit = React.useCallback(() => {
    if (!output) {
      setError("O documento precisa estar definido. Um documento em branco ou sem informação não pode ser resumido.");
    }

    logger.info("Submitting petition summary message");

    if (output) {
      const actionText = `Criar um resumo com os dados informados ${flowActionToText(output)}`;
      sendMessage({
        id: uuidV4(),
        type: "FLOW",
        direction: "SENT",
        author: "Current User",
        date: DateTime.now(),
        status: "READ",
        text: actionText,
        actions: [
          {
            id: ActionId.CREATE_PETITION_SUMMARY,
            petition: output,
            text: actionText,
          },
        ],
        files: output.file
          ? [
              {
                id: output.file.id,
                type: "UPLOADING",
                file: output.file.file,
                name: output.file.name,
              },
            ]
          : [],
      });

      navigate(ROUTE_PATHS.CHAT);
    }
  }, [output]);

  const onSubmitSkillsApi = React.useCallback(async () => {
    const proceduralDocument = flowOutputToUserInput(output);
    if (!output || !proceduralDocument) {
      throw new Error(
        "O documento precisa estar definido. Um documento em branco ou sem informação não pode ser resumido."
      );
    }

    logger.info("Submitting petition summary skill");

    try {
      const newRequestId = uuidV4();

      const skillPayload: SkillsPayload = {
        skillId: ActionId.SUMMARIZE_DOCUMENT,
        requestId: newRequestId,
        payload: {
          procedural_document: proceduralDocument,
        },
        messageToSave: `Criar um resumo com os dados informados ${flowActionToText(output)}`,
      };

      startSkillProcess(skillPayload);
      navigate(ROUTE_PATHS.CHAT);
    } catch (e) {
      WebToast.error("Erro ao criar resumo. Tente novamente mais tarde.");
    }
  }, [output]);

  return (
    <InternalPageStructure
      onSubmit={documentSummaryAsSkillEnabled ? onSubmitSkillsApi : onSubmit}
      error={error}
      dismissError={dismissError}
      submitButtonText={threadConnectionState !== ConnectionState.OPEN ? "Carregando..." : "Resumir Documento"}
      submitButtonDisabled={threadConnectionState !== ConnectionState.OPEN || !output}
    >
      <Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="preTitle" sx={{ textWrap: "wrap" }}>
            RESUMIR DOCUMENTO PROCESSUAL
          </Typography>
        </Box>

        <Box>
          <Typography variant="multiLineBody">{description}</Typography>
        </Box>

        <Box sx={{ mt: 3, mb: 2 }}>
          <Typography variant="preTitle" sx={{ textWrap: "wrap" }}>
            Escolha de onde os dados serão extraídos:
          </Typography>
        </Box>

        <Box>
          <FlowInput onChange={onChange} activeOptions={["CONTENT", "FILE"]} />
        </Box>
      </Box>
    </InternalPageStructure>
  );
};
