import { EditorContentField } from "@/components/SkillStepsForm/fields/editorContentField";
import { BaseInputTemplate } from "@/components/SkillStepsForm/templates/baseInputTemplate";
import { FieldTemplate } from "@/components/SkillStepsForm/templates/fieldTemplate";
import { ObjectFieldTemplate } from "@/components/SkillStepsForm/templates/objectFieldTemplate";
import { FileWidget } from "@/components/SkillStepsForm/widgets/fileWidget";
import RadioWidget from "@/components/SkillStepsForm/widgets/radioWidget";
import SelectWidget from "@/components/SkillStepsForm/widgets/selectWidget";
import TextareaWidget from "@/components/SkillStepsForm/widgets/textareaWidget";
import { ConditionalSkillStep, SkillStep } from "@/core/skillSteps/types";
import { useEditor } from "@/hooks/useEditor";
import { Editor } from "@/hooks/useEditor/types";
import { Stepper } from "@/taskpane/components/core/Stepper";
import { Box, Button } from "@mui/material";
import { IChangeEvent } from "@rjsf/core";
import Form from "@rjsf/mui";
import { RJSFValidationError } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import brazilianLocalizer from "ajv-i18n/localize/pt-BR";
import { ErrorObject } from "ajv";
import React, { useMemo, useState } from "react";

type FormData = Record<string, string>;

const validator = customizeValidator({}, (errors) => {
  const rest: ErrorObject[] = [];
  for (const error of errors ?? []) {
    if (error.keyword === "required") {
      error.message = "deve ter a propriedade obrigatória";
      continue;
    }
    rest.push(error);
  }
  brazilianLocalizer(rest);
});

const parseFormData = async ({ formData }: { formData: Record<string, string>; editor?: Editor }) => {
  const parsedFormData: Record<string, string> = {};
  for (const [key, value] of Object.entries(formData)) {
    if (key.includes("override:")) {
      const [, , keyWithoutFreeText] = key.split(":");
      parsedFormData[keyWithoutFreeText] = value;
      continue;
    }

    parsedFormData[key] = formData[key];
  }
  return parsedFormData;
};

const evaluateStep = ({ steps, formData }: { steps: ConditionalSkillStep[]; formData: FormData }) => {
  for (const step of steps) {
    const conditions = Array.isArray(step.condition) ? step.condition : [step.condition];
    const conditionApply = conditions.every(
      (condition) => condition.field in formData && formData[condition.field] === condition.value
    );
    if (conditionApply) {
      return step;
    }
  }
  return undefined;
};

export const SkillStepsForm = ({
  steps,
  currentStepIndex,
  onNextStep,
  onSubmit,
}: {
  steps: Array<SkillStep | ConditionalSkillStep[]>;
  currentStepIndex: number;
  onNextStep: () => void;
  onSubmit: (data: FormData) => void;
}) => {
  const { editor } = useEditor();
  const [finalFormData, setFinalFormData] = useState<FormData>({});
  const [rawStepFormData, setRawStepFormData] = useState<FormData[]>([]);

  const currentStep = useMemo(() => {
    const step = steps?.[currentStepIndex];
    return Array.isArray(step)
      ? evaluateStep({
          steps: step,
          formData: rawStepFormData.reduce((acc, stepData) => ({ ...acc, ...stepData }), {}),
        })
      : step;
  }, [steps, currentStepIndex, rawStepFormData]);

  const onSubmitHandler = async (data: IChangeEvent) => {
    setRawStepFormData((rawStepFormData) => {
      rawStepFormData[currentStepIndex] = data.formData;
      return rawStepFormData;
    });
    const parsedFormData = await parseFormData({ formData: data.formData, editor });
    setFinalFormData((currentFormaData) => ({ ...currentFormaData, ...parsedFormData }));
    if (currentStepIndex < steps.length - 1) {
      onNextStep();
    } else {
      onSubmit({ ...finalFormData, ...parsedFormData });
    }
  };

  const transformErrors = (errors: RJSFValidationError[]) => {
    return errors.map((error) => {
      return { ...error, message: error.message?.replace(/override:([\w:_]+)/g, "") };
    });
  };

  if (!currentStep) return null;

  return (
    <Box
      component={Form}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 4,
        justifyContent: "space-between",
      }}
      schema={currentStep.schema}
      uiSchema={currentStep.uiSchema}
      formData={rawStepFormData[currentStepIndex] ?? currentStep.initialData}
      validator={validator}
      onSubmit={onSubmitHandler}
      fields={{
        EditorContentField,
      }}
      templates={{
        ObjectFieldTemplate,
        FieldTemplate,
        BaseInputTemplate,
      }}
      widgets={{
        SelectWidget,
        TextareaWidget,
        RadioWidget,
        FileWidget,
      }}
      showErrorList={false}
      transformErrors={transformErrors}
      noHtml5Validate
    >
      <Box>
        {steps.length > 1 && (
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Stepper steps={steps.length} activeStep={currentStepIndex} sx={{ pb: "16px" }} />
          </Box>
        )}
        <Button type="submit" variant="contained" sx={{ width: "100%" }}>
          {currentStep.submitButtonText ?? "Continuar"}
        </Button>
      </Box>
    </Box>
  );
};
