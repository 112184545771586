import { SkillId } from "@/core/skills/types";

export const defaultFlagValues = {
  /** Habilita a criação de novas peças */
  newPieces: true,
  /** Habilita e passa o link do vídeo de introdução */
  tutorialVideoUrl: "",
  /** Habilita o novo fluxo de cadastro dentro do add in. Desabilitado mantém o link web do cadastro.lexter. */
  addInSignUpEnabled: true,
  /** Habilita o botão de cancelar no componente de carregamento do chat*/
  cancelButtonEnabled: true,
  /** Exibe os planos dentro do add-in ao invés de redirecionar para o link externo */
  plansInsideAddin: true,
  /** Habilita o alerta de documento read only */
  warnReadOnlyDocument: false,
  /** JSON com os textos do carrossel de login e signup */
  loginCarouselTexts: {
    slides: [],
  },
  /** Url do WhatsApp para iniciar uma conversa com CS */
  customerServiceWhatsApp: "",
  /** Habilita o login e cadastro com google */
  googleAuthEnabled: true,
  /** Habilita o ícone de notificações no header web */
  headerNotificationsEnabled: false,
  /** Habilita as opções de recurso nas áreas: previdenciário, tributário, criminal e outros */
  showAgnosticAppeal: true,
  /** Mensagem exibida enquanto o documento está sendo criado */
  creatingDocumentWaitingMessage: "Gerando a peça solicitada. Esse processo pode levar alguns minutos.",
  /** Mensagem exibida enquanto cria um documento que não é uma peça */
  creatingGenericDocumentWaitingMessage: "Gerando o documento solicitado. Esse processo pode levar alguns minutos.",
  /** Mensagem exibida enquanto o documento de resumo está sendo criado */
  creatingSummaryWaitingMessage: "Gerando o resumo solicitado. Esse processo pode levar alguns minutos.",
  /** Mensagem exibida enquanto uma interação com o chat está em progresso */
  interactingWithChatWaitingMessage: "Só um momento, já estou trabalhando na resposta de sua interação.",
  /** Mensagem exibida enquanto as teses estão sendo extraídas */
  extractingClaimsWaitingMessage: "Ok! Analisando dados enviados, isso pode levar alguns minutos.",
  /** Mensagem exibida enquanto as teses estão sendo selecionadas */
  selectingClaimsWaitingMessage: "Ok! Analisando dados enviados, isso pode levar alguns minutos.",
  /** Mensagem genérica exibida durante o processamento */
  genericWaitingMessage: "Ok! Estamos executando sua solicitação, isso pode levar alguns instantes.",
  /** Habilita modal de threads */
  isThreadsEnabled: true,
  /** Habilita a nova tela inicial */
  newHomeEnabled: false,
  /** Habilita o banner de uso de creditos */
  creditsUsageBanner: false,
  /** Remove o botão de "+" do campo de input e leva como um botão com o texto de "Nova ação +" no footer do chat */
  newActionButtonInFooter: true,
  /** Oculta barra do chat, a qual só é aberta pelo menu de ações */
  chatAsSkill: true,
  /** Habilita a funcionalidade do onboarding */
  onboardingModalEnabled: false,
  /** Habilita skill de provas e documentos */
  createEvidenceAndRequiredDocumentsEnabled: false,
  /** Habilita skill roteiro de entrevista */
  interviewScriptEnabled: false,
  /** Habilita skill elaborar estratégia */
  createStrategyEnabled: false,
  /** Habilita skill roteiro para audiências */
  hearingsScriptEnabled: false,
  /** Habilita skill petição intercorrente */
  intercurrentMotionEnabled: false,
  /** Habilita skill contrato de honorários */
  feeAgreementEnabled: false,
  /** Ativa o botão de skill de Elaboração de Contratos */
  contractCreationEnabled: false,
  /** Habilita a funcionalidade de buscar jurisprudência */
  jurisprudenceSearch: false,
  /** Ativa o botão de skill de Notificação extrajudicial */
  extrajudicialNoticeEnabled: false,
  /** Permite a demonstração de interesse por uma futura funcionalidade */
  comingSoonButtonEnabled: false,
  /** Habilita botão da skill de editor no menu de ações */
  editorAsSkill: false,
  /** Habilita a skill de dúvidas jurídicas */
  legalQuestionEnabled: false,
  /** Habilita a formatação de respostas do chat em markdown */
  markdownChatFormattingEnabled: false,
  /** Habilita a skill de resumo de peças no novo endpoint de skills */
  documentSummaryAsSkillEnabled: false,
  /** Habilita as páginas internas de pagamento */
  internalPaymentPageEnabled: false,
  /** Habilita o redirecionamneto automtica para o portal de pagamento do usuario  */
  paymentUserPortalEnabled: false,
  /** Habilita a skill de elaboração de parecer jurídico */
  legalAdviceEnabled: false,
  /** Habilita a skill de elaboração de proposta de acordo */
  settlementOfferEnabled: false,
  /** Habilita as skills que devem seguir o novo fluxo */
  newSkillsFlow: [] as SkillId[],
  /** Habilita o Banner de Encerrar Cancelamento do Plano  */
  restorePlan: {
    enabled: false,
  } as {
    enabled: boolean;
    couponCode?: string;
    text?: string;
  },
  /** Habilita sugestão de skills específicas para cada fluxo definido */
  suggestedSkillsFlowEnabled: false,
  /** Habilita novo card da ação sugerida com valores (créditos) */
  newActionCardWithCreditAmountEnabled: false,
};
