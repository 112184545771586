import ArrowBack from "@mui/icons-material/ArrowBack";
import Alert from "@mui/material/Alert";
import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import { Stepper } from "../Stepper";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";

export interface InternalPageStructureProps {
  goBack?: () => unknown;
  onSubmit: () => unknown;
  error?: string | null;
  dismissError?: () => unknown;
  submitButtonText: string;
  goBackButtonText?: string;
  children: React.ReactNode;
  submitButtonDisabled?: boolean;
  onSecondaryAction?: {
    text: string;
    onClick: () => unknown;
    disabled?: boolean;
  };
  totalSteps?: number;
  currentStep?: number;
  title?: string;
  hidden?: boolean;
  contentBoxProps?: BoxProps;
  showSubmitButton?: boolean;
}

export function InternalPageStructure(props: InternalPageStructureProps) {
  const {
    goBack,
    onSubmit,
    error,
    dismissError,
    submitButtonText,
    goBackButtonText,
    submitButtonDisabled,
    children,
    contentBoxProps,
    title,
    hidden,
    showSubmitButton = true,
  } = props;

  const hasFinalContent = Boolean(
    (props.totalSteps && props.totalSteps > 1) || showSubmitButton || props.onSecondaryAction
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: hidden ? "none" : "grid",
        gridTemplateRows: "min-content minmax(0, 1fr) min-content",
      }}
    >
      <InternalPageHeader goBack={goBack} goBackButtonText={goBackButtonText} />

      <Box
        {...contentBoxProps}
        sx={{
          height: "100%",
          overflowY: "auto",
          pt: 3,
          pl: 2,
          pr: 2,
          pb: 3,
          ...contentBoxProps?.sx,
        }}
      >
        {title && (
          <Box sx={{ mb: 1, pb: 1 }}>
            <Typography variant="preTitle" color={"text.primary"}>
              {title}
            </Typography>
          </Box>
        )}

        {children}
      </Box>

      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          px: "16px",
        }}
      >
        {error ? (
          <Box sx={{ py: 2, width: "100%" }}>
            <Alert onClose={dismissError} severity="error">
              {error}
            </Alert>
          </Box>
        ) : (
          <Box
            sx={{
              py: hasFinalContent ? 2 : 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {props.totalSteps && props.totalSteps > 1 ? (
              <Stepper steps={props.totalSteps} activeStep={props.currentStep} sx={{ pb: "16px" }} />
            ) : null}

            {showSubmitButton && (
              <Button
                variant="contained"
                onClick={onSubmit}
                id="form_submit_button"
                disabled={Boolean(submitButtonDisabled)}
                sx={{ width: "100%" }}
              >
                {submitButtonText}
              </Button>
            )}
            {props.onSecondaryAction ? (
              <Button
                onClick={props.onSecondaryAction.onClick}
                disabled={Boolean(props.onSecondaryAction.disabled)}
                sx={{
                  mt: 2,
                  color: "text.primary",
                }}
              >
                {props.onSecondaryAction.text}
              </Button>
            ) : null}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export function InternalPageHeader({
  goBack,
  goBackButtonText,
  hideOutsideOffice,
}: {
  goBack?: () => void;
  goBackButtonText?: string;
  hideOutsideOffice?: boolean;
}) {
  const navigate = useNavigate();
  const isOutsideOfficeClient = outsideOfficeClient();

  if (isOutsideOfficeClient && hideOutsideOffice) return <></>;
  return (
    <Box
      sx={{
        height: "48px",
        backgroundColor: "common.white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        pl: 2,
        pr: 2,
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <Button
        onClick={goBack || (() => navigate(-1))}
        startIcon={<ArrowBack />}
        size="small"
        sx={{
          color: "text.primary",
          alignSelf: "flex-start",
        }}
      >
        <Typography variant="button">{goBackButtonText || "Voltar"}</Typography>
      </Button>
    </Box>
  );
}
