import React from "react";
import { OnboardingContext } from "./context";
import { Onboarding, OnboardingData } from "@/components/Onboarding";
import { OnboardingProps } from "./types";
import { useAuthContext } from "@/contexts/AuthContext";
import { Toast } from "@/taskpane/components/core/Toast";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { LexterApiService } from "@/services/lexterApi";
import { AuthService } from "@/services/auth";

export const OnboardingProvider = ({ children }: { children: React.JSX.Element }) => {
  const [modalProps, setModalProps] = React.useState<OnboardingProps>();
  const { user } = useAuthContext();
  const { onboardingModalEnabled } = useFeatureFlags();
  const openOnboarding = (params: OnboardingProps) => {
    setModalProps(params);
  };

  const handleSubmit = async (data: OnboardingData) => {
    try {
      if (!user) throw new Error("User not found");
      const token = await AuthService.getAuthToken();
      if (!token) throw new Error("Token not found");

      const activityAreas = data.activityAreas.filter((area) => area.selected).map((area) => area.value);
      const activitiesDaily = data.activitiesDaily
        .filter((activity) => activity.selected)
        .map((activity) => activity.value);
      const numberOfMotionsPerWeek = data.numberOfMotionsPerWeek;
      const officeNumberOfEmployees = data.userOffice.numberOfEmployees;
      const officeName = data.userOffice.name;
      const officeState = data.userOffice.state;
      const haveOffice = officeName !== "" || officeState !== "" || officeNumberOfEmployees !== "";

      await LexterApiService.completeProfile({
        userId: user?.userId,
        companyId: user?.companyId,
        token: token.token!,
        office: haveOffice
          ? {
              name: officeName ?? undefined,
              numberOfEmployees: officeNumberOfEmployees ?? undefined,
              state: officeState ?? undefined,
            }
          : undefined,
        activityAreas: activityAreas.length > 0 ? activityAreas : undefined,
        activitiesDaily: activitiesDaily.length > 0 ? activitiesDaily : undefined,
        numberOfMotionsPerWeek: numberOfMotionsPerWeek === "" ? undefined : numberOfMotionsPerWeek,
        sourceOfReferral: data.sourceOfReferral.find((source) => source.selected)?.value,
      });

      if (window.userGuiding) {
        window.userGuiding.identify(user.userId, { perfilCompleto: true });
      }

      const hasOffice = officeName !== "" && officeState !== "" && officeNumberOfEmployees !== "";
      const hasSourceOfReferral = data.sourceOfReferral.find((source) => source.selected);
      if (
        hasOffice &&
        activitiesDaily.length > 0 &&
        activityAreas.length > 0 &&
        numberOfMotionsPerWeek !== "" &&
        !!hasSourceOfReferral
      ) {
        user.profileCompleted = true;
        Toast.success("Perfil completado com sucesso");
      }
    } catch (error) {
      Toast.error("Erro ao completar o perfil");
    }
  };

  const handleClose = () => {
    setModalProps(undefined);
  };

  return (
    <OnboardingContext.Provider
      value={{
        openOnboarding,
      }}
    >
      {children}

      <Onboarding
        open={!!modalProps && onboardingModalEnabled}
        onSubmit={handleSubmit}
        onClose={handleClose}
        firstTimeInOnboarding={modalProps?.firstTimeInOnboarding}
      />
    </OnboardingContext.Provider>
  );
};
