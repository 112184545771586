import {
  ExpandLessRounded as ExpandLessRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
} from "@mui/icons-material";
import { Box, Checkbox, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { ClaimWithCheck } from "@/contexts/MessagesContext";
import React from "react";

interface ClaimCardProps extends ClaimWithCheck {
  onCheck?: (checked: boolean) => void;
  highlight?: string;
}

export const ClaimCard = ({ txt_title, document_name, txt_summary, checked, onCheck, highlight }: ClaimCardProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const ExpandIcon = isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon;

  const highlightText = (text: string, highlightTerm?: string) => {
    if (!highlightTerm?.trim()) {
      return <>{text}</>;
    }
    const parts = text.split(new RegExp(`(${highlightTerm})`, "gi"));
    return (
      <>
        {parts.map((part, i) =>
          part.toLowerCase() === highlightTerm.toLowerCase() ? <mark key={i}>{part}</mark> : part
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        borderColor: "divider",
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "min-content minmax(0, 1fr) min-content",
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        <Checkbox checked={checked} onChange={(event) => onCheck && onCheck(event.target.checked)} />

        <Box
          sx={{
            my: 1,
          }}
        >
          <Typography
            variant="multiLineBody"
            sx={{
              display: "block",
              mb: 1,
            }}
          >
            <strong>Título:</strong>
            <br />
            {highlightText(txt_title, highlight)}
          </Typography>

          <Typography
            variant="multiLineBody"
            sx={{
              display: "block",
            }}
          >
            <strong>Documento:</strong>
            <br />
            {highlightText(document_name, highlight)}
          </Typography>
        </Box>

        <IconButton onClick={() => setIsExpanded((prev) => !prev)}>
          <ExpandIcon fontSize="medium" />
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Divider
          sx={{
            mt: 1,
          }}
        />

        <Typography
          variant="multiLineBody"
          sx={{
            display: "block",
            p: 2,
          }}
        >
          <strong>Resumo:</strong>
          <br />
          {highlightText(txt_summary, highlight)}
        </Typography>
      </Collapse>
    </Box>
  );
};
