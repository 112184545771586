import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Link, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { Checkbox } from "@/components/Checkbox";
import { PasswordValidationInput } from "@/components/PasswordValidationInput";
import { TextField } from "@/components/TextField";
import { Layout } from "@/pages/Signup/Layout";
import React, { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { EXTERNAL_URLS } from "@/routes/routePaths";
import { ZodError } from "zod";
import { PasswordErrors, PasswordFormData, passwordErrorsDefault, passwordSchema } from "./types";
import { useSignUpContext } from "../../context";

export const PasswordForm = () => {
  const { submitData, isLoading, previousStep } = useSignUpContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordFormData>({
    resolver: zodResolver(passwordSchema),
  });
  const [passwordErrors, setPasswordErrors] = useState<PasswordErrors>(passwordErrorsDefault);

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newPasswordErrors = passwordErrorsDefault;

    try {
      passwordSchema.parse({
        password: event.currentTarget.value,
      });
    } catch (error) {
      const passwordIssues = (error as ZodError).issues.filter((issue) => issue.path[0] === "password");
      const passwordIssuesByMessage = passwordIssues.reduce(
        (acc, issue) => ({ ...acc, [issue.message]: true }),
        {} as PasswordErrors
      );

      newPasswordErrors = {
        ...newPasswordErrors,
        ...passwordIssuesByMessage,
      };
    }

    setPasswordErrors(newPasswordErrors);
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          overflow: "auto",
        }}
        component="form"
        onSubmit={handleSubmit(submitData)}
      >
        <IconButton
          onClick={previousStep}
          aria-label="voltar"
          size="medium"
          sx={{
            color: "common.white",
            width: "min-content",
            margin: -1,
          }}
        >
          <ArrowBack />
        </IconButton>

        <PasswordValidationInput
          validations={[
            { text: "uma letra", valid: !passwordErrors.letter },
            { text: "um número ou caractere especial", valid: !passwordErrors.number_or_special },
            { text: "mínimo de 8 caracteres", valid: !passwordErrors.min },
          ]}
          label="Senha"
          placeholder="Crie uma senha para acesso"
          type="password"
          {...register("password")}
          onChange={handlePasswordChange}
          error={!!errors.password || passwordErrors.max}
          helperText={passwordErrors.max ? "Máximo de 200 caracteres." : undefined}
        />

        <TextField
          label="Confirmar senha"
          placeholder="Confirme sua senha de acesso"
          type="password"
          {...register("confirmPassword")}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword?.message}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <Typography variant="label" color="common.white">
            Política de privacidade
          </Typography>

          <Checkbox
            {...register("terms")}
            error={!!errors.terms}
            helperText={errors.terms?.message}
            label={
              <Typography variant="multiLineBody" color="common.white">
                Estou de acordo com a{" "}
                <Link href={EXTERNAL_URLS.PRIVACY_POLICY} target="_blank">
                  Política de Privacidade
                </Link>{" "}
                da Lexter.Ai.
              </Typography>
            }
          />
        </Box>

        <Button type="submit" variant="contained" fullWidth isLoading={isLoading}>
          Cadastrar
        </Button>
      </Box>
    </Layout>
  );
};
