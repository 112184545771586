import { Box, Typography } from "@mui/material";
import { Button } from "@/components/Button";

import React from "react";

import SaleSvg from "@/assets/svgs/sale.svg?react";
import { useCreditsContext } from "@/contexts/CreditsContext";
import { useApi } from "@/hooks/useApi";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useAuthContext } from "@/contexts/AuthContext";
import { Toast } from "@/taskpane/components/core/Toast";
import { logger } from "@/core/logger";

export const RestorePlanBanner = () => {
  const { cancellationAtFormatted, refreshCredits } = useCreditsContext();
  const { user } = useAuthContext();
  const { restorePlan: restorePlanFeatureFlag } = useFeatureFlags();
  const { restorePlan } = useApi();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handleEndCancellation = async () => {
    setLoading(true);
    try {
      if (!user?.companyId) throw new Error("Company not found");

      await restorePlan({
        companyId: user.companyId,
        couponCode: restorePlanFeatureFlag.couponCode,
      });

      setLoading(false);
      refreshCredits();
      setSuccess(true);
      Toast.success("Plano renovado com sucesso!");
    } catch (err) {
      logger.error("handleEndCancellation", err);
      Toast.error("Ocorreu um erro ao encerrar o cancelamento. Tente novamente mais tarde.");
      setLoading(false);
    }
  };

  if (!cancellationAtFormatted || !restorePlanFeatureFlag.enabled || success) return null;

  return (
    <Box
      sx={{
        backgroundColor: "secondary.main",
        padding: "8px 16px 8px 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      <Typography
        color="common.black"
        sx={{
          display: "flex",
          gap: 1,
          py: 0.25,
        }}
      >
        <SaleSvg
          style={{
            margin: "auto 0 auto 0",
          }}
        />
        <Typography
          sx={{
            fontWeight: 700,
            height: "20px",
            "@media (max-width: 960px)": {
              height: "auto",
            },
          }}
          color="common.black"
        >
          {restorePlanFeatureFlag.text}
        </Typography>
      </Typography>
      <Button
        sx={{
          width: "max-content",
          borderRadius: "20px",
          lineHeight: "22px",
          paddingY: 0.5,
          alignSelf: "center",
        }}
        variant="contained"
        onClick={handleEndCancellation}
        isLoading={loading}
        loaderStyle={{ maxWidth: "233px", borderRadius: "20px", height: "30px" }}
        loadingColor="common.backdrop"
        bgcolor="common.black"
      >
        Encerrar cancelamento
      </Button>
    </Box>
  );
};
