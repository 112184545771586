import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import * as React from "react";
import { v4 as uuidV4 } from "uuid";

import { FlowOutput, InMessageFile, Message } from "@/contexts/MessagesContext";
import { flowActionToText } from "@/utils/flowActionToText";
import * as logger from "../../../../core/logger";
import { InternalPageStructure } from "../../core/InternalPageStructure";
import { FileInput, FlowInput } from "../FlowInput";

export function DefenseStrategy(props: { goBack: () => void; sendMessage: (message: Message) => void }) {
  const { goBack, sendMessage } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [defenseFacts, setDefenseFacts] = React.useState<FlowOutput | null>(null);
  const [claimDocument, setClaimDocument] = React.useState<FlowOutput | null>(null);

  const dismissError = React.useCallback(() => {
    setError(null);
  }, []);

  const onClaimDocumentChange = React.useCallback((files: File[]) => {
    setClaimDocument({
      source: "FILE",
      file: { id: uuidV4(), file: files[0], name: files[0].name },
    });
    dismissError();
  }, []);

  const onDefenseFactsChange = React.useCallback((input: FlowOutput | null) => {
    setDefenseFacts(input);
    dismissError();
  }, []);

  const onSubmit = React.useCallback(() => {
    if (!defenseFacts || !claimDocument) {
      setError(
        "É preciso submeter uma petição inicial à ser contestada e escolher alguma das opções de contestação dos fatos."
      );
      return;
    }

    if (claimDocument.source !== "FILE") {
      setError("É preciso submeter um arquivo de petição inicial à ser contestada.");
      return;
    }

    logger.info("Submitting defense strategy message");

    if (defenseFacts && claimDocument.file) {
      const actionText = `Criar uma estratégia de contestação para o arquivo ${
        claimDocument.file.name
      } com os fatos ${flowActionToText(defenseFacts)}`;

      const files: InMessageFile[] = [
        {
          id: claimDocument.file.id,
          type: "UPLOADING",
          file: claimDocument.file.file,
          name: claimDocument.file.name,
        },
      ];

      if (defenseFacts.file) {
        files.push({
          id: defenseFacts.file.id,
          type: "UPLOADING",
          file: defenseFacts.file.file,
          name: defenseFacts.file.name,
        });
      }

      sendMessage({
        id: uuidV4(),
        type: "FLOW",
        direction: "SENT",
        author: "Current User",
        date: DateTime.now(),
        status: "READ",
        text: actionText,
        actions: [
          {
            id: "CREATE_CONTESTATION_STRATEGY",
            initialPetition: claimDocument,
            userFacts: defenseFacts.source !== "NONE" ? defenseFacts : undefined,
            text: actionText,
          },
        ],
        files,
      });

      goBack();
    }
  }, [defenseFacts, claimDocument]);

  return (
    <InternalPageStructure
      goBack={goBack}
      onSubmit={onSubmit}
      error={error}
      dismissError={dismissError}
      submitButtonText="criar estratégia de contestação"
      submitButtonDisabled={!defenseFacts}
    >
      <Box>
        <Box sx={{ mb: 1 }}>
          <Typography variant="preTitle" color={"text.primary"}>
            CRIAR ESTRATÉGIA DE CONTESTAÇÃO
          </Typography>
        </Box>

        <Box>
          <Typography variant="multiLineBody" color={"text.primary"}>
            Para criarmos uma estratégia de contestação, precisamos que você faça o upload da petição inicial e que,
            utilizando uma das opções disponíveis, submeta a versão resumida dos fatos e alegações do seu cliente (Réu)
            para refutar a narrativa trazida pelo(s) Autor(es) na Petição Inicial.
          </Typography>
        </Box>

        <Box sx={{ mt: 1 }}>
          <Typography variant="bold" color={"text.primary"}>
            ATENÇÃO:
          </Typography>

          <Typography variant="multiLineBody" color={"text.primary"} sx={{ ml: 1 }}>
            Quando a contestação for criada ela substituirá o conteúdo do documento aberto.
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="multiLineBody" color={"text.primary"}>
            Para começar, faça o upload do arquivo da petição inicial do caso:
          </Typography>
        </Box>

        <FileInput onChange={onClaimDocumentChange} />

        <Box sx={{ mt: 3 }}>
          <Typography variant="multiLineBody" color={"text.primary"}>
            Agora escolha uma das opções abaixo para enviar a versão resumida dos fatos e alegações do seu cliente (Réu)
            para refutar a narrativa trazida pelo(s) Autor(es) na Petição Inicial.
          </Typography>
        </Box>

        <Box>
          <FlowInput onChange={onDefenseFactsChange} activeOptions={["CONTENT", "FILE", "TEXT", "NONE"]} />
        </Box>
      </Box>
    </InternalPageStructure>
  );
}
